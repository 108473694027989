<template>
  <BaseDialog
    v-model="model"
    v-bind="$attrs"
    :persistent="true"
    :max-width="500"
    :min-height="350"
    :max-height="350"
    icon="mdi-calendar-multiple"
  >
    <v-container class="ma-0 pa-0" fluid>
      <v-row>
        <v-col>
          {{ dialogText }}
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="store.mode === ManageSlotsMode.Create">
          {{ store.percentComplete }}%
          <v-progress-linear
            color="primary"
            height="10"
            v-model:model-value="store.percentComplete"
            :striped="store.status === ManageSlotsStatus.InProgress"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>

    <template v-slot:actions>
      <ButtonSecondary
        v-if="store.status !== ManageSlotsStatus.Complete"
        @click="cancelEdit"
        :text="cancelButtonText"
      />
      <v-spacer />
      <ButtonPrimary
        v-if="store.status !== ManageSlotsStatus.Complete"
        @click="createOrClearEvents"
        type="submit"
        :loading="
          store.status === ManageSlotsStatus.InProgress || eventsStore.workStatus.deleteMany
        "
        :text="saveButtonText"
      />
      <ButtonPrimary
        v-if="store.status === ManageSlotsStatus.Complete"
        @click="closeDialog"
        type="submit"
        :text="t('buttons.done')"
      />
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { TimeValue } from '@/classes/TimeValue'
import { ManageSlotsMode } from '@/enums/ManageSlotsMode'
import { ManageSlotsStatus } from '@/enums/ManageSlotsStatus'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSlotsStore } from '@/stores/ui/SlotsStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const calendarStore = useCalendarStore()
const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
const store = useSlotsStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const model = defineModel<boolean>({ default: false })

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const dialogText = computed(() => {
  if (store.mode === ManageSlotsMode.Clear) {
    const eventTitle = calendarStore.getEventTitle(store.eventCategory)
    return t('text.clearSlots', {
      eventTitle,
      startDate: store.startDate.toLocaleDateString(),
      startTime: store.startTime.toTimeString(calendarStore.twelveHourClock),
      endDate: store.endDate.toLocaleDateString(),
      endTime: store.endTime.toTimeString(calendarStore.twelveHourClock)
    })
  }
  let text = t('text.createSlots', {
    startDate: store.startDate.toLocaleDateString(),
    startTime: store.startTime.toTimeString(calendarStore.twelveHourClock),
    endDate: store.endDate.toLocaleDateString(),
    endTime: store.endTime.toTimeString(calendarStore.twelveHourClock),
    total: store.totalSlots
  })
  if (store.skippedSlots?.length > 0) {
    if (store.skippedSlots.length === 1) {
      text += ` ${t('text.skipSingleSlot', { total: store.skippedSlots.length })}`
    } else {
      text += ` ${t('text.skipMultipleSlots', { total: store.skippedSlots.length })}`
    }
  }
  return text
})

const cancelButtonText = computed(() => {
  if (store.status === ManageSlotsStatus.InProgress) {
    return t('buttons.pause')
  }
  return t('buttons.cancel')
})

const saveButtonText = computed(() => {
  if (store.status === ManageSlotsStatus.Paused) {
    return t('buttons.resume')
  }
  if (store.mode === ManageSlotsMode.Clear) {
    return t('buttons.clear')
  }
  return t('buttons.create')
})

// LIFECYCLE HOOKS

// FUNCTIONS
const cancelEdit = () => {
  if (store.status !== ManageSlotsStatus.InProgress) {
    store.showCreateEvents = false
  }
  store.status = ManageSlotsStatus.Paused
}

const closeDialog = () => {
  store.showCreateEvents = false
}

const createOrClearEvents = async () => {
  switch (store.mode) {
    case ManageSlotsMode.Create: {
      await store.saveSlots()
      await store.loadOptions()
      break
    }
    case ManageSlotsMode.Clear: {
      const startDay = TimeValue.formatValue(store.startDate.getDate())
      const startMonth = TimeValue.formatValue(store.startDate.getMonth() + 1)
      const startYear = store.startDate.getFullYear()
      const endDay = TimeValue.formatValue(store.endDate.getDate())
      const endMonth = TimeValue.formatValue(store.endDate.getMonth() + 1)
      const endYear = store.endDate.getFullYear()
      const startDateValue = new Date(
        `${startYear}-${startMonth}-${startDay} ${store.startTime.toTimeString()}`
      )
      const endDateValue = new Date(
        `${endYear}-${endMonth}-${endDay} ${store.endTime.toTimeString()}`
      )

      store.showCreateEvents = false
      eventsStore.clearEvents(startDateValue, endDateValue, store.eventCategory)
      break
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-text {
  margin-bottom: 0rem;
}
</style>
