import { getStoreDefinition } from '@/composables/StoreDefinition'
import { EndPoint } from '@/enums/EndPoint'
import { SqlComparison } from '@/enums/SqlComparison'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { IMedicalClearancesStore } from '@/interfaces/api/IMedicalClearancesStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { MedicalClearanceRecord } from '@/models/MedicalClearanceRecord'
import { defineStore } from 'pinia'
import { useAPIStore } from './ApiStore'

let _store: IMedicalClearancesStore

export const useMedicalClearancesStore = (): ITableStore => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.MedicalClearances
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as IMedicalClearancesStore
  _extend(_store)
  return _store
}

const _extend = (store: IMedicalClearancesStore) => {
  const apiStore = useAPIStore()

  store.selectByPersonId = async (personId: number) => {
    const queryOptions = {
      where: [
        {
          comparison: SqlComparison.Equal,
          field: 'personId',
          value1: personId
        }
      ]
    }
    const { data, error } = await apiStore.getMany(EndPoint.MedicalClearances, queryOptions)
    if (!error && data && data.count > 0) {
      store.editItem(data?.results[0], false)
    } else {
      store.createNewItem(new MedicalClearanceRecord(personId))
    }
  }
}
