import { getStoreDefinition } from '@/composables/StoreDefinition'
import { EndPoint } from '@/enums/EndPoint'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOperator } from '@/enums/SqlOperator'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { useSettingsStore } from '../ui/SettingsStore'
import { useAPIStore } from './ApiStore'

let _store: IDevicesStore

export const useDevicesStore = () => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.Devices
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as IDevicesStore
  _extend(_store)
  return _store
}
const _extend = (store: IDevicesStore) => {
  const apiStore = useAPIStore()
  const settingsStore = useSettingsStore()

  store.getDeviceOptions = async (): Promise<Array<ISimpleOption>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['id', 'name', 'serialNumber', 'location'],
      orderBy: [
        { field: 'name', order: SqlOrder.ASC },
        { field: 'serialNumber', order: SqlOrder.ASC }
      ]
    }
    const response = await apiStore.getMany(EndPoint.Devices, query)
    let options: Array<ISimpleOption> = []
    if (!response.error) {
      const supervisors = response.data?.results || []
      options = supervisors.map((item) => {
        return { title: `${item.name} (${item.serialNumber})`, value: item.id! }
      })
    }
    return options
  }

  store.getDeviceExpirations = async () => {
    const calExpires = dayjs().subtract(
      settingsStore.globals.devices.daysToExpireCalibration -
        settingsStore.globals.devices.daysWarningCalibration -
        1,
      'day'
    )
    const planExpires = dayjs().add(settingsStore.globals.devices.daysWarningPlan - 1, 'day')
    const queryOptions: IQueryOptions = {
      where: [
        {
          comparison: SqlComparison.LessThan,
          field: 'lastCalibration',
          value1: calExpires.toISOString(),
          operator: SqlOperator.Or
        },
        {
          comparison: SqlComparison.LessThan,
          field: 'planExpiration',
          value1: planExpires.toISOString()
        }
      ],
      fields: ['id', 'serialNumber', 'lastCalibration', 'planExpiration']
    }
    return apiStore.getMany(EndPoint.Devices, queryOptions)
  }
}
