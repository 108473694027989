<template>
  <DisplayText :value="title" :label="label" v-if="mode === EditorMode.View" />
  <v-radio-group
    v-bind="$attrs"
    v-model="model"
    v-if="mode === EditorMode.Edit"
    :label="label"
    class="radio-group"
    inline
    density="comfortable"
    :hide-details="true"
  >
    <template v-slot:label="{ label }">
      <span class="radio-label">{{ label }}</span>
    </template>
    <v-radio
      :key="index"
      v-for="(item, index) in items"
      :label="item.title"
      :value="item.value"
    ></v-radio>
  </v-radio-group>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { EditorMode } from '@/enums/EditorMode'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { computed, type PropType } from 'vue'

// PROPS & EMITS
const props = defineProps({
  items: {
    type: Array as PropType<Array<ISimpleOption>>,
    default: () => {
      return []
    }
  },
  label: { type: String, default: '' },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit },
  value: { type: [String, Number, null] }
})

// REACTIVE VARIABLES
const model = defineModel<any>({ default: 0 })

// COMPUTED PROPERTIES
const title = computed(() => {
  const selected = props.items.find((item) => {
    return item.value === model.value
  })
  if (selected) {
    return selected.title
  }
  return ''
})
// LIFECYCLE HOOKS

// WATCHERS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.radio-label {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.v-radio-group:focus-within {
  .radio-label {
    color: $color-main-primary;
  }
}
</style>
