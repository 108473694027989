import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ISort } from '@/interfaces/api/ISort'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import type { Composer } from 'vue-i18n'
import { getTableHeaders as getMedicalClearanceHeaders } from './HeadersClearance'
import { getTableHeaders as getCustomFieldsHeaders } from './HeadersCustomFields'
import { getTableHeaders as getDailyChecksHeaders } from './HeadersDailyChecks'
import { getTableHeaders as getDeviceHeaders } from './HeadersDevices'
import { getTableHeaders as getEventHeaders } from './HeadersEvents'
import { getTableHeaders as getFitTestHeaders } from './HeadersFitTests'
import { getTableHeaders as getMaskHeaders } from './HeadersMasks'
import { getTableHeaders as getPeopleHeaders } from './HeadersPeople'

// Store a default sort for all tables.
const _tableSorts = new Map<TableStoreId, Array<ISort>>()
_tableSorts.set(TableStoreId.CustomFields, [{ field: 'labelName', order: SqlOrder.ASC }])
_tableSorts.set(TableStoreId.DailyChecks, [{ field: 'date', order: SqlOrder.DESC }])
_tableSorts.set(TableStoreId.Devices, [{ field: 'serialNumber', order: SqlOrder.ASC }])
_tableSorts.set(TableStoreId.Events, [{ field: 'dtStart', order: SqlOrder.DESC }])
_tableSorts.set(TableStoreId.FitTests, [{ field: 'testDate', order: SqlOrder.DESC }])
_tableSorts.set(TableStoreId.Masks, [{ field: 'maskManufacturer', order: SqlOrder.ASC }])
_tableSorts.set(TableStoreId.MedicalClearances, [{ field: 'expiration', order: SqlOrder.DESC }])
_tableSorts.set(TableStoreId.People, [{ field: 'lastName', order: SqlOrder.ASC }])

const _getTableHeaders = async (
  tableStoreId: TableStoreId,
  i18n: Composer<{}, {}, {}, string, never, string>
) => {
  switch (tableStoreId) {
    case TableStoreId.CustomFields: {
      return getCustomFieldsHeaders(i18n)
    }
    case TableStoreId.DailyChecks: {
      return getDailyChecksHeaders(i18n)
    }
    case TableStoreId.Devices: {
      return getDeviceHeaders(i18n)
    }
    case TableStoreId.Events: {
      return getEventHeaders(i18n)
    }
    case TableStoreId.FitTests: {
      return getFitTestHeaders(i18n)
    }
    case TableStoreId.Masks: {
      return getMaskHeaders(i18n)
    }
    case TableStoreId.MedicalClearances: {
      return getMedicalClearanceHeaders(i18n)
    }
    case TableStoreId.People: {
      const peopleHeaders = await getPeopleHeaders(i18n)
      return peopleHeaders
    }
  }
}

/**
 * Returns the default sort based on the specified table store identifier.
 * @param tableStoreId The table store id used to lookup the correct headers.
 * @returns
 */
const getDefaultSort = (tableStoreId: TableStoreId): Array<ISort> => {
  const sort = _tableSorts.get(tableStoreId)
  if (sort) {
    return sort
  }
  return []
}

/**
 * Returns a proper table header based on the specified table store identifier.
 * @param tableStoreId The table store id used to lookup the correct headers.
 * @param i18n The i18n instace.
 * @returns
 */
const getTableHeaders = async (
  tableStoreId: TableStoreId,
  i18n: Composer<{}, {}, {}, string, never, string>
): Promise<Array<ITableHeader>> => {
  const tableHeaders = await _getTableHeaders(tableStoreId, i18n)
  if (tableHeaders) {
    return tableHeaders
  }
  return []
}

export { getDefaultSort, getTableHeaders }
