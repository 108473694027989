import type { TableStoreId } from '@/enums/TableStoreId'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { useCustomFieldsStore } from './CustomFieldsStore'
import { useDailyChecksStore } from './DailyChecksStore'
import { useDevicesStore } from './DevicesStore'
import { useEventsStore } from './EventsStore'
import { useFilesStore } from './FilesStore'
import { useFitTestsStore } from './FitTestsStore'
import { useMasksStore } from './MasksStore'
import { useMedicalClearancesStore } from './MedicalClearancesStore'
import { usePeopleStore } from './PeopleStore'

const _tableStores = new Map<TableStoreId, ITableStore>()

const _initializeTableStores = () => {
  _tableStores.set(TableStoreOptions.CustomFields.id, useCustomFieldsStore())
  _tableStores.set(TableStoreOptions.DailyChecks.id, useDailyChecksStore())
  _tableStores.set(TableStoreOptions.Devices.id, useDevicesStore())
  _tableStores.set(TableStoreOptions.Events.id, useEventsStore())
  _tableStores.set(TableStoreOptions.Files.id, useFilesStore())
  _tableStores.set(TableStoreOptions.FitTests.id, useFitTestsStore())
  _tableStores.set(TableStoreOptions.Masks.id, useMasksStore())
  _tableStores.set(TableStoreOptions.MedicalClearances.id, useMedicalClearancesStore())
  _tableStores.set(TableStoreOptions.People.id, usePeopleStore())
}

export class TableStoreFactory {
  public static get(id: TableStoreId) {
    if (_tableStores.size === 0) {
      _initializeTableStores()
    }
    const store = _tableStores.get(id)
    if (!store) {
      throw new Error(`The table store '${id}' is not defined in the Store Factory.`)
    }
    return store
  }
}
