<template>
  <v-container fluid>
    <PrimarySelect
      :max-width="350"
      v-model="store.localeCode"
      :label="t('labels.language')"
      :items="settingsStore.languageOptions"
      v-on:update:model-value="onLanguageChange"
    ></PrimarySelect>

    <DataTable
      :table-store-id="TableStoreId.EmailTemplates"
      :show-new="false"
      :search-type="SearchType.Query"
    >
      <template v-slot:footer>
        <EditEmailTemplate />
      </template>
    </DataTable>
  </v-container>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { SearchType } from '@/enums/SearchType'
import { SqlComparison } from '@/enums/SqlComparison'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEmailTemplatesStore } from '@/interfaces/api/IEmailTemplatesStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useI18n } from 'vue-i18n'

const settingsStore = useSettingsStore()
const store = TableStoreFactory.get(TableStoreId.EmailTemplates) as IEmailTemplatesStore
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// WATCHERS

// LIFECYCLE HOOKS

// FUNCTIONS
const onLanguageChange = async () => {
  store.itemQuery = {
    where: [
      {
        field: 'locale',
        comparison: SqlComparison.Equal,
        value1: store.localeCode
      }
    ]
  }
  await store.loadItems()
}
</script>

<style lang="scss" scoped></style>
