<template>
  <div v-if="mode === EditorMode.Edit">
    <div v-if="label?.length > 0" class="header">
      {{ label }}<span v-if="required" style="margin-left: 0.1rem" class="required-text">*</span>
    </div>
    <div style="display: inline-flex">
      <PrimarySelect
        :hide-details="true"
        :label="t('labels.hours')"
        :width="60"
        v-model="model.hours"
        :items="hourOptions"
        @update:model-value="onTimeChange"
      ></PrimarySelect>
      <span class="separator">:</span>
      <PrimarySelect
        :hide-details="true"
        :label="t('labels.minutes')"
        v-model="model.minutes"
        :width="60"
        :items="minuteOptions"
        @update:model-value="onTimeChange"
      ></PrimarySelect>
      <span v-if="twelveHourClock && !durationMode" class="meridiem">{{ model.meridiem }}</span>
    </div>
  </div>
  <DisplayText :value="displayValue" :label="label" v-if="mode === EditorMode.View" />
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { TimeValue } from '@/classes/TimeValue'
import { EditorMode } from '@/enums/EditorMode'
import { computed, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const props = defineProps({
  label: { type: String, default: '' },
  durationMode: { type: Boolean, default: false },
  hourInterval: { type: Number, default: 1 },
  maxMinutes: { type: Number, default: 59 },
  maxHours: { type: Number, default: 23 },
  minMinutes: { type: Number, default: 0 },
  minHours: { type: Number, default: 0 },
  minuteInterval: { type: Number, default: 5 },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit },
  required: { type: Boolean, default: true },
  twelveHourClock: { type: Boolean, default: false }
})

// REACTIVE VARIABLES
const model = defineModel<TimeValue>({ default: new TimeValue() })

const emit = defineEmits<{
  (e: 'update:modelValue', model: TimeValue): void
}>()

// COMPUTED PROPERTIES
const displayValue = computed(() => {
  return model.value.toShortTimeString(props.twelveHourClock)
})

const hourOptions = computed(() => {
  const results = []
  for (let index = props.minHours; index <= props.maxHours; index += props.hourInterval) {
    let title = TimeValue.formatHours(index, props.twelveHourClock)
    results.push({ title, value: index })
  }
  return results
})

const minuteOptions = computed(() => {
  const results = []
  for (let index = props.minMinutes; index < props.maxMinutes; index += props.minuteInterval) {
    const disabled = model.value.hours === 0 && index === 0 && props.durationMode
    results.push({ title: TimeValue.formatValue(index), value: index, props: { disabled } })
  }
  return results
})

// LIFECYCLE HOOKS

// FUNCTIONS
const onTimeChange = () => {
  if (model.value.hours === 0 && model.value.minutes === 0 && props.durationMode) {
    model.value.minutes = minuteOptions.value[1].value
  }
  emit('update:modelValue', model.value)
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
.meridiem {
  margin: 0.35rem 0.5rem 0 0.5rem;
}
.separator {
  margin: 0.35rem 0.5rem 0 0.5rem;
}
</style>
