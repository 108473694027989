/**
 * Enum of tab names used in Manage People.
 */
export enum ManagePersonTab {
  Appointments = 'appointments',
  Info = 'details',
  FitTest = 'fitTest',
  MedicalClearance = 'clearance',
  Training = 'training'
}
