<template>
  <BaseDialog
    v-model="model"
    v-bind="$attrs"
    :min-height="250"
    :max-width="500"
    icon="mdi-note-text"
  >
    <pre class="message-text">{{ message }}</pre>
  </BaseDialog>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import type { PropType } from 'vue'

// PROPS & EMITS
const model = defineModel<boolean>({ default: false })

defineProps({
  message: { type: [String, null] as PropType<string | null>, default: null }
})

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.message-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  line-height: 1.25rem;
}
</style>
