<template>
  <span>
    <div style="display: inline-flex" v-if="mini">
      <v-icon
        v-if="isoDate"
        v-tooltip="{ disabled: !isoDate, text: getToolTipText(isoDate) }"
        style="font-size: 1rem; margin-left: 0.25rem"
        :color="getStatusColor(isoDate)"
        >{{ getStatusIcon(isoDate) }}</v-icon
      >
    </div>
    <div v-else>
      <span class="chip-label" v-if="label">{{ label }}:</span>
      <v-chip
        v-tooltip="{ disabled: !isoDate, text: getToolTipText(isoDate) }"
        v-if="!disabled"
        :color="getStatusColor(isoDate)"
        size="small"
        density="compact"
      >
        {{ getStatusMessage(isoDate) }}
      </v-chip>
      <span v-else style="margin-left: 0.25rem">
        {{ _defaultValue }}
      </span>
    </div>
  </span>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { formatDate, isDefined } from '@/composables/utils'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import Colors from '@/styles/_colors.module.scss'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const settingsStore = useSettingsStore()

// PROPS & EMITS
const props = defineProps({
  daysWarning: { type: Number, default: 30 },
  daysToExpire: { type: Number, default: 0 },
  defaultValue: { type: String },
  disabled: { type: Boolean, default: false },
  isoDate: { type: String },
  label: { type: String },
  msgDueSoon: { type: String },
  msgOverdue: { type: String },
  msgCompliant: { type: String },
  msgNoRecord: { type: String },
  expires: { type: Boolean, default: true },
  mini: { type: Boolean, default: false }
})

// REACTIVE VARIABLES
const _defaultValue = ref<string>(t('labels.notApplicable'))

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// WATCHERS
watch(
  (): any => props.defaultValue,
  (value) => {
    _defaultValue.value = value
  }
)

// FUNCTIONS
const getStatusColor = (value?: string) => {
  if (!isDefined(value)) {
    return Colors.dark56
  }
  if (isDueSoon(value!) && props.expires) {
    return Colors.accentPrimary
  }
  if (isOverdue(value!) && props.expires) {
    return Colors.accentError
  }
  return Colors.accentSuccess
}

const getStatusIcon = (value?: string) => {
  if (isDueSoon(value!) && props.expires) {
    return 'mdi-alert'
  }
  if (isOverdue(value!) && props.expires) {
    return 'mdi-alert-circle'
  }
  return 'mdi-check-circle'
}

const getStatusMessage = (value?: string) => {
  const msgDueSoon = isDefined(props.msgDueSoon) ? props.msgDueSoon : t('labels.dueSoon')
  const msgOverdue = isDefined(props.msgOverdue) ? props.msgOverdue : t('labels.overdue')
  const msgCompliant = isDefined(props.msgCompliant) ? props.msgCompliant : t('labels.compliant')
  const msgNoRecord = isDefined(props.msgNoRecord) ? props.msgNoRecord : t('labels.noRecord')
  if (!isDefined(value)) {
    return msgNoRecord
  }
  if (props.expires && isDueSoon(value!)) {
    return msgDueSoon
  }
  if (props.expires && isOverdue(value!)) {
    return msgOverdue
  }
  return msgCompliant
}

const getToolTipText = (isoDate?: string) => {
  if (!isoDate) {
    return ''
  }

  if (props.expires && isDueSoon(isoDate)) {
    const days = dayjs(isoDate).diff(new Date(), 'day')
    if (days === 0) {
      return t('labels.expiresTomorrow')
    }
    return t('labels.daysToExpire', { days: (days + 1).toString() })
  }

  if (props.expires && isOverdue(isoDate)) {
    const days = dayjs(new Date()).diff(isoDate, 'day')
    if (days === 1) {
      return t('labels.dayOverdue', { days: days.toString() })
    }
    return t('labels.daysOverdue', { days: days.toString() })
  }

  if (props.mini) {
    return isDefined(props.msgCompliant) ? props.msgCompliant : t('labels.compliant')
  }

  return formatDate(
    dayjs(isoDate).add(props.daysToExpire, 'day').toISOString(),
    false,
    settingsStore.localeCode
  )
}

const isDueSoon = (value: string) => {
  const today = dayjs(dayjs().format('MM/DD/YYYY')).toDate()
  const futureDate = dayjs(today).add(props.daysWarning, 'day').toDate()
  const testDueDate = dayjs(value).toDate()
  return testDueDate >= today && testDueDate <= futureDate
}

const isOverdue = (value: string) => {
  const today = dayjs().toDate()
  const testDueDate = dayjs(value).toDate()
  return testDueDate < today
}
</script>

<style lang="scss" scoped>
.chip-label {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}
</style>
