/**
 * Enum of all currently supported Calendar Subscription Types.
 */
export enum CalendarSubscriptionType {
  CopyLink = 1,
  DownloadFile = 2,
  Google = 3,
  Outlook365 = 4,
  WebCal = 5,
  Yahoo = 6
}
