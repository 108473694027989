<template>
  <v-form :fast-fail="false" @submit.prevent ref="manageSlotsForm">
    <v-btn-toggle
      class="toggle-button"
      v-model="store.mode"
      density="compact"
      color="primary"
      rounded="8"
      mandatory
      variant="outlined"
      @update:model-value="onToggleChange"
    >
      <v-btn :value="ManageSlotsMode.Create">{{ t('buttons.create') }}</v-btn>
      <v-btn :value="ManageSlotsMode.Clear">{{ t('buttons.clear') }}</v-btn>
    </v-btn-toggle>

    <v-container class="ma-1 pa-1" max-width="425px">
      <v-row style="margin-top: 0rem">
        <v-col cols="12">
          <PrimarySelect
            v-model="store.eventCategory"
            :label="t('labels.eventCategory')"
            :items="calendarStore.getEventCategories(store.mode === ManageSlotsMode.Clear)"
          ></PrimarySelect>
          <v-container class="ma-0 pa-0" max-width="425px">
            <v-row>
              <v-col cols="6">
                <DatePicker
                  required
                  v-model="store.startDate"
                  :label="t('labels.slots.from')"
                  :min="store.minStartDate"
                  :max="store.maxStartDate"
                  :rules="
                    RulesManager.getRules([
                      Rule.Required,
                      {
                        key: Rule.ValidDate,
                        payload: {
                          min: store.minStartDate,
                          max: store.maxStartDate
                        }
                      }
                    ])
                  "
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  required
                  :rules="[
                    RulesManager.getRules(Rule.Required),
                    {
                      key: Rule.ValidDate,
                      payload: {
                        min: store.minEndDate,
                        max: store.maxEndDate
                      }
                    }
                  ]"
                  v-model="store.endDate"
                  :label="t('labels.slots.to')"
                  :min="store.minEndDate"
                  :max="store.maxEndDate"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="6" class="ma-0 pa-0">
                <TimePicker
                  v-model="store.startTime"
                  :label="t('labels.slots.startTime')"
                  :twelve-hour-clock="calendarStore.twelveHourClock"
                />
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <TimePicker
                  v-model="store.endTime"
                  :label="t('labels.slots.endTime')"
                  :twelve-hour-clock="calendarStore.twelveHourClock"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="store.mode === ManageSlotsMode.Create"
            class="ma-0 pa-0"
            max-width="425px"
          >
            <v-row>
              <v-col cols="6">
                <TimePicker
                  v-model="store.duration"
                  :label="t('labels.slots.duration')"
                  :max-hours="8"
                  :duration-mode="true"
                />
              </v-col>
              <v-col cols="6" style="padding-top: 2rem">
                <PrimarySwitch
                  v-model="store.includeWeekends"
                  :label="t('labels.slots.includeWeekends')"
                ></PrimarySwitch>
              </v-col>
            </v-row>
          </v-container>
          <PrimaryComboBox
            v-if="store.mode === ManageSlotsMode.Create"
            required
            style="margin-top: 1rem"
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="store.selectedAdminOption"
            @update:model-value="onAdministratorChange"
            @blur="onAdministratorBlur"
            :label="t('labels.administrator')"
            :items="store.administratorOptions"
          />
          <PrimaryComboBox
            v-if="store.mode === ManageSlotsMode.Create"
            required
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="store.selectedLocation"
            :label="t('labels.location')"
            :items="store.locationValues"
          />
          <PrimaryComboBox
            required
            v-if="
              store.eventCategory === EventCategory.FitTest && store.mode === ManageSlotsMode.Create
            "
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="store.selectedDeviceOption"
            :label="t('labels.slots.device')"
            :items="store.deviceOptions"
            @blur="onDeviceBlur"
          />
          <p v-if="store.timeRangeError" class="error-text">
            {{ t('errors.invalidTimeRange') }}
          </p>
        </v-col>
        <v-divider />
        <v-col class="buttons" cols="12">
          <ButtonPrimary
            v-if="store.mode === ManageSlotsMode.Clear"
            @click="clearSlots"
            :disabled="!manageSlotsForm?.isValid || store.timeRangeError"
            :text="t('buttons.clearSlots')"
          />
          <ButtonPrimary
            v-if="store.mode === ManageSlotsMode.Create"
            @click="createSlots"
            :disabled="!manageSlotsForm?.isValid || store.timeRangeError"
            :text="t('buttons.createSlots')"
          />
          <DialogSlotsConfirm
            v-model="store.showCreateEvents"
            :title="title"
            @close="hideCreateEvents"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { EventCategory } from '@/enums/EventCategory'
import { ManageSlotsMode } from '@/enums/ManageSlotsMode'
import { Rule } from '@/enums/Rule'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSlotsStore } from '@/stores/ui/SlotsStore'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const calendarStore = useCalendarStore()
const RulesManager = useRulesManager()
const store = useSlotsStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const manageSlotsForm = ref<any>()

// COMPUTED PROPERTIES
const title = computed(() => {
  let slugPrefix = store.mode as string
  switch (store.eventCategory) {
    case EventCategory.FitTest: {
      return t(`dialogs.headers.${slugPrefix}FitTestSlots`)
    }
    case EventCategory.MedicalClearance: {
      return t(`dialogs.headers.${slugPrefix}MedicalClearanceSlots`)
    }
    case EventCategory.Training: {
      return t(`dialogs.headers.${slugPrefix}TrainingSlots`)
    }
  }
  return ''
})

// LIFECYCLE HOOKS
onMounted(async () => {
  store.loadOptions()
})

// FUNCTIONS
const clearSlots = async () => {
  const { valid } = await manageSlotsForm.value.validate()
  if (valid) {
    store.clearSlots()
    store.showCreateEvents = true
  }
}

const createSlots = async () => {
  const { valid } = await manageSlotsForm.value.validate()
  if (valid) {
    store.createSlots()
    store.showCreateEvents = true
  }
}

const hideCreateEvents = () => {
  store.showCreateEvents = false
}

const onAdministratorBlur = () => {
  if (typeof store.selectedAdminOption !== 'object') {
    store.selectedAdminOption = null
  }
}

const onAdministratorChange = async () => {
  await store.loadAdministratorOptions()
}

const onDeviceBlur = () => {
  if (typeof store.selectedDeviceOption !== 'object') {
    store.selectedDeviceOption = null
  }
}

const onToggleChange = () => {
  if (store.mode === ManageSlotsMode.Create) {
    store.eventCategory = EventCategory.FitTest
  }
  if (store.mode === ManageSlotsMode.Clear) {
    store.eventCategory = EventCategory.All
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: inline-flex;
}
.error-text {
  margin: 0;
  padding: 0;
  color: red;
}

.toggle-button {
  margin-top: 1rem;
}
</style>
