import { getStoreDefinition } from '@/composables/StoreDefinition'
import { SqlComparison } from '@/enums/SqlComparison'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { IEmailTemplatesStore } from '@/interfaces/api/IEmailTemplatesStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { defineStore } from 'pinia'
import { useSettingsStore } from '../ui/SettingsStore'

let _store: IEmailTemplatesStore

export const useEmailTemplatesStore = (): ITableStore => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.EmailTemplates
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as IEmailTemplatesStore
  _extend(_store)
  return _store
}

const _extend = (store: IEmailTemplatesStore) => {
  const settingsStore = useSettingsStore()
  store.localeCode = settingsStore.globals.defaultLocaleCode
  store.itemQuery = {
    where: [
      {
        field: 'locale',
        comparison: SqlComparison.Equal,
        value1: store.localeCode
      }
    ]
  }
}
