<template>
  <BaseEditor
    v-bind="$attrs"
    v-model="store.showEditDialog"
    :table-store-id="TableStoreId.Devices"
    :title="title"
    :max-width="500"
    :max-height="600"
    @save-item="saveItem"
  >
    <v-form :fast-fail="false" @submit.prevent ref="editDeviceForm">
      <v-row>
        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
          <PrimaryTextField
            :label="t('fields.devices.name')"
            :maxlength="64"
            v-model.trim="item.name"
          />
          <PrimaryTextField
            required
            :label="t('fields.devices.serialNumber')"
            :maxlength="64"
            v-model.trim="item.serialNumber"
            :rules="RulesManager.getRules(Rule.Required)"
            @update:model-value="updateDeviceModel"
          />
          <PrimaryTextField
            :label="t('fields.devices.model')"
            :maxlength="64"
            v-model.trim="item.model"
          />
          <PrimaryTextField
            :label="t('fields.devices.location')"
            :maxlength="64"
            v-model.trim="item.location"
          />
          <v-file-input
            v-if="!item.calCertId"
            v-model.trim="selectedFile"
            class="file-input"
            :max-width="300"
            :label="t('fields.devices.calCert')"
            color="primary"
            density="comfortable"
            variant="underlined"
            accept=".pdf,.png,.doc,.gif,.docx,.jpg,.jpeg,.tif,.tiff,.xls,.xlsx"
            @update:model-value="startReadingFile"
            @click:clear="clearFile"
            primary
          ></v-file-input>
          <FileView
            v-if="item.calCertId"
            :label="t('fields.devices.calCert')"
            :value="fileRecord.name"
            @click="clearCalCert"
          />
        </v-col>
        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
          <DatePicker v-model="lastCalibration" :label="t('fields.devices.lastCalibration')" />
          <DisplayText
            :label="t('fields.devices.calibrationExpires')"
            :value="`${formatDate(calExpiration, false, settingsStore.localeCode)}`"
          >
            <template v-slot:icon>
              <StatusChip
                :mini="true"
                :iso-date="item.lastCalibration"
                :days-to-expire="settingsStore.globals.devices.daysToExpireCalibration"
                :days-warning="settingsStore.globals.devices.daysWarningCalibration"
              />
            </template>
          </DisplayText>
          <div style="position: relative">
            <DatePicker v-model="planExpiration" :label="t('fields.devices.planExpiration')" />
            <StatusChip
              style="position: absolute; top: 1.05rem; right: 0"
              :mini="true"
              :iso-date="item.planExpiration"
              :days-warning="settingsStore.globals.devices.daysWarningPlan"
            />
          </div>
          <PrimaryTextArea
            :maxlength="128"
            v-model="item.planInfo"
            :label="t('fields.devices.planInfo')"
          />
        </v-col>
      </v-row>
    </v-form>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { formatDate, isDefined } from '@/composables/utils'
import { Rule } from '@/enums/Rule'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { DeviceRecord } from '@/models/DeviceRecord'
import { FileRecord } from '@/models/FileRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import dayjs from 'dayjs'
import { computed, onUpdated, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DisplayText from '../DisplayText.vue'

const store = TableStoreFactory.get(TableStoreId.Devices) as IDevicesStore
const filesStore = TableStoreFactory.get(TableStoreId.Files) as ITableStore
const settingsStore = useSettingsStore()
const RulesManager = useRulesManager()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const editDeviceForm = ref<any>()
const encodedFile = ref<string | null>(null)
const fileRecord = ref<FileRecord>(new FileRecord())
const selectedFile = ref()

// COMPUTED PROPERTIES
const item = computed(() => {
  if (!store.selectedItem) {
    return new DeviceRecord()
  }
  return store.selectedItem as DeviceRecord
})

const lastCalibration = computed({
  get: (): Date | null => {
    if (isDefined(item.value.lastCalibration)) {
      return new Date(item.value.lastCalibration!)
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.lastCalibration = isDefined(value) ? value!.toISOString() : null
  }
})

const planExpiration = computed({
  get: (): Date | null => {
    if (isDefined(item.value.planExpiration)) {
      return new Date(item.value.planExpiration!)
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.planExpiration = isDefined(value) ? value!.toISOString() : null
  }
})

const calExpiration = computed(() => {
  if (lastCalibration.value) {
    return dayjs(lastCalibration.value)
      .add(settingsStore.globals.devices.daysToExpireCalibration, 'day')
      .toISOString()
  }
  return null
})

const title = computed((): string => {
  if (item.value?.id) {
    return t('dialogs.headers.editDevice')
  }
  return t('dialogs.headers.createDevice')
})

// WATCHERS

// LIFECYCLE HOOKS

onUpdated(async () => {
  if (item.value.calCertId) {
    const response = await filesStore.getOne(item.value.calCertId, [
      'id',
      'name',
      'type',
      'lastModified'
    ])
    if (!response.error && response.data) {
      fileRecord.value = response.data as FileRecord
    }
  }
})

const readFile = async (): Promise<any | void> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    try {
      if (isDefined(selectedFile.value)) {
        reader.readAsDataURL(selectedFile.value)
      } else {
        resolve(null)
      }
    } catch (error) {
      reject(error)
    }
  })
}

const clearCalCert = () => {
  item.value.calCertId = null
}

const clearFile = async () => {
  if (item.value) {
    encodedFile.value = null
    item.value.calCertId = null
  }
}

const startReadingFile = async () => {
  if (item.value) {
    encodedFile.value = await readFile()
  }
}

const saveItem = async () => {
  const { valid } = await editDeviceForm.value.validate()
  if (!valid) {
    return
  }

  store.workStatus.save = true
  const oldCalCertId = (store.selectedItem as DeviceRecord).calCertId
  if (encodedFile.value) {
    const fileResponse = await filesStore.save(true, false, true, {
      name: selectedFile.value.name,
      size: selectedFile.value.size,
      type: selectedFile.value.type,
      lastModified: selectedFile.value.lastModifiedDate.toISOString(),
      encodedFile: encodedFile.value
    })

    if (!fileResponse.error && fileResponse?.data) {
      item.value.calCertId = fileResponse.data.id ? fileResponse.data.id : null
    }
  }
  store.workStatus.save = false
  await store.save()
  if (item.value.calCertId !== oldCalCertId && oldCalCertId) {
    filesStore.deleteOne({ id: oldCalCertId })
  }
}

const updateDeviceModel = () => {
  if (item.value.serialNumber.length > 0) {
    item.value.model = item.value.serialNumber.substring(0, 4)
  }
}
</script>

<style lang="scss" scoped>
.max-message {
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
}
</style>
