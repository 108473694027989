import { LocalStorageKey } from '@/enums/LocalStorageKey'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import DashboardView from '@/views/DashboardView.vue'
import PeopleView from '@/views/PeopleView.vue'
import PreviewView from '@/views/PreviewView.vue'
import SchedulerView from '@/views/SchedulerView.vue'
import SelfScheduleView from '@/views/SelfScheduleView.vue'
import SettingsView from '@/views/SettingsView.vue'
import { getAuthInstance, getPermissionsInstance } from '@fusion/auth'
import axios from 'axios'
import { type App, type Component } from 'vue'
import { createRouter, createWebHistory, type RouteRecordRaw, type Router } from 'vue-router'

let _router: Router

/**
 * Register all navigation components required to properly render the router.
 * @param app The Vue application instance.
 * @returns
 */
function registerComponents(app: App): Record<string, Component<any>> {
  app.component('DashboardView', DashboardView)
  app.component('SettingsView', SettingsView)
  app.component('PeopleView', PeopleView)
  app.component('SchedulerView', SchedulerView)
  app.component('SelfScheduleView', SelfScheduleView)
  app.component('PreviewView', PreviewView)
  return app._context.components
}

/**
 * Gets the router dynamically using data from the database.
 * @param app The Vue application instance.
 * @param auth0 The Auth0 plugin instance.
 * @returns
 */
const _initializeRouter = async (app: App<Element>): Promise<Router> => {
  const applicationId = APP_ENV.VITE_APP_APPLICATION_ID
  let response

  // Cache the navigation, If it ever fails pull from the cache.
  try {
    response = await axios.get(
      `${APP_ENV.VITE_APP_APPLICATIONS_HOST}/applications/${applicationId}/navigation`
    )
    localStorage.setItem(LocalStorageKey.Navigation, JSON.stringify(response.data))
  } catch {
    const data = localStorage.getItem(LocalStorageKey.Navigation)
    if (data) {
      response = { status: 200, data: JSON.parse(data) }
    } else {
      response = { status: 500 }
    }
  }

  const components = registerComponents(app)
  const routes: Array<RouteRecordRaw> = []

  if (response.status >= 200 && response.status <= 299 && Array.isArray(response.data)) {
    response.data.forEach((route: RouteRecordRaw) => {
      if (route.meta) {
        const component: Component = components[route.meta.componentName]
        if (component || route.path === '*') {
          route.component = component
          route.meta.routePermissions.alwaysVisible = true
          routes.push(route)
        } else {
          console.error(
            `Router - missing component name in meta field: ${route.meta.componentName}`
          )
        }
      } else {
        console.error(`Router - missing component name, route was skipped.`)
      }
    })
  } else {
    console.error('Failed to load navigation routes: ', response.statusText)
  }

  _router = createRouter({
    history: createWebHistory(APP_ENV.VITE_APP_PUBLIC_PATH),
    routes
  })

  _router.beforeEach((to, from, next) => {
    const auth = getAuthInstance()
    const intervalId = setInterval(() => {
      if (!auth.loading) {
        clearInterval(intervalId)
        if (auth.isAuthenticated) {
          const permissionsManager = getPermissionsInstance()
          const routePermissions = to.meta.routePermissions?.slugs || []
          const settingsStore = useSettingsStore()
          if (!settingsStore.isLoading) {
            settingsStore.load()
          }

          try {
            const isAllowed = permissionsManager.isAllowed(routePermissions)
            if (isAllowed) {
              return next()
            }
            next('/')
          } catch (e) {
            next('/')
          }
        } else {
          if (!to.meta?.routePermissions || to.meta.routePermissions.authRequired) {
            auth.getTokenSilently()
          } else {
            next()
          }
        }
      }
    }, 100)
  })
  return _router
}

/**
 * Ensure the router is created only once.
 * @param app
 * @returns
 */
export const getRouter = async (app: App<Element>): Promise<Router> => {
  if (_router) {
    return _router
  }
  return _initializeRouter(app)
}
