<template>
  <v-container fluid>
    <PrimarySelect
      :disabled="isDirty"
      style="margin-bottom: 1rem"
      min-width="300"
      max-width="1000"
      v-model="emailTemplatesStore.templateId"
      :label="t('labels.emailTemplate')"
      :items="emailTemplatesStore.templates"
      :persistent-hint="true"
      :hint="emailTemplatesStore.selectedTemplate?.props?.hint"
    />
    <PrimaryTextField
      required
      min-width="300"
      max-width="1000"
      :label="t('labels.subject')"
      :maxlength="64"
      v-model.trim="subject"
      :rules="RulesManager.getRules(Rule.Required)"
    />
    <PrimarySwitch
      :label="enabled ? t('labels.emailEnabled') : t('labels.emailNotEnabled')"
      v-model.trim="enabled"
    />
    <div style="max-width: 1000px">
      <RichTextEditor v-model="content" />
    </div>
    <div style="margin-top: 0.5rem">
      <ButtonSecondary
        :disabled="!isDirty"
        style="margin-right: 0.5rem"
        @click="cancel"
        :text="t('buttons.cancel')"
      />
      <ButtonPrimary :disabled="!isDirty" @click="save" :text="t('buttons.save')" />
    </div>
  </v-container>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { Rule } from '@/enums/Rule'
import { useEmailTemplatesStore } from '@/stores/ui/EmailTemplatesStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { isEqual } from 'lodash-es'
import tinymce from 'tinymce'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const emailTemplatesStore = useEmailTemplatesStore()
const settingsStore = useSettingsStore()
const RulesManager = useRulesManager()

// PROPS & EMITS
// REACTIVE VARIABLES
const enabled = ref<boolean>(true)
const subject = ref<string>()
const content = ref<string>()

// COMPUTED PROPERTIES
const isDirty = computed(() => {
  const oldSubject = settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].subject
  const oldContent = settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].content
  const oldEnabled = settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].enabled
  return (
    !isEqual(oldSubject, subject.value) ||
    !isEqual(oldContent, content.value) ||
    !isEqual(oldEnabled, enabled.value)
  )
})

// WATCHERS
watch(
  (): any => emailTemplatesStore.templateId,
  () => {
    update()
  }
)

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  update()
})

// FUNCTIONS
const cancel = () => {
  update()
}

const save = async () => {
  settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].enabled = enabled.value
  settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].subject = subject.value || ''
  settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].content = content.value || ''
  await settingsStore.save()
}

const update = () => {
  enabled.value = settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].enabled
  subject.value = settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].subject
  content.value = settingsStore.globals.emailTemplates[emailTemplatesStore.templateId].content
  if (tinymce.activeEditor) {
    tinymce.activeEditor?.resetContent(content.value)
  }
}
</script>

<style lang="scss" scoped></style>
