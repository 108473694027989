<template>
  <v-container fluid>
    <h1 class="page-title">{{ t(title) }}</h1>
    <div class="content">
      <slot></slot>
      <div style="position: fixed; right: 0.5rem; bottom: 0.5rem">
        {{ display.name.value }} ({{ display.width }},{{ display.height }})
      </div>
    </div>
  </v-container>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useDisplay, type DisplayInstance } from 'vuetify'

const display: DisplayInstance = useDisplay()
const { t } = useI18n({ useScope: 'global' })
const currentRoute = useRoute()

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const title = computed((): string => {
  return currentRoute?.meta ? currentRoute.meta.title : ''
})

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.content {
  margin: 0 0 0 0;
  padding: 0;
}

.page-title {
  width: 100%;
  font-size: 1.5rem !important;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  border-bottom: 1px solid $color-main-primary;
  margin: 0 0 0.5rem 0;
  padding: 0;
}
</style>
