import { formatDate, isDefined } from '@/composables/utils'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { type Composer } from 'vue-i18n'
import type { EventRecord } from '../EventRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>,
  localeCode?: string
): Array<ITableHeader> => {
  const calendarStore = useCalendarStore()
  return [
    {
      title: i18n.t('fields.events.category'),
      key: 'category',
      nowrap: true,
      align: 'start',
      value: (item: EventRecord) => {
        return calendarStore.getEventTitle(item.category)
      }
    },
    {
      title: i18n.t('fields.events.location'),
      key: 'location',
      align: 'start',
      headerProps: { searchField: true }
    },
    {
      title: i18n.t('fields.events.organizer'),
      key: 'organizerId',
      align: 'start',
      value: (item: EventRecord) => {
        return `${item.organizerLastName}, ${item.organizerFirstName}`
      }
    },
    {
      title: i18n.t('fields.events.dtStart'),
      key: 'dtStart',
      nowrap: true,
      align: 'start',
      value: (item: EventRecord) => {
        return isDefined(item.dtStart) ? formatDate(item.dtStart, true, localeCode) : '-'
      }
    },
    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      nowrap: true,
      sortable: false
    }
  ]
}
