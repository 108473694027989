import { ImportField } from '@/classes/ImportField'
import { isDefined, sanitizeAlphaNumeric } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import { ImportMode } from '@/enums/ImportMode'
import { ImportState } from '@/enums/ImportState'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOperator } from '@/enums/SqlOperator'
import { StoreId } from '@/enums/StoreId'
import type { ICollectionResponse } from '@/interfaces/api/ICollectionResponse'
import type { IQueryFilter } from '@/interfaces/api/IQueryFilter'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import type { IImportError } from '@/interfaces/IImportError'
import type { IImportFieldDefinition } from '@/interfaces/IImportFieldDefinition'
import type { IImportStep } from '@/interfaces/IImportStep'
import type { IItemsPerPageOption } from '@/interfaces/IItemsPerPageOption'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import type { TableRecord } from '@/models/TableRecord'
import { useAPIStore } from '@/stores/db/ApiStore'
import Papa from 'papaparse'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { getImportDefinitions } from './definitions'

export const useImportStore = defineStore(StoreId.Import, () => {
  // STORES, IMPORTS, & COMPOSABLES
  const i18n = useI18n({ useScope: 'global' })
  const apiStore = useAPIStore()

  // REACTIVE VARIABLES
  const itemsPerPageOptions = ref<Array<IItemsPerPageOption>>([
    { title: i18n.t('tables.five'), value: 5 },
    { title: i18n.t('tables.ten'), value: 10 },
    { title: i18n.t('tables.fifteen'), value: 15 },
    { title: i18n.t('tables.twenty'), value: 20 }
  ])
  const errorHeaders = ref<Array<any>>([
    {
      title: i18n.t('errors.importTable.rowNumber'),
      key: 'row',
      align: 'start',
      sortable: false
    },
    {
      title: i18n.t('errors.importTable.name'),
      key: 'name',
      align: 'start',
      sortable: false
    },
    {
      title: i18n.t('errors.importTable.errorDetails'),
      key: 'messages',
      align: 'start',
      sortable: false
    }
  ])
  const fileError = ref<boolean>(false)
  const selectedImportFields = ref<Array<ImportField>>([])
  const importDefinitions = ref<Array<IImportDefinition>>(getImportDefinitions())
  const parsedFile = ref<any>([])
  const parsedFileHeaders = ref<Array<string | null>>([])
  const selectedFields = ref<Array<string | null>>([])
  const selectedFile = ref()
  const selectedImportDefinition = ref<IImportDefinition>()
  const currentStep = ref<number>(1)
  const importMode = ref<string>(ImportMode.Insert)
  const importState = ref<ImportState>(ImportState.Ready)
  const importErrors = ref<Array<IImportError>>([])
  const totalInserted = ref<number>(0)
  const totalOverwritten = ref<number>(0)
  const steps = ref<Array<IImportStep>>([
    {
      title: i18n.t('labels.selectTable'),
      hasCompleted: false
    },
    {
      title: i18n.t('labels.selectFile'),
      hasCompleted: false
    },
    { title: i18n.t('labels.parseFile'), hasCompleted: false },
    { title: i18n.t('labels.submit'), hasCompleted: false }
  ])

  // WATCHERS
  watch(
    (): Array<string | null> =>
      selectedFields.value.map((field: string | null) => {
        return field
      }),
    (newValue: Array<string | null>, oldValue: Array<string | null>) => {
      if (newValue?.length === 0 || oldValue.length === 0) {
        return
      }
      _remapFieldImportFields(newValue)
      const selected = newValue.filter((field) => {
        return isDefined(field)
      })
      steps.value[2].hasCompleted = selected?.length > 0
    },
    { deep: true }
  )

  watch(selectedImportDefinition, (newValue: IImportDefinition | undefined) => {
    if (isDefined(selectedImportDefinition.value)) {
      resetImport(false)
    }
    if (newValue?.endpoint === EndPoint.CustomFields) {
      importMode.value = ImportMode.Overwrite
    }
    steps.value[0].hasCompleted = isDefined(selectedImportDefinition.value)
  })

  // COMPUTED PROPERTIES

  /**
   * Returns an Array of field titles that represent missing required fields from the selected import fields.
   * In order to import properly, all key fields are required plus any required fields lacking a default value.
   */
  const missingFields = computed(() => {
    const results: Array<string> = []
    const requiredFields: Array<IImportFieldDefinition> = []

    if (selectedImportDefinition.value) {
      const keyFields = selectedImportDefinition.value.keyFields
      selectedImportDefinition.value.fields.forEach((fieldDefinition: IImportFieldDefinition) => {
        if (fieldDefinition.name) {
          if (keyFields.indexOf(fieldDefinition.name) >= 0) {
            requiredFields.push(fieldDefinition)
          } else {
            if (importMode.value === ImportMode.Insert) {
              if (fieldDefinition.required && !isDefined(fieldDefinition.defaultValue)) {
                requiredFields.push(fieldDefinition)
              }
            }
          }
        }
      })
    }

    requiredFields.forEach((fieldDefinition: IImportFieldDefinition) => {
      if (selectedFields.value.indexOf(fieldDefinition.name) === -1) {
        results.push(fieldDefinition.title)
      }
    })

    return results
  })

  /**
   * The percent completed (0-100) used by any progress bar.
   */
  const importProgress = computed(() => {
    if (Array.isArray(parsedFile.value.data)) {
      return Math.round(
        ((totalInserted.value + totalOverwritten.value + importErrors.value.length) /
          totalRecords.value) *
          100
      )
    }
    return 0
  })

  /**
   * Flag that indicates the import is running.
   */
  const isImporting = computed(() => {
    return importState.value === ImportState.InProgress
  })

  /**
   * The total number of skipped columns.
   */
  const skipCount = computed(() => {
    let result = 0
    selectedImportFields.value.forEach((importField: ImportField) => {
      if (!isDefined(importField.name) && !importField.ignore) {
        result++
      }
    })
    return result
  })

  /**
   * The total number of records found in the import file.
   */
  const totalRecords = computed(() => {
    if (Array.isArray(parsedFile.value.data)) {
      return parsedFile.value.data.length
    }
    return 0
  })

  // FUNCTIONS - PRIVATE
  /**
   * Creates a query used to overwrite records. The query is based on field definition keys.
   * @param item The item used to overwrite the matching database record.
   * @returns
   */
  const _createQueryOptions = (item: TableRecord): IQueryOptions => {
    const queryOptions: IQueryOptions = {
      where: []
    }
    if (selectedImportDefinition.value) {
      const fieldCount = selectedImportDefinition.value.keyFields.length
      selectedImportDefinition.value.keyFields.forEach((field: string, index: number) => {
        const queryFilter: IQueryFilter = {
          comparison: SqlComparison.Equal,
          field,
          value1: item[field]
        }
        if (index < fieldCount - 1) {
          queryFilter.operator = SqlOperator.And
        }
        queryOptions.where?.push(queryFilter)
      })
    }
    return queryOptions
  }

  /**
   * Creates a new ImportField instance from a field definition or returns the default.
   * @param fieldName The field name to match.
   * @returns
   */
  const _createImportField = (fieldName: string | null, index: number): ImportField => {
    if (fieldName && _ignoreField(fieldName)) {
      return new ImportField(null, `<${i18n.t('labels.ignore')}:${fieldName}>`, index, true)
    }

    if (selectedImportDefinition.value && fieldName) {
      // Strip all non-alphanumeric characters and lower case the field name.
      const sanitizedFieldName = sanitizeAlphaNumeric(fieldName)

      // Find the matching field definition.
      const fieldDefinition = selectedImportDefinition.value.fields.find(
        (fieldDefinition: IImportFieldDefinition) => {
          if (fieldDefinition.name) {
            const targetFieldName = sanitizeAlphaNumeric(fieldDefinition.name)
            if (sanitizedFieldName === targetFieldName) {
              return fieldDefinition
            }
            if (Array.isArray(fieldDefinition.aliases)) {
              const found = fieldDefinition.aliases.find((alias: string) => {
                const sanitizedAlias = sanitizeAlphaNumeric(alias)
                if (sanitizedFieldName === sanitizedAlias) {
                  return fieldDefinition
                }
              })
              return found
            }
          }
        }
      )

      if (fieldDefinition) {
        return new ImportField(
          fieldDefinition.name,
          fieldDefinition.title,
          index,
          false,
          fieldDefinition.aliases,
          fieldDefinition.defaultValue,
          fieldDefinition.required,
          fieldDefinition.convert
        )
      }
    }
    return new ImportField(null, `<${i18n.t('labels.skip')}>`, index)
  }

  /**
   * Formats an error to be displayed in the error table after a file import is completed.
   * @param error An error instance.
   * @param item The item that generated an error.
   * @returns
   */
  const _formatError = (error: any, item: Record<string, any>): Array<string> => {
    const messages = []
    switch (error.name) {
      case 'SequelizeUniqueConstraintError': {
        const data: Array<any> = []
        const endpoint = selectedImportDefinition.value?.endpoint
        const keyFields = selectedImportDefinition.value?.keyFields || []
        if (keyFields.length > 0) {
          const message = _mapErrorFields(keyFields, endpoint!)
          keyFields.forEach((keyField) => {
            data.push(item[keyField])
          })
          messages.push(message)
        }
        const constraints = selectedImportDefinition.value?.constraints || []
        constraints.forEach((constraintFields) => {
          const message = _mapErrorFields(constraintFields, endpoint!)
          messages.push(message)
          constraintFields.forEach((constraintField) => {
            data.push(item[constraintField])
          })
        })
        messages.push(`${i18n.t('labels.checkData')}: [${data.join(', ')}]`)
        break
      }
      default: {
        messages.push(error.message)
        break
      }
    }
    return messages
  }

  /**
   * Returns true if the field should be ignored.
   * @param fieldName The field name to ignore.
   * @returns
   */
  const _ignoreField = (fieldName: string): boolean => {
    if (!selectedImportDefinition.value) {
      return false
    }

    let result = false
    const sanitizedFieldName = sanitizeAlphaNumeric(fieldName)

    if (Array.isArray(selectedImportDefinition.value.ignoredFields)) {
      const ignoredFields = selectedImportDefinition.value.ignoredFields
      result = ignoredFields.some((fieldName: string) => {
        const targetFieldName = sanitizeAlphaNumeric(fieldName)
        if (sanitizedFieldName === targetFieldName) {
          return true
        }
        return false
      })
    }
    return result
  }

  /**
   * Inserts a new item in the database.
   * @param item The item to be inserted int the database table.
   * @param row The row number from the input file used to create the item.
   */
  const _insertItem = async (item: Record<string, any>, row: number) => {
    if (selectedImportDefinition.value) {
      const { error } = await apiStore.save(selectedImportDefinition.value.endpoint, item, false)
      if (!error) {
        totalInserted.value++
      } else {
        importErrors.value.push({
          name: i18n.t(`errors.importTable.codes.${error.name}`),
          row,
          messages: _formatError(error, item)
        })
      }
    }
  }

  /**
   * Map the item values using a corresponding field definition. Set defaults and ensures optional fields are null.
   * @param data An array of strings corresponding to one row from the import file.
   * @returns
   */
  const _mapItem = (data: Array<string>): Record<string, any> => {
    const item: Record<string, any> = {}

    // When inserting, we need to initialize every value to null.
    if (importMode.value === ImportMode.Insert) {
      selectedImportDefinition.value?.fields.forEach((fieldDefinition: IImportFieldDefinition) => {
        item[fieldDefinition.name!] = isDefined(fieldDefinition.defaultValue)
          ? fieldDefinition.defaultValue
          : null
      })
    }

    selectedImportFields.value.forEach((importField: ImportField) => {
      if (isDefined(importField.name) && isDefined(importField.index)) {
        if (importField.convert) {
          item[importField.name!] = importField.convert(data[importField.index], importField)
        } else {
          item[importField.name!] = data[importField.index]
        }
      }
    })
    if (selectedImportDefinition.value?.addCalculatedFields) {
      selectedImportDefinition.value.addCalculatedFields(item)
    }
    return item
  }

  const _mapErrorFields = (fields: Array<string>, endpoint: EndPoint) => {
    const results = fields.map((field) => {
      return i18n.t(`fields.${endpoint}.${field}`)
    })
    const fieldNames = results.join(', ')
    return i18n.t(`errors.importTable.constraintError`, { fieldNames })
  }

  /**
   * Maps all import fields to each column from the import file with corresponding fields in selected database table.
   * @param header An array of strings that includes expected field names from the first row of the import file.
   */
  const _mapImportFields = (header: Array<string | null>): void => {
    selectedImportFields.value = []

    header.forEach((fieldName: string | null, index: number) => {
      const importField = _createImportField(fieldName, index)
      selectedImportFields.value.push(importField)
    })

    selectedFields.value = selectedImportFields.value.map((importField: ImportField) => {
      return importField.name
    })
  }

  /**
   * Overwrites the database with new item values.
   * @param item The item parsed from the input file.
   * @param row The row number from the input file used to create the item.
   */
  const _overwriteItem = async (item: any, row: number) => {
    if (selectedImportDefinition.value) {
      const queryOptions = _createQueryOptions(item)
      const response: ICollectionResponse = await apiStore.getMany(
        selectedImportDefinition.value.endpoint,
        queryOptions
      )
      if (response.data?.results.length) {
        response.data?.results.forEach(async (result) => {
          item.id = result.id
          const { error } = await apiStore.save(
            selectedImportDefinition.value!.endpoint,
            item,
            false
          )
          if (!error) {
            totalOverwritten.value++
          } else {
            importErrors.value.push({
              name: i18n.t(`errors.importTable.codes.${error.name}`),
              row,
              messages: _formatError(error, item)
            })
          }
        })
      } else {
        await _insertItem(item, row)
      }
    }
  }

  /**
   * Parses the raw file data using the Papa CSV library.
   * @param rawFileData Raw data string.
   */
  const _parseRawFileData = (rawFileData: string) => {
    const results: { data: Array<Array<string | null>> } = Papa.parse(rawFileData, {
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP, ' '],
      skipEmptyLines: true
    })

    _trimValues(results.data)
    _mapImportFields(results.data[0])

    // Do not include the first row if there is at least one match with a field definition.
    if (selectedImportFields.value.length > 0) {
      parsedFileHeaders.value = results.data.shift() || []
    }
    parsedFile.value = results
  }

  /**
   * Reads the file stream from the browser input selection.
   */
  const _readFile = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      try {
        reader.readAsText(selectedFile.value)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * Resets a specific column's import field to the user-selected option.
   * @param newFields Collection fo new selected fields.
   * @returns
   */
  const _remapFieldImportFields = (newFields: Array<string | null>): void => {
    let fieldName: string | null = null
    let fieldIndex: number = -1

    // Find the field that changed.
    const found = selectedImportFields.value.some((importField: ImportField, index: number) => {
      if (importField.name !== newFields[index]) {
        fieldName = newFields[index]
        fieldIndex = index
        return true
      }
      return false
    })

    // If there is no field found then back out.
    if (!found) {
      return
    }

    selectedImportFields.value[fieldIndex] = _createImportField(fieldName, fieldIndex)
  }

  /**
   * Trims any spaces before or after all data values. Nulls are kept intact.
   * @param data An array a of string values.
   */
  const _trimValues = (data: Array<Array<string | null>>): void => {
    data.forEach((row: Array<string | null>) => {
      row.forEach((item: string | null, index: number) => {
        if (isDefined(item)) {
          row[index] = item!.trim()
        } else {
          row[index] = null
        }
      })
    })
  }

  // FUNCTIONS - PUBLIC

  /**
   * Cancels the import process.
   */
  const cancelImport = () => {
    importState.value = ImportState.Cancelled
  }

  /**
   * Clear out the selected import file.
   */
  const clearFile = () => {
    steps.value[1].hasCompleted = false
  }

  /**
   * Returns converted field definitions to options that can be displayed by the Select component.
   */
  const getFieldOptions = (index: number) => {
    const header = parsedFileHeaders.value[index]
    const skipOption = {
      title: header ? `<${i18n.t('labels.skip')}:${header}>` : `<${i18n.t('labels.skip')}>`,
      value: null
    }
    const options: Array<ISimpleOption> = [skipOption]
    if (Array.isArray(selectedImportDefinition.value?.fields)) {
      selectedImportDefinition.value.fields.forEach((fieldDefinition: IImportFieldDefinition) => {
        options.push({
          title: fieldDefinition.title,
          value: fieldDefinition.name,
          props: {
            disabled: selectedFields.value.includes(fieldDefinition.name)
          }
        })
      })
    }
    return options
  }

  /**
   * Begin the file import process.
   */

  const importFile = async () => {
    totalInserted.value = 0
    totalOverwritten.value = 0
    importErrors.value = []
    let rowCount = 1
    importState.value = ImportState.InProgress
    for (const data of parsedFile.value.data) {
      let item
      try {
        item = _mapItem(data)
      } catch (error: any) {
        item = null
        importErrors.value.push({
          name: i18n.t(`errors.importTable.codes.${error.name}`),
          row: rowCount,
          messages: [error.messages]
        })
      }
      if (item) {
        switch (importMode.value) {
          case ImportMode.Insert: {
            await _insertItem(item, rowCount)
            break
          }
          case ImportMode.Overwrite: {
            await _overwriteItem(item, rowCount)
            break
          }
        }
      }
      rowCount++
    }
    importState.value = ImportState.Completed
  }

  /**
   * Reads and parses the import file.
   */
  const readFile = async () => {
    fileError.value = false
    steps.value[1].hasCompleted = false
    if (selectedFile.value) {
      try {
        const rawFileData = await _readFile()
        _parseRawFileData(rawFileData)
        if (parsedFile.value.errors.length === 0) {
          steps.value[1].hasCompleted = true
        } else {
          fileError.value = true
        }
      } catch (error) {
        fileError.value = true
      }
    }
  }

  /**
   * Resets the import wizard so it can be used for another import.
   * @param clearSelectedImportDefinition Optional flag that is used to skip clearing of the currently selected import.
   */
  const resetImport = (clearSelectedImportDefinition: boolean = true) => {
    currentStep.value = 1
    fileError.value = false
    importErrors.value = []
    selectedImportFields.value = []
    importState.value = ImportState.Ready
    parsedFile.value = []
    selectedFields.value = []
    selectedFile.value = undefined
    if (clearSelectedImportDefinition) {
      selectedImportDefinition.value = undefined
    }
    steps.value[0].hasCompleted = false
    steps.value[1].hasCompleted = false
    steps.value[2].hasCompleted = false
    steps.value[3].hasCompleted = false
    totalInserted.value = 0
    totalOverwritten.value = 0
  }

  return {
    currentStep,
    errorHeaders,
    fileError,
    importProgress,
    importMode,
    itemsPerPageOptions,
    importDefinitions,
    importErrors,
    importState,
    isImporting,
    missingFields,
    parsedFile,
    parsedFileHeaders,
    totalInserted,
    totalOverwritten,
    totalRecords,
    selectedFields,
    selectedImportFields,
    selectedFile,
    selectedImportDefinition,
    skipCount,
    steps,
    cancelImport,
    clearFile,
    getFieldOptions,
    importFile,
    readFile,
    resetImport
  }
})
