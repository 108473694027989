<template>
  <BaseDialog
    v-model="model"
    v-bind="$attrs"
    :max-width="800"
    :max-height="getSafeHeight(display, 600)"
    icon="mdi-calendar-export"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          Please select the calendar subscription format and follow the instructions. Calendar
          subscriptions are read-only. Events will be refreshed every 15 minutes. You can subscribe
          to multiple calendars.
        </v-col>
      </v-row>
      <v-row>
        <v-col style="margin-bottom: 0">
          <PrimarySelect
            v-model="subscriptionType"
            :label="t('labels.calendar.selectSubscriptionType')"
            :items="calendars"
          ></PrimarySelect>
          <PrimarySelect
            v-model="eventCategory"
            :label="t('labels.eventCategory')"
            :items="calendarStore.getEventCategories()"
          ></PrimarySelect>
          <PrimarySwitch
            v-model="includeOpenSlots"
            :label="t('labels.calendar.includeOpenSlots')"
          ></PrimarySwitch>
        </v-col>
      </v-row>
      <v-row v-if="subscriptionType === CalendarSubscriptionType.WebCal">
        <v-col>
          <p class="instructions">{{ t('labels.calendar.instructions.webcal') }}</p>
          <ButtonLink
            :href="subscribeLink"
            icon="mdi-calendar-export"
            :label="t('buttons.subscribe')"
          />
        </v-col>
      </v-row>
      <v-row v-else-if="subscriptionType === CalendarSubscriptionType.Google">
        <v-col>
          <p class="instructions">{{ t('labels.calendar.instructions.google') }}</p>
          <ButtonLink
            target="_blank"
            :href="subscribeLink"
            icon="mdi-open-in-new"
            :label="t('buttons.openCalendar')"
          />
        </v-col>
      </v-row>
      <v-row v-else-if="subscriptionType === CalendarSubscriptionType.DownloadFile">
        <v-col>
          <p class="instructions">{{ t('labels.calendar.instructions.download') }}</p>
          <ButtonLink :href="subscribeLink" icon="mdi-download" :label="t('buttons.download')" />
        </v-col>
      </v-row>
      <v-row v-else-if="subscriptionType === CalendarSubscriptionType.Outlook365">
        <v-col>
          <p class="instructions">{{ t('labels.calendar.instructions.outlook365') }}</p>
          <ButtonLink
            target="_blank"
            :href="subscribeLink"
            icon="mdi-open-in-new"
            :label="t('buttons.openCalendar')"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <p class="instructions">
            {{ t('labels.calendar.instructions.copy') }}
          </p>
          <ButtonIcon
            style="margin-top: 0.25rem"
            @click="copyToClipboard"
            :text="t('buttons.copyToClipboard')"
            icon="mdi-content-copy"
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseDialog>
</template>

<script setup lang="ts">
import { getSafeHeight } from '@/composables/utils'
import { CalendarSubscriptionType } from '@/enums/CalendarSubscriptionType'
import { EventCategory } from '@/enums/EventCategory'
import { MessageType } from '@/enums/MessageType'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay, type DisplayInstance } from 'vuetify'

// STORES, IMPORTS, & COMPOSABLES
const calendarStore = useCalendarStore()
const display: DisplayInstance = useDisplay()
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const model = defineModel<boolean>({ default: false })

// REACTIVE VARIABLES
const calendars = ref<Array<ISimpleOption>>([
  {
    title: 'Apple Calendar, Lotus Notes, Outlook for Windows, Thunderbird',
    value: CalendarSubscriptionType.WebCal
  },
  { title: 'Google Calendar', value: CalendarSubscriptionType.Google },
  { title: 'Copy as Link (manually subscribe)', value: CalendarSubscriptionType.CopyLink },
  { title: 'Download ICS File', value: CalendarSubscriptionType.DownloadFile },
  { title: 'Outlook 365, Outlook for Mac', value: CalendarSubscriptionType.Outlook365 },
  { title: 'Yahoo Calendar', value: CalendarSubscriptionType.Yahoo }
])

const includeOpenSlots = ref<boolean>(false)
const subscriptionType = ref<CalendarSubscriptionType>(CalendarSubscriptionType.WebCal)
const eventCategory = ref<EventCategory>(EventCategory.FitTest)

// COMPUTED PROPERTIES
const subscribeLink = computed(() => {
  let host = APP_ENV.VITE_APP_FITPRO_HOST

  let link = `${host}/calendar.ics?auth=${settingsStore.sapAccountId}&category=${eventCategory.value}`
  if (includeOpenSlots.value) {
    link += `&tentative=true`
  }
  const webcalLink = link.replace(location.protocol, 'webcal:')

  switch (subscriptionType.value) {
    case CalendarSubscriptionType.DownloadFile: {
      return link
    }
    case CalendarSubscriptionType.WebCal: {
      return webcalLink
    }
    case CalendarSubscriptionType.Google: {
      host = host.replace(location.protocol, 'webcal:')
      return `https://calendar.google.com/calendar/u/0/r?cid=${encodeURIComponent(webcalLink)}`
    }
    case CalendarSubscriptionType.Outlook365: {
      return `https://outlook.office.com/calendar/0/addfromweb?url=${encodeURIComponent(webcalLink)}`
    }
  }
  return link
})

// LIFECYCLE HOOKS

// FUNCTIONS
const copyToClipboard = () => {
  navigator.clipboard.writeText(subscribeLink.value)
  snackbarStore.addMessage(t('text.copiedToClipboard'), MessageType.Info)
}
</script>

<style lang="scss" scoped>
.instructions {
  margin-top: 0;
}
</style>
