<template>
  <BaseEditor
    v-bind="$attrs"
    v-model="store.showEditDialog"
    :allowEdit="allowEdit"
    :table-store-id="currentStore.$id"
    :title="title"
    :sub-title="personHeader"
    :min-height="getSafeHeight(display, 750)"
    :max-height="getSafeHeight(display, 750)"
    :max-width="1050"
    @save-item="saveItem"
    @cancel-edit="onCancelEdit"
    @close-view="onCloseView"
  >
    <v-tabs
      v-model="store.selectedTab"
      align-tabs="start"
      density="compact"
      color="primary"
      style="margin: 0; padding: 0"
    >
      <v-tab
        :disabled="isDisabled(ManagePersonTab.Info)"
        :key="ManagePersonTab.Info"
        :value="ManagePersonTab.Info"
      >
        {{ t('tabs.managePeople.info') }}
      </v-tab>
      <v-tab
        :disabled="isDisabled(ManagePersonTab.Training)"
        v-if="
          item.respiratorRequired && isDefined(item.id) && settingsStore.globals.training.enabled
        "
        :key="ManagePersonTab.Training"
        :value="ManagePersonTab.Training"
      >
        {{ t('tabs.managePeople.training') }}
      </v-tab>
      <v-tab
        :disabled="isDisabled(ManagePersonTab.FitTest)"
        v-if="item.respiratorRequired && isDefined(item.id)"
        :key="ManagePersonTab.FitTest"
        :value="ManagePersonTab.FitTest"
      >
        {{ t('tabs.managePeople.fitTest') }}
      </v-tab>
      <v-tab
        :disabled="isDisabled(ManagePersonTab.MedicalClearance)"
        v-if="
          item.respiratorRequired && isDefined(item.id) && settingsStore.globals.clearance.enabled
        "
        :key="ManagePersonTab.MedicalClearance"
        :value="ManagePersonTab.MedicalClearance"
      >
        {{ t('tabs.managePeople.clearance') }}
      </v-tab>
      <v-tab
        :disabled="isDisabled(ManagePersonTab.Appointments)"
        v-if="isDefined(item.id) && (item.respiratorRequired || item.isTestAdmin)"
        :key="ManagePersonTab.Appointments"
        :value="ManagePersonTab.Appointments"
      >
        {{ t('tabs.managePeople.appointments') }}
      </v-tab>
    </v-tabs>

    <v-divider />
    <v-tabs-window class="tab-content" v-model="store.selectedTab">
      <v-tabs-window-item :key="ManagePersonTab.Info" :value="ManagePersonTab.Info">
        <v-form :fast-fail="false" @submit.prevent ref="editPersonForm">
          <v-row v-if="store.selectedTab === ManagePersonTab.Info">
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" xxl="4">
              <PrimaryTextField
                required
                :label="t('fields.people.firstName')"
                :maxlength="64"
                v-model.trim="item.firstName"
                :rules="RulesManager.getRules(Rule.Required)"
              />
              <PrimaryTextField
                required
                :label="t('fields.people.lastName')"
                :maxlength="64"
                v-model.trim="item.lastName"
                :rules="RulesManager.getRules(Rule.Required)"
              />
              <PrimaryTextField
                required
                :label="t('fields.people.idNumber')"
                :maxlength="64"
                v-model.trim="item.idNumber"
                :rules="RulesManager.getRules(Rule.Required)"
                @blur="showIdNumberWarning"
              />
              <PrimarySelect
                v-if="supervisorOptions.length > 0"
                v-model="item.supervisorId"
                :label="t('fields.people.supervisor')"
                :items="supervisorOptions"
              ></PrimarySelect>
              <DisplayText
                v-if="supervisorOptions.length === 0 && isLoaded"
                :label="t('fields.people.supervisor')"
                :value="t('errors.noSupervisors')"
              >
                <template v-slot:icon>
                  <v-icon :color="Colors.accentError">mdi-alert-circle</v-icon>
                </template>
              </DisplayText>
              <PrimaryTextField
                required
                inputmode="email"
                type="email"
                :label="t('fields.people.email')"
                :maxlength="64"
                v-model.trim="item.email"
                :rules="RulesManager.getRules([Rule.ValidEmail, Rule.Required])"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" xxl="4">
              <TextSelectCombo
                style="margin-top: 1rem"
                v-for="fieldDefinition in customFieldsStore.activeFieldDefinitions"
                :key="fieldDefinition.id"
                :maxlength="64"
                v-model="item[fieldDefinition.valueField]"
                :rules="getCustomFieldRules(fieldDefinition.item)"
                :item="fieldDefinition.item"
                :select-options="fieldDefinition.selectOptions"
                :auto-values="fieldDefinition.autoValues"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" xxl="4">
              <PrimarySwitch
                :hideDetails="directReports.length === 0"
                :hint="t('warnings.disableInactiveSupervisor')"
                :persistent-hint="directReports.length > 0"
                :disabled="directReports.length > 0"
                :label="t('fields.people.active')"
                v-model="item.inactive"
                :true-value="false"
                :false-value="true"
              />
              <PrimarySwitch
                :label="t('fields.people.respiratorRequired')"
                v-model="item.respiratorRequired"
              />
              <PrimarySwitch :label="t('fields.people.isTestAdmin')" v-model="item.isTestAdmin" />
              <PrimarySwitch
                :hideDetails="directReports.length === 0"
                :hint="t('warnings.disableSupervisor')"
                :persistent-hint="directReports.length > 0"
                :disabled="directReports.length > 0"
                style="margin-bottom: 1rem"
                :label="t('fields.people.isSupervisor')"
                v-model="item.isSupervisor"
              />
              <PrimaryTextArea
                :maxlength="128"
                v-model="item.note"
                :label="t('fields.people.note')"
              />
              <div v-if="directReports?.length > 0">
                <DisplayText :label="t('labels.directReports')" :values="directReports" />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-tabs-window-item>
      <v-tabs-window-item
        :disabled="isDisabled(ManagePersonTab.FitTest)"
        :key="ManagePersonTab.FitTest"
        :value="ManagePersonTab.FitTest"
      >
        <div v-if="item.respiratorRequired && fitTestsStore.selectedItem">
          <div style="display: flex">
            <DisplayText
              :label="t('fields.people.testExpires')"
              :value="`${formatDate(fitTestsStore.selectedItem!.dueDate, false, settingsStore.localeCode)}`"
            >
              <template v-slot:icon>
                <StatusChip
                  :iso-date="fitTestsStore.selectedItem!.dueDate"
                  :days-warning="settingsStore.globals.testing.daysWarning"
                  :expires="true"
                  :mini="true"
                />
              </template>
            </DisplayText>
            <v-spacer />
            <FitTestStatus v-model="fitTestsStore.selectedItem!.overallPass" />
          </div>
          <v-form :fast-fail="false" @submit.prevent ref="editFitTestForm">
            <FitTestForm />
          </v-form>
        </div>
        <div v-else>
          <p>No Fit Tests saved.</p>
        </div>
      </v-tabs-window-item>
      <v-tabs-window-item
        :disabled="isDisabled(ManagePersonTab.Training)"
        :key="ManagePersonTab.Training"
        :value="ManagePersonTab.Training"
      >
        <v-form :fast-fail="false" @submit.prevent ref="editTrainingForm">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <DatePicker
                v-if="settingsStore.globals.training.enabled"
                v-model="trainingDate"
                :label="t('fields.people.trainingDate')"
                @update:model-value="updateTrainingExpiration"
              />
              <div style="position: relative">
                <DatePicker
                  v-model="trainingExpiration"
                  :label="t('fields.people.trainingExpiration')"
                />
                <StatusChip
                  style="position: absolute; top: 1.05rem; right: 0"
                  :iso-date="trainingExpiration?.toISOString()"
                  :days-warning="settingsStore.globals.training.daysWarning"
                  :expires="settingsStore.globals.training.expires"
                  :mini="true"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-tabs-window-item>
      <v-tabs-window-item
        :disabled="isDisabled(ManagePersonTab.MedicalClearance)"
        :key="ManagePersonTab.MedicalClearance"
        :value="ManagePersonTab.MedicalClearance"
      >
        <v-form :fast-fail="false" @submit.prevent ref="editMedicalClearanceForm">
          <MedicalClearanceForm />
        </v-form>
      </v-tabs-window-item>
      <v-tabs-window-item
        :disabled="isDisabled(ManagePersonTab.Appointments)"
        :key="ManagePersonTab.Appointments"
        :value="ManagePersonTab.Appointments"
      >
        <RadioGroup
          :mode="eventRoleMode"
          :label="t('labels.displayEvents')"
          v-model="eventRole"
          :items="eventRoleOptions"
          @update:model-value="onUserTypeChange"
        />

        <DataTable
          :items-per-page="5"
          :search-type="SearchType.Query"
          :show-new="false"
          :table-store-id="TableStoreId.Events"
          :no-data-text="t('text.noEventsScheduled')"
          :deleteWarning="t('dialogs.delete.warning', { name: t('dialogs.delete.eventName') })"
          :delete-title="t('dialogs.headers.deleteEvent')"
        >
        </DataTable>
        <EditEvent />
      </v-tabs-window-item>
    </v-tabs-window>
    <BaseDialog
      icon="mdi-alert"
      :icon-color="Colors.accentWarning"
      :title="t('dialogs.headers.fitTestRelationship')"
      v-model="showIdNumberDialog"
    >
      {{ t('warnings.fitTestRelationshipWarning') }}
    </BaseDialog>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { formatDate, getEarliestISODateTime, getSafeHeight, isDefined } from '@/composables/utils'
import { EditorMode } from '@/enums/EditorMode'
import { EventRole } from '@/enums/EventRole'
import { ManagePersonTab } from '@/enums/ManagePersonTab'
import { MessageType } from '@/enums/MessageType'
import { Rule } from '@/enums/Rule'
import { SearchType } from '@/enums/SearchType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import type { IMedicalClearancesStore } from '@/interfaces/api/IMedicalClearancesStore'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import type { CustomFieldRecord } from '@/models/CustomFieldRecord'
import { PeopleRecord } from '@/models/PeopleRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import Colors from '@/styles/_colors.module.scss'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay, type DisplayInstance } from 'vuetify'

const store = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
const customFieldsStore = TableStoreFactory.get(TableStoreId.CustomFields) as ICustomFieldsStore
const display: DisplayInstance = useDisplay()
const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
const fitTestsStore = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore
const medicalCearanceStore = TableStoreFactory.get(
  TableStoreId.MedicalClearances
) as IMedicalClearancesStore
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()

const { t } = useI18n({ useScope: 'global' })

const RulesManager = useRulesManager()

// PROPS & EMITS

// REACTIVE VARIABLES
const eventRole = ref<EventRole>(EventRole.Attendee)
const eventRoleOptions = ref<Array<ISimpleOption>>([
  { title: t('options.eventRole.asAttendee'), value: EventRole.Attendee },
  { title: t('options.eventRole.asOrganizer'), value: EventRole.Organizer }
])
const isLoaded = ref<boolean>(false)
const editPersonForm = ref<any>()
const editFitTestForm = ref<any>()
const editTrainingForm = ref<any>()
const editMedicalClearanceForm = ref<any>()
const showIdNumberDialog = ref<boolean>(false)
const supervisorOptions = ref<Array<ISimpleOption>>([])
const directReports = ref<Array<string>>([])

// COMPUTED PROPERTIES
const allowEdit = computed(() => {
  if (
    store.selectedTab === ManagePersonTab.FitTest &&
    !isDefined(currentStore.value.selectedItem?.id)
  ) {
    return false
  }
  if (store.selectedTab === ManagePersonTab.Appointments) {
    return false
  }
  return true
})

const eventRoleMode = computed(() => {
  if (!item.value?.respiratorRequired || !item.value?.isTestAdmin) {
    return EditorMode.View
  }
  return EditorMode.Edit
})

const item = computed(() => {
  if (!store.selectedItem) {
    return new PeopleRecord()
  }
  return store.selectedItem as PeopleRecord
})

const currentStore = computed(() => {
  switch (store.selectedTab) {
    case ManagePersonTab.FitTest: {
      return fitTestsStore
    }
    case ManagePersonTab.MedicalClearance: {
      return medicalCearanceStore
    }
    default: {
      return store
    }
  }
})

const trainingDate = computed({
  get: (): Date | null => {
    if (isDefined(item.value.trainingDate)) {
      return new Date(item.value.trainingDate!)
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.trainingDate = isDefined(value) ? value!.toISOString() : null
  }
})

const trainingExpiration = computed({
  get: (): Date | null => {
    if (isDefined(item.value.trainingExpiration)) {
      return new Date(item.value.trainingExpiration!)
    }
    if (trainingDate.value) {
      return dayjs(trainingDate.value)
        .add(settingsStore.globals.clearance.daysToExpire, 'days')
        .toDate()
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.trainingExpiration = isDefined(value) ? value!.toISOString() : null
  }
})

const title = computed((): string => {
  if (item.value?.id) {
    return t('dialogs.headers.editPerson')
  }
  return t('dialogs.headers.createPerson')
})

const personHeader = computed(() => {
  let results = []
  if (isDefined(store.selectedItem?.firstName)) {
    results.push(store.selectedItem?.firstName)
  }
  if (isDefined(store.selectedItem?.lastName)) {
    results.push(store.selectedItem?.lastName)
  }
  if (isDefined(store.selectedItem?.idNumber)) {
    results.push(`(${store.selectedItem?.idNumber})`)
  }
  return results.join(' ')
})

// LIFECYCLE HOOKS

// WATCHERS
watch(
  (): any => store.showEditDialog,
  (value) => {
    if (value) {
      loadData()
      loadEvents()
      if (isDefined(store.selectedItem?.id)) {
        if (store.selectedItem?.respiratorRequired) {
          loadFitTest()
        }
        if (item.value.respiratorRequired && settingsStore.globals.clearance.enabled) {
          loadMedicalClearance()
        }
      }
      store.selectedTab = ManagePersonTab.Info
    }
  }
)

// FUNCTIONS
const getCustomFieldRules = (
  customField: CustomFieldRecord | undefined,
  rules: Array<Rule> = []
): Array<Function | undefined> => {
  if (!customField) {
    return []
  }
  if (customField?.required === 1) {
    rules.push(Rule.Required)
  }
  return RulesManager.getRules(rules)
}

const isDisabled = (tab: ManagePersonTab) => {
  return currentStore.value.isDirty && store.selectedTab !== tab
}

const loadData = () => {
  const promises = []
  // Get all supervisors
  promises.push(
    store.getSupervisorOptions().then((options: Array<ISimpleOption>) => {
      supervisorOptions.value = options
    })
  )

  // Get all supervised
  if (item.value.id) {
    promises.push(
      store.getDirectReports(item.value.id).then((reports: Array<string>) => {
        directReports.value = reports
      })
    )
  }

  // Get custom field definitions
  promises.push(customFieldsStore.loadItems())
  Promise.all(promises).then(() => {
    isLoaded.value = true
  })
}

const loadEvents = async () => {
  if (item.value?.id) {
    // Ensure the default is available.
    if (item.value.respiratorRequired && !item.value.isTestAdmin) {
      eventRole.value = EventRole.Attendee
    }
    if (!item.value.respiratorRequired && item.value.isTestAdmin) {
      eventRole.value = EventRole.Organizer
    }

    const date = getEarliestISODateTime()
    const today = date.toISOString()
    if (eventRole.value === EventRole.Attendee) {
      eventsStore.setEventsFilterForAttendee(item.value.id, today)
    } else {
      eventsStore.setEventsFilterForOrganizer(item.value.id, today)
    }
    eventsStore.loadItems()
  }
}

const loadFitTest = async () => {
  fitTestsStore.selectByIdNumber(item.value.idNumber!)
}

const loadMedicalClearance = async () => {
  await medicalCearanceStore.selectByPersonId(item.value.id!)
}

const onCancelEdit = (reverted: boolean) => {
  switch (store.selectedTab) {
    case ManagePersonTab.Appointments:
    case ManagePersonTab.MedicalClearance: {
      if (currentStore.value.isDirty === false && !reverted) {
        store.showEditDialog = false
      }
      break
    }
  }
}

const onCloseView = () => {
  if (currentStore.value.isDirty === false || !isDefined(currentStore.value.selectedItem?.id)) {
    store.showEditDialog = false
  }
}

const onUserTypeChange = () => {
  loadEvents()
}

const saveFitTest = async () => {
  const { valid } = await editFitTestForm.value.validate()
  if (!valid) {
    return
  }
  await fitTestsStore.save(true, true)
  await store.loadItems()
  snackbarStore.addMessage(t('text.fitTestUpdated'), MessageType.Info)
}

const saveMedicalClearance = async () => {
  const { valid } = await editMedicalClearanceForm.value.validate()
  if (!valid) {
    return
  }
  await medicalCearanceStore.save(true, true)
  await store.loadItems()
  snackbarStore.addMessage(t('text.medicalClearanceUpdated'), MessageType.Info)
}

const savePerson = async () => {
  if (store.selectedTab === ManagePersonTab.Info) {
    const { valid } = await editPersonForm.value.validate()
    if (!valid) {
      return
    }
  }

  if (store.selectedTab === ManagePersonTab.Training) {
    const { valid } = await editTrainingForm.value.validate()
    if (!valid) {
      return
    }
  }

  // Sanitize the person's custom fields before saving
  customFieldsStore.sanitizePerson(store.selectedItem!)
  const response = await store.save(true, false, false)
  const oldIdNumber = store.selectedItem?.idNumber
  const newIdNumber = store.backupItem?.idNumber
  if (!response.error && isDefined(oldIdNumber) && isDefined(newIdNumber)) {
    await fitTestsStore.updateFitTestsByPerson(oldIdNumber, newIdNumber)
  }
}

const saveItem = async () => {
  switch (currentStore.value.$id) {
    case TableStoreId.FitTests: {
      await saveFitTest()
      break
    }
    case TableStoreId.MedicalClearances: {
      await saveMedicalClearance()
      break
    }
    default: {
      await savePerson()
      break
    }
  }
}

const showIdNumberWarning = () => {
  const oldIdNumber = store.backupItem?.idNumber
  const newIdNumber = store.selectedItem?.idNumber
  if (isDefined(item.value.id) && oldIdNumber !== newIdNumber) {
    showIdNumberDialog.value = true
  }
}

const updateTrainingExpiration = () => {
  if (trainingDate.value) {
    trainingExpiration.value = dayjs(trainingDate.value)
      .add(settingsStore.globals.training.daysToExpire, 'days')
      .toDate()
  } else {
    trainingExpiration.value = null
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  margin-top: 1rem;
}
</style>
