<template>
  <div>
    <DataTable
      :table-store-id="TableStoreId.Devices"
      :delete-warning="deleteWarning"
      :delete-title="t('dialogs.headers.deleteDevice')"
      @new="createNewItem"
    >
      <template v-slot:item.calCertId="{ item, value }">
        <div v-if="value > 0">
          <v-icon @click="downloadOrPreviewFile(item)">mdi-download</v-icon>
        </div>
        <div v-else>-</div>
      </template>
      <template v-slot:item.lastCalibration="{ value }">
        <StatusChip
          :iso-date="value"
          :days-to-expire="settingsStore.globals.devices.daysToExpireCalibration"
          :days-warning="settingsStore.globals.devices.daysWarningCalibration"
        />
      </template>
      <template v-slot:item.planExpiration="{ value }">
        <StatusChip
          :iso-date="value"
          :days-warning="settingsStore.globals.devices.daysWarningPlan"
          :display-days-until-expired="true"
        />
      </template>
      <template v-slot:item.planInfo="{ item }">
        <v-icon v-if="(item as DeviceRecord).planInfo" size="small" @click="showPlanInfo(item)">
          mdi-note-text
        </v-icon>
        <span v-else>-</span>
      </template>

      <template v-slot:footer>
        <EditDevice />
      </template>
    </DataTable>
    <a ref="hiddenAnchor" style="display: none" :download="downloadName" :href="encodedFile"></a>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { TableStoreId } from '@/enums/TableStoreId'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { DeviceRecord } from '@/models/DeviceRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const store = TableStoreFactory.get(TableStoreId.Devices) as IDevicesStore
const filesStore = TableStoreFactory.get(TableStoreId.Files) as ITableStore
const settingsStore = useSettingsStore()

// PROPS & EMITS

// REACTIVE VARIABLES
const downloadName = ref<string>('')
const encodedFile = ref<string>('')
const hiddenAnchor = ref()
const isDownloading = ref<boolean>(false)

// COMPUTED PROPERTIES
const deleteWarning = computed((): string => {
  if (store.selectedItem?.name) {
    return t('dialogs.delete.warning', { name: store.selectedItem.name })
  }
  return t('dialogs.delete.warning')
})

// LIFECYCLE HOOKS

// FUNCTIONS
const createNewItem = () => {
  store.createNewItem(new DeviceRecord())
}

const downloadOrPreviewFile = async (item: DeviceRecord) => {
  if (isDownloading.value) {
    return
  }
  isDownloading.value = true
  if (item.calCertId) {
    const response = await filesStore.getOne(item.calCertId)
    if (!response.error && response.data) {
      const type: string = response.data.type
      const previewable = ['application/pdf', 'image/png', 'image/jpeg', 'image/gif']
      if (previewable.includes(type)) {
        window.open(`./preview?id=${item.calCertId}`)
      } else {
        downloadName.value = response.data.name
        encodedFile.value = response.data.encodedFile
        await nextTick()
        hiddenAnchor.value.click()
      }
    }
  }
  isDownloading.value = false
}

const showPlanInfo = (item: DeviceRecord) => {
  if (item.planInfo) {
    let title = t('dialogs.headers.showPlanInfoDevice')
    title += ` ${item.serialNumber}:`
    store.showInfo(title, item.planInfo)
  }
}
</script>

<style lang="scss" scoped>
.options {
  padding: 0rem;
  margin: 0.25rem 0 0.25rem 0;
  max-width: 400px;
}

.status-duesoon {
  font-size: 0.75rem;
  padding: 0.25rem;
  background-color: $color-accent-warning;
}

.status-overdue {
  font-size: 0.75rem;
  padding: 0.25rem;
  background-color: $color-accent-error;
}

.status-compliant {
  font-size: 0.75rem;
  padding: 0.25rem;
  background-color: $color-accent-success;
}
</style>
