import type { EventCategory } from '@/enums/EventCategory'
import { TableRecord } from './TableRecord'

export class EmailTemplateRecord extends TableRecord {
  public code!: string
  public locale!: string
  public category!: EventCategory
  public subject!: string
  public content!: string
  public enabled!: boolean
}
