<template>
  <BaseEditor
    v-bind="$attrs"
    v-model="store.showEditDialog"
    :table-store-id="TableStoreId.EmailTemplates"
    :title="t('dialogs.headers.editEmailTemplate')"
    :max-width="1100"
    @save-item="saveItem"
  >
    <v-form :fast-fail="false" @submit.prevent ref="editEmailTemplateForm">
      <v-container fluid>
        <PrimaryTextField
          required
          :label="t('labels.subject')"
          :maxlength="255"
          v-model.trim="item.subject"
          :rules="RulesManager.getRules(Rule.Required)"
        />
        <PrimarySwitch
          :label="item.enabled ? t('labels.emailEnabled') : t('labels.emailNotEnabled')"
          v-model.trim="item.enabled"
        />
        <div style="max-width: 1000px">
          <RichTextEditor v-model="item.content" :rules="RulesManager.getRules(Rule.Required)" />
        </div>
      </v-container>
    </v-form>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { Rule } from '@/enums/Rule'
import { TableStoreId } from '@/enums/TableStoreId'
import { EmailTemplateRecord } from '@/models/EmailTemplateRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const RulesManager = useRulesManager()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const editEmailTemplateForm = ref<any>()
const store = TableStoreFactory.get(TableStoreId.EmailTemplates)

// COMPUTED PROPERTIES
const item = computed(() => {
  if (!store.selectedItem) {
    return new EmailTemplateRecord()
  }
  return store.selectedItem as EmailTemplateRecord
})

// LIFECYCLE HOOKS

// WATCHERS

// FUNCTIONS
const saveItem = async () => {
  const { valid } = await editEmailTemplateForm.value.validate()
  if (!valid) {
    return
  }
  await store.save()
}
</script>

<style lang="scss" scoped></style>
