import tinymce from 'tinymce'
export const loadLocales = () => {
  tinymce.addI18n('de-DE', {
    '#': '#',
    Accessibility: 'Barrierefreiheit',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: 'Aktion',
    Activity: 'Aktivit\xe4t',
    Address: 'Adresse',
    'Add Variable': 'Variable hinzufügen',
    Advanced: 'Erweitert',
    Align: 'Ausrichtung',
    'Align center': 'Zentrieren',
    'Align left': 'Linksb\xfcndig ausrichten',
    'Align right': 'Rechtsb\xfcndig ausrichten',
    Alignment: 'Ausrichtung',
    'Alignment {0}': '',
    All: 'Alle',
    'Alternative description': 'Alternative Beschreibung',
    'Alternative source': 'Alternative Quelle',
    'Alternative source URL': 'URL der alternativen Quelle',
    Anchor: 'Anker',
    'Anchor...': 'Textmarke',
    Anchors: 'Anker',
    'Animals and Nature': 'Tiere und Natur',
    Arrows: 'Pfeile',
    B: 'B',
    'Background color': 'Hintergrundfarbe',
    'Background color {0}': '',
    Black: 'Schwarz',
    Block: 'Blocksatz',
    'Block {0}': '',
    Blockquote: 'Blockzitat',
    Blocks: 'Bl\xf6cke',
    Blue: 'Blau',
    'Blue component': 'Blauanteil',
    Body: 'Inhalt',
    Bold: 'Fett',
    Border: 'Rahmen',
    'Border color': 'Rahmenfarbe',
    'Border style': 'Rahmenstil',
    'Border width': 'Rahmenbreite',
    Bottom: 'Unten',
    'Browse files': '',
    'Browse for an image': 'Bild...',
    'Browse links': '',
    'Bullet list': 'Aufz\xe4hlung',
    Cancel: 'Abbrechen',
    Caption: 'Beschriftung',
    Cell: 'Zelle',
    'Cell padding': 'Zelleninnenabstand',
    'Cell properties': 'Zelleigenschaften',
    'Cell spacing': 'Zellenabstand',
    'Cell styles': 'Zellstil',
    'Cell type': 'Zelltyp',
    Center: 'Zentriert',
    Characters: 'Zeichen',
    'Characters (no spaces)': 'Zeichen (ohne Leerzeichen)',
    Circle: 'Kreis',
    Class: 'Klasse',
    'Clear formatting': 'Formatierung entfernen',
    Close: 'Schlie\xdfen',
    Code: 'Code',
    'Code sample...': 'Codebeispiel...',
    'Code view': 'Code Ansicht',
    'Color Picker': 'Farbwahl',
    'Color swatch': 'Farbpalette',
    Cols: 'Spalten',
    Column: 'Spalte',
    'Column clipboard actions': 'Spalten-Zwischenablage-Aktionen',
    'Column group': 'Spaltengruppe',
    'Column header': 'Spaltenkopf',
    'Constrain proportions': 'Seitenverh\xe4ltnis beibehalten',
    Copy: 'Kopieren',
    'Copy column': 'Spalte kopieren',
    'Copy row': 'Zeile kopieren',
    'Could not find the specified string.': 'Die angegebene Zeichenfolge wurde nicht gefunden.',
    'Could not load emojis': 'Emojis konnten nicht geladen werden',
    Count: 'Anzahl',
    Currency: 'W\xe4hrung',
    'Current window': 'Aktuelles Fenster',
    'Custom color': 'Benutzerdefinierte Farbe',
    'Custom...': 'Benutzerdefiniert...',
    Cut: 'Ausschneiden',
    'Cut column': 'Spalte ausschneiden',
    'Cut row': 'Zeile ausschneiden',
    'Dark Blue': 'Dunkelblau',
    'Dark Gray': 'Dunkelgrau',
    'Dark Green': 'Dunkelgr\xfcn',
    'Dark Orange': 'Dunkelorange',
    'Dark Purple': 'Dunkellila',
    'Dark Red': 'Dunkelrot',
    'Dark Turquoise': 'Dunkelt\xfcrkis',
    'Dark Yellow': 'Dunkelgelb',
    Dashed: 'Gestrichelt',
    'Date/time': 'Datum/Uhrzeit',
    'Decrease indent': 'Einzug verkleinern',
    Default: 'Standard',
    'Delete accordion': '',
    'Delete column': 'Spalte l\xf6schen',
    'Delete row': 'Zeile l\xf6schen',
    'Delete table': 'Tabelle l\xf6schen',
    Dimensions: 'Abmessungen',
    Disc: 'Scheibe',
    Div: 'Div',
    Document: 'Dokument',
    Dotted: 'Gepunktet',
    Double: 'Doppelt',
    'Drop an image here': 'Bild hier ablegen',
    'Dropped file type is not supported': 'Hereingezogener Dateityp wird nicht unterst\xfctzt',
    Edit: 'Bearbeiten',
    Embed: 'Einbettung',
    Emojis: 'Emojis',
    'Emojis...': 'Emojis...',
    Error: 'Fehler',
    'Error: Form submit field collision.': 'Fehler: Kollision der Formularbest\xe4tigungsfelder.',
    'Error: No form element found.': 'Fehler: Kein Formularelement gefunden.',
    'Extended Latin': 'Erweitertes Latein',
    'Failed to initialize plugin: {0}': 'Plugin konnte nicht initialisiert werden: {0}',
    'Failed to load plugin url: {0}': 'Plugin-URL konnte nicht geladen werden: {0}',
    'Failed to load plugin: {0} from url {1}':
      'Plugin konnte nicht geladen werden: {0} von URL {1}',
    'Failed to upload image: {0}': 'Bild konnte nicht hochgeladen werden: {0}',
    File: 'Datei',
    Find: 'Suchen',
    'Find (if searchreplace plugin activated)':
      'Suchen (wenn Suchen/Ersetzen-Plugin aktiviert ist)',
    'Find and Replace': 'Suchen und Ersetzen',
    'Find and replace...': 'Suchen und ersetzen...',
    'Find in selection': 'In Auswahl suchen',
    'Find whole words only': 'Nur ganze W\xf6rter suchen',
    Flags: 'Flaggen',
    'Focus to contextual toolbar': 'Fokus auf kontextbezogene Symbolleiste',
    'Focus to element path': 'Fokus auf Elementpfad',
    'Focus to menubar': 'Fokus auf Men\xfcleiste',
    'Focus to toolbar': 'Fokus auf Symbolleiste',
    Font: 'Schriftart',
    'Font size {0}': '',
    'Font sizes': 'Schriftgr\xf6\xdfen',
    'Font {0}': '',
    Fonts: 'Schriftarten',
    'Food and Drink': 'Essen und Trinken',
    Footer: 'Fu\xdfzeile',
    Format: 'Format',
    'Format {0}': '',
    Formats: 'Formate',
    Fullscreen: 'Vollbild',
    G: 'G',
    General: 'Allgemein',
    Gray: 'Grau',
    Green: 'Gr\xfcn',
    'Green component': 'Gr\xfcnanteil',
    Groove: 'Gekantet',
    'Handy Shortcuts': 'Praktische Tastenkombinationen',
    Header: 'Kopfzeile',
    'Header cell': 'Kopfzelle',
    'Heading 1': '\xdcberschrift 1',
    'Heading 2': '\xdcberschrift 2',
    'Heading 3': '\xdcberschrift 3',
    'Heading 4': '\xdcberschrift 4',
    'Heading 5': '\xdcberschrift 5',
    'Heading 6': '\xdcberschrift 6',
    Headings: '\xdcberschriften',
    Height: 'H\xf6he',
    Help: 'Hilfe',
    'Hex color code': 'Hexadezimal-Farbwert',
    Hidden: 'Unsichtbar',
    'Horizontal align': 'Horizontal ausrichten',
    'Horizontal line': 'Horizontale Linie',
    'Horizontal space': 'Horizontaler Raum',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'Die ID muss mit einem Buchstaben beginnen gefolgt von Buchstaben, Zahlen, Bindestrichen, Punkten, Doppelpunkten oder Unterstrichen.',
    'Image is decorative': 'Bild ist dekorativ',
    'Image list': 'Bildliste',
    'Image title': 'Bildtitel',
    'Image...': 'Bild...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      'Image Proxy HTTP Fehler: Kann Image Proxy nicht finden',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      'Image Proxy HTTP Fehler: Falsche Image Proxy URL',
    'ImageProxy HTTP error: Rejected request': 'Image Proxy HTTP Fehler: Abgewiesene Anfrage',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      'Image Proxy HTTP Fehler: Unbekannter Image Proxy Fehler',
    'Increase indent': 'Einzug vergr\xf6\xdfern',
    Inline: 'Zeichenformate',
    Insert: 'Einf\xfcgen',
    'Insert Template': 'Vorlage einf\xfcgen',
    'Insert accordion': '',
    'Insert column after': 'Neue Spalte danach einf\xfcgen',
    'Insert column before': 'Neue Spalte davor einf\xfcgen',
    'Insert date/time': 'Datum/Uhrzeit einf\xfcgen',
    'Insert image': 'Bild einf\xfcgen',
    'Insert link (if link plugin activated)': 'Link einf\xfcgen (wenn Link-Plugin aktiviert ist)',
    'Insert row after': 'Neue Zeile danach einf\xfcgen',
    'Insert row before': 'Neue Zeile davor einf\xfcgen',
    'Insert table': 'Tabelle einf\xfcgen',
    'Insert template...': 'Vorlage einf\xfcgen...',
    'Insert video': 'Video einf\xfcgen',
    'Insert/Edit code sample': 'Codebeispiel einf\xfcgen/bearbeiten',
    'Insert/edit image': 'Bild einf\xfcgen/bearbeiten',
    'Insert/edit link': 'Link einf\xfcgen/bearbeiten',
    'Insert/edit media': 'Medien einf\xfcgen/bearbeiten',
    'Insert/edit video': 'Video einf\xfcgen/bearbeiten',
    Inset: 'Eingelassen',
    'Invalid hex color code: {0}': 'Ung\xfcltiger Hexadezimal-Farbwert: {0}',
    'Invalid input': 'Ung\xfcltige Eingabe',
    Italic: 'Kursiv',
    Justify: 'Blocksatz',
    'Keyboard Navigation': 'Tastaturnavigation',
    Language: 'Sprache',
    'Learn more...': 'Erfahren Sie mehr dazu...',
    Left: 'Links',
    'Left to right': 'Von links nach rechts',
    'Light Blue': 'Hellblau',
    'Light Gray': 'Hellgrau',
    'Light Green': 'Hellgr\xfcn',
    'Light Purple': 'Helllila',
    'Light Red': 'Hellrot',
    'Light Yellow': 'Hellgelb',
    'Line height': 'Liniendicke',
    'Link list': 'Linkliste',
    'Link...': 'Link...',
    'List Properties': 'Liste Eigenschaften',
    'List properties...': 'Liste Eigenschaften',
    'Loading emojis...': 'Lade Emojis...',
    'Loading...': 'Wird geladen...',
    'Lower Alpha': 'Lateinisches Alphabet in Kleinbuchstaben',
    'Lower Greek': 'Griechische Kleinbuchstaben',
    'Lower Roman': 'Kleiner r\xf6mischer Buchstabe',
    'Match case': 'Gro\xdf-/Kleinschreibung beachten',
    Mathematical: 'Mathematisch',
    'Media poster (Image URL)': 'Medienposter (Bild-URL)',
    'Media...': 'Medien...',
    'Medium Blue': 'Mittleres Blau',
    'Medium Gray': 'Mittelgrau',
    'Medium Purple': 'Mittelviolett',
    'Merge cells': 'Zellen verbinden',
    Middle: 'Mitte',
    'Midnight Blue': 'Mitternachtsblau',
    'More...': 'Mehr...',
    Name: 'Name',
    'Navy Blue': 'Marineblau',
    'New document': 'Neues Dokument',
    'New window': 'Neues Fenster',
    Next: 'N\xe4chste',
    No: 'Nein',
    'No alignment': 'Keine Ausrichtung',
    'No color': 'Keine Farbe',
    'Nonbreaking space': 'Gesch\xfctztes Leerzeichen',
    None: 'Keine',
    'Numbered list': 'Nummerierte Liste',
    OR: 'ODER',
    Objects: 'Objekte',
    Ok: 'Ok',
    'Open help dialog': 'Hilfe-Dialog \xf6ffnen',
    'Open link': 'Link \xf6ffnen',
    'Open link in...': 'Link \xf6ffnen in...',
    'Open popup menu for split buttons': '\xd6ffne Popup Menge um Buttons zu trennen',
    Orange: 'Orange',
    Outset: 'Hervorstehend',
    'Page break': 'Seitenumbruch',
    Paragraph: 'Absatz',
    Paste: 'Einf\xfcgen',
    'Paste as text': 'Als Text einf\xfcgen',
    'Paste column after': 'Spalte danach einf\xfcgen',
    'Paste column before': 'Spalte davor einf\xfcgen',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      'Einf\xfcgen ist nun im unformatierten Textmodus. Inhalte werden ab jetzt als unformatierter Text eingef\xfcgt, bis Sie diese Einstellung wieder deaktivieren.',
    'Paste or type a link': 'Link einf\xfcgen oder eingeben',
    'Paste row after': 'Zeile danach einf\xfcgen',
    'Paste row before': 'Zeile davor einf\xfcgen',
    'Paste your embed code below:': 'F\xfcgen Sie Ihren Einbettungscode unten ein:',
    People: 'Menschen',
    Plugins: 'Plugins',
    'Plugins installed ({0}):': 'Installierte Plugins ({0}):',
    'Powered by {0}': 'Betrieben von {0}',
    Pre: 'Pre',
    Preferences: 'Einstellungen',
    Preformatted: 'Vorformatiert',
    'Premium plugins:': 'Premium-Plugins:',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: 'Vorschau',
    Previous: 'Vorherige',
    Print: 'Drucken',
    'Print...': 'Drucken...',
    Purple: 'Violett',
    Quotations: 'Anf\xfchrungszeichen',
    R: 'R',
    'Range 0 to 255': 'Spanne 0 bis 255',
    Red: 'Rot',
    'Red component': 'Rotanteil',
    Redo: 'Wiederholen',
    Remove: 'Entfernen',
    'Remove color': 'Farbauswahl aufheben',
    'Remove link': 'Link entfernen',
    Replace: 'Ersetzen',
    'Replace all': 'Alle ersetzen',
    'Replace with': 'Ersetzen durch',
    Resize: 'Skalieren',
    'Restore last draft': 'Letzten Entwurf wiederherstellen',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': 'Rich-Text-Area',
    'Rich Text Area. Press ALT-0 for help.':
      'Rich-Text-Bereich. Dr\xfccken Sie Alt+0 f\xfcr Hilfe.',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      'Rich-Text-Bereich. Dr\xfccken Sie Alt+F9 f\xfcr das Men\xfc. Dr\xfccken Sie Alt+F10 f\xfcr die Symbolleiste. Dr\xfccken Sie Alt+0 f\xfcr Hilfe.',
    Ridge: 'Eingeritzt',
    Right: 'Rechts',
    'Right to left': 'Von rechts nach links',
    Row: 'Zeile',
    'Row clipboard actions': 'Zeilen-Zwischenablage-Aktionen',
    'Row group': 'Zeilengruppe',
    'Row header': 'Zeilenkopf',
    'Row properties': 'Zeileneigenschaften',
    'Row type': 'Zeilentyp',
    Rows: 'Zeilen',
    Save: 'Speichern',
    'Save (if save plugin activated)': 'Speichern (wenn Save-Plugin aktiviert ist)',
    Scope: 'Bereich',
    Search: 'Suchen',
    'Select all': 'Alles ausw\xe4hlen',
    'Select...': 'Auswahl...',
    Selection: 'Auswahl',
    Shortcut: 'Tastenkombination',
    'Show blocks': 'Bl\xf6cke anzeigen',
    'Show caption': 'Beschriftung anzeigen',
    'Show invisible characters': 'Unsichtbare Zeichen anzeigen',
    Size: 'Schriftgr\xf6\xdfe',
    Solid: 'Durchgezogen',
    Source: 'Quelle',
    'Source code': 'Quellcode',
    'Special Character': 'Sonderzeichen',
    'Special character...': 'Sonderzeichen...',
    'Split cell': 'Zelle aufteilen',
    Square: 'Rechteck',
    'Start list at number': 'Beginne Liste mit Nummer',
    Strikethrough: 'Durchgestrichen',
    Style: 'Formatvorlage',
    Subscript: 'Tiefgestellt',
    Superscript: 'Hochgestellt',
    'Switch to or from fullscreen mode': 'Vollbildmodus umschalten',
    Symbols: 'Symbole',
    'System Font': 'Betriebssystemschriftart',
    Table: 'Tabelle',
    'Table caption': 'Tabellenbeschriftung',
    'Table properties': 'Tabelleneigenschaften',
    'Table styles': 'Tabellenstil',
    Template: 'Vorlage',
    Templates: 'Vorlagen',
    Text: 'Text',
    'Text color': 'Textfarbe',
    'Text color {0}': '',
    'Text to display': 'Anzuzeigender Text',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      'Diese URL scheint eine E-Mail-Adresse zu sein. M\xf6chten Sie das dazu ben\xf6tigte mailto: voranstellen?',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      'Diese URL scheint ein externer Link zu sein. M\xf6chten Sie das dazu ben\xf6tigte http:// voranstellen?',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      'Die eingegebene URL scheint ein externer Link zu sein. Soll das fehlende https:// davor erg\xe4nzt werden?',
    Title: 'Titel',
    'To open the popup, press Shift+Enter':
      'Dr\xfccken Sie Umschalt+Eingabe, um das Popup-Fenster zu \xf6ffnen.',
    'Toggle accordion': '',
    Tools: 'Werkzeuge',
    Top: 'Oben',
    'Travel and Places': 'Reisen und Orte',
    Turquoise: 'T\xfcrkis',
    Underline: 'Unterstrichen',
    Undo: 'R\xfcckg\xe4ngig machen',
    Upload: 'Hochladen',
    'Uploading image': 'Bild wird hochgeladen',
    'Upper Alpha': 'Lateinisches Alphabet in Gro\xdfbuchstaben',
    'Upper Roman': 'Gro\xdfer r\xf6mischer Buchstabe',
    Url: 'URL',
    'User Defined': 'Benutzerdefiniert',
    Valid: 'G\xfcltig',
    Version: 'Version',
    'Vertical align': 'Vertikal ausrichten',
    'Vertical space': 'Vertikaler Raum',
    View: 'Ansicht',
    'Visual aids': 'Visuelle Hilfen',
    Warn: 'Warnung',
    White: 'Wei\xdf',
    Width: 'Breite',
    'Word count': 'Anzahl der W\xf6rter',
    Words: 'W\xf6rter',
    'Words: {0}': 'Wortzahl: {0}',
    Yellow: 'Gelb',
    Yes: 'Ja',
    'You are using {0}': 'Sie verwenden {0}',
    'You have unsaved changes are you sure you want to navigate away?':
      'Die \xc4nderungen wurden noch nicht gespeichert. Sind Sie sicher, dass Sie diese Seite verlassen wollen?',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      'Ihr Browser unterst\xfctzt leider keinen direkten Zugriff auf die Zwischenablage. Bitte benutzen Sie die Tastenkombinationen Strg+X/C/V.',
    alignment: 'Ausrichtung',
    'austral sign': 'Australzeichen',
    'cedi sign': 'Cedizeichen',
    'colon sign': 'Doppelpunkt',
    'cruzeiro sign': 'Cruzeirozeichen',
    'currency sign': 'W\xe4hrungssymbol',
    'dollar sign': 'Dollarzeichen',
    'dong sign': 'Dongzeichen',
    'drachma sign': 'Drachmezeichen',
    'euro-currency sign': 'Eurozeichen',
    example: 'Beispiel',
    formatting: 'Formatierung',
    'french franc sign': 'Franczeichen',
    'german penny symbol': 'Pfennigzeichen',
    'guarani sign': 'Guaranizeichen',
    history: 'Historie',
    'hryvnia sign': 'Hrywnjazeichen',
    indentation: 'Einr\xfcckungen',
    'indian rupee sign': 'Indisches Rupiezeichen',
    'kip sign': 'Kipzeichen',
    'lira sign': 'Lirezeichen',
    'livre tournois sign': 'Livrezeichen',
    'manat sign': 'Manatzeichen',
    'mill sign': 'Millzeichen',
    'naira sign': 'Nairazeichen',
    'new sheqel sign': 'Schekelzeichen',
    'nordic mark sign': 'Zeichen nordische Mark',
    'peseta sign': 'Pesetazeichen',
    'peso sign': 'Pesozeichen',
    'ruble sign': 'Rubelzeichen',
    'rupee sign': 'Rupiezeichen',
    'spesmilo sign': 'Spesmilozeichen',
    styles: 'Stile',
    'tenge sign': 'Tengezeichen',
    'tugrik sign': 'Tugrikzeichen',
    'turkish lira sign': 'T\xfcrkisches Lirazeichen',
    'won sign': 'Wonzeichen',
    'yen character': 'Yenzeichen',
    'yen/yuan character variant one': 'Yen-/Yuanzeichen Variante 1',
    'yuan character': 'Yuanzeichen',
    'yuan character, in hong kong and taiwan': 'Yuanzeichen in Hongkong und Taiwan',
    '{0} characters': '{0}\xa0Zeichen',
    '{0} columns, {1} rows': '',
    '{0} words': '{0} W\xf6rter'
  })
  tinymce.addI18n('es-ES', {
    '#': '#',
    Accessibility: 'Accesibilidad',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: 'Acci\xf3n',
    Activity: 'Actividad',
    Address: 'Direcci\xf3n',
    'Add Variable': 'Agregar variable',
    Advanced: 'Avanzado',
    Align: 'Alinear',
    'Align center': 'Alinear al centro',
    'Align left': 'Alinear a la izquierda',
    'Align right': 'Alinear a la derecha',
    Alignment: 'Alineaci\xf3n',
    'Alignment {0}': '',
    All: 'Todo',
    'Alternative description': 'Descripci\xf3n alternativa',
    'Alternative source': 'Enlace alternativo',
    'Alternative source URL': 'Origen de URL alternativo',
    Anchor: 'Anclaje',
    'Anchor...': 'Anclaje...',
    Anchors: 'Anclajes',
    'Animals and Nature': 'Animales y naturaleza',
    Arrows: 'Flechas',
    B: 'B',
    'Background color': 'Color de fondo',
    'Background color {0}': '',
    Black: 'Negro',
    Block: 'Bloque',
    'Block {0}': '',
    Blockquote: 'Cita en bloque',
    Blocks: 'Bloques',
    Blue: 'Azul',
    'Blue component': 'Componente azul',
    Body: 'Cuerpo',
    Bold: 'Negrita',
    Border: 'Borde',
    'Border color': 'Color de borde',
    'Border style': 'Estilo de borde',
    'Border width': 'Ancho de borde',
    Bottom: 'Inferior',
    'Browse files': '',
    'Browse for an image': 'Buscar una imagen',
    'Browse links': '',
    'Bullet list': 'Lista de vi\xf1etas',
    Cancel: 'Cancelar',
    Caption: 'Leyenda',
    Cell: 'Celda',
    'Cell padding': 'Relleno de celda',
    'Cell properties': 'Propiedades de la celda',
    'Cell spacing': 'Espacio entre celdas',
    'Cell styles': 'Estilos de celda',
    'Cell type': 'Tipo de celda',
    Center: 'Centro',
    Characters: 'Caracteres',
    'Characters (no spaces)': 'Caracteres (sin espacios)',
    Circle: 'C\xedrculo',
    Class: 'Clase',
    'Clear formatting': 'Limpiar formato',
    Close: 'Cerrar',
    Code: 'C\xf3digo',
    'Code sample...': 'Ejemplo de c\xf3digo...',
    'Code view': 'Vista de c\xf3digo',
    'Color Picker': 'Selector de colores',
    'Color swatch': 'Muestrario de colores',
    Cols: 'Columnas',
    Column: 'Columna',
    'Column clipboard actions': 'Acciones del portapapeles de la columna',
    'Column group': 'Grupo de columnas',
    'Column header': 'Encabezado de columna',
    'Constrain proportions': 'Restringir proporciones',
    Copy: 'Copiar',
    'Copy column': 'Copiar columna',
    'Copy row': 'Copiar fila',
    'Could not find the specified string.': 'No se encuentra la cadena especificada.',
    'Could not load emojis': 'No se pudieron cargar los emojis',
    Count: 'Recuento',
    Currency: 'Divisa',
    'Current window': 'Ventana actual',
    'Custom color': 'Color personalizado',
    'Custom...': 'Personalizado...',
    Cut: 'Cortar',
    'Cut column': 'Cortar columna',
    'Cut row': 'Cortar fila',
    'Dark Blue': 'Azul oscuro',
    'Dark Gray': 'Gris oscuro',
    'Dark Green': 'Verde oscuro',
    'Dark Orange': 'Naranja oscuro',
    'Dark Purple': 'Morado oscuro',
    'Dark Red': 'Rojo oscuro',
    'Dark Turquoise': 'Turquesa oscuro',
    'Dark Yellow': 'Amarillo oscuro',
    Dashed: 'Guiones',
    'Date/time': 'Fecha/hora',
    'Decrease indent': 'Disminuir sangr\xeda',
    Default: 'Por defecto',
    'Delete accordion': '',
    'Delete column': 'Eliminar columna',
    'Delete row': 'Eliminar fila',
    'Delete table': 'Eliminar tabla',
    Dimensions: 'Dimensiones',
    Disc: 'Disco',
    Div: 'Div',
    Document: 'Documento',
    Dotted: 'Puntos',
    Double: 'Doble',
    'Drop an image here': 'Arrastre una imagen aqu\xed',
    'Dropped file type is not supported': 'No se soporta el archivo arrastrado',
    Edit: 'Editar',
    Embed: 'Insertar',
    Emojis: 'Emojis',
    'Emojis...': 'Emojis...',
    Error: 'Error',
    'Error: Form submit field collision.': 'Error: Colisi\xf3n de campo al enviar formulario.',
    'Error: No form element found.': 'Error: No se encuentra ning\xfan elemento de formulario.',
    'Extended Latin': 'Latino extendido A',
    'Failed to initialize plugin: {0}': 'Fallo al iniciar el complemento: {0}',
    'Failed to load plugin url: {0}': 'Fallo al cargar URL del complemento: {0}',
    'Failed to load plugin: {0} from url {1}': 'Fallo al cargar complemento: {0} desde URL {1}',
    'Failed to upload image: {0}': 'Fallo al cargar imagen: {0}',
    File: 'Archivo',
    Find: 'Buscar',
    'Find (if searchreplace plugin activated)':
      'Buscar (si el complemento buscar-reemplazar est\xe1 activado)',
    'Find and Replace': 'Buscar y Reemplazar',
    'Find and replace...': 'Buscar y reemplazar...',
    'Find in selection': 'Buscar en la selecci\xf3n',
    'Find whole words only': 'Solo palabras completas',
    Flags: 'Banderas',
    'Focus to contextual toolbar': 'Enfocar la barra de herramientas contextual',
    'Focus to element path': 'Enfocar la ruta del elemento',
    'Focus to menubar': 'Enfocar la barra del men\xfa',
    'Focus to toolbar': 'Enfocar la barra de herramientas',
    Font: 'Fuente',
    'Font size {0}': '',
    'Font sizes': 'Tama\xf1os de fuente',
    'Font {0}': '',
    Fonts: 'Fuentes',
    'Food and Drink': 'Comida y bebida',
    Footer: 'Pie de p\xe1gina',
    Format: 'Formato',
    'Format {0}': '',
    Formats: 'Formatos',
    Fullscreen: 'Pantalla completa',
    G: 'G',
    General: 'General',
    Gray: 'Gris',
    Green: 'Verde',
    'Green component': 'Componente verde',
    Groove: 'Groove',
    'Handy Shortcuts': 'Accesos pr\xe1cticos',
    Header: 'Encabezado',
    'Header cell': 'Celda de encabezado',
    'Heading 1': 'Encabezado 1',
    'Heading 2': 'Encabezado 2',
    'Heading 3': 'Encabezado 3',
    'Heading 4': 'Encabezado 4',
    'Heading 5': 'Encabezado 5',
    'Heading 6': 'Encabezado 6',
    Headings: 'Encabezados',
    Height: 'Altura',
    Help: 'Ayuda',
    'Hex color code': 'C\xf3digo de color hexadecimal',
    Hidden: 'Oculto',
    'Horizontal align': 'Alineaci\xf3n horizontal',
    'Horizontal line': 'L\xednea horizontal',
    'Horizontal space': 'Espacio horizontal',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'El ID deber\xeda comenzar con una letra y seguir solo con letras, n\xfameros, guiones, puntos, dos puntos o guiones bajos.',
    'Image is decorative': 'La imagen es decorativa',
    'Image list': 'Lista de im\xe1genes',
    'Image title': 'Titulo de imagen',
    'Image...': 'Imagen...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      'Error HTTP de Image Proxy: no se ha podido encontrar Image Proxy',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      'Error HTTP de Image Proxy: la URL de Image Proxy no es correcta',
    'ImageProxy HTTP error: Rejected request': 'Error HTTP de Image Proxy: petici\xf3n rechazada',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      'Error HTTP de Image Proxy: error desconocido de Image Proxy',
    'Increase indent': 'Incrementar sangr\xeda',
    Inline: 'Alineado',
    Insert: 'Insertar',
    'Insert Template': 'Insertar Plantilla',
    'Insert accordion': '',
    'Insert column after': 'Insertar columna despu\xe9s',
    'Insert column before': 'Insertar columna antes',
    'Insert date/time': 'Insertar fecha/hora',
    'Insert image': 'Insertar imagen',
    'Insert link (if link plugin activated)':
      'Insertar enlace (si el complemento de enlace est\xe1 activado)',
    'Insert row after': 'Insertar fila despu\xe9s',
    'Insert row before': 'Insertar fila antes',
    'Insert table': 'Insertar tabla',
    'Insert template...': 'Insertar plantilla...',
    'Insert video': 'Insertar v\xeddeo',
    'Insert/Edit code sample': 'Insertar/editar ejemplo de c\xf3digo',
    'Insert/edit image': 'Insertar/editar imagen',
    'Insert/edit link': 'Insertar/editar enlace',
    'Insert/edit media': 'Insertar/editar medio',
    'Insert/edit video': 'Insertar/editar v\xeddeo',
    Inset: 'Insertar',
    'Invalid hex color code: {0}': 'Color hexadecimal no v\xe1lido: {0}',
    'Invalid input': 'Entrada inv\xe1lida',
    Italic: 'Cursiva',
    Justify: 'Justificar',
    'Keyboard Navigation': 'Navegaci\xf3n con el teclado',
    Language: 'Idioma',
    'Learn more...': 'M\xe1s informaci\xf3n...',
    Left: 'Izquierda',
    'Left to right': 'Izquierda a derecha',
    'Light Blue': 'Azul claro',
    'Light Gray': 'Gris claro',
    'Light Green': 'Verde claro',
    'Light Purple': 'Morado claro',
    'Light Red': 'Rojo claro',
    'Light Yellow': 'Amarillo claro',
    'Line height': 'Altura de l\xednea',
    'Link list': 'Lista de enlaces',
    'Link...': 'Enlace...',
    'List Properties': 'Propiedades de Lista',
    'List properties...': 'Propiedades de Lista...',
    'Loading emojis...': 'Cargando emojis...',
    'Loading...': 'Cargando...',
    'Lower Alpha': 'Letras min\xfasculas',
    'Lower Greek': 'Griego en min\xfasculas',
    'Lower Roman': 'Romano en min\xfasculas',
    'Match case': 'Coincidir may\xfasculas y min\xfasculas',
    Mathematical: 'S\xedmbolo matem\xe1tico',
    'Media poster (Image URL)': 'P\xf3ster de medio (URL de imagen)',
    'Media...': 'Medios...',
    'Medium Blue': 'Azul medio',
    'Medium Gray': 'Gris medio',
    'Medium Purple': 'P\xfarpura medio',
    'Merge cells': 'Combinar celdas',
    Middle: 'Central',
    'Midnight Blue': 'Azul medio',
    'More...': 'M\xe1s...',
    Name: 'Nombre',
    'Navy Blue': 'Azul marino',
    'New document': 'Nuevo documento',
    'New window': 'Nueva ventana',
    Next: 'Siguiente',
    No: 'No',
    'No alignment': 'Sin alineaci\xf3n',
    'No color': 'Sin color',
    'Nonbreaking space': 'Espacio de no separaci\xf3n',
    None: 'Ninguno',
    'Numbered list': 'Lista numerada',
    OR: 'O',
    Objects: 'Objetos',
    Ok: 'Ok',
    'Open help dialog': 'Abrir di\xe1logo de ayuda',
    'Open link': 'Abrir enlace',
    'Open link in...': 'Abrir enlace en...',
    'Open popup menu for split buttons': 'Abrir men\xfa emergente para botones de separado',
    Orange: 'Naranja',
    Outset: 'Comienzo',
    'Page break': 'Salto de p\xe1gina',
    Paragraph: 'P\xe1rrafo',
    Paste: 'Pegar',
    'Paste as text': 'Pegar como texto',
    'Paste column after': 'Pegar columna despu\xe9s',
    'Paste column before': 'Pegar columna antes',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      'Pegar est\xe1 ahora en modo de texto sin formato. El contenido se pegar\xe1 ahora como texto sin formato hasta que desactive esta opci\xf3n.',
    'Paste or type a link': 'Pegue o escriba un enlace',
    'Paste row after': 'Pegar la fila despu\xe9s',
    'Paste row before': 'Pegar la fila antes',
    'Paste your embed code below:': 'Pegue el c\xf3digo para insertar a continuaci\xf3n:',
    People: 'Personas',
    Plugins: 'Complementos',
    'Plugins installed ({0}):': 'Complementos instalados ({0}):',
    'Powered by {0}': 'Con tecnolog\xeda de {0}',
    Pre: 'Pre',
    Preferences: 'Preferencias',
    Preformatted: 'Con formato previo',
    'Premium plugins:': 'Complementos premium:',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: 'Previsualizar',
    Previous: 'Anterior',
    Print: 'Imprimir',
    'Print...': 'Imprimir...',
    Purple: 'P\xfarpura',
    Quotations: 'Comillas',
    R: 'R',
    'Range 0 to 255': 'Rango de 0 a 255',
    Red: 'Rojo',
    'Red component': 'Componente rojo',
    Redo: 'Rehacer',
    Remove: 'Quitar',
    'Remove color': 'Quitar color',
    'Remove link': 'Quitar enlace',
    Replace: 'Reemplazar',
    'Replace all': 'Reemplazar todo',
    'Replace with': 'Reemplazar por',
    Resize: 'Redimensionar',
    'Restore last draft': 'Restaurar el \xfaltimo borrador',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': '\xc1rea de Texto Enriquecido',
    'Rich Text Area. Press ALT-0 for help.':
      '\xc1rea de texto enriquecido. Pulse ALT-0 para abrir la ayuda.',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      '\xc1rea de texto enriquecido. Pulse ALT-F9 para el men\xfa. Pulse ALT-F10 para la barra de herramientas. Pulse ALT-0 para la ayuda.',
    Ridge: 'Cresta',
    Right: 'Derecha',
    'Right to left': 'Derecha a izquierda',
    Row: 'Fila',
    'Row clipboard actions': 'Acciones del portapapeles de la fila',
    'Row group': 'Grupo de filas',
    'Row header': 'Encabezado de fila',
    'Row properties': 'Propiedades de la fila',
    'Row type': 'Tipo de fila',
    Rows: 'Filas',
    Save: 'Guardar',
    'Save (if save plugin activated)': 'Guardar (si el complemento de guardar est\xe1 activado)',
    Scope: '\xc1mbito',
    Search: 'Buscar',
    'Select all': 'Seleccionar todo',
    'Select...': 'Seleccionar...',
    Selection: 'Selecci\xf3n',
    Shortcut: 'Acceso directo',
    'Show blocks': 'Mostrar bloques',
    'Show caption': 'Mostrar t\xedtulo',
    'Show invisible characters': 'Mostrar caracteres invisibles',
    Size: 'Tama\xf1o',
    Solid: 'S\xf3lido',
    Source: 'C\xf3digo fuente',
    'Source code': 'C\xf3digo fuente',
    'Special Character': 'Car\xe1cter especial',
    'Special character...': 'Car\xe1cter especial...',
    'Split cell': 'Dividir celda',
    Square: 'Cuadrado',
    'Start list at number': 'Iniciar lista con un n\xfamero',
    Strikethrough: 'Tachado',
    Style: 'Estilo',
    Subscript: 'Sub\xedndice',
    Superscript: 'Super\xedndice',
    'Switch to or from fullscreen mode': 'Activar o desactivar modo pantalla completa',
    Symbols: 'S\xedmbolos',
    'System Font': 'Fuente de sistema',
    Table: 'Tabla',
    'Table caption': 'T\xedtulo de la tabla',
    'Table properties': 'Propiedades de la tabla',
    'Table styles': 'Estilos de tabla',
    Template: 'Plantilla',
    Templates: 'Plantillas',
    Text: 'Texto',
    'Text color': 'Color del texto',
    'Text color {0}': '',
    'Text to display': 'Texto que mostrar',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      'La URL que ha introducido parece ser una direcci\xf3n de correo electr\xf3nico. \xbfQuiere a\xf1adir el prefijo necesario mailto:?',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      'La URL que ha introducido parece ser un enlace externo. \xbfQuiere a\xf1adir el prefijo necesario http://?',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      'La URL que ha introducido parece ser un enlace externo. \xbfQuiere a\xf1adir el prefijo necesario https://?',
    Title: 'T\xedtulo',
    'To open the popup, press Shift+Enter':
      'Para abrir el elemento emergente, pulse May\xfas+Intro',
    'Toggle accordion': '',
    Tools: 'Herramientas',
    Top: 'Superior',
    'Travel and Places': 'Viajes y lugares',
    Turquoise: 'Turquesa',
    Underline: 'Subrayado',
    Undo: 'Deshacer',
    Upload: 'Cargar',
    'Uploading image': 'Subiendo imagen',
    'Upper Alpha': 'Letras may\xfasculas',
    'Upper Roman': 'Romano en may\xfasculas',
    Url: 'URL',
    'User Defined': 'Definido por el usuario',
    Valid: 'V\xe1lido',
    Version: 'Versi\xf3n',
    'Vertical align': 'Alineaci\xf3n vertical',
    'Vertical space': 'Espacio vertical',
    View: 'Ver',
    'Visual aids': 'Ayudas visuales',
    Warn: 'Advertencia',
    White: 'Blanco',
    Width: 'Ancho',
    'Word count': 'Contar palabras',
    Words: 'Palabras',
    'Words: {0}': 'Palabras: {0}',
    Yellow: 'Amarillo',
    Yes: 'S\xed',
    'You are using {0}': 'Est\xe1 usando {0}',
    'You have unsaved changes are you sure you want to navigate away?':
      'Tiene cambios sin guardar. \xbfEst\xe1 seguro de que quiere salir?',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      'Su navegador no es compatible con el acceso directo al portapapeles. Use las teclas Crtl+X/C/V de su teclado.',
    alignment: 'alineaci\xf3n',
    'austral sign': 'signo de austral',
    'cedi sign': 'signo de cedi',
    'colon sign': 'signo de dos puntos',
    'cruzeiro sign': 'signo de cruceiro',
    'currency sign': 'signo de divisa',
    'dollar sign': 'signo de d\xf3lar',
    'dong sign': 'signo de dong',
    'drachma sign': 'signo de dracma',
    'euro-currency sign': 'signo de euro',
    example: 'ejemplo',
    formatting: 'formato',
    'french franc sign': 'signo de franco franc\xe9s',
    'german penny symbol': 'signo de penique alem\xe1n',
    'guarani sign': 'signo de guaran\xed',
    history: 'historial',
    'hryvnia sign': 'signo de grivna',
    indentation: 'sangr\xeda',
    'indian rupee sign': 'signo de rupia india',
    'kip sign': 'signo de kip',
    'lira sign': 'signo de lira',
    'livre tournois sign': 'signo de libra tornesa',
    'manat sign': 'signo de manat',
    'mill sign': 'signo de mill',
    'naira sign': 'signo de naira',
    'new sheqel sign': 'signo de nuevo s\xe9quel',
    'nordic mark sign': 'signo de marco n\xf3rdico',
    'peseta sign': 'signo de peseta',
    'peso sign': 'signo de peso',
    'ruble sign': 'signo de rublo',
    'rupee sign': 'signo de rupia',
    'spesmilo sign': 'signo de spesmilo',
    styles: 'estilos',
    'tenge sign': 'signo de tenge',
    'tugrik sign': 'signo de tugrik',
    'turkish lira sign': 'signo de lira turca',
    'won sign': 'signo de won',
    'yen character': 'car\xe1cter de yen',
    'yen/yuan character variant one': 'Variante uno de car\xe1cter de yen/yuan',
    'yuan character': 'car\xe1cter de yuan',
    'yuan character, in hong kong and taiwan': 'car\xe1cter de yuan en Hong Kong y Taiw\xe1n',
    '{0} characters': '{0} caracteres',
    '{0} columns, {1} rows': '',
    '{0} words': '{0} palabras'
  })
  tinymce.addI18n('fr-FR', {
    '#': '#',
    Accessibility: 'Accessibilit\xe9',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: 'Action',
    Activity: 'Activit\xe9',
    Address: 'Adresse',
    'Add Variable': 'Ajouter une variable',
    Advanced: 'Options avanc\xe9es',
    Align: 'Aligner',
    'Align center': 'Centrer',
    'Align left': 'Aligner \xe0 gauche',
    'Align right': 'Aligner \xe0 droite',
    Alignment: 'Alignement',
    'Alignment {0}': '',
    All: 'Tout',
    'Alternative description': 'Description alternative',
    'Alternative source': 'Source alternative',
    'Alternative source URL': 'URL de la source alternative',
    Anchor: 'Ancre',
    'Anchor...': 'Ancre...',
    Anchors: 'Ancres',
    'Animals and Nature': 'Animaux & nature',
    Arrows: 'Fl\xe8ches',
    B: 'B',
    'Background color': "Couleur d'arri\xe8re-plan",
    'Background color {0}': '',
    Black: 'Noir',
    Block: 'Bloc',
    'Block {0}': '',
    Blockquote: 'Bloc de citation',
    Blocks: 'Blocs',
    Blue: 'Bleu',
    'Blue component': 'Composant bleu',
    Body: 'Corps',
    Bold: 'Gras',
    Border: 'Bordure',
    'Border color': 'Couleur de bordure',
    'Border style': 'Style de la bordure',
    'Border width': '\xc9paisseur de la bordure',
    Bottom: 'En bas',
    'Browse files': '',
    'Browse for an image': 'Rechercher une image',
    'Browse links': '',
    'Bullet list': 'Liste \xe0 puces',
    Cancel: 'Annuler',
    Caption: 'L\xe9gende',
    Cell: 'Cellule',
    'Cell padding': 'Marge int\xe9rieure des cellules',
    'Cell properties': 'Propri\xe9t\xe9s de la cellule',
    'Cell spacing': 'Espacement entre les cellules',
    'Cell styles': 'Type de cellule',
    'Cell type': 'Type de cellule',
    Center: 'Centre',
    Characters: 'Caract\xe8res',
    'Characters (no spaces)': 'Caract\xe8res (espaces non compris)',
    Circle: 'Cercle',
    Class: 'Classe',
    'Clear formatting': 'Effacer la mise en forme',
    Close: 'Fermer',
    Code: 'Code',
    'Code sample...': 'Exemple de code...',
    'Code view': 'Affichage du code',
    'Color Picker': 'S\xe9lecteur de couleurs',
    'Color swatch': '\xc9chantillon de couleurs',
    Cols: 'Colonnes',
    Column: 'Colonne',
    'Column clipboard actions': 'Actions du presse-papiers des colonnes',
    'Column group': 'Groupe de colonnes',
    'Column header': 'En-t\xeate de colonne',
    'Constrain proportions': 'Limiter les proportions',
    Copy: 'Copier',
    'Copy column': 'Copier la colonne',
    'Copy row': 'Copier la ligne',
    'Could not find the specified string.': 'Impossible de trouver la cha\xeene sp\xe9cifi\xe9e.',
    'Could not load emojis': 'Impossible de charger les emojis',
    Count: 'Total',
    Currency: 'Devise',
    'Current window': 'Fen\xeatre active',
    'Custom color': 'Couleur personnalis\xe9e',
    'Custom...': 'Personnalis\xe9e...',
    Cut: 'Couper',
    'Cut column': 'Couper la colonne',
    'Cut row': 'Couper la ligne',
    'Dark Blue': 'Bleu fonc\xe9',
    'Dark Gray': 'Gris fonc\xe9',
    'Dark Green': 'Vert fonc\xe9',
    'Dark Orange': 'Orange fonc\xe9',
    'Dark Purple': 'Violet fonc\xe9',
    'Dark Red': 'Rouge fonc\xe9',
    'Dark Turquoise': 'Turquoise fonc\xe9',
    'Dark Yellow': 'Jaune fonc\xe9',
    Dashed: 'Tirets',
    'Date/time': 'Date/heure',
    'Decrease indent': 'R\xe9duire le retrait',
    Default: 'Par d\xe9faut',
    'Delete accordion': '',
    'Delete column': 'Supprimer la colonne',
    'Delete row': 'Supprimer la ligne',
    'Delete table': 'Supprimer le tableau',
    Dimensions: 'Dimensions',
    Disc: 'Disque',
    Div: 'Div',
    Document: 'Document',
    Dotted: 'Pointill\xe9',
    Double: 'Deux traits continus',
    'Drop an image here': 'D\xe9poser une image ici',
    'Dropped file type is not supported':
      "Le type de fichier d\xe9pos\xe9 n'est pas pris en charge",
    Edit: 'Modifier',
    Embed: 'Incorporer',
    Emojis: '\xc9mojis',
    'Emojis...': '\xc9mojis...',
    Error: 'Erreur',
    'Error: Form submit field collision.':
      'Erreur\xa0: conflit de champs lors de la soumission du formulaire.',
    'Error: No form element found.': 'Erreur : aucun \xe9l\xe9ment de formulaire trouv\xe9.',
    'Extended Latin': 'Latin \xe9tendu',
    'Failed to initialize plugin: {0}': "\xc9chec d'initialisation du plug-in\xa0: {0}",
    'Failed to load plugin url: {0}': "\xc9chec de chargement de l'URL du plug-in\xa0: {0}",
    'Failed to load plugin: {0} from url {1}':
      '\xc9chec de chargement du plug-in\xa0: {0} \xe0 partir de l\u2019URL {1}',
    'Failed to upload image: {0}': "\xc9chec d'envoi de l'image\xa0: {0}",
    File: 'Fichier',
    Find: 'Rechercher',
    'Find (if searchreplace plugin activated)':
      'Rechercher (si le plug-in searchreplace est activ\xe9)',
    'Find and Replace': 'Trouver et remplacer',
    'Find and replace...': 'Trouver et remplacer...',
    'Find in selection': 'Trouver dans la s\xe9lection',
    'Find whole words only': 'Mot entier',
    Flags: 'Drapeaux',
    'Focus to contextual toolbar': "Mettre le focus sur la barre d'outils contextuelle",
    'Focus to element path': "Mettre le focus sur le chemin vers l'\xe9l\xe9ment",
    'Focus to menubar': 'Mettre le focus sur la barre de menu',
    'Focus to toolbar': "Mettre le focus sur la barre d'outils",
    Font: 'Police',
    'Font size {0}': '',
    'Font sizes': 'Tailles de police',
    'Font {0}': '',
    Fonts: 'Polices',
    'Food and Drink': 'Nourriture & boissons',
    Footer: 'Pied de page',
    Format: 'Format',
    'Format {0}': '',
    Formats: 'Formats',
    Fullscreen: 'Plein \xe9cran',
    G: 'V',
    General: 'G\xe9n\xe9ral',
    Gray: 'Gris',
    Green: 'Vert',
    'Green component': 'Composant vert',
    Groove: 'Sculpt\xe9',
    'Handy Shortcuts': 'Raccourcis utiles',
    Header: 'En-t\xeate',
    'Header cell': "Cellule d'en-t\xeate",
    'Heading 1': 'Titre\xa01',
    'Heading 2': 'Titre\xa02',
    'Heading 3': 'Titre\xa03',
    'Heading 4': 'Titre\xa04',
    'Heading 5': 'Titre\xa05',
    'Heading 6': 'Titre\xa06',
    Headings: 'Titres',
    Height: 'Hauteur',
    Help: 'Aide',
    'Hex color code': 'Code couleur hexad\xe9cimal',
    Hidden: 'Masqu\xe9',
    'Horizontal align': 'Alignement horizontal',
    'Horizontal line': 'Ligne horizontale',
    'Horizontal space': 'Espace horizontal',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      "L'ID doit commencer par une lettre, suivie uniquement par des lettres, num\xe9ros, tirets, points, deux-points et underscores.",
    'Image is decorative': "L'image est d\xe9corative",
    'Image list': 'Liste des images',
    'Image title': "Titre d'image",
    'Image...': 'Image...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      "Erreur HTTP d'ImageProxy : Impossible de trouver ImageProxy",
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      "Erreur HTTP d'ImageProxy : URL de ImageProxy incorrecte",
    'ImageProxy HTTP error: Rejected request': "Erreur HTTP d'ImageProxy : Requ\xeate rejet\xe9e",
    'ImageProxy HTTP error: Unknown ImageProxy error':
      "Erreur HTTP d'ImageProxy : Erreur ImageProxy inconnue",
    'Increase indent': 'Augmenter le retrait',
    Inline: 'En ligne',
    Insert: 'Ins\xe9rer',
    'Insert Template': 'Ins\xe9rer le mod\xe8le',
    'Insert accordion': '',
    'Insert column after': 'Ins\xe9rer une colonne apr\xe8s',
    'Insert column before': 'Ins\xe9rer une colonne avant',
    'Insert date/time': 'Ins\xe9rer date/heure',
    'Insert image': 'Ins\xe9rer une image',
    'Insert link (if link plugin activated)':
      'Ins\xe9rer un lien (si le plug-in link est activ\xe9)',
    'Insert row after': 'Ins\xe9rer une ligne apr\xe8s',
    'Insert row before': 'Ins\xe9rer une ligne avant',
    'Insert table': 'Ins\xe9rer un tableau',
    'Insert template...': 'Ins\xe9rer un mod\xe8le...',
    'Insert video': 'Ins\xe9rer une vid\xe9o',
    'Insert/Edit code sample': 'Ins\xe9rer / modifier un bloc de code',
    'Insert/edit image': 'Ins\xe9rer/modifier image',
    'Insert/edit link': 'Ins\xe9rer/modifier lien',
    'Insert/edit media': 'Ins\xe9rer/modifier un m\xe9dia',
    'Insert/edit video': 'Ins\xe9rer/modifier une vid\xe9o',
    Inset: 'Incrust\xe9',
    'Invalid hex color code: {0}': 'Code couleur hexad\xe9cimal invalide : {0}',
    'Invalid input': 'Entr\xe9e invalide',
    Italic: 'Italique',
    Justify: 'Justifier',
    'Keyboard Navigation': 'Navigation au clavier',
    Language: 'Langue',
    'Learn more...': 'En savoir plus...',
    Left: 'Gauche',
    'Left to right': 'De gauche \xe0 droite',
    'Light Blue': 'Bleu clair',
    'Light Gray': 'Gris clair',
    'Light Green': 'Vert clair',
    'Light Purple': 'Violet clair',
    'Light Red': 'Rouge clair',
    'Light Yellow': 'Jaune clair',
    'Line height': 'Hauteur de la ligne',
    'Link list': 'Liste des liens',
    'Link...': 'Lien...',
    'List Properties': 'Propri\xe9t\xe9s de la liste',
    'List properties...': 'Lister les propri\xe9t\xe9s...',
    'Loading emojis...': 'Chargement des emojis...',
    'Loading...': 'Chargement...',
    'Lower Alpha': 'Alphabet en minuscules',
    'Lower Greek': 'Alphabet grec en minuscules',
    'Lower Roman': 'Chiffre romain inf\xe9rieur',
    'Match case': 'Respecter la casse',
    Mathematical: 'Op\xe9rateurs math\xe9matiques',
    'Media poster (Image URL)': "Affiche de m\xe9dia (URL de l'image)",
    'Media...': 'M\xe9dia...',
    'Medium Blue': 'Bleu moyen',
    'Medium Gray': 'Gris moyen',
    'Medium Purple': 'Violet moyen',
    'Merge cells': 'Fusionner les cellules',
    Middle: 'Au milieu',
    'Midnight Blue': 'Bleu de minuit',
    'More...': 'Plus...',
    Name: 'Nom',
    'Navy Blue': 'Bleu marine',
    'New document': 'Nouveau document',
    'New window': 'Nouvelle fen\xeatre',
    Next: 'Suivante',
    No: 'Non',
    'No alignment': 'Aucun alignement',
    'No color': 'Aucune couleur',
    'Nonbreaking space': 'Espace ins\xe9cable',
    None: 'Aucun',
    'Numbered list': 'Liste num\xe9rot\xe9e',
    OR: 'OU',
    Objects: 'Objets',
    Ok: 'OK',
    'Open help dialog': "Ouvrir la bo\xeete de dialogue d'aide",
    'Open link': 'Ouvrir le lien',
    'Open link in...': 'Ouvrir le lien dans...',
    'Open popup menu for split buttons': 'Ouvrir le menu contextuel pour les boutons partag\xe9s',
    Orange: 'Orange',
    Outset: 'Relief',
    'Page break': 'Saut de page',
    Paragraph: 'Paragraphe',
    Paste: 'Coller',
    'Paste as text': 'Coller comme texte',
    'Paste column after': 'Coller la colonne apr\xe8s',
    'Paste column before': 'Coller la colonne avant',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      "Le collage est maintenant en mode texte brut. Les contenus seront coll\xe9s sans retenir les formatages jusqu'\xe0 ce que vous d\xe9sactivez cette option.",
    'Paste or type a link': 'Coller ou taper un lien',
    'Paste row after': 'Coller la ligne apr\xe8s',
    'Paste row before': 'Coller la ligne avant',
    'Paste your embed code below:': 'Collez votre code incorpor\xe9 ci-dessous :',
    People: 'Personnes',
    Plugins: 'Plug-ins',
    'Plugins installed ({0}):': 'Plug-ins install\xe9s ({0})\xa0:',
    'Powered by {0}': 'Avec {0}',
    Pre: 'Pr\xe9format\xe9',
    Preferences: 'Pr\xe9f\xe9rences',
    Preformatted: 'Pr\xe9format\xe9',
    'Premium plugins:': 'Plug-ins premium\xa0:',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: 'Aper\xe7u',
    Previous: 'Pr\xe9c\xe9dente',
    Print: 'Imprimer',
    'Print...': 'Imprimer...',
    Purple: 'Violet',
    Quotations: 'Citations',
    R: 'R',
    'Range 0 to 255': 'Plage de 0 \xe0 255',
    Red: 'Rouge',
    'Red component': 'Composant rouge',
    Redo: 'R\xe9tablir',
    Remove: 'Retir\xe9',
    'Remove color': 'Supprimer la couleur',
    'Remove link': 'Enlever le lien',
    Replace: 'Remplacer',
    'Replace all': 'Remplacer tout',
    'Replace with': 'Remplacer par',
    Resize: 'Redimensionner',
    'Restore last draft': 'Restaurer le dernier brouillon',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': 'Zone de Texte Riche',
    'Rich Text Area. Press ALT-0 for help.': "Zone de Texte Riche. Appuyez sur ALT-0 pour l'aide.",
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      "Zone de Texte Riche. Appuyez sur ALT-F9 pour le menu. Appuyez sur ALT-F10 pour la barre d'outils. Appuyez sur ALT-0 pour l'aide",
    Ridge: 'Extrud\xe9',
    Right: 'Droite',
    'Right to left': 'De droite \xe0 gauche',
    Row: 'Ligne',
    'Row clipboard actions': 'Actions du presse-papiers des lignes',
    'Row group': 'Groupe de lignes',
    'Row header': 'En-t\xeate de ligne',
    'Row properties': 'Propri\xe9t\xe9s de la ligne',
    'Row type': 'Type de ligne',
    Rows: 'Lignes',
    Save: 'Enregistrer',
    'Save (if save plugin activated)': 'Enregistrer (si le plug-in save est activ\xe9)',
    Scope: '\xc9tendue',
    Search: 'Rechercher',
    'Select all': 'S\xe9lectionner tout',
    'Select...': 'S\xe9lectionner...',
    Selection: 'S\xe9lection',
    Shortcut: 'Raccourci',
    'Show blocks': 'Afficher les blocs',
    'Show caption': 'Afficher une l\xe9gende',
    'Show invisible characters': 'Afficher les caract\xe8res invisibles',
    Size: 'Taille',
    Solid: 'Trait continu',
    Source: 'Source',
    'Source code': 'Code source',
    'Special Character': 'Caract\xe8re sp\xe9cial',
    'Special character...': 'Caract\xe8re sp\xe9cial...',
    'Split cell': 'Diviser la cellule',
    Square: 'Carr\xe9',
    'Start list at number': 'Liste de d\xe9part au num\xe9ro',
    Strikethrough: 'Barr\xe9',
    Style: 'Style',
    Subscript: 'Indice',
    Superscript: 'Exposant',
    'Switch to or from fullscreen mode': 'Passer en ou quitter le mode plein \xe9cran',
    Symbols: 'Symboles',
    'System Font': 'Police syst\xe8me',
    Table: 'Tableau',
    'Table caption': 'L\xe9gende de tableau',
    'Table properties': 'Propri\xe9t\xe9s du tableau',
    'Table styles': 'Style tableau',
    Template: 'Mod\xe8le',
    Templates: 'Mod\xe8les',
    Text: 'Texte',
    'Text color': 'Couleur du texte',
    'Text color {0}': '',
    'Text to display': 'Texte \xe0 afficher',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      "L'URL que vous avez saisi semble \xeatre une adresse e-mail. Souhaitez-vous y ajouter le pr\xe9fixe requis mailto:\xa0?",
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      "L'URL que vous avez saisi semble \xeatre un lien externe. Souhaitez-vous y ajouter le pr\xe9fixe requis mailto:\xa0?",
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      "L'URL que vous avez saisie semble \xeatre un lien externe. Voulez-vous ajouter le pr\xe9fixe https:// requis\xa0?",
    Title: 'Titre',
    'To open the popup, press Shift+Enter': 'Pour ouvrir la popup, appuyez sur Maj+Entr\xe9e',
    'Toggle accordion': '',
    Tools: 'Outils',
    Top: 'En haut',
    'Travel and Places': 'Voyages & lieux',
    Turquoise: 'Turquoise',
    Underline: 'Soulign\xe9',
    Undo: 'Annuler',
    Upload: 'T\xe9l\xe9charger',
    'Uploading image': "T\xe9l\xe9versement d'une image",
    'Upper Alpha': 'Alphabet en majuscules',
    'Upper Roman': 'Chiffre romain sup\xe9rieur',
    Url: 'URL',
    'User Defined': "D\xe9fini par l'utilisateur",
    Valid: 'Valide',
    Version: 'Version',
    'Vertical align': 'Alignement vertical',
    'Vertical space': 'Espace vertical',
    View: 'Afficher',
    'Visual aids': 'Aides visuelles',
    Warn: 'Avertir',
    White: 'Blanc',
    Width: 'Largeur',
    'Word count': 'Nombre de mots',
    Words: 'Mots',
    'Words: {0}': 'Mots\xa0: {0}',
    Yellow: 'Jaune',
    Yes: 'Oui',
    'You are using {0}': 'Vous utilisez {0}',
    'You have unsaved changes are you sure you want to navigate away?':
      'Vous avez des modifications non enregistr\xe9es, \xeates-vous s\xfbr de vouloir quitter la page\xa0?',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      "Votre navigateur ne supporte pas l\u2019acc\xe8s direct au presse-papiers. Merci d'utiliser les raccourcis clavier Ctrl+X/C/V.",
    alignment: 'alignement',
    'austral sign': 'Symbole austral',
    'cedi sign': 'Symbole cedi',
    'colon sign': 'Symbole col\xf3n',
    'cruzeiro sign': 'Symbole cruzeiro',
    'currency sign': 'Symbole devise',
    'dollar sign': 'Symbole dollar',
    'dong sign': 'Symbole dong',
    'drachma sign': 'Symbole drachme',
    'euro-currency sign': 'Symbole euro',
    example: 'exemple',
    formatting: 'mise en forme',
    'french franc sign': 'Symbole franc fran\xe7ais',
    'german penny symbol': 'Symbole pfennig',
    'guarani sign': 'Symbole guarani',
    history: 'historique',
    'hryvnia sign': 'Symbole hryvnia',
    indentation: 'retrait',
    'indian rupee sign': 'Symbole roupie indienne',
    'kip sign': 'Symbole kip',
    'lira sign': 'Symbole lire',
    'livre tournois sign': 'Symbole livre tournois',
    'manat sign': 'Symbole manat',
    'mill sign': 'Symbole milli\xe8me',
    'naira sign': 'Symbole naira',
    'new sheqel sign': 'Symbole nouveau ch\xe9kel',
    'nordic mark sign': 'Symbole du mark nordique',
    'peseta sign': 'Symbole peseta',
    'peso sign': 'Symbole peso',
    'ruble sign': 'Symbole rouble',
    'rupee sign': 'Symbole roupie',
    'spesmilo sign': 'Symbole spesmilo',
    styles: 'styles',
    'tenge sign': 'Symbole tenge',
    'tugrik sign': 'Symbole tougrik',
    'turkish lira sign': 'Symbole lire turque',
    'won sign': 'Symbole won',
    'yen character': 'Sinogramme Yen',
    'yen/yuan character variant one': 'Sinogramme Yen/Yuan, premi\xe8re variante',
    'yuan character': 'Sinogramme Yuan',
    'yuan character, in hong kong and taiwan': 'Sinogramme Yuan, Hong Kong et Taiwan',
    '{0} characters': '{0}\xa0caract\xe8res',
    '{0} columns, {1} rows': '',
    '{0} words': '{0} mots'
  })
  tinymce.addI18n('ja-JP', {
    '#': '#',
    Accessibility: '\u30a2\u30af\u30bb\u30b7\u30d3\u30ea\u30c6\u30a3',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: '\u30a2\u30af\u30b7\u30e7\u30f3',
    Activity: '\u884c\u52d5',
    Address: '\u30a2\u30c9\u30ec\u30b9',
    'Add Variable': '\u5909\u6570\u306E\u8FFD\u52A0',
    Advanced: '\u8a73\u7d30',
    Align: '\u914d\u7f6e',
    'Align center': '\u4e2d\u592e\u63c3\u3048',
    'Align left': '\u5de6\u63c3\u3048',
    'Align right': '\u53f3\u63c3\u3048',
    Alignment: '\u914d\u7f6e',
    'Alignment {0}': '',
    All: '\u3059\u3079\u3066',
    'Alternative description': '\u4ee3\u66ff\u306e\u8aac\u660e\u6587',
    'Alternative source': '\u4ee3\u66ff\u30bd\u30fc\u30b9',
    'Alternative source URL': '\u4ee3\u66ff\u30bd\u30fc\u30b9URL',
    Anchor: '\u30a2\u30f3\u30ab\u30fc',
    'Anchor...': '\u30a2\u30f3\u30ab\u30fc...',
    Anchors: '\u30a2\u30f3\u30ab\u30fc',
    'Animals and Nature': '\u52d5\u7269\u3068\u81ea\u7136',
    Arrows: '\u77e2\u5370',
    B: '\u9752',
    'Background color': '\u80cc\u666f\u8272',
    'Background color {0}': '',
    Black: '\u30d6\u30e9\u30c3\u30af',
    Block: '\u30d6\u30ed\u30c3\u30af',
    'Block {0}': '',
    Blockquote: '\u5f15\u7528',
    Blocks: '\u30d6\u30ed\u30c3\u30af',
    Blue: '\u30d6\u30eb\u30fc',
    'Blue component': '\u9752\u8272\u30b3\u30f3\u30dd\u30fc\u30cd\u30f3\u30c8',
    Body: '\u672c\u6587',
    Bold: '\u592a\u5b57',
    Border: '\u30dc\u30fc\u30c0\u30fc',
    'Border color': '\u30dc\u30fc\u30c0\u30fc\u306e\u8272',
    'Border style': '\u67a0\u7dda\u30b9\u30bf\u30a4\u30eb',
    'Border width': '\u67a0\u7dda\u5e45',
    Bottom: '\u4e0b\u63c3\u3048',
    'Browse files': '',
    'Browse for an image': '\u753b\u50cf\u3092\u53c2\u7167',
    'Browse links': '',
    'Bullet list': '\u7b87\u6761\u66f8\u304d',
    Cancel: '\u53d6\u6d88',
    Caption: '\u30ad\u30e3\u30d7\u30b7\u30e7\u30f3',
    Cell: '\u30bb\u30eb',
    'Cell padding': '\u30bb\u30eb\u5185\u306e\u30b9\u30da\u30fc\u30b9',
    'Cell properties': '\u30bb\u30eb\u306e\u30d7\u30ed\u30d1\u30c6\u30a3',
    'Cell spacing': '\u30bb\u30eb\u306e\u9593\u9694',
    'Cell styles': '\u30bb\u30eb\u306e\u7a2e\u985e',
    'Cell type': '\u30bb\u30eb\u306e\u7a2e\u985e',
    Center: '\u4e2d\u592e\u63c3\u3048',
    Characters: '\u6587\u5b57\u6570',
    'Characters (no spaces)': '\u6587\u5b57\u6570 (\u30b9\u30da\u30fc\u30b9\u306a\u3057)',
    Circle: '\u4e38',
    Class: '\u30af\u30e9\u30b9',
    'Clear formatting': '\u66f8\u5f0f\u3092\u30af\u30ea\u30a2',
    Close: '\u9589\u3058\u308b',
    Code: '\u30b3\u30fc\u30c9',
    'Code sample...': '\u30b3\u30fc\u30c9\u306e\u30b5\u30f3\u30d7\u30eb...',
    'Code view': '\u30b3\u30fc\u30c9\u8868\u793a',
    'Color Picker': '\u30ab\u30e9\u30fc\u30d4\u30c3\u30ab\u30fc',
    'Color swatch': '\u8272\u306e\u898b\u672c',
    Cols: '\u5217\u6570',
    Column: '\u5217',
    'Column clipboard actions':
      '\u5217\u306e\u30af\u30ea\u30c3\u30d7\u30dc\u30fc\u30c9\u30a2\u30af\u30b7\u30e7\u30f3',
    'Column group': '\u5217\u30b0\u30eb\u30fc\u30d7',
    'Column header': '\u5217\u306e\u30d8\u30c3\u30c0\u30fc',
    'Constrain proportions': '\u7e26\u6a2a\u6bd4\u3092\u56fa\u5b9a',
    Copy: '\u30b3\u30d4\u30fc',
    'Copy column': '\u5217\u3092\u30b3\u30d4\u30fc',
    'Copy row': '\u884c\u306e\u30b3\u30d4\u30fc',
    'Could not find the specified string.':
      '\u304a\u63a2\u3057\u306e\u6587\u5b57\u5217\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f\u3002',
    'Could not load emojis':
      '\u7d75\u6587\u5b57\u304c\u8aad\u307f\u8fbc\u3081\u307e\u305b\u3093\u3067\u3057\u305f',
    Count: '\u30ab\u30a6\u30f3\u30c8',
    Currency: '\u901a\u8ca8',
    'Current window': '\u540c\u3058\u30a6\u30a3\u30f3\u30c9\u30a6',
    'Custom color': '\u30e6\u30fc\u30b6\u30fc\u8a2d\u5b9a\u306e\u8272',
    'Custom...': '\u30e6\u30fc\u30b6\u30fc\u8a2d\u5b9a...',
    Cut: '\u5207\u308a\u53d6\u308a',
    'Cut column': '\u5217\u3092\u30ab\u30c3\u30c8',
    'Cut row': '\u884c\u306e\u5207\u308a\u53d6\u308a',
    'Dark Blue': '\u30c0\u30fc\u30af\u30d6\u30eb\u30fc',
    'Dark Gray': '\u30c0\u30fc\u30af\u30b0\u30ec\u30fc',
    'Dark Green': '\u30c0\u30fc\u30af\u30b0\u30ea\u30fc\u30f3',
    'Dark Orange': '\u30c0\u30fc\u30af\u30aa\u30ec\u30f3\u30b8',
    'Dark Purple': '\u30c0\u30fc\u30af\u30d1\u30fc\u30d7\u30eb',
    'Dark Red': '\u30c0\u30fc\u30af\u30ec\u30c3\u30c9',
    'Dark Turquoise': '\u30c0\u30fc\u30af\u30bf\u30fc\u30b3\u30a4\u30ba',
    'Dark Yellow': '\u30c0\u30fc\u30af\u30a4\u30a8\u30ed\u30fc',
    Dashed: '\u7834\u7dda',
    'Date/time': '\u65e5\u4ed8/\u6642\u523b',
    'Decrease indent': '\u30a4\u30f3\u30c7\u30f3\u30c8\u3092\u6e1b\u3089\u3059',
    Default: '\u65e2\u5b9a',
    'Delete accordion': '',
    'Delete column': '\u5217\u306e\u524a\u9664',
    'Delete row': '\u884c\u306e\u524a\u9664',
    'Delete table': '\u30c6\u30fc\u30d6\u30eb\u306e\u524a\u9664',
    Dimensions: '\u30b5\u30a4\u30ba',
    Disc: '\u9ed2\u4e38',
    Div: '\u5206\u5272',
    Document: '\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8',
    Dotted: '\u70b9\u7dda',
    Double: '\u4e8c\u91cd\u7dda',
    'Drop an image here': '\u3053\u3053\u306b\u753b\u50cf\u3092\u30c9\u30ed\u30c3\u30d7',
    'Dropped file type is not supported':
      '\u30c9\u30ed\u30c3\u30d7\u3055\u308c\u305f\u30d5\u30a1\u30a4\u30eb\u30bf\u30a4\u30d7\u306f\u30b5\u30dd\u30fc\u30c8\u3055\u308c\u3066\u3044\u307e\u305b\u3093',
    Edit: '\u7de8\u96c6',
    Embed: '\u57cb\u3081\u8fbc\u307f',
    Emojis: '\u7d75\u6587\u5b57',
    'Emojis...': '\u7d75\u6587\u5b57...',
    Error: '\u30a8\u30e9\u30fc',
    'Error: Form submit field collision.':
      '\u30a8\u30e9\u30fc\uff1a\u30d5\u30a9\u30fc\u30e0\u9001\u4fe1\u30d5\u30a3\u30fc\u30eb\u30c9\u304c\u7af6\u5408\u3057\u3066\u3044\u307e\u3059\u3002',
    'Error: No form element found.':
      '\u30a8\u30e9\u30fc\uff1a\u30d5\u30a9\u30fc\u30e0\u8981\u7d20\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f\u3002',
    'Extended Latin': '\u30e9\u30c6\u30f3\u6587\u5b57\u62e1\u5f35',
    'Failed to initialize plugin: {0}':
      '\u30d7\u30e9\u30b0\u30a4\u30f3{0}\u306e\u521d\u671f\u5316\u306b\u5931\u6557\u3057\u307e\u3057\u305f',
    'Failed to load plugin url: {0}':
      '\u30d7\u30e9\u30b0\u30a4\u30f3\u306eURL{0}\u3092\u8aad\u307f\u8fbc\u3081\u307e\u305b\u3093\u3067\u3057\u305f',
    'Failed to load plugin: {0} from url {1}':
      'URL{1}\u304b\u3089\u306e\u30d7\u30e9\u30b0\u30a4\u30f3{0}\u306e\u8aad\u307f\u8fbc\u307f\u306b\u5931\u6557\u3057\u307e\u3057\u305f',
    'Failed to upload image: {0}':
      '\u753b\u50cf{0}\u3092\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f',
    File: '\u30d5\u30a1\u30a4\u30eb',
    Find: '\u691c\u7d22...',
    'Find (if searchreplace plugin activated)':
      '\u691c\u7d22 (\u7f6e\u63db\u30d7\u30e9\u30b0\u30a4\u30f3\u6709\u52b9\u6642)',
    'Find and Replace': '\u691c\u7d22\u3068\u7f6e\u63db',
    'Find and replace...': '\u7f6e\u63db...',
    'Find in selection': '\u9078\u629e\u90e8\u5206\u3067\u691c\u7d22',
    'Find whole words only':
      '\u8a9e\u5168\u4f53\u3092\u542b\u3080\u3082\u306e\u306e\u307f\u691c\u7d22',
    Flags: '\u65d7',
    'Focus to contextual toolbar':
      '\u30b3\u30f3\u30c6\u30ad\u30b9\u30c8\u30c4\u30fc\u30eb\u30d0\u30fc\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
    'Focus to element path': '\u8981\u7d20\u30d1\u30b9\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
    'Focus to menubar': '\u30e1\u30cb\u30e5\u30fc\u30d0\u30fc\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
    'Focus to toolbar': '\u30c4\u30fc\u30eb\u30d0\u30fc\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
    Font: '\u30d5\u30a9\u30f3\u30c8',
    'Font size {0}': '',
    'Font sizes': '\u30d5\u30a9\u30f3\u30c8\u306e\u30b5\u30a4\u30ba',
    'Font {0}': '',
    Fonts: '\u30d5\u30a9\u30f3\u30c8',
    'Food and Drink': '\u98df\u3079\u7269\u3068\u98f2\u307f\u7269',
    Footer: '\u30d5\u30c3\u30bf\u30fc',
    Format: '\u66f8\u5f0f\u8a2d\u5b9a',
    'Format {0}': '',
    Formats: '\u66f8\u5f0f',
    Fullscreen: '\u30d5\u30eb\u30b9\u30af\u30ea\u30fc\u30f3',
    G: '\u7dd1',
    General: '\u4e00\u822c',
    Gray: '\u30b0\u30ec\u30fc',
    Green: '\u30b0\u30ea\u30fc\u30f3',
    'Green component': '\u7dd1\u8272\u30b3\u30f3\u30dd\u30fc\u30cd\u30f3\u30c8',
    Groove: '\u8c37\u7dda',
    'Handy Shortcuts': '\u4fbf\u5229\u306a\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8',
    Header: '\u30d8\u30c3\u30c0\u30fc',
    'Header cell': '\u30d8\u30c3\u30c0\u30fc\u30bb\u30eb',
    'Heading 1': '\u898b\u51fa\u30571',
    'Heading 2': '\u898b\u51fa\u30572',
    'Heading 3': '\u898b\u51fa\u30573',
    'Heading 4': '\u898b\u51fa\u30574',
    'Heading 5': '\u898b\u51fa\u30575',
    'Heading 6': '\u898b\u51fa\u30576',
    Headings: '\u898b\u51fa\u3057',
    Height: '\u9ad8\u3055',
    Help: '\u30d8\u30eb\u30d7',
    'Hex color code': '16\u9032\u30ab\u30e9\u30fc\u30b3\u30fc\u30c9',
    Hidden: '\u975e\u8868\u793a',
    'Horizontal align': '\u6c34\u5e73\u306b\u6574\u5217',
    'Horizontal line': '\u6c34\u5e73\u7f6b\u7dda',
    'Horizontal space': '\u5de6\u53f3\u4f59\u767d',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'ID\u306f\u6587\u5b57\u3067\u59cb\u307e\u308a\u3001\u305d\u306e\u5f8c\u306b\u6587\u5b57\u3001\u6570\u5b57\u3001\u30c0\u30c3\u30b7\u30e5\u3001\u30d4\u30ea\u30aa\u30c9\u3001\u30b3\u30ed\u30f3\u3001\u307e\u305f\u306f\u30a2\u30f3\u30c0\u30fc\u30b9\u30b3\u30a2\u304c\u7d9a\u304f\u5fc5\u8981\u304c\u3042\u308a\u307e\u3059\u3002',
    'Image is decorative': '\u753b\u50cf\u306f\u88c5\u98fe\u753b\u50cf',
    'Image list': '\u753b\u50cf\u30ea\u30b9\u30c8',
    'Image title': '\u753b\u50cf\u30bf\u30a4\u30c8\u30eb',
    'Image...': '\u753b\u50cf..',
    'ImageProxy HTTP error: Could not find Image Proxy':
      '\u753b\u50cf\u30d7\u30ed\u30ad\u30b7 HTTP\u30a8\u30e9\u30fc\uff1a\u753b\u50cf\u30d7\u30ed\u30ad\u30b7\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      '\u753b\u50cf\u30d7\u30ed\u30ad\u30b7 HTTP\u30a8\u30e9\u30fc\uff1a\u4e0d\u6b63\u306a\u753b\u50cf\u30d7\u30ed\u30ad\u30b7URL',
    'ImageProxy HTTP error: Rejected request':
      '\u753b\u50cf\u30d7\u30ed\u30ad\u30b7 HTTP\u30a8\u30e9\u30fc\uff1a\u62d2\u5426\u3055\u308c\u305f\u30ea\u30af\u30a8\u30b9\u30c8',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      '\u753b\u50cf\u30d7\u30ed\u30ad\u30b7 HTTP\u30a8\u30e9\u30fc\uff1a\u4e0d\u660e\u306a\u753b\u50cf\u30d7\u30ed\u30ad\u30b7\u30a8\u30e9\u30fc',
    'Increase indent': '\u30a4\u30f3\u30c7\u30f3\u30c8\u3092\u5897\u3084\u3059',
    Inline: '\u30a4\u30f3\u30e9\u30a4\u30f3',
    Insert: '\u633f\u5165',
    'Insert Template': '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u306e\u633f\u5165..',
    'Insert accordion': '',
    'Insert column after': '\u5f8c\u306b\u5217\u3092\u633f\u5165',
    'Insert column before': '\u524d\u306b\u5217\u3092\u633f\u5165',
    'Insert date/time': '\u65e5\u4ed8/\u6642\u523b\u306e\u633f\u5165',
    'Insert image': '\u753b\u50cf\u306e\u633f\u5165',
    'Insert link (if link plugin activated)':
      '\u30ea\u30f3\u30af\u3092\u633f\u5165 (\u30ea\u30f3\u30af\u30d7\u30e9\u30b0\u30a4\u30f3\u6709\u52b9\u6642)',
    'Insert row after': '\u5f8c\u306b\u884c\u3092\u633f\u5165',
    'Insert row before': '\u524d\u306b\u884c\u3092\u633f\u5165',
    'Insert table': '\u8868\u306e\u633f\u5165',
    'Insert template...': '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u306e\u633f\u5165..',
    'Insert video': '\u52d5\u753b\u306e\u633f\u5165',
    'Insert/Edit code sample':
      '\u30b3\u30fc\u30c9\u30b5\u30f3\u30d7\u30eb\u306e\u633f\u5165/\u7de8\u96c6',
    'Insert/edit image': '\u753b\u50cf\u306e\u633f\u5165/\u7de8\u96c6',
    'Insert/edit link': '\u30ea\u30f3\u30af\u306e\u633f\u5165/\u7de8\u96c6',
    'Insert/edit media': '\u30e1\u30c7\u30a3\u30a2\u306e\u633f\u5165/\u7de8\u96c6',
    'Insert/edit video': '\u52d5\u753b\u306e\u633f\u5165/\u7de8\u96c6',
    Inset: '\u5185\u7dda',
    'Invalid hex color code: {0}':
      '\u7121\u52b9\u306a16\u9032\u30ab\u30e9\u30fc\u30b3\u30fc\u30c9: {0}',
    'Invalid input': '\u7121\u52b9\u306a\u5165\u529b',
    Italic: '\u659c\u4f53',
    Justify: '\u4e21\u7aef\u63c3\u3048',
    'Keyboard Navigation':
      '\u30ad\u30fc\u30dc\u30fc\u30c9\u30ca\u30d3\u30b2\u30fc\u30b7\u30e7\u30f3',
    Language: '\u8a00\u8a9e',
    'Learn more...': '\u8a73\u7d30...',
    Left: '\u5de6\u63c3\u3048',
    'Left to right': '\u5de6\u304b\u3089\u53f3',
    'Light Blue': '\u30e9\u30a4\u30c8\u30d6\u30eb\u30fc',
    'Light Gray': '\u30e9\u30a4\u30c8\u30b0\u30ec\u30fc',
    'Light Green': '\u30e9\u30a4\u30c8\u30b0\u30ea\u30fc\u30f3',
    'Light Purple': '\u30e9\u30a4\u30c8\u30d1\u30fc\u30d7\u30eb',
    'Light Red': '\u30e9\u30a4\u30c8\u30ec\u30c3\u30c9',
    'Light Yellow': '\u30e9\u30a4\u30c8\u30a4\u30a8\u30ed\u30fc',
    'Line height': '\u884c\u306e\u9ad8\u3055',
    'Link list': '\u30ea\u30f3\u30af\u306e\u4e00\u89a7',
    'Link...': '\u30ea\u30f3\u30af...',
    'List Properties': '\u7b87\u6761\u66f8\u304d\u306e\u30d7\u30ed\u30d1\u30c6\u30a3',
    'List properties...': '\u7b87\u6761\u66f8\u304d\u306e\u30d7\u30ed\u30d1\u30c6\u30a3...',
    'Loading emojis...':
      '\u7d75\u6587\u5b57\u3092\u8aad\u307f\u8fbc\u3093\u3067\u3044\u307e\u3059\u2026',
    'Loading...': '\u8aad\u307f\u8fbc\u3093\u3067\u3044\u307e\u3059...',
    'Lower Alpha': '\u5c0f\u6587\u5b57\u30a2\u30eb\u30d5\u30a1\u30d9\u30c3\u30c8',
    'Lower Greek': '\u5c0f\u6587\u5b57\u30ae\u30ea\u30b7\u30e3\u6587\u5b57',
    'Lower Roman': '\u5c0f\u6587\u5b57\u30ed\u30fc\u30de\u5b57',
    'Match case': '\u5927\u6587\u5b57\u3068\u5c0f\u6587\u5b57\u3092\u533a\u5225',
    Mathematical: '\u6570\u5b66\u8a18\u53f7',
    'Media poster (Image URL)':
      '\u30e1\u30c7\u30a3\u30a2\u30dd\u30b9\u30bf\u30fc (\u753b\u50cfURL)',
    'Media...': '\u30e1\u30c7\u30a3\u30a2\u2026',
    'Medium Blue': '\u30e1\u30c7\u30a3\u30a2\u30e0\u30d6\u30eb\u30fc',
    'Medium Gray': '\u30df\u30c7\u30a3\u30a2\u30e0\u30b0\u30ec\u30fc',
    'Medium Purple': '\u30df\u30c7\u30a3\u30a2\u30e0\u30d1\u30fc\u30d7\u30eb',
    'Merge cells': '\u30bb\u30eb\u306e\u7d50\u5408',
    Middle: '\u4e2d\u592e\u63c3\u3048',
    'Midnight Blue': '\u30df\u30c3\u30c9\u30ca\u30a4\u30c8\u30d6\u30eb\u30fc',
    'More...': '\u8a73\u7d30...',
    Name: '\u540d\u524d',
    'Navy Blue': '\u30cd\u30a4\u30d3\u30fc',
    'New document': '\u65b0\u898f\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8',
    'New window': '\u65b0\u898f\u30a6\u30a3\u30f3\u30c9\u30a6',
    Next: '\u6b21\u3078',
    No: '\u3044\u3044\u3048',
    'No alignment': '\u914d\u7f6e\u306a\u3057',
    'No color': '\u8272\u306a\u3057',
    'Nonbreaking space': '\u56fa\u5b9a\u30b9\u30da\u30fc\u30b9',
    None: '\u306a\u3057',
    'Numbered list': '\u756a\u53f7\u4ed8\u304d\u7b87\u6761\u66f8\u304d',
    OR: '\u307e\u305f\u306f',
    Objects: '\u7269',
    Ok: 'OK',
    'Open help dialog': '\u30d8\u30eb\u30d7\u30c0\u30a4\u30a2\u30ed\u30b0\u3092\u958b\u304f',
    'Open link': '\u30ea\u30f3\u30af\u3092\u958b\u304f',
    'Open link in...': '\u30ea\u30f3\u30af\u306e\u958b\u304d\u65b9...',
    'Open popup menu for split buttons':
      '\u5206\u5272\u30dc\u30bf\u30f3\u306e\u30dd\u30c3\u30d7\u30a2\u30c3\u30d7\u30e1\u30cb\u30e5\u30fc\u3092\u958b\u304f',
    Orange: '\u30aa\u30ec\u30f3\u30b8',
    Outset: '\u5916\u7dda',
    'Page break': '\u30da\u30fc\u30b8\u533a\u5207\u308a',
    Paragraph: '\u6bb5\u843d',
    Paste: '\u8cbc\u308a\u4ed8\u3051',
    'Paste as text': '\u30c6\u30ad\u30b9\u30c8\u3068\u3057\u3066\u8cbc\u308a\u4ed8\u3051',
    'Paste column after': '\u5f8c\u306b\u5217\u3092\u8cbc\u308a\u4ed8\u3051',
    'Paste column before': '\u524d\u306b\u5217\u3092\u8cbc\u308a\u4ed8\u3051',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      '\u8cbc\u308a\u4ed8\u3051\u306f\u73fe\u5728\u30d7\u30ec\u30fc\u30f3\u30c6\u30ad\u30b9\u30c8\u30e2\u30fc\u30c9\u3067\u3059\u3002\u3053\u306e\u30aa\u30d7\u30b7\u30e7\u30f3\u3092\u30aa\u30d5\u306b\u3057\u306a\u3044\u9650\u308a\u5185\u5bb9\u306f\u30d7\u30ec\u30fc\u30f3\u30c6\u30ad\u30b9\u30c8\u3068\u3057\u3066\u8cbc\u308a\u4ed8\u3051\u3089\u308c\u307e\u3059\u3002',
    'Paste or type a link':
      '\u30ea\u30f3\u30af\u3092\u30da\u30fc\u30b9\u30c8\u307e\u305f\u306f\u5165\u529b',
    'Paste row after': '\u4e0b\u5074\u306b\u884c\u3092\u8cbc\u308a\u4ed8\u3051',
    'Paste row before': '\u4e0a\u5074\u306b\u884c\u3092\u8cbc\u308a\u4ed8\u3051',
    'Paste your embed code below:':
      '\u57cb\u3081\u8fbc\u307f\u7528\u30b3\u30fc\u30c9\u3092\u4ee5\u4e0b\u306b\u8cbc\u308a\u4ed8\u3051\u3066\u304f\u3060\u3055\u3044\u3002',
    People: '\u4eba',
    Plugins: '\u30d7\u30e9\u30b0\u30a4\u30f3',
    'Plugins installed ({0}):':
      '\u30a4\u30f3\u30b9\u30c8\u30fc\u30eb\u6e08\u30d7\u30e9\u30b0\u30a4\u30f3 ({0})\uff1a',
    'Powered by {0}': '{0} \u306b\u3088\u3063\u3066\u642d\u8f09\u3055\u308c\u305f',
    Pre: '\u6574\u5f62\u6e08\u307f\u30c6\u30ad\u30b9\u30c8',
    Preferences: '\u30d7\u30ea\u30d5\u30a1\u30ec\u30f3\u30b9',
    Preformatted: '\u66f8\u5f0f\u8a2d\u5b9a\u6e08\u307f',
    'Premium plugins:': '\u30d7\u30ec\u30df\u30a2\u30e0\u30d7\u30e9\u30b0\u30a4\u30f3\uff1a',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: '\u30d7\u30ec\u30d3\u30e5\u30fc',
    Previous: '\u524d\u3078',
    Print: '\u5370\u5237',
    'Print...': '\u5370\u5237...',
    Purple: '\u30d1\u30fc\u30d7\u30eb',
    Quotations: '\u5f15\u7528',
    R: '\u8d64',
    'Range 0 to 255': '\u7bc4\u56f20\u301c255',
    Red: '\u30ec\u30c3\u30c9',
    'Red component': '\u8d64\u8272\u30b3\u30f3\u30dd\u30fc\u30cd\u30f3\u30c8',
    Redo: '\u3084\u308a\u76f4\u3057',
    Remove: '\u524a\u9664',
    'Remove color': '\u8272\u8a2d\u5b9a\u3092\u89e3\u9664',
    'Remove link': '\u30ea\u30f3\u30af\u306e\u524a\u9664',
    Replace: '\u7f6e\u63db',
    'Replace all': '\u3059\u3079\u3066\u7f6e\u63db',
    'Replace with': '\u7f6e\u63db\u5f8c\u306e\u6587\u5b57\u5217',
    Resize: '\u30b5\u30a4\u30ba\u5909\u66f4',
    'Restore last draft': '\u524d\u56de\u306e\u4e0b\u66f8\u304d\u3092\u56de\u5fa9',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': '\u30ea\u30c3\u30c1\u30c6\u30ad\u30b9\u30c8\u30a8\u30ea\u30a2',
    'Rich Text Area. Press ALT-0 for help.':
      '\u30ea\u30c3\u30c1\u30c6\u30ad\u30b9\u30c8\u30a8\u30ea\u30a2\u3002Alt-0\u3067\u30d8\u30eb\u30d7\u304c\u8868\u793a\u3055\u308c\u307e\u3059\u3002',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      '\u30ea\u30c3\u30c1\u30c6\u30ad\u30b9\u30c8\u30a8\u30ea\u30a2\u3002ALT-F9\u3067\u30e1\u30cb\u30e5\u30fc\u3001ALT-F10\u3067\u30c4\u30fc\u30eb\u30d0\u30fc\u3001ALT-0\u3067\u30d8\u30eb\u30d7\u304c\u8868\u793a\u3055\u308c\u307e\u3059\u3002',
    Ridge: '\u5c71\u7dda',
    Right: '\u53f3\u63c3\u3048',
    'Right to left': '\u53f3\u304b\u3089\u5de6',
    Row: '\u884c',
    'Row clipboard actions':
      '\u884c\u306e\u30af\u30ea\u30c3\u30d7\u30dc\u30fc\u30c9\u30a2\u30af\u30b7\u30e7\u30f3',
    'Row group': '\u884c\u30b0\u30eb\u30fc\u30d7',
    'Row header': '\u884c\u306e\u30d8\u30c3\u30c0\u30fc',
    'Row properties': '\u884c\u306e\u30d7\u30ed\u30d1\u30c6\u30a3',
    'Row type': '\u884c\u30bf\u30a4\u30d7',
    Rows: '\u884c\u6570',
    Save: '\u4fdd\u5b58',
    'Save (if save plugin activated)':
      '\u4fdd\u5b58 (\u4fdd\u5b58\u30d7\u30e9\u30b0\u30a4\u30f3\u6709\u52b9\u6642)',
    Scope: '\u30b9\u30b3\u30fc\u30d7',
    Search: '\u691c\u7d22',
    'Select all': '\u3059\u3079\u3066\u9078\u629e',
    'Select...': '\u9078\u629e...',
    Selection: '\u9078\u629e',
    Shortcut: '\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8',
    'Show blocks': '\u6587\u7ae0\u306e\u533a\u5207\u308a\u3092\u70b9\u7dda\u3067\u8868\u793a',
    'Show caption': '\u30ad\u30e3\u30d7\u30b7\u30e7\u30f3\u306e\u8868\u793a',
    'Show invisible characters': '\u975e\u8868\u793a\u6587\u5b57\u3092\u8868\u793a',
    Size: '\u30b5\u30a4\u30ba',
    Solid: '\u5b9f\u7dda',
    Source: '\u30bd\u30fc\u30b9',
    'Source code': '\u30bd\u30fc\u30b9\u30b3\u30fc\u30c9',
    'Special Character': '\u7279\u6b8a\u6587\u5b57',
    'Special character...': '\u7279\u6b8a\u6587\u5b57...',
    'Split cell': '\u30bb\u30eb\u306e\u5206\u5272',
    Square: '\u56db\u89d2',
    'Start list at number': '\u756a\u53f7\u30ea\u30b9\u30c8\u306e\u958b\u59cb',
    Strikethrough: '\u53d6\u6d88\u7dda',
    Style: '\u30b9\u30bf\u30a4\u30eb',
    Subscript: '\u4e0b\u4ed8\u304d',
    Superscript: '\u4e0a\u4ed8\u304d',
    'Switch to or from fullscreen mode':
      '\u30d5\u30eb\u30b9\u30af\u30ea\u30fc\u30f3\u30e2\u30fc\u30c9\u5207\u66ff',
    Symbols: '\u8a18\u53f7',
    'System Font': '\u30b7\u30b9\u30c6\u30e0\u30d5\u30a9\u30f3\u30c8',
    Table: '\u8868',
    'Table caption': '\u30c6\u30fc\u30d6\u30eb\u306e\u898b\u51fa\u3057',
    'Table properties': '\u30c6\u30fc\u30d6\u30eb\u306e\u30d7\u30ed\u30d1\u30c6\u30a3',
    'Table styles': '\u30c6\u30fc\u30d6\u30eb\u306e\u7a2e\u985e',
    Template: '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8',
    Templates: '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8',
    Text: '\u30c6\u30ad\u30b9\u30c8',
    'Text color': '\u30c6\u30ad\u30b9\u30c8\u8272',
    'Text color {0}': '',
    'Text to display': '\u8868\u793a\u3059\u308b\u30c6\u30ad\u30b9\u30c8',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      '\u5165\u529b\u3055\u308c\u305fURL\u306f\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u306e\u3088\u3046\u3067\u3059\u3002\u300cmailto:\u300d\u30d7\u30ec\u30d5\u30a3\u30c3\u30af\u30b9\u3092\u8ffd\u52a0\u3057\u307e\u3059\u304b\uff1f',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      '\u5165\u529b\u3055\u308c\u305fURL\u306f\u5916\u90e8\u30ea\u30f3\u30af\u306e\u3088\u3046\u3067\u3059\u3002\u300chttp://\u300d\u30d7\u30ec\u30d5\u30a3\u30c3\u30af\u30b9\u3092\u8ffd\u52a0\u3057\u307e\u3059\u304b\uff1f',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      '\u5165\u529b\u3055\u308c\u305fURL\u306f\u5916\u90e8\u30ea\u30f3\u30af\u306e\u3088\u3046\u3067\u3059\u3002\u5fc5\u8981\u306a\u300chttps://\u300d\u30d7\u30ec\u30d5\u30a3\u30c3\u30af\u30b9\u3092\u8ffd\u52a0\u3057\u307e\u3059\u304b\uff1f',
    Title: '\u30bf\u30a4\u30c8\u30eb',
    'To open the popup, press Shift+Enter':
      '\u30dd\u30c3\u30d7\u30a2\u30c3\u30d7\u3092\u958b\u304f\u306b\u306f\u3001Shift+Enter\u3092\u62bc\u3057\u3066\u304f\u3060\u3055\u3044',
    'Toggle accordion': '',
    Tools: '\u30c4\u30fc\u30eb',
    Top: '\u4e0a\u63c3\u3048',
    'Travel and Places': '\u65c5\u884c\u3068\u5834\u6240',
    Turquoise: '\u30bf\u30fc\u30b3\u30a4\u30ba',
    Underline: '\u4e0b\u7dda',
    Undo: '\u5143\u306b\u623b\u3059',
    Upload: '\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9',
    'Uploading image': '\u753b\u50cf\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u4e2d',
    'Upper Alpha': '\u5927\u6587\u5b57\u30a2\u30eb\u30d5\u30a1\u30d9\u30c3\u30c8',
    'Upper Roman': '\u5927\u6587\u5b57\u30ed\u30fc\u30de\u5b57',
    Url: 'URL',
    'User Defined': '\u30e6\u30fc\u30b6\u30fc\u5b9a\u7fa9',
    Valid: '\u6709\u52b9',
    Version: '\u30d0\u30fc\u30b8\u30e7\u30f3',
    'Vertical align': '\u5782\u76f4\u306b\u6574\u5217',
    'Vertical space': '\u4e0a\u4e0b\u4f59\u767d',
    View: '\u8868\u793a',
    'Visual aids': '\u8868\u306e\u67a0\u7dda\u3092\u70b9\u7dda\u3067\u8868\u793a',
    Warn: '\u8b66\u544a',
    White: '\u30db\u30ef\u30a4\u30c8',
    Width: '\u5e45',
    'Word count': '\u6587\u5b57\u6570\u30ab\u30a6\u30f3\u30c8',
    Words: '\u5358\u8a9e\u6570',
    'Words: {0}': '\u5358\u8a9e\u6570\uff1a{0}',
    Yellow: '\u30a4\u30a8\u30ed\u30fc',
    Yes: '\u306f\u3044',
    'You are using {0}': '{0}\u3092\u4f7f\u7528\u4e2d',
    'You have unsaved changes are you sure you want to navigate away?':
      '\u307e\u3060\u4fdd\u5b58\u3057\u3066\u3044\u306a\u3044\u5909\u66f4\u304c\u3042\u308a\u307e\u3059\u3002\u3053\u306e\u30da\u30fc\u30b8\u3092\u96e2\u308c\u307e\u3059\u304b\uff1f',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      '\u304a\u4f7f\u3044\u306e\u30d6\u30e9\u30a6\u30b6\u3067\u306f\u30af\u30ea\u30c3\u30d7\u30dc\u30fc\u30c9\u6a5f\u80fd\u3092\u5229\u7528\u3059\u308b\u3053\u3068\u304c\u3067\u304d\u307e\u305b\u3093\u3002\u30ad\u30fc\u30dc\u30fc\u30c9\u306e\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8\uff08Ctrl+X, Ctrl+C, Ctrl+V\uff09\u3092\u4f7f\u7528\u3057\u3066\u304f\u3060\u3055\u3044\u3002',
    alignment: '\u914d\u7f6e',
    'austral sign': '\u30a2\u30a6\u30b9\u30c8\u30e9\u30eb\u8a18\u53f7',
    'cedi sign': '\u30bb\u30c7\u30a3\u8a18\u53f7',
    'colon sign': '\u30b3\u30ed\u30f3\u8a18\u53f7',
    'cruzeiro sign': '\u30af\u30eb\u30bc\u30a4\u30ed\u8a18\u53f7',
    'currency sign': '\u901a\u8ca8\u8a18\u53f7',
    'dollar sign': '\u30c9\u30eb\u8a18\u53f7',
    'dong sign': '\u30c9\u30f3\u8a18\u53f7',
    'drachma sign': '\u30c9\u30e9\u30af\u30de\u8a18\u53f7',
    'euro-currency sign': '\u30e6\u30fc\u30ed\u8a18\u53f7',
    example: '\u4f8b',
    formatting: '\u66f8\u5f0f',
    'french franc sign': '\u30d5\u30e9\u30f3\u30b9\u30d5\u30e9\u30f3\u8a18\u53f7',
    'german penny symbol': '\u30c9\u30a4\u30c4\u30da\u30cb\u30fc\u8a18\u53f7',
    'guarani sign': '\u30ac\u30e9\u30cb\u8a18\u53f7',
    history: '\u5c65\u6b74',
    'hryvnia sign': '\u30d5\u30ea\u30f4\u30cb\u30e3\u8a18\u53f7',
    indentation: '\u30a4\u30f3\u30c7\u30f3\u30c8',
    'indian rupee sign': '\u30a4\u30f3\u30c9\u30eb\u30d4\u30fc\u8a18\u53f7',
    'kip sign': '\u30ad\u30fc\u30d7\u8a18\u53f7',
    'lira sign': '\u30ea\u30e9\u8a18\u53f7',
    'livre tournois sign': '\u30c8\u30a5\u30fc\u30eb\u30dd\u30f3\u30c9\u8a18\u53f7',
    'manat sign': '\u30de\u30ca\u30c8\u8a18\u53f7',
    'mill sign': '\u30df\u30eb\u8a18\u53f7',
    'naira sign': '\u30ca\u30a4\u30e9\u8a18\u53f7',
    'new sheqel sign': '\u65b0\u30b7\u30a7\u30b1\u30eb\u8a18\u53f7',
    'nordic mark sign': '\u5317\u6b27\u30de\u30eb\u30af\u8a18\u53f7',
    'peseta sign': '\u30da\u30bb\u30bf\u8a18\u53f7',
    'peso sign': '\u30da\u30bd\u8a18\u53f7',
    'ruble sign': '\u30eb\u30fc\u30d6\u30eb\u8a18\u53f7',
    'rupee sign': '\u30eb\u30d4\u30fc\u8a18\u53f7',
    'spesmilo sign': '\u30b9\u30da\u30b9\u30df\u30fc\u30ed\u8a18\u53f7',
    styles: '\u30b9\u30bf\u30a4\u30eb',
    'tenge sign': '\u30c6\u30f3\u30b2\u8a18\u53f7',
    'tugrik sign': '\u30c8\u30a5\u30b0\u30eb\u30b0\u8a18\u53f7',
    'turkish lira sign': '\u30c8\u30eb\u30b3\u30ea\u30e9\u8a18\u53f7',
    'won sign': '\u30a6\u30a9\u30f3\u8a18\u53f7',
    'yen character': '\u5186\u8a18\u53f7',
    'yen/yuan character variant one':
      '\u5186/\u5143\u8a18\u53f7\u306e\u30d0\u30ea\u30a8\u30fc\u30b7\u30e7\u30f3',
    'yuan character': '\u4eba\u6c11\u5143\u8a18\u53f7',
    'yuan character, in hong kong and taiwan':
      '\u9999\u6e2f\u304a\u3088\u3073\u53f0\u6e7e\u306b\u304a\u3051\u308b\u5143\u8a18\u53f7',
    '{0} characters': '{0}\u6587\u5b57',
    '{0} columns, {1} rows': '',
    '{0} words': '{0}\u8a9e'
  })
  tinymce.addI18n('ko-KR', {
    '#': '#',
    Accessibility: '\uc811\uadfc\uc131',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: '\uc791\uc5c5',
    Activity: '\ud65c\ub3d9',
    Address: '\uc8fc\uc18c',
    Advanced: '\uc0c1\uc138',
    Align: '\uc815\ub82c',
    'Align center': '\uc911\uc559 \uc815\ub82c',
    'Align left': '\uc67c\ucabd \uc815\ub82c',
    'Align right': '\uc624\ub978\ucabd \uc815\ub82c',
    Alignment: '\uc815\ub82c',
    'Alignment {0}': '',
    All: '\ubaa8\ub450',
    'Alternative description': '\ub300\uccb4 \uc124\uba85\ubb38',
    'Alternative source': '\ub300\uccb4 \uc18c\uc2a4',
    'Alternative source URL': '\ub300\uccb4 \uc18c\uc2a4 URL',
    Anchor: '\ub9c1\ud06c \uc9c0\uc810',
    'Anchor...': '\uc575\ucee4...',
    Anchors: '\uc575\ucee4',
    'Animals and Nature': '\ub3d9\ubb3c\uacfc \uc790\uc5f0',
    Arrows: '\ud654\uc0b4\ud45c',
    B: '\ud30c\ub791',
    'Background color': '\ubc30\uacbd \uc0c9',
    'Background color {0}': '',
    Black: '\uac80\uc740\uc0c9',
    Block: '\ube14\ub85d',
    'Block {0}': '',
    Blockquote: '\uc778\uc6a9\ubb38',
    Blocks: '\ube14\ub85d',
    Blue: '\ud30c\ub780\uc0c9',
    'Blue component': '\uccad\uc0c9 \uc694\uc18c',
    Body: '\ubcf8\ubb38',
    Bold: '\uad75\uac8c',
    Border: '\ud14c\ub450\ub9ac',
    'Border color': '\ud14c\ub450\ub9ac \uc0c9',
    'Border style': '\ud14c\ub450\ub9ac \uc2a4\ud0c0\uc77c',
    'Border width': '\ud14c\ub450\ub9ac \ub450\uaed8',
    Bottom: '\uc544\ub798 \ub9de\ucda4',
    'Browse files': '',
    'Browse for an image': '\uc774\ubbf8\uc9c0 \ucc3e\uae30',
    'Browse links': '',
    'Bullet list': '\uae00\uba38\ub9ac \uae30\ud638 \ubaa9\ub85d',
    Cancel: 'Cancel',
    Caption: '\ucea1\uc158',
    Cell: '\uc140',
    'Cell padding': '\uc140 \uc548\ucabd \uc5ec\ubc31',
    'Cell properties': '\uc140 \uc18d\uc131',
    'Cell spacing': '\uc140 \uac04\uaca9',
    'Cell styles': '\uc140 \ubaa8\uc591',
    'Cell type': '\uc140 \uc720\ud615',
    Center: '\uac00\uc6b4\ub370 \ub9de\ucda4',
    Characters: '\ubb38\uc790 \uc218',
    'Characters (no spaces)': '\ubb38\uc790 \uc218 (\uacf5\ubc31 \uc5c6\uc74c)',
    Circle: '\ub3d9\uadf8\ub77c\ubbf8',
    Class: '\ud074\ub798\uc2a4',
    'Clear formatting': '\uc11c\uc2dd \uc9c0\uc6b0\uae30',
    Close: '\ub2eb\uae30',
    Code: '\ucf54\ub4dc',
    'Code sample...': '\ucf54\ub4dc \uc0d8\ud50c...',
    'Code view': '\ucf54\ub4dc \ud45c\uc2dc',
    'Color Picker': '\uc0c9 \uc120\ud0dd\uae30',
    'Color swatch': '\uc0c9\uc0c1 \uacac\ubcf8',
    Cols: '\uc5f4 \uc218',
    Column: '\uc5f4',
    'Column clipboard actions': '\uc5f4 \ud074\ub9bd\ubcf4\ub4dc \ub3d9\uc791',
    'Column group': '\uc5f4 \uadf8\ub8f9',
    'Column header': '\uc5f4 \uc81c\ubaa9',
    'Constrain proportions': '\ube44\uc728 \uace0\uc815',
    Copy: '카피도',
    'Copy column': '\uc5f4 \ubcf5\uc0ac',
    'Copy row': '\ud589 \ubcf5\uc0ac',
    'Could not find the specified string.':
      '\uc9c0\uc815\ud55c \ubb38\uc790\ub97c \ucc3e\uc744 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.',
    'Could not load emojis':
      '\uc774\ubaa8\uc9c0\ub97c \ubd88\ub7ec\uc62c \uc218 \uc5c6\uc2b5\ub2c8\ub2e4',
    Count: '\uac1c\uc218',
    Currency: '\ud1b5\ud654',
    'Current window': '\ud604\uc7ac \ucc3d',
    'Custom color': '\uc0ac\uc6a9\uc790 \uc9c0\uc815 \uc0c9',
    'Custom...': '\uc0ac\uc6a9\uc790 \uc9c0\uc815...',
    Cut: '자르다',
    'Cut column': '\uc5f4 \uc798\ub77c\ub0b4\uae30',
    'Cut row': '\ud589 \uc798\ub77c\ub0b4\uae30',
    'Dark Blue': '\uc9c4\ud55c \ud30c\ub780\uc0c9',
    'Dark Gray': '\uc9c4\ud55c \ud68c\uc0c9',
    'Dark Green': '\uc9c4\ud55c \ucd08\ub85d\uc0c9',
    'Dark Orange': '\uc9c4\ud55c \uc8fc\ud669\uc0c9',
    'Dark Purple': '\uc9c4\ud55c \ubcf4\ub77c\uc0c9',
    'Dark Red': '\uc9c4\ud55c \ube68\uac04\uc0c9',
    'Dark Turquoise': '\uc9c4\ud55c \uccad\ub85d\uc0c9',
    'Dark Yellow': '\uc9c4\ud55c \ub178\ub780\uc0c9',
    Dashed: '\ud30c\uc120',
    'Date/time': '\ub0a0\uc9dc/\uc2dc\uac04',
    'Decrease indent': '\ub0b4\uc5b4\uc4f0\uae30',
    Default: '\uae30\ubcf8\uac12',
    'Delete accordion': '',
    'Delete column': '\uc5f4 \uc0ad\uc81c',
    'Delete row': '\ud589 \uc0ad\uc81c',
    'Delete table': '\ud45c \uc0ad\uc81c',
    Dimensions: '\ud06c\uae30',
    Disc: '\ub514\uc2a4\ud06c',
    Div: 'Div',
    Document: '\ubb38\uc11c',
    Dotted: '\uc810\uc120',
    Double: '\uc774\uc911 \uc2e4\uc120',
    'Drop an image here':
      '\uc5ec\uae30\ub85c \uc774\ubbf8\uc9c0\ub97c \ub04c\uc5b4\uc624\uc138\uc694',
    'Dropped file type is not supported':
      '\ub04c\uc5b4\ub2e4 \ub193\uc740 \ud30c\uc77c \ud615\uc2dd\uc744 \uc9c0\uc6d0\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4',
    Edit: '\ud3b8\uc9d1',
    Embed: '\uc0bd\uc785',
    Emojis: '\uc774\ubaa8\uc9c0',
    'Emojis...': '\uc774\ubaa8\uc9c0...',
    Error: '\uc624\ub958',
    'Error: Form submit field collision.':
      '\uc624\ub958: \uc591\uc2dd \uc81c\ucd9c \ud544\ub4dc \ubd88\uc77c\uce58',
    'Error: No form element found.': '\uc624\ub958: \uc591\uc2dd \ud56d\ubaa9 \uc5c6\uc74c',
    'Extended Latin': '\ud655\uc7a5 \ub77c\ud2f4\uc5b4',
    'Failed to initialize plugin: {0}':
      '\ud50c\ub7ec\uadf8\uc778 {0}\uc758 \ucd08\uae30\ud654\uac00 \uc2e4\ud328\ud588\uc2b5\ub2c8\ub2e4',
    'Failed to load plugin url: {0}':
      '\ud50c\ub7ec\uadf8\uc778 URL {0}\uc744 \ubd88\ub7ec\uc624\uc9c0 \ubabb\ud588\uc2b5\ub2c8\ub2e4.',
    'Failed to load plugin: {0} from url {1}':
      'URL {1}\ub85c\ubd80\ud130 \ud50c\ub7ec\uadf8\uc778 {0}\uc744 \ubd88\ub7ec\uc624\uc9c0 \ubabb\ud588\uc2b5\ub2c8\ub2e4.',
    'Failed to upload image: {0}':
      '\uc774\ubbf8\uc9c0{0}\uc744(\ub97c) \uc5c5\ub85c\ub4dc \ud558\uc9c0 \ubabb\ud588\uc2b5\ub2c8\ub2e4.',
    File: '\ud30c\uc77c',
    Find: '\ucc3e\uae30',
    'Find (if searchreplace plugin activated)':
      '\ucc3e\uae30 (searchreplace \ud50c\ub7ec\uadf8\uc778\uc774 \ud65c\uc131\ud654\ub41c \uacbd\uc6b0)',
    'Find and Replace': '\ucc3e\uae30 \ubc0f \ubc14\uafb8\uae30',
    'Find and replace...': '\ucc3e\uae30 \ubc0f \ubc14\uafb8\uae30...',
    'Find in selection': '\uc120\ud0dd\ub41c \ubd80\ubd84\uc5d0\uc11c \uac80\uc0c9',
    'Find whole words only': '\ubaa8\ub450 \uc77c\uce58\ud558\ub294 \ub2e8\uc5b4 \ucc3e\uae30',
    Flags: '\uae43\ubc1c',
    'Focus to contextual toolbar':
      '\ucee8\ud14d\uc2a4\ud2b8 \ud234\ubc14\uc5d0 \uac15\uc870\ud45c\uc2dc',
    'Focus to element path': '\uc694\uc18c \uacbd\ub85c\uc5d0 \uac15\uc870\ud45c\uc2dc',
    'Focus to menubar': '\uba54\ub274\ubc14\uc5d0 \uac15\uc870\ud45c\uc2dc',
    'Focus to toolbar': '\ud234\ubc14\uc5d0 \uac15\uc870\ud45c\uc2dc',
    Font: '\uae00\uaf34',
    'Font size {0}': '',
    'Font sizes': '\uae00\uaf34 \ud06c\uae30',
    'Font {0}': '',
    Fonts: '\uae00\uaf34',
    'Food and Drink': '\uc74c\uc2dd\uacfc \uc74c\ub8cc',
    Footer: '\ud478\ud130',
    Format: '\uc11c\uc2dd',
    'Format {0}': '',
    Formats: '\uc11c\uc2dd',
    Fullscreen: '\uc804\uccb4 \ud654\uba74',
    G: '\ub179\uc0c9',
    General: '\uc77c\ubc18',
    Gray: '\ud68c\uc0c9',
    Green: '\ucd08\ub85d\uc0c9',
    'Green component': '\ub179\uc0c9 \uc694\uc18c',
    Groove: '\uc785\uccb4 \ud14c\ub450\ub9ac',
    'Handy Shortcuts': '\uc720\uc6a9\ud55c \ub2e8\ucd95\ud0a4',
    Header: '\uc81c\ubaa9',
    'Header cell': '\ud5e4\ub354 \uc140',
    'Heading 1': '\uc81c\ubaa9 1',
    'Heading 2': '\uc81c\ubaa9 2',
    'Heading 3': '\uc81c\ubaa9 3',
    'Heading 4': '\uc81c\ubaa9 4',
    'Heading 5': '\uc81c\ubaa9 5',
    'Heading 6': '\uc81c\ubaa9 6',
    Headings: '\uc81c\ubaa9',
    Height: '\ub192\uc774',
    Help: '\ub3c4\uc6c0\ub9d0',
    'Hex color code': '16\uc9c4\uc218 \uc0c9\uc0c1 \ucf54\ub4dc',
    Hidden: '\uc228\uae40',
    'Horizontal align': '\uc218\ud3c9 \uc815\ub82c',
    'Horizontal line': '\uc218\ud3c9\uc120',
    'Horizontal space': '\uc88c\uc6b0 \uc5ec\ubc31',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'ID\ub294 \ubb38\uc790\ub85c \uc2dc\uc791\ud574\uc57c \ud558\uba70, \uadf8 \ub2e4\uc74c\uc5d0\ub294 \ubb38\uc790, \uc22b\uc790, \ub300\uc2dc, \uad6c\ub450\uc810, \ucf5c\ub860, \ubc11\uc904 \ubb38\uc790\uac00 \uc62c \uc218 \uc788\uc2b5\ub2c8\ub2e4.',
    'Image is decorative': '\uc774\ubbf8\uc9c0 \uc7a5\uc2dd \uac00\ub2a5',
    'Image list': '\uc774\ubbf8\uc9c0 \ubaa9\ub85d',
    'Image title': '\uc774\ubbf8\uc9c0 \uc81c\ubaa9',
    'Image...': '\uc774\ubbf8\uc9c0...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      'ImageProxy HTTP \uc624\ub958: \uc774\ubbf8\uc9c0 \ud504\ub85d\uc2dc\ub97c \ucc3e\uc744 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      'ImageProxy HTTP \uc624\ub958: \uc62c\ubc14\ub974\uc9c0 \uc54a\uc740 \uc774\ubbf8\uc9c0 \ud504\ub85d\uc2dc URL \uc8fc\uc18c',
    'ImageProxy HTTP error: Rejected request':
      'ImageProxy HTTP \uc624\ub958: \uc694\uccad \uac70\ubd80',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      'ImageProxy HTTP \uc624\ub958: \uc54c \uc218 \uc5c6\ub294 \uc774\ubbf8\uc9c0 \ud504\ub85d\uc2dc \uc624\ub958',
    'Increase indent': '\ub4e4\uc5ec\uc4f0\uae30',
    Inline: '\uc778\ub77c\uc778',
    Insert: '\uc0bd\uc785',
    'Insert Template': '\ud15c\ud50c\ub9bf \uc0bd\uc785',
    'Insert accordion': '',
    'Insert column after': '\ub2e4\uc74c\uc5d0 \uc5f4 \uc0bd\uc785',
    'Insert column before': '\uc774\uc804\uc5d0 \uc5f4 \uc0bd\uc785',
    'Insert date/time': '\ub0a0\uc9dc/\uc2dc\uac04 \uc0bd\uc785',
    'Insert image': '\uc774\ubbf8\uc9c0 \uc0bd\uc785',
    'Insert link (if link plugin activated)':
      '\ub9c1\ud06c \uc0bd\uc785 (link \ud50c\ub7ec\uadf8\uc778\uc774 \ud65c\uc131\ud654\ub41c \uacbd\uc6b0)',
    'Insert row after': '\ub2e4\uc74c\uc5d0 \ud589 \uc0bd\uc785',
    'Insert row before': '\uc774\uc804\uc5d0 \ud589 \uc0bd\uc785',
    'Insert table': '\ud45c \uc0bd\uc785',
    'Insert template...': '\ud15c\ud50c\ub9bf \uc0bd\uc785...',
    'Insert video': '\ube44\ub514\uc624 \uc0bd\uc785',
    'Insert/Edit code sample': '\ucf54\ub4dc \uc0d8\ud50c \uc0bd\uc785/\ud3b8\uc9d1',
    'Insert/edit image': '\uc774\ubbf8\uc9c0 \uc0bd\uc785/\ud3b8\uc9d1',
    'Insert/edit link': '\ub9c1\ud06c \uc0bd\uc785/\ud3b8\uc9d1',
    'Insert/edit media': '\ubbf8\ub514\uc5b4 \uc0bd\uc785/\ud3b8\uc9d1',
    'Insert/edit video': '\ube44\ub514\uc624 \uc0bd\uc785/\ud3b8\uc9d1',
    Inset: '\uc140 \ud568\ubab0',
    'Invalid hex color code: {0}':
      '\ubd80\uc801\uc808\ud55c 16\uc9c4\uc218 \uc0c9\uc0c1 \ucf54\ub4dc: {0}',
    'Invalid input': '\ubd80\uc801\uc808\ud55c \uc785\ub825',
    Italic: '\uae30\uc6b8\uc784\uaf34',
    Justify: '\uc591\ucabd \uc815\ub82c',
    'Keyboard Navigation': '\ub2e8\ucd95\ud0a4',
    Language: '\uc5b8\uc5b4',
    'Learn more...': '\uc880 \ub354 \uc0b4\ud3b4\ubcf4\uae30...',
    Left: '\uc67c\ucabd \ub9de\ucda4',
    'Left to right': '\uc67c\ucabd\uc5d0\uc11c \uc624\ub978\ucabd',
    'Light Blue': '\ubc1d\uc740 \ud30c\ub780\uc0c9',
    'Light Gray': '\ubc1d\uc740 \ud68c\uc0c9',
    'Light Green': '\ubc1d\uc740 \ub179\uc0c9',
    'Light Purple': '\ubc1d\uc740 \ubcf4\ub77c\uc0c9',
    'Light Red': '\ubc1d\uc740 \ube68\uac04\uc0c9',
    'Light Yellow': '\ubc1d\uc740 \ub178\ub780\uc0c9',
    'Line height': '\ud589 \ub192\uc774',
    'Link list': '\ub9c1\ud06c \ubaa9\ub85d',
    'Link...': '\ub9c1\ud06c...',
    'List Properties': '\ud56d\ubaa9 \uc18d\uc131',
    'List properties...': '\ud56d\ubaa9 \uc18d\uc131...',
    'Loading emojis...': '\uc774\ubaa8\uc9c0 \ubd88\ub7ec\uc624\ub294 \uc911...',
    'Loading...': '\ubd88\ub7ec\uc624\ub294 \uc911...',
    'Lower Alpha': '\uc54c\ud30c\ubcb3 \uc18c\ubb38\uc790',
    'Lower Greek': '\uadf8\ub9ac\uc2a4\uc5b4 \uc18c\ubb38\uc790',
    'Lower Roman': '\ub85c\ub9c8\uc790 \uc18c\ubb38\uc790',
    'Match case': '\ub300/\uc18c\ubb38\uc790 \uad6c\ubd84',
    Mathematical: '\uc218\ud559\uae30\ud638',
    'Media poster (Image URL)': '\ubbf8\ub514\uc5b4 \ud3ec\uc2a4\ud130 (\uc774\ubbf8\uc9c0 URL)',
    'Media...': '\ubbf8\ub514\uc5b4...',
    'Medium Blue': '\uc911\uac04 \ud30c\ub780\uc0c9',
    'Medium Gray': '\uc911\uac04 \ud68c\uc0c9',
    'Medium Purple': '\uc911\uac04 \ubcf4\ub77c\uc0c9',
    'Merge cells': '\uc140 \ubcd1\ud569',
    Middle: '\uac00\uc6b4\ub370 \ub9de\ucda4',
    'Midnight Blue': '\uc9c4\ud55c \ud30c\ub780\uc0c9',
    'More...': '\ub354 \ubcf4\uae30...',
    Name: '\uc774\ub984',
    'Navy Blue': '\ub0a8\uc0c9',
    'New document': '새 문서',
    'New window': '\uc0c8 \ucc3d',
    Next: '\ub2e4\uc74c',
    No: '\uc544\ub2c8\uc624',
    'No alignment': '\uc815\ub82c \uc5c6\uc74c',
    'No color': '\uc0c9 \uc5c6\uc74c',
    'Nonbreaking space': '\ub744\uc5b4\uc4f0\uae30',
    None: '\uc5c6\uc74c',
    'Numbered list': '\ubc88\ud638 \ub9e4\uae30\uae30 \ubaa9\ub85d',
    OR: '\ub610\ub294',
    Objects: '\ubb3c\uac74',
    Ok: 'Ok',
    'Open help dialog': '\ub3c4\uc6c0\ub9d0 \ub2e4\uc774\uc5bc\ub85c\uadf8 \uc5f4\uae30',
    'Open link': '\ub9c1\ud06c \uc5f4\uae30',
    'Open link in...': '...\uc5d0\uc11c \ub9c1\ud06c \uc5f4\uae30',
    'Open popup menu for split buttons':
      '\ubd84\ud560 \ubc84\ud2bc\uc73c\ub85c \ud31d\uc5c5 \uba54\ub274 \uc5f4\uae30',
    Orange: '\uc8fc\ud669\uc0c9',
    Outset: '\uc140 \ub3cc\ucd9c',
    'Page break': '\ud398\uc774\uc9c0 \uad6c\ubd84\uc790',
    Paragraph: '\ub2e8\ub77d',
    Paste: '반죽',
    'Paste as text': '\ud14d\uc2a4\ud2b8\ub85c \ubd99\uc5ec\ub123\uae30',
    'Paste column after': '\ub2e4\uc74c\uc5d0 \uc5f4 \ubd99\uc5ec\ub123\uae30',
    'Paste column before': '\uc774\uc804\uc5d0 \uc5f4 \ubd99\uc5ec\ub123\uae30',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      '\ubd99\uc5ec\ub123\uae30\uac00 \ud604\uc7ac \uc77c\ubc18 \ud14d\uc2a4\ud2b8 \ubaa8\ub4dc\uc785\ub2c8\ub2e4. \uc774 \uc635\uc158\uc744 \ud574\uc81c\ud560 \ub54c\uae4c\uc9c0 \uc77c\ubc18 \ud14d\uc2a4\ud2b8\ub85c \ubd99\uc5ec\ub123\uc2b5\ub2c8\ub2e4.',
    'Paste or type a link':
      '\ub9c1\ud06c\ub97c \ubd99\uc5ec\ub123\uac70\ub098 \uc785\ub825\ud558\uc2ed\uc2dc\uc624.',
    'Paste row after': '\ub2e4\uc74c\uc5d0 \ud589 \ubd99\uc5ec\ub123\uae30',
    'Paste row before': '\uc774\uc804\uc5d0 \ud589 \ubd99\uc5ec\ub123\uae30',
    'Paste your embed code below:':
      '\uc0bd\uc785\ud560 \ucf54\ub4dc\ub97c \uc544\ub798\uc5d0 \ubd99\uc5ec \ub123\uc5b4\uc8fc\uc138\uc694.',
    People: '\uc0ac\ub78c',
    Plugins: '\ud50c\ub7ec\uadf8\uc778',
    'Plugins installed ({0}):': '\uc124\uce58\ub41c \ud50c\ub7ec\uadf8\uc778({0}):',
    'Powered by {0}': '{0}\uc5d0\uc11c \uc9c0\uc6d0',
    Pre: 'Pre',
    Preferences: '\ud658\uacbd\uc124\uc815',
    Preformatted: '\uc11c\uc2dd \ubbf8\uc124\uc815',
    'Premium plugins:': '\ud504\ub9ac\ubbf8\uc5c4 \ud50c\ub7ec\uadf8\uc778:',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: '\ubbf8\ub9ac \ubcf4\uae30',
    Previous: '\uc774\uc804',
    Print: '\uc778\uc1c4',
    'Print...': '\uc778\uc1c4...',
    Purple: '\ubcf4\ub77c\uc0c9',
    Quotations: '\uc778\uc6a9\ubb38',
    R: '\ube68\uac15',
    'Range 0 to 255': '0\ubd80\ud130 255\uae4c\uc9c0\uc758 \ubc94\uc704',
    Red: '\ube68\uac04\uc0c9',
    'Red component': '\uc801\uc0c9 \uc694\uc18c',
    Redo: '다시 하다',
    Remove: '\uc81c\uac70',
    'Remove color': '\uc0c9 \uc81c\uac70',
    'Remove link': '\ub9c1\ud06c \uc81c\uac70',
    Replace: '\ubc14\uafb8\uae30',
    'Replace all': '\ubaa8\ub450 \ubc14\uafb8\uae30',
    'Replace with': '\ub2e4\uc74c\uc73c\ub85c \ubc14\uafb8\uae30:',
    Resize: '\ud06c\uae30 \uc870\uc808',
    'Restore last draft': '\ub9c8\uc9c0\ub9c9 \ucd08\uc548 \ubcf5\uc6d0',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': '\uc11c\uc2dd \ud14d\uc2a4\ud2b8 \uc601\uc5ed',
    'Rich Text Area. Press ALT-0 for help.':
      '\uc11c\uc2dd \uc788\ub294 \ud14d\uc2a4\ud2b8 \uc601\uc5ed. ALT-0\uc744 \ub204\ub974\uba74 \ub3c4\uc6c0\ub9d0\uc744 \ubcfc \uc218 \uc788\uc2b5\ub2c8\ub2e4.',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      '\uc11c\uc2dd \uc788\ub294 \ud14d\uc2a4\ud2b8 \uc601\uc5ed. ALT-F9\ub97c \ub204\ub974\uba74 \uba54\ub274, ALT-F10\uc744 \ub204\ub974\uba74 \ud234\ubc14, ALT-0\uc744 \ub204\ub974\uba74 \ub3c4\uc6c0\ub9d0\uc744 \ubcfc \uc218 \uc788\uc2b5\ub2c8\ub2e4.',
    Ridge: '\ub3cc\ucd9c \ud14c\ub450\ub9ac',
    Right: '\uc624\ub978\ucabd \ub9de\ucda4',
    'Right to left': '\uc624\ub978\ucabd\uc5d0\uc11c \uc67c\ucabd',
    Row: '\ud589',
    'Row clipboard actions': '\ud589 \ud074\ub9bd\ubcf4\ub4dc \ub3d9\uc791',
    'Row group': '\ud589 \uadf8\ub8f9',
    'Row header': '\ud589 \uc81c\ubaa9',
    'Row properties': '\ud589 \uc18d\uc131',
    'Row type': '\ud589 \uc720\ud615',
    Rows: '\ud589 \uc218',
    Save: '\uc800\uc7a5',
    'Save (if save plugin activated)':
      '\uc800\uc7a5 (save \ud50c\ub7ec\uadf8\uc778\uc774 \ud65c\uc131\ud654\ub41c \uacbd\uc6b0)',
    Scope: '\ubc94\uc704',
    Search: '\uac80\uc0c9',
    'Select all': '모두 선택',
    'Select...': '\uc120\ud0dd...',
    Selection: '\uc120\ud0dd',
    Shortcut: '\ubc14\ub85c\uac00\uae30',
    'Show blocks': '\ube14\ub85d \ud45c\uc2dc',
    'Show caption': '\ucea1\uc158 \ud45c\uc2dc',
    'Show invisible characters': '\ube44\ud45c\uc2dc \ubb38\uc790 \ud45c\uc2dc',
    Size: '\ud06c\uae30',
    Solid: '\uc2e4\uc120',
    Source: '\uc18c\uc2a4',
    'Source code': '\uc18c\uc2a4\ucf54\ub4dc',
    'Special Character': '\ud2b9\uc218 \ubb38\uc790',
    'Special character...': '\ud2b9\uc218 \ubb38\uc790...',
    'Split cell': '\uc140 \ubd84\ud560',
    Square: '\ub124\ubaa8',
    'Start list at number': '\ubc88\ud638 \ub9ac\uc2a4\ud2b8 \uc2dc\uc791',
    Strikethrough: '\ucde8\uc18c\uc120',
    Style: '\uc2a4\ud0c0\uc77c',
    Subscript: '\uc544\ub798 \ucca8\uc790',
    Superscript: '\uc704 \ucca8\uc790',
    'Switch to or from fullscreen mode': '\uc804\uccb4 \ud654\uba74 \ubaa8\ub4dc \uc804\ud658',
    Symbols: '\uae30\ud638',
    'System Font': '\uc2dc\uc2a4\ud15c \uae00\uaf34',
    Table: '\ud45c',
    'Table caption': '\ud45c \ucea1\uc158',
    'Table properties': '\ud45c \uc18d\uc131',
    'Table styles': '\ud45c \ubaa8\uc591',
    Template: '\ud15c\ud50c\ub9bf',
    Templates: '\ud15c\ud50c\ub9bf',
    Text: '\ud14d\uc2a4\ud2b8',
    'Text color': '\uae00\uc790 \uc0c9',
    'Text color {0}': '',
    'Text to display': '\ud45c\uc2dc\ud560 \ud14d\uc2a4\ud2b8',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      '\uc785\ub825\ud558\uc2e0 URL\uc774 \uc774\uba54\uc77c \uc8fc\uc18c\uc778 \uac83 \uac19\uc2b5\ub2c8\ub2e4. "mailto:" \uc811\ub450\uc0ac\ub97c \ucd94\uac00\ud558\uc2dc\uaca0\uc2b5\ub2c8\uae4c?',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      '\uc785\ub825\ud558\uc2e0 URL\uc774 \uc678\ubd80 \ub9c1\ud06c\uc778 \uac83 \uac19\uc2b5\ub2c8\ub2e4. "http://" \uc811\ub450\uc0ac\ub97c \ucd94\uac00\ud558\uc2dc\uaca0\uc2b5\ub2c8\uae4c?',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      '\uc785\ub825\ud558\uc2e0 URL\uc774 \uc678\ubd80 \ub9c1\ud06c\uc778 \uac83 \uac19\uc2b5\ub2c8\ub2e4. "https://" \uc811\ub450\uc0ac\ub97c \ucd94\uac00\ud558\uc2dc\uaca0\uc2b5\ub2c8\uae4c?',
    Title: '\uc81c\ubaa9',
    'To open the popup, press Shift+Enter':
      '\ud31d\uc5c5\uc744 \uc5f4\ub824\uba74 Shift+Enter\ub97c \ub204\ub974\uc2ed\uc2dc\uc624.',
    'Toggle accordion': '',
    Tools: '\ub3c4\uad6c',
    Top: '\uc704\ucabd \ub9de\ucda4',
    'Travel and Places': '\uc5ec\ud589\uacfc \uc7a5\uc18c',
    Turquoise: '\uccad\ub85d\uc0c9',
    Underline: '\ubc11\uc904',
    Undo: '끄르다',
    Upload: '\uc5c5\ub85c\ub4dc',
    'Uploading image': '\uc774\ubbf8\uc9c0 \uc5c5\ub85c\ub4dc \uc911',
    'Upper Alpha': '\uc54c\ud30c\ubcb3 \ub300\ubb38\uc790',
    'Upper Roman': '\ub85c\ub9c8\uc790 \ub300\ubb38\uc790',
    Url: 'URL',
    'User Defined': '\uc0ac\uc6a9\uc790 \uc815\uc758',
    Valid: '\uc720\ud6a8\ud568',
    Version: '\ubc84\uc804',
    'Vertical align': '\uc218\uc9c1 \uc815\ub82c',
    'Vertical space': '\uc0c1\ud558 \uc5ec\ubc31',
    View: '\ubcf4\uae30',
    'Visual aids': '\ud45c\uc758 \ud14c\ub450\ub9ac\ub97c \uc810\uc120\uc73c\ub85c \ud45c\uc2dc',
    Warn: '\uacbd\uace0',
    White: '\ud770\uc0c9',
    Width: '\ub108\ube44',
    'Word count': '\ubb38\uc790 \uc218',
    Words: '\ub2e8\uc5b4 \uc218',
    'Words: {0}': '\ub2e8\uc5b4 \uc218: {0}',
    Yellow: '\ub178\ub780\uc0c9',
    Yes: '\ub124',
    'You are using {0}': '{0} \uc0ac\uc6a9 \uc911',
    'You have unsaved changes are you sure you want to navigate away?':
      '\uc800\uc7a5\ud558\uc9c0 \uc54a\uc740 \uc815\ubcf4\uac00 \uc788\uc2b5\ub2c8\ub2e4. \uc774 \ud398\uc774\uc9c0\ub97c \ub098\uac00\uc2dc\uaca0\uc2b5\ub2c8\uae4c?',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      '\ube0c\ub77c\uc6b0\uc800\uac00 \ud074\ub9bd\ubcf4\ub4dc \uc811\uadfc\uc744 \uc9c0\uc6d0\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4. Ctrl+X/C/V \ub2e8\ucd95\ud0a4\ub97c \uc774\uc6a9\ud574\uc8fc\uc138\uc694.',
    alignment: '\uc815\ub82c',
    'austral sign': '\uc544\uc6b0\uc2a4\ud2b8\ub784 \uae30\ud638',
    'cedi sign': '\uc138\ub514 \uae30\ud638',
    'colon sign': '\ucf5c\ub860 \uae30\ud638',
    'cruzeiro sign': '\ud06c\ub8e8\uc81c\uc774\ub85c \uae30\ud638',
    'currency sign': '\ud1b5\ud654 \uae30\ud638',
    'dollar sign': '\ub2ec\ub7ec \uae30\ud638',
    'dong sign': '\ub3d9 \uae30\ud638',
    'drachma sign': '\ub4dc\ub77c\ud06c\ub9c8 \uae30\ud638',
    'euro-currency sign': '\uc720\ub85c\ud654 \uae30\ud638',
    example: '\uc608\uc81c',
    formatting: '\uc11c\uc2dd',
    'french franc sign': '\ud504\ub791\uc2a4 \ud504\ub791 \uae30\ud638',
    'german penny symbol': '\ub3c5\uc77c \ud398\ub2c8 \uae30\ud638',
    'guarani sign': '\uacfc\ub77c\ub2c8 \uae30\ud638',
    history: '\uc774\ub825',
    'hryvnia sign': '\uadf8\ub9ac\ube0c\ub098 \uae30\ud638',
    indentation: '\ub4e4\uc5ec\uc4f0\uae30',
    'indian rupee sign': '\uc778\ub3c4 \ub8e8\ud53c \uae30\ud638',
    'kip sign': '\ud0b5 \uae30\ud638',
    'lira sign': '\ub9ac\ub77c \uae30\ud638',
    'livre tournois sign': '\ub9ac\ube0c\ub974 \ud2b8\ub974\ub204\uc544 \uae30\ud638',
    'manat sign': '\ub9c8\ub098\ud2b8 \uae30\ud638',
    'mill sign': '\ubc00 \uae30\ud638',
    'naira sign': '\ub098\uc774\ub77c \uae30\ud638',
    'new sheqel sign': '\ub274 \uc138\ucf08 \uae30\ud638',
    'nordic mark sign': '\ub178\ub974\ub515 \ub9c8\ub974\ud06c \uae30\ud638',
    'peseta sign': '\ud398\uc138\ud0c0 \uae30\ud638',
    'peso sign': '\ud398\uc18c \uae30\ud638',
    'ruble sign': '\ub8e8\ube14 \uae30\ud638',
    'rupee sign': '\ub8e8\ud53c \uae30\ud638',
    'spesmilo sign': '\uc2a4\ud398\uc2a4\ubc00\ub85c \uae30\ud638',
    styles: '\uc2a4\ud0c0\uc77c',
    'tenge sign': '\ud161\uac8c \uae30\ud638',
    'tugrik sign': '\ud22c\uadf8\ub9ac\ud06c \uae30\ud638',
    'turkish lira sign': '\ud130\ud0a4 \ub9ac\ub77c \uae30\ud638',
    'won sign': '\uc6d0 \uae30\ud638',
    'yen character': '\uc5d4 \uae30\ud638',
    'yen/yuan character variant one': '\uc5d4/\uc704\uc548 \ubb38\uc790 \ubcc0\ud615',
    'yuan character': '\uc704\uc548 \uae30\ud638',
    'yuan character, in hong kong and taiwan': '\ub300\ub9cc \uc704\uc548 \uae30\ud638',
    '{0} characters': '{0} \ubb38\uc790',
    '{0} columns, {1} rows': '',
    '{0} words': '{0}\uac1c\uc758 \ub2e8\uc5b4'
  })
  tinymce.addI18n('pt-BR', {
    '#': '#',
    Accessibility: 'Acessibilidade',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: 'A\xe7\xe3o',
    Activity: 'Atividade',
    Address: 'Endere\xe7o',
    'Add Variable': 'Adicionar variável',
    Advanced: 'Avan\xe7ado',
    Align: 'Alinhamento',
    'Align center': 'Centralizar',
    'Align left': 'Alinhar \xe0 esquerda',
    'Align right': 'Alinhar \xe0 direita',
    Alignment: 'Alinhamento',
    'Alignment {0}': '',
    All: 'Tudo',
    'Alternative description': 'Descri\xe7\xe3o alternativa',
    'Alternative source': 'Endere\xe7o alternativo',
    'Alternative source URL': 'Endere\xe7o URL alternativo',
    Anchor: '\xc2ncora',
    'Anchor...': '\xc2ncora...',
    Anchors: '\xc2ncoras',
    'Animals and Nature': 'Animais e Natureza',
    Arrows: 'Setas',
    B: 'B',
    'Background color': 'Cor do fundo',
    'Background color {0}': '',
    Black: 'Preto',
    Block: 'Par\xe1grafo',
    'Block {0}': '',
    Blockquote: 'Bloco de cita\xe7\xe3o',
    Blocks: 'Par\xe1grafo',
    Blue: 'Azul',
    'Blue component': 'Componente azul',
    Body: 'Corpo',
    Bold: 'Negrito',
    Border: 'Borda',
    'Border color': 'Cor da borda',
    'Border style': 'Estilo da borda',
    'Border width': 'Espessura da borda',
    Bottom: 'Inferior',
    'Browse files': '',
    'Browse for an image': 'Procurar uma imagem',
    'Browse links': '',
    'Bullet list': 'Lista com marcadores',
    Cancel: 'Cancelar',
    Caption: 'Legenda',
    Cell: 'C\xe9lula',
    'Cell padding': 'Espa\xe7amento interno da c\xe9lula',
    'Cell properties': 'Propriedades da c\xe9lula',
    'Cell spacing': 'Espa\xe7amento da c\xe9lula',
    'Cell styles': 'Estilos da c\xe9lula',
    'Cell type': 'Tipo de c\xe9lula',
    Center: 'Centro',
    Characters: 'Caracteres',
    'Characters (no spaces)': 'Caracteres (sem espa\xe7os)',
    Circle: 'C\xedrculo',
    Class: 'Classe',
    'Clear formatting': 'Limpar formata\xe7\xe3o',
    Close: 'Fechar',
    Code: 'Monoespa\xe7ada',
    'Code sample...': 'C\xf3digo...',
    'Code view': 'Ver c\xf3digo',
    'Color Picker': 'Seletor de cores',
    'Color swatch': 'Amostra de cor',
    Cols: 'Colunas',
    Column: 'Coluna',
    'Column clipboard actions': 'A\xe7\xf5es da \xe1rea de transfer\xeancia de colunas',
    'Column group': 'Grupo de colunas',
    'Column header': 'Cabe\xe7alho da coluna',
    'Constrain proportions': 'Restringir propor\xe7\xf5es',
    Copy: 'Copiar',
    'Copy column': 'Copiar coluna',
    'Copy row': 'Copiar linha',
    'Could not find the specified string.':
      'N\xe3o foi poss\xedvel encontrar o termo especificado.',
    'Could not load emojis': 'N\xe3o foi poss\xedvel carregar os emojis',
    Count: 'Contar',
    Currency: 'Moeda',
    'Current window': 'Janela atual',
    'Custom color': 'Cor personalizada',
    'Custom...': 'Personalizado...',
    Cut: 'Recortar',
    'Cut column': 'Recortar coluna',
    'Cut row': 'Recortar linha',
    'Dark Blue': 'Azul escuro',
    'Dark Gray': 'Cinza escuro',
    'Dark Green': 'Verde escuro',
    'Dark Orange': 'Laranja escuro',
    'Dark Purple': 'Roxo escuro',
    'Dark Red': 'Vermelho escuro',
    'Dark Turquoise': 'Turquesa escuro',
    'Dark Yellow': 'Amarelo escuro',
    Dashed: 'Tracejada',
    'Date/time': 'Data/hora',
    'Decrease indent': 'Diminuir recuo',
    Default: 'Padr\xe3o',
    'Delete accordion': '',
    'Delete column': 'Excluir coluna',
    'Delete row': 'Excluir linha',
    'Delete table': 'Excluir tabela',
    Dimensions: 'Dimens\xf5es',
    Disc: 'Disco',
    Div: 'Se\xe7\xe3o (div)',
    Document: 'Documento',
    Dotted: 'Pontilhada',
    Double: 'Dupla',
    'Drop an image here': 'Arraste uma imagem para c\xe1',
    'Dropped file type is not supported': 'O tipo do arquivo arrastado n\xe3o \xe9 compat\xedvel',
    Edit: 'Editar',
    Embed: 'Incorporar',
    Emojis: 'Emojis',
    'Emojis...': 'Emojis...',
    Error: 'Erro',
    'Error: Form submit field collision.':
      'Erro: colis\xe3o de bot\xe3o de envio do formul\xe1rio.',
    'Error: No form element found.': 'Erro: elemento de formul\xe1rio n\xe3o encontrado.',
    'Extended Latin': 'Latino estendido',
    'Failed to initialize plugin: {0}': 'Falha ao iniciar plugin: {0}',
    'Failed to load plugin url: {0}': 'Falha ao carregar URL do plugin: {0}',
    'Failed to load plugin: {0} from url {1}': 'Falha ao carregar plugin: {0} da URL {1}',
    'Failed to upload image: {0}': 'Falha ao carregar imagem: {0}',
    File: 'Arquivo',
    Find: 'Localizar',
    'Find (if searchreplace plugin activated)':
      'Localizar (se o plugin de localizar e substituir estiver ativado)',
    'Find and Replace': 'Localizar e substituir',
    'Find and replace...': 'Localizar e substituir...',
    'Find in selection': 'Localizar na sele\xe7\xe3o',
    'Find whole words only': 'Encontrar somente palavras inteiras',
    Flags: 'Bandeiras',
    'Focus to contextual toolbar': 'Focalizar barra de ferramentas contextual',
    'Focus to element path': 'Focalizar caminho do elemento',
    'Focus to menubar': 'Focalizar barra de menus',
    'Focus to toolbar': 'Focalizar barra de ferramentas',
    Font: 'Fonte',
    'Font size {0}': '',
    'Font sizes': 'Tamanho da fonte',
    'Font {0}': '',
    Fonts: 'Fonte',
    'Food and Drink': 'Comida e Bebida',
    Footer: 'Rodap\xe9',
    Format: 'Formatar',
    'Format {0}': '',
    Formats: 'Formata\xe7\xe3o',
    Fullscreen: 'Tela cheia',
    G: 'G',
    General: 'Geral',
    Gray: 'Cinza',
    Green: 'Verde',
    'Green component': 'Componente verde',
    Groove: 'Chanfrada',
    'Handy Shortcuts': 'Atalhos \xfateis',
    Header: 'Cabe\xe7alho',
    'Header cell': 'C\xe9lula de cabe\xe7alho',
    'Heading 1': 'T\xedtulo 1',
    'Heading 2': 'T\xedtulo 2',
    'Heading 3': 'T\xedtulo 3',
    'Heading 4': 'T\xedtulo 4',
    'Heading 5': 'T\xedtulo 5',
    'Heading 6': 'T\xedtulo 6',
    Headings: 'T\xedtulos',
    Height: 'Altura',
    Help: 'Ajuda',
    'Hex color code': 'C\xf3digo hexadecimal de cor',
    Hidden: 'Oculta',
    'Horizontal align': 'Alinhamento horizontal',
    'Horizontal line': 'Linha horizontal',
    'Horizontal space': 'Espa\xe7o horizontal',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'O ID deve come\xe7ar com uma letra, seguida apenas por letras, n\xfameros, tra\xe7os, v\xedrgulas ou sublinhas.',
    'Image is decorative': 'A imagem \xe9 decorativa',
    'Image list': 'Lista de imagens',
    'Image title': 'T\xedtulo da imagem',
    'Image...': 'Imagem...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      'Erro de HTTP ImageProxy: n\xe3o foi poss\xedvel encontrar o proxy de imagem',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      'Erro de HTTP ImageProxy: URL de proxy de imagem incorreto',
    'ImageProxy HTTP error: Rejected request': 'Erro HTTP ImageProxy: solicita\xe7\xe3o rejeitada',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      'Erro de HTTP ImageProxy: erro ImageProxy desconhecido',
    'Increase indent': 'Aumentar recuo',
    Inline: 'Fonte',
    Insert: 'Inserir',
    'Insert Template': 'Inserir modelo',
    'Insert accordion': '',
    'Insert column after': 'Inserir coluna depois',
    'Insert column before': 'Inserir coluna antes',
    'Insert date/time': 'Inserir data/hora',
    'Insert image': 'Inserir imagem',
    'Insert link (if link plugin activated)': 'Inserir link (se o plugin de link estiver ativado)',
    'Insert row after': 'Inserir linha depois',
    'Insert row before': 'Inserir linha antes',
    'Insert table': 'Inserir tabela',
    'Insert template...': 'Inserir modelo...',
    'Insert video': 'Inserir v\xeddeo',
    'Insert/Edit code sample': 'Inserir/editar c\xf3digo',
    'Insert/edit image': 'Inserir/editar imagem',
    'Insert/edit link': 'Inserir/editar link',
    'Insert/edit media': 'Inserir/editar m\xeddia',
    'Insert/edit video': 'Inserir/editar v\xeddeo',
    Inset: 'Baixo relevo',
    'Invalid hex color code: {0}': 'C\xf3digo hexadecimal de cor inv\xe1lido: {0}',
    'Invalid input': 'Entrada inv\xe1lida',
    Italic: 'It\xe1lico',
    Justify: 'Justificar',
    'Keyboard Navigation': 'Navega\xe7\xe3o pelo teclado',
    Language: 'Idioma',
    'Learn more...': 'Saber mais...',
    Left: '\xc0 esquerda',
    'Left to right': 'Esquerda para direita',
    'Light Blue': 'Azul claro',
    'Light Gray': 'Cinza claro',
    'Light Green': 'Verde claro',
    'Light Purple': 'Roxo claro',
    'Light Red': 'Vermelho claro',
    'Light Yellow': 'Amarelo claro',
    'Line height': 'Altura da linha',
    'Link list': 'Lista de links',
    'Link...': 'Link...',
    'List Properties': 'Listar propriedades',
    'List properties...': 'Listar propriedades...',
    'Loading emojis...': 'Carregando emojis...',
    'Loading...': 'Carregando...',
    'Lower Alpha': 'Letra Min\xfasc.',
    'Lower Greek': 'Grego Min\xfasc.',
    'Lower Roman': 'Romano Min\xfasc.',
    'Match case': 'Diferenciar mai\xfascula/min\xfascula',
    Mathematical: 'Matem\xe1tico',
    'Media poster (Image URL)': 'Post de m\xeddia (URL da Imagem)',
    'Media...': 'M\xeddia...',
    'Medium Blue': 'Azul m\xe9dio',
    'Medium Gray': 'Cinza m\xe9dio',
    'Medium Purple': 'Roxo m\xe9dio',
    'Merge cells': 'Agrupar c\xe9lulas',
    Middle: 'Meio',
    'Midnight Blue': 'Azul meia-noite',
    'More...': 'Mais...',
    Name: 'Nome',
    'Navy Blue': 'Azul marinho',
    'New document': 'Novo documento',
    'New window': 'Nova janela',
    Next: 'Pr\xf3xima',
    No: 'N\xe3o',
    'No alignment': 'Sem alinhamento',
    'No color': 'Nenhuma cor',
    'Nonbreaking space': 'Espa\xe7o inquebr\xe1vel',
    None: 'Nenhum(a)',
    'Numbered list': 'Lista numerada',
    OR: 'OU',
    Objects: 'Objetos',
    Ok: 'OK',
    'Open help dialog': 'Abrir di\xe1logo de ajuda',
    'Open link': 'Abrir link',
    'Open link in...': 'Abrir link em...',
    'Open popup menu for split buttons': 'Abrir menu popup para bot\xf5es com divis\xe3o',
    Orange: 'Laranja',
    Outset: 'Alto relevo',
    'Page break': 'Quebra de p\xe1gina',
    Paragraph: 'Simples',
    Paste: 'Colar',
    'Paste as text': 'Colar como texto',
    'Paste column after': 'Colar coluna depois',
    'Paste column before': 'Colar coluna antes',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      'O comando Colar est\xe1 no modo de texto simples. O conte\xfado ser\xe1 colado como texto simples, at\xe9 voc\xea desligar essa op\xe7\xe3o.',
    'Paste or type a link': 'Cole ou digite um link',
    'Paste row after': 'Colar linha depois',
    'Paste row before': 'Colar linha antes',
    'Paste your embed code below:': 'Insira o c\xf3digo de incorpora\xe7\xe3o abaixo:',
    People: 'Pessoas',
    Plugins: 'Plugins',
    'Plugins installed ({0}):': 'Plugins instalados ({0}):',
    'Powered by {0}': 'Distribu\xeddo por {0}',
    Pre: 'Pr\xe9-formatado (pre)',
    Preferences: 'Prefer\xeancias',
    Preformatted: 'Pr\xe9-formatado',
    'Premium plugins:': 'Plugins premium:',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: 'Pr\xe9-visualizar',
    Previous: 'Anterior',
    Print: 'Imprimir',
    'Print...': 'Imprimir...',
    Purple: 'Roxo',
    Quotations: 'Cita\xe7\xf5es',
    R: 'R',
    'Range 0 to 255': 'Faixa entre 0 e 255',
    Red: 'Vermelho',
    'Red component': 'Componente vermelho',
    Redo: 'Refazer',
    Remove: 'Remover',
    'Remove color': 'Remover cor',
    'Remove link': 'Remover link',
    Replace: 'Substituir',
    'Replace all': 'Substituir tudo',
    'Replace with': 'Substituir por',
    Resize: 'Redimensionar',
    'Restore last draft': 'Restaurar \xfaltimo rascunho',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': '\xc1rea de texto rico',
    'Rich Text Area. Press ALT-0 for help.': '\xc1rea de texto rico. Pressione Alt+0 para ajuda.',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      '\xc1rea de texto rico. Pressione Alt+F9 para exibir o menu, Alt+F10 para exibir a barra de ferramentas ou Alt+0 para exibir a ajuda.',
    Ridge: 'Ressaltada',
    Right: '\xc0 direita',
    'Right to left': 'Direita para esquerda',
    Row: 'Linha',
    'Row clipboard actions': 'A\xe7\xf5es da \xe1rea de transfer\xeancia de linhas',
    'Row group': 'Grupo de linhas',
    'Row header': 'Cabe\xe7alho da linha',
    'Row properties': 'Propriedades da linha',
    'Row type': 'Tipo de linha',
    Rows: 'Linhas',
    Save: 'Salvar',
    'Save (if save plugin activated)': 'Salvar (se o plugin de salvar estiver ativado)',
    Scope: 'Escopo',
    Search: 'Pesquisar',
    'Select all': 'Selecionar tudo',
    'Select...': 'Selecionar...',
    Selection: 'Sele\xe7\xe3o',
    Shortcut: 'Atalho',
    'Show blocks': 'Mostrar blocos',
    'Show caption': 'Exibir legenda',
    'Show invisible characters': 'Exibir caracteres invis\xedveis',
    Size: 'Tamanho',
    Solid: 'S\xf3lida',
    Source: 'Endere\xe7o',
    'Source code': 'C\xf3digo-fonte',
    'Special Character': 'Caractere especial',
    'Special character...': 'Caractere especial...',
    'Split cell': 'Dividir c\xe9lula',
    Square: 'Quadrado',
    'Start list at number': 'Iniciar a lista no n\xfamero',
    Strikethrough: 'Tachado',
    Style: 'Estilo',
    Subscript: 'Subscrito',
    Superscript: 'Sobrescrito',
    'Switch to or from fullscreen mode': 'Abrir ou fechar modo de tela cheia',
    Symbols: 'S\xedmbolos',
    'System Font': 'Fonte do sistema',
    Table: 'Tabela',
    'Table caption': 'Legenda da tabela',
    'Table properties': 'Propriedades da tabela',
    'Table styles': 'Estilos de tabela',
    Template: 'Modelo',
    Templates: 'Modelos',
    Text: 'Texto',
    'Text color': 'Cor do texto',
    'Text color {0}': '',
    'Text to display': 'Texto a ser exibido',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      'A URL que voc\xea informou parece ser um endere\xe7o de e-mail. Deseja adicionar o prefixo obrigat\xf3rio mailto:?',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      'A URL que voc\xea informou parece ser um link externo. Deseja incluir o prefixo http://?',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      'A URL informada parece ser um link externo. Deseja adicionar o prefixo obrigat\xf3rio https://?',
    Title: 'T\xedtulo',
    'To open the popup, press Shift+Enter': 'Para abrir o di\xe1logo, pressione Shit+Enter',
    'Toggle accordion': '',
    Tools: 'Ferramentas',
    Top: 'Superior',
    'Travel and Places': 'Viagem e Lugares',
    Turquoise: 'Turquesa',
    Underline: 'Sublinhado',
    Undo: 'Desfazer',
    Upload: 'Carregar',
    'Uploading image': 'Carregando imagem',
    'Upper Alpha': 'Letra Mai\xfasc.',
    'Upper Roman': 'Romano Mai\xfasc.',
    Url: 'URL',
    'User Defined': 'Definido pelo Usu\xe1rio',
    Valid: 'V\xe1lido',
    Version: 'Vers\xe3o',
    'Vertical align': 'Alinhamento vertical',
    'Vertical space': 'Espa\xe7o vertical',
    View: 'Visualizar',
    'Visual aids': 'Ajuda visual',
    Warn: 'Alerta',
    White: 'Branco',
    Width: 'Largura',
    'Word count': 'Contador de palavras',
    Words: 'Palavras',
    'Words: {0}': 'Palavras: {0}',
    Yellow: 'Amarelo',
    Yes: 'Sim',
    'You are using {0}': 'Voc\xea est\xe1 usando {0}',
    'You have unsaved changes are you sure you want to navigate away?':
      'Voc\xea tem altera\xe7\xf5es n\xe3o salvas. Voc\xea tem certeza de que deseja sair?',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      'O seu navegador n\xe3o suporta acesso direto \xe0 \xe1rea de transfer\xeancia. Por favor use os atalhos do teclado Ctrl+X/C/V como alternativa.',
    alignment: 'alinhamento',
    'austral sign': 's\xedmbolo do austral',
    'cedi sign': 's\xedmbolo do cedi',
    'colon sign': 's\xedmbolo do colon',
    'cruzeiro sign': 's\xedmbolo do cruzeiro',
    'currency sign': 's\xedmbolo de moeda',
    'dollar sign': 's\xedmbolo do d\xf3lar',
    'dong sign': 's\xedmbolo do dong',
    'drachma sign': 's\xedmbolo do drachma',
    'euro-currency sign': 's\xedmbolo do euro',
    example: 'exemplo',
    formatting: 'formata\xe7\xe3o',
    'french franc sign': 's\xedmbolo do franco franc\xeas',
    'german penny symbol': 's\xedmbolo de centavo alem\xe3o',
    'guarani sign': 's\xedmbolo do guarani',
    history: 'hist\xf3rico',
    'hryvnia sign': 's\xedmbolo do hryvnia',
    indentation: 'indenta\xe7\xe3o',
    'indian rupee sign': 's\xedmbolo de r\xfapia indiana',
    'kip sign': 's\xedmbolo do kip',
    'lira sign': 's\xedmbolo da lira',
    'livre tournois sign': 's\xedmbolo do livre tournois',
    'manat sign': 's\xedmbolo do manat',
    'mill sign': 's\xedmbolo do mill',
    'naira sign': 's\xedmbolo da naira',
    'new sheqel sign': 's\xedmbolo do novo sheqel',
    'nordic mark sign': 's\xedmbolo do marco n\xf3rdico',
    'peseta sign': 's\xedmbolo da peseta',
    'peso sign': 's\xedmbolo do peso',
    'ruble sign': 's\xedmbolo do rublo',
    'rupee sign': 's\xedmbolo da r\xfapia',
    'spesmilo sign': 's\xedmbolo do spesmilo',
    styles: 'estilos',
    'tenge sign': 's\xedmbolo do tenge',
    'tugrik sign': 's\xedmbolo do tugrik',
    'turkish lira sign': 's\xedmbolo de lira turca',
    'won sign': 's\xedmbolo do won',
    'yen character': 'caractere do yen',
    'yen/yuan character variant one': 'varia\xe7\xe3o do caractere de yen/yuan',
    'yuan character': 'caractere do yuan',
    'yuan character, in hong kong and taiwan': 'caractere do yuan, em Hong Kong e Taiwan',
    '{0} characters': '{0} caracteres',
    '{0} columns, {1} rows': '',
    '{0} words': '{0} palavras'
  })
  tinymce.addI18n('zh-CN', {
    '#': '#',
    Accessibility: '\u8f85\u52a9\u529f\u80fd',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: '\u52a8\u4f5c',
    Activity: '\u6d3b\u52a8',
    Address: '\u5730\u5740',
    'Add Variable': '\u6DFB\u52A0\u53D8\u91CF',
    Advanced: '\u9ad8\u7ea7',
    Align: '\u5bf9\u9f50',
    'Align center': '\u5c45\u4e2d\u5bf9\u9f50',
    'Align left': '\u5de6\u5bf9\u9f50',
    'Align right': '\u53f3\u5bf9\u9f50',
    Alignment: '\u5bf9\u9f50',
    'Alignment {0}': '',
    All: '\u5168\u90e8',
    'Alternative description': '\u66ff\u4ee3\u63cf\u8ff0',
    'Alternative source': '\u955c\u50cf',
    'Alternative source URL': '\u66ff\u4ee3\u6765\u6e90\u7f51\u5740',
    Anchor: '\u951a\u70b9',
    'Anchor...': '\u951a\u70b9...',
    Anchors: '\u951a\u70b9',
    'Animals and Nature': '\u52a8\u7269\u548c\u81ea\u7136',
    Arrows: '\u7bad\u5934',
    B: 'B',
    'Background color': '\u80cc\u666f\u989c\u8272',
    'Background color {0}': '',
    Black: '\u9ed1\u8272',
    Block: '\u5757',
    'Block {0}': '',
    Blockquote: '\u5f15\u6587\u533a\u5757',
    Blocks: '\u6837\u5f0f',
    Blue: '\u84dd\u8272',
    'Blue component': '\u767d\u8272\u90e8\u5206',
    Body: '\u8868\u4f53',
    Bold: '\u7c97\u4f53',
    Border: '\u6846\u7ebf',
    'Border color': '\u6846\u7ebf\u989c\u8272',
    'Border style': '\u8fb9\u6846\u6837\u5f0f',
    'Border width': '\u8fb9\u6846\u5bbd\u5ea6',
    Bottom: '\u4e0b\u65b9\u5bf9\u9f50',
    'Browse files': '',
    'Browse for an image': '\u6d4f\u89c8\u56fe\u50cf',
    'Browse links': '',
    'Bullet list': '\u65e0\u5e8f\u5217\u8868',
    Cancel: '\u53d6\u6d88',
    Caption: '\u6807\u9898',
    Cell: '\u5355\u5143\u683c',
    'Cell padding': '\u5355\u5143\u683c\u5185\u8fb9\u8ddd',
    'Cell properties': '\u5355\u5143\u683c\u5c5e\u6027',
    'Cell spacing': '\u5355\u5143\u683c\u5916\u95f4\u8ddd',
    'Cell styles': '\u5355\u5143\u683c\u6837\u5f0f',
    'Cell type': '\u50a8\u5b58\u683c\u522b',
    Center: '\u5c45\u4e2d',
    Characters: '\u5b57\u7b26',
    'Characters (no spaces)': '\u5b57\u7b26(\u65e0\u7a7a\u683c)',
    Circle: '\u7a7a\u5fc3\u5706',
    Class: '\u7c7b\u578b',
    'Clear formatting': '\u6e05\u9664\u683c\u5f0f',
    Close: '\u5173\u95ed',
    Code: '\u4ee3\u7801',
    'Code sample...': '\u793a\u4f8b\u4ee3\u7801...',
    'Code view': '\u4ee3\u7801\u89c6\u56fe',
    'Color Picker': '\u9009\u8272\u5668',
    'Color swatch': '\u989c\u8272\u6837\u672c',
    Cols: '\u5217',
    Column: '\u5217',
    'Column clipboard actions': '\u5217\u526a\u8d34\u677f\u64cd\u4f5c',
    'Column group': '\u5217\u7ec4',
    'Column header': '\u5217\u6807\u9898',
    'Constrain proportions': '\u4fdd\u6301\u6bd4\u4f8b',
    Copy: '\u590d\u5236',
    'Copy column': '\u590d\u5236\u5217',
    'Copy row': '\u590d\u5236\u884c',
    'Could not find the specified string.': '\u672a\u627e\u5230\u641c\u7d22\u5185\u5bb9\u3002',
    'Could not load emojis': '\u65e0\u6cd5\u52a0\u8f7dEmojis',
    Count: '\u8ba1\u6570',
    Currency: '\u8d27\u5e01',
    'Current window': '\u5f53\u524d\u7a97\u53e3',
    'Custom color': '\u81ea\u5b9a\u4e49\u989c\u8272',
    'Custom...': '\u81ea\u5b9a\u4e49......',
    Cut: '\u526a\u5207',
    'Cut column': '\u526a\u5207\u5217',
    'Cut row': '\u526a\u5207\u884c',
    'Dark Blue': '\u6df1\u84dd\u8272',
    'Dark Gray': '\u6df1\u7070\u8272',
    'Dark Green': '\u6df1\u7eff\u8272',
    'Dark Orange': '\u6df1\u6a59\u8272',
    'Dark Purple': '\u6df1\u7d2b\u8272',
    'Dark Red': '\u6df1\u7ea2\u8272',
    'Dark Turquoise': '\u6df1\u84dd\u7eff\u8272',
    'Dark Yellow': '\u6697\u9ec4\u8272',
    Dashed: '\u865a\u7ebf',
    'Date/time': '\u65e5\u671f/\u65f6\u95f4',
    'Decrease indent': '\u51cf\u5c11\u7f29\u8fdb',
    Default: '\u9884\u8bbe',
    'Delete accordion': '',
    'Delete column': '\u5220\u9664\u5217',
    'Delete row': '\u5220\u9664\u884c',
    'Delete table': '\u5220\u9664\u8868\u683c',
    Dimensions: '\u5c3a\u5bf8',
    Disc: '\u5b9e\u5fc3\u5706',
    Div: 'Div',
    Document: '\u6587\u6863',
    Dotted: '\u865a\u7ebf',
    Double: '\u53cc\u7cbe\u5ea6',
    'Drop an image here': '\u62d6\u653e\u4e00\u5f20\u56fe\u50cf\u81f3\u6b64',
    'Dropped file type is not supported':
      '\u6b64\u6587\u4ef6\u7c7b\u578b\u4e0d\u652f\u6301\u62d6\u653e',
    Edit: '\u7f16\u8f91',
    Embed: '\u5185\u5d4c',
    Emojis: 'Emojis',
    'Emojis...': 'Emojis...',
    Error: '\u9519\u8bef',
    'Error: Form submit field collision.':
      '\u9519\u8bef: \u8868\u5355\u63d0\u4ea4\u5b57\u6bb5\u51b2\u7a81\u3002',
    'Error: No form element found.': '\u9519\u8bef: \u6ca1\u6709\u8868\u5355\u63a7\u4ef6\u3002',
    'Extended Latin': '\u62c9\u4e01\u8bed\u6269\u5145',
    'Failed to initialize plugin: {0}': '\u63d2\u4ef6\u521d\u59cb\u5316\u5931\u8d25: {0}',
    'Failed to load plugin url: {0}': '\u63d2\u4ef6\u52a0\u8f7d\u5931\u8d25 \u94fe\u63a5: {0}',
    'Failed to load plugin: {0} from url {1}':
      '\u63d2\u4ef6\u52a0\u8f7d\u5931\u8d25: {0} \u6765\u81ea\u94fe\u63a5 {1}',
    'Failed to upload image: {0}': '\u56fe\u7247\u4e0a\u4f20\u5931\u8d25: {0}',
    File: '\u6587\u4ef6',
    Find: '\u5bfb\u627e',
    'Find (if searchreplace plugin activated)':
      '\u67e5\u627e(\u5982\u679c\u67e5\u627e\u66ff\u6362\u63d2\u4ef6\u5df2\u6fc0\u6d3b)',
    'Find and Replace': '\u67e5\u627e\u548c\u66ff\u6362',
    'Find and replace...': '\u67e5\u627e\u5e76\u66ff\u6362...',
    'Find in selection': '\u5728\u9009\u533a\u4e2d\u67e5\u627e',
    'Find whole words only': '\u5168\u5b57\u5339\u914d',
    Flags: '\u65d7\u5e1c',
    'Focus to contextual toolbar': '\u79fb\u52a8\u7126\u70b9\u5230\u4e0a\u4e0b\u6587\u83dc\u5355',
    'Focus to element path': '\u79fb\u52a8\u7126\u70b9\u5230\u5143\u7d20\u8def\u5f84',
    'Focus to menubar': '\u79fb\u52a8\u7126\u70b9\u5230\u83dc\u5355\u680f',
    'Focus to toolbar': '\u79fb\u52a8\u7126\u70b9\u5230\u5de5\u5177\u680f',
    Font: '\u5b57\u4f53',
    'Font size {0}': '',
    'Font sizes': '\u5b57\u4f53\u5927\u5c0f',
    'Font {0}': '',
    Fonts: '\u5b57\u4f53',
    'Food and Drink': '\u98df\u7269\u548c\u996e\u54c1',
    Footer: '\u8868\u5c3e',
    Format: '\u683c\u5f0f',
    'Format {0}': '',
    Formats: '\u683c\u5f0f',
    Fullscreen: '\u5168\u5c4f',
    G: 'G',
    General: '\u4e00\u822c',
    Gray: '\u7070\u8272',
    Green: '\u7eff\u8272',
    'Green component': '\u7eff\u8272\u90e8\u5206',
    Groove: '\u51f9\u69fd',
    'Handy Shortcuts': '\u5feb\u6377\u952e',
    Header: '\u8868\u5934',
    'Header cell': '\u8868\u5934\u5355\u5143\u683c',
    'Heading 1': '\u4e00\u7ea7\u6807\u9898',
    'Heading 2': '\u4e8c\u7ea7\u6807\u9898',
    'Heading 3': '\u4e09\u7ea7\u6807\u9898',
    'Heading 4': '\u56db\u7ea7\u6807\u9898',
    'Heading 5': '\u4e94\u7ea7\u6807\u9898',
    'Heading 6': '\u516d\u7ea7\u6807\u9898',
    Headings: '\u6807\u9898',
    Height: '\u9ad8\u5ea6',
    Help: '\u5e2e\u52a9',
    'Hex color code': '\u5341\u516d\u8fdb\u5236\u989c\u8272\u4ee3\u7801',
    Hidden: '\u9690\u85cf',
    'Horizontal align': '\u6c34\u5e73\u5bf9\u9f50',
    'Horizontal line': '\u6c34\u5e73\u5206\u5272\u7ebf',
    'Horizontal space': '\u6c34\u5e73\u95f4\u8ddd',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'ID\u5e94\u8be5\u4ee5\u82f1\u6587\u5b57\u6bcd\u5f00\u5934\uff0c\u540e\u9762\u53ea\u80fd\u6709\u82f1\u6587\u5b57\u6bcd\u3001\u6570\u5b57\u3001\u7834\u6298\u53f7\u3001\u70b9\u3001\u5192\u53f7\u6216\u4e0b\u5212\u7ebf\u3002',
    'Image is decorative': '\u56fe\u50cf\u662f\u88c5\u9970\u6027\u7684',
    'Image list': '\u56fe\u7247\u6e05\u5355',
    'Image title': '\u56fe\u7247\u6807\u9898',
    'Image...': '\u56fe\u7247...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      '\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u65e0\u6cd5\u627e\u5230\u56fe\u7247\u4ee3\u7406',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      '\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u56fe\u7247\u4ee3\u7406\u5730\u5740\u9519\u8bef',
    'ImageProxy HTTP error: Rejected request':
      '\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u8bf7\u6c42\u88ab\u62d2\u7edd',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      '\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u672a\u77e5\u7684\u56fe\u7247\u4ee3\u7406\u9519\u8bef',
    'Increase indent': '\u589e\u52a0\u7f29\u8fdb',
    Inline: '\u6587\u672c',
    Insert: '\u63d2\u5165',
    'Insert Template': '\u63d2\u5165\u6a21\u677f',
    'Insert accordion': '',
    'Insert column after': '\u5728\u53f3\u4fa7\u63d2\u5165\u5217',
    'Insert column before': '\u5728\u5de6\u4fa7\u63d2\u5165\u5217',
    'Insert date/time': '\u63d2\u5165\u65e5\u671f/\u65f6\u95f4',
    'Insert image': '\u63d2\u5165\u56fe\u7247',
    'Insert link (if link plugin activated)':
      '\u63d2\u5165\u94fe\u63a5 (\u5982\u679c\u94fe\u63a5\u63d2\u4ef6\u5df2\u6fc0\u6d3b)',
    'Insert row after': '\u5728\u4e0b\u65b9\u63d2\u5165\u884c',
    'Insert row before': '\u5728\u4e0a\u65b9\u63d2\u5165\u884c',
    'Insert table': '\u63d2\u5165\u8868\u683c',
    'Insert template...': '\u63d2\u5165\u6a21\u677f...',
    'Insert video': '\u63d2\u5165\u89c6\u9891',
    'Insert/Edit code sample': '\u63d2\u5165/\u7f16\u8f91\u4ee3\u7801\u793a\u4f8b',
    'Insert/edit image': '\u63d2\u5165/\u7f16\u8f91\u56fe\u7247',
    'Insert/edit link': '\u63d2\u5165/\u7f16\u8f91\u94fe\u63a5',
    'Insert/edit media': '\u63d2\u5165/\u7f16\u8f91\u5a92\u4f53',
    'Insert/edit video': '\u63d2\u5165/\u7f16\u8f91\u89c6\u9891',
    Inset: '\u5d4c\u5165',
    'Invalid hex color code: {0}':
      '\u5341\u516d\u8fdb\u5236\u989c\u8272\u4ee3\u7801\u65e0\u6548\uff1a {0}',
    'Invalid input': '\u65e0\u6548\u8f93\u5165',
    Italic: '\u659c\u4f53',
    Justify: '\u4e24\u7aef\u5bf9\u9f50',
    'Keyboard Navigation': '\u952e\u76d8\u6307\u5f15',
    Language: '\u8bed\u8a00',
    'Learn more...': '\u4e86\u89e3\u66f4\u591a...',
    Left: '\u5de6',
    'Left to right': '\u7531\u5de6\u5230\u53f3',
    'Light Blue': '\u6d45\u84dd\u8272',
    'Light Gray': '\u6d45\u7070\u8272',
    'Light Green': '\u6d45\u7eff\u8272',
    'Light Purple': '\u6d45\u7d2b\u8272',
    'Light Red': '\u6d45\u7ea2\u8272',
    'Light Yellow': '\u6d45\u9ec4\u8272',
    'Line height': '\u884c\u9ad8',
    'Link list': '\u94fe\u63a5\u6e05\u5355',
    'Link...': '\u94fe\u63a5...',
    'List Properties': '\u5217\u8868\u5c5e\u6027',
    'List properties...': '\u6807\u9898\u5b57\u4f53\u5c5e\u6027',
    'Loading emojis...': '\u6b63\u5728\u52a0\u8f7dEmojis...',
    'Loading...': '\u52a0\u8f7d\u4e2d...',
    'Lower Alpha': '\u5c0f\u5199\u82f1\u6587\u5b57\u6bcd',
    'Lower Greek': '\u5c0f\u5199\u5e0c\u814a\u5b57\u6bcd',
    'Lower Roman': '\u5c0f\u5199\u7f57\u9a6c\u6570\u5b57',
    'Match case': '\u5927\u5c0f\u5199\u5339\u914d',
    Mathematical: '\u6570\u5b66',
    'Media poster (Image URL)': '\u5c01\u9762(\u56fe\u7247\u5730\u5740)',
    'Media...': '\u591a\u5a92\u4f53...',
    'Medium Blue': '\u4e2d\u84dd\u8272',
    'Medium Gray': '\u4e2d\u7070\u8272',
    'Medium Purple': '\u4e2d\u7d2b\u8272',
    'Merge cells': '\u5408\u5e76\u5355\u5143\u683c',
    Middle: '\u5c45\u4e2d\u5bf9\u9f50',
    'Midnight Blue': '\u6df1\u84dd\u8272',
    'More...': '\u66f4\u591a...',
    Name: '\u540d\u79f0',
    'Navy Blue': '\u6d77\u519b\u84dd',
    'New document': '\u65b0\u5efa\u6587\u6863',
    'New window': '\u65b0\u7a97\u53e3',
    Next: '\u4e0b\u4e00\u4e2a',
    No: '\u5426',
    'No alignment': '\u672a\u5bf9\u9f50',
    'No color': '\u65e0',
    'Nonbreaking space': '\u4e0d\u95f4\u65ad\u7a7a\u683c',
    None: '\u65e0',
    'Numbered list': '\u6709\u5e8f\u5217\u8868',
    OR: '\u6216',
    Objects: '\u7269\u4ef6',
    Ok: '\u786e\u5b9a',
    'Open help dialog': '\u6253\u5f00\u5e2e\u52a9\u5bf9\u8bdd\u6846',
    'Open link': '\u6253\u5f00\u94fe\u63a5',
    'Open link in...': '\u94fe\u63a5\u6253\u5f00\u4f4d\u7f6e...',
    'Open popup menu for split buttons':
      '\u6253\u5f00\u5f39\u51fa\u5f0f\u83dc\u5355\uff0c\u7528\u4e8e\u62c6\u5206\u6309\u94ae',
    Orange: '\u6a59\u8272',
    Outset: '\u5916\u7f6e',
    'Page break': '\u5206\u9875\u7b26',
    Paragraph: '\u6bb5\u843d',
    Paste: '\u7c98\u8d34',
    'Paste as text': '\u7c98\u8d34\u4e3a\u6587\u672c',
    'Paste column after': '\u7c98\u8d34\u540e\u9762\u7684\u5217',
    'Paste column before': '\u7c98\u8d34\u6b64\u5217\u524d',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      '\u5f53\u524d\u4e3a\u7eaf\u6587\u672c\u7c98\u8d34\u6a21\u5f0f\uff0c\u518d\u6b21\u70b9\u51fb\u53ef\u4ee5\u56de\u5230\u666e\u901a\u7c98\u8d34\u6a21\u5f0f\u3002',
    'Paste or type a link': '\u7c98\u8d34\u6216\u8f93\u5165\u94fe\u63a5',
    'Paste row after': '\u7c98\u8d34\u884c\u5230\u4e0b\u65b9',
    'Paste row before': '\u7c98\u8d34\u884c\u5230\u4e0a\u65b9',
    'Paste your embed code below:': '\u5c06\u5185\u5d4c\u4ee3\u7801\u7c98\u8d34\u5728\u4e0b\u9762:',
    People: '\u4eba\u7c7b',
    Plugins: '\u63d2\u4ef6',
    'Plugins installed ({0}):': '\u5df2\u5b89\u88c5\u63d2\u4ef6 ({0}):',
    'Powered by {0}': '\u7531{0}\u9a71\u52a8',
    Pre: '\u524d\u8a00',
    Preferences: '\u9996\u9009\u9879',
    Preformatted: '\u9884\u5148\u683c\u5f0f\u5316\u7684',
    'Premium plugins:': '\u4f18\u79c0\u63d2\u4ef6\uff1a',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: '\u9884\u89c8',
    Previous: '\u4e0a\u4e00\u4e2a',
    Print: '\u6253\u5370',
    'Print...': '\u6253\u5370...',
    Purple: '\u7d2b\u8272',
    Quotations: '\u5f15\u7528',
    R: 'R',
    'Range 0 to 255': '\u8303\u56f40\u81f3255',
    Red: '\u7ea2\u8272',
    'Red component': '\u7ea2\u8272\u90e8\u5206',
    Redo: '\u91cd\u505a',
    Remove: '\u79fb\u9664',
    'Remove color': '\u79fb\u9664\u989c\u8272',
    'Remove link': '\u79fb\u9664\u94fe\u63a5',
    Replace: '\u66ff\u6362',
    'Replace all': '\u66ff\u6362\u5168\u90e8',
    'Replace with': '\u66ff\u6362\u4e3a',
    Resize: '\u8c03\u6574\u5927\u5c0f',
    'Restore last draft': '\u6062\u590d\u4e0a\u6b21\u7684\u8349\u7a3f',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': '\u5bcc\u6587\u672c\u533a\u57df',
    'Rich Text Area. Press ALT-0 for help.':
      '\u7f16\u8f91\u533a\u3002\u6309Alt+0\u952e\u6253\u5f00\u5e2e\u52a9\u3002',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      '\u7f16\u8f91\u533a\u3002\u6309ALT-F9\u6253\u5f00\u83dc\u5355\uff0c\u6309ALT-F10\u6253\u5f00\u5de5\u5177\u680f\uff0c\u6309ALT-0\u67e5\u770b\u5e2e\u52a9',
    Ridge: '\u6d77\u810a\u5ea7',
    Right: '\u53f3',
    'Right to left': '\u7531\u53f3\u5230\u5de6',
    Row: '\u884c',
    'Row clipboard actions': '\u884c\u526a\u8d34\u677f\u64cd\u4f5c',
    'Row group': '\u884c\u7ec4',
    'Row header': '\u884c\u5934',
    'Row properties': '\u884c\u5c5e\u6027',
    'Row type': '\u884c\u7c7b\u578b',
    Rows: '\u884c\u6570',
    Save: '\u4fdd\u5b58',
    'Save (if save plugin activated)':
      '\u4fdd\u5b58(\u5982\u679c\u4fdd\u5b58\u63d2\u4ef6\u5df2\u6fc0\u6d3b)',
    Scope: '\u8303\u56f4',
    Search: '\u641c\u7d22',
    'Select all': '\u5168\u9009',
    'Select...': '\u9009\u62e9...',
    Selection: '\u9009\u62e9',
    Shortcut: '\u5feb\u6377\u65b9\u5f0f',
    'Show blocks': '\u663e\u793a\u533a\u5757\u8fb9\u6846',
    'Show caption': '\u663e\u793a\u6807\u9898',
    'Show invisible characters': '\u663e\u793a\u4e0d\u53ef\u89c1\u5b57\u7b26',
    Size: '\u5b57\u53f7',
    Solid: '\u5b9e\u7ebf',
    Source: '\u6e90',
    'Source code': '\u6e90\u4ee3\u7801',
    'Special Character': '\u7279\u6b8a\u5b57\u7b26',
    'Special character...': '\u7279\u6b8a\u5b57\u7b26...',
    'Split cell': '\u62c6\u5206\u5355\u5143\u683c',
    Square: '\u5b9e\u5fc3\u65b9\u5757',
    'Start list at number': '\u4ee5\u6570\u5b57\u5f00\u59cb\u5217\u8868',
    Strikethrough: '\u5220\u9664\u7ebf',
    Style: '\u6837\u5f0f',
    Subscript: '\u4e0b\u6807',
    Superscript: '\u4e0a\u6807',
    'Switch to or from fullscreen mode': '\u5207\u6362\u5168\u5c4f\u6a21\u5f0f',
    Symbols: '\u7b26\u53f7',
    'System Font': '\u7cfb\u7edf\u5b57\u4f53',
    Table: '\u8868\u683c',
    'Table caption': '\u8868\u683c\u6807\u9898',
    'Table properties': '\u8868\u683c\u5c5e\u6027',
    'Table styles': '\u8868\u683c\u6837\u5f0f',
    Template: '\u6a21\u677f',
    Templates: '\u6a21\u677f',
    Text: '\u6587\u5b57',
    'Text color': '\u6587\u672c\u989c\u8272',
    'Text color {0}': '',
    'Text to display': '\u8981\u663e\u793a\u7684\u6587\u672c',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      '\u4f60\u6240\u586b\u5199\u7684URL\u5730\u5740\u4e3a\u90ae\u4ef6\u5730\u5740\uff0c\u9700\u8981\u52a0\u4e0amailto: \u524d\u7f00\u5417\uff1f',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      '\u4f60\u6240\u586b\u5199\u7684URL\u5730\u5740\u5c5e\u4e8e\u5916\u90e8\u94fe\u63a5\uff0c\u9700\u8981\u52a0\u4e0ahttp:// \u524d\u7f00\u5417\uff1f',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      '\u60a8\u8f93\u5165\u7684 URL \u4f3c\u4e4e\u662f\u4e00\u4e2a\u5916\u90e8\u94fe\u63a5\u3002\u60a8\u60f3\u6dfb\u52a0\u6240\u9700\u7684 https:// \u524d\u7f00\u5417\uff1f',
    Title: '\u6807\u9898',
    'To open the popup, press Shift+Enter': '\u6309Shitf+Enter\u952e\u6253\u5f00\u5bf9\u8bdd\u6846',
    'Toggle accordion': '',
    Tools: '\u5de5\u5177',
    Top: '\u4e0a\u65b9\u5bf9\u9f50',
    'Travel and Places': '\u65c5\u6e38\u548c\u5730\u70b9',
    Turquoise: '\u9752\u7eff\u8272',
    Underline: '\u4e0b\u5212\u7ebf',
    Undo: '\u64a4\u9500',
    Upload: '\u4e0a\u4f20',
    'Uploading image': '\u4e0a\u4f20\u56fe\u7247',
    'Upper Alpha': '\u5927\u5199\u82f1\u6587\u5b57\u6bcd',
    'Upper Roman': '\u5927\u5199\u7f57\u9a6c\u6570\u5b57',
    Url: '\u5730\u5740',
    'User Defined': '\u81ea\u5b9a\u4e49',
    Valid: '\u6709\u6548',
    Version: '\u7248\u672c',
    'Vertical align': '\u5782\u76f4\u5bf9\u9f50',
    'Vertical space': '\u5782\u76f4\u95f4\u8ddd',
    View: '\u67e5\u770b',
    'Visual aids': '\u7f51\u683c\u7ebf',
    Warn: '\u8b66\u544a',
    White: '\u767d\u8272',
    Width: '\u5bbd\u5ea6',
    'Word count': '\u5b57\u6570',
    Words: '\u5355\u8bcd',
    'Words: {0}': '\u5b57\u6570\uff1a{0}',
    Yellow: '\u9ec4\u8272',
    Yes: '\u662f',
    'You are using {0}': '\u4f60\u6b63\u5728\u4f7f\u7528 {0}',
    'You have unsaved changes are you sure you want to navigate away?':
      '\u4f60\u8fd8\u6709\u6587\u6863\u5c1a\u672a\u4fdd\u5b58\uff0c\u786e\u5b9a\u8981\u79bb\u5f00\uff1f',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      '\u4f60\u7684\u6d4f\u89c8\u5668\u4e0d\u652f\u6301\u6253\u5f00\u526a\u8d34\u677f\uff0c\u8bf7\u4f7f\u7528Ctrl+X/C/V\u7b49\u5feb\u6377\u952e\u3002',
    alignment: '\u5bf9\u9f50',
    'austral sign': '\u6fb3\u5143\u7b26\u53f7',
    'cedi sign': '\u585e\u5730\u7b26\u53f7',
    'colon sign': '\u5192\u53f7',
    'cruzeiro sign': '\u514b\u9c81\u8d5b\u7f57\u5e01\u7b26\u53f7',
    'currency sign': '\u8d27\u5e01\u7b26\u53f7',
    'dollar sign': '\u7f8e\u5143\u7b26\u53f7',
    'dong sign': '\u8d8a\u5357\u76fe\u7b26\u53f7',
    'drachma sign': '\u5fb7\u62c9\u514b\u9a6c\u7b26\u53f7',
    'euro-currency sign': '\u6b27\u5143\u7b26\u53f7',
    example: '\u793a\u4f8b',
    formatting: '\u683c\u5f0f\u5316',
    'french franc sign': '\u6cd5\u90ce\u7b26\u53f7',
    'german penny symbol': '\u5fb7\u56fd\u4fbf\u58eb\u7b26\u53f7',
    'guarani sign': '\u74dc\u62c9\u5c3c\u7b26\u53f7',
    history: '\u5386\u53f2',
    'hryvnia sign': '\u683c\u91cc\u592b\u5c3c\u4e9a\u7b26\u53f7',
    indentation: '\u7f29\u8fdb',
    'indian rupee sign': '\u5370\u5ea6\u5362\u6bd4',
    'kip sign': '\u8001\u631d\u57fa\u666e\u7b26\u53f7',
    'lira sign': '\u91cc\u62c9\u7b26\u53f7',
    'livre tournois sign': '\u91cc\u5f17\u5f17\u5c14\u7b26\u53f7',
    'manat sign': '\u9a6c\u7eb3\u7279\u7b26\u53f7',
    'mill sign': '\u5bc6\u5c14\u7b26\u53f7',
    'naira sign': '\u5948\u62c9\u7b26\u53f7',
    'new sheqel sign': '\u65b0\u8c22\u514b\u5c14\u7b26\u53f7',
    'nordic mark sign': '\u5317\u6b27\u9a6c\u514b',
    'peseta sign': '\u6bd4\u585e\u5854\u7b26\u53f7',
    'peso sign': '\u6bd4\u7d22\u7b26\u53f7',
    'ruble sign': '\u5362\u5e03\u7b26\u53f7',
    'rupee sign': '\u5362\u6bd4\u7b26\u53f7',
    'spesmilo sign': 'spesmilo\u7b26\u53f7',
    styles: '\u6837\u5f0f',
    'tenge sign': '\u575a\u6208\u7b26\u53f7',
    'tugrik sign': '\u56fe\u683c\u91cc\u514b\u7b26\u53f7',
    'turkish lira sign': '\u571f\u8033\u5176\u91cc\u62c9',
    'won sign': '\u97e9\u5143\u7b26\u53f7',
    'yen character': '\u65e5\u5143\u5b57\u6837',
    'yen/yuan character variant one': '\u5143\u5b57\u6837\uff08\u5927\u5199\uff09',
    'yuan character': '\u4eba\u6c11\u5e01\u5143\u5b57\u6837',
    'yuan character, in hong kong and taiwan':
      '\u5143\u5b57\u6837\uff08\u6e2f\u53f0\u5730\u533a\uff09',
    '{0} characters': '{0} \u4e2a\u5b57\u7b26',
    '{0} columns, {1} rows': '',
    '{0} words': '{0} \u5b57'
  })
  tinymce.addI18n('zh-TW', {
    '#': '#',
    Accessibility: '\u5354\u52a9\u5de5\u5177',
    Accordion: '',
    'Accordion body...': '',
    'Accordion summary...': '',
    Action: '\u52d5\u4f5c',
    Activity: '\u6d3b\u52d5',
    Address: '\u5730\u5740',
    'Add Variable': '\u65B0\u589E\u8B8A\u6578',
    Advanced: '\u9ad8\u7d1a',
    Align: '\u5c0d\u9f4a',
    'Align center': '\u7f6e\u4e2d\u5c0d\u9f4a',
    'Align left': '\u5de6\u5c0d\u9f4a',
    'Align right': '\u53f3\u5c0d\u9f4a',
    Alignment: '\u5c0d\u9f4a',
    'Alignment {0}': '',
    All: '\u5168\u90e8',
    'Alternative description': '\u66ff\u4ee3\u63cf\u8ff0',
    'Alternative source': '\u93e1\u50cf',
    'Alternative source URL': '\u66ff\u4ee3\u4f86\u6e90\u7db2\u5740',
    Anchor: '\u9328\u9ede',
    'Anchor...': '\u9328\u9ede...',
    Anchors: '\u9328\u9ede',
    'Animals and Nature': '\u52d5\u7269\u548c\u81ea\u7136',
    Arrows: '\u7bad\u982d',
    B: '\u85cd',
    'Background color': '\u80cc\u666f\u984f\u8272',
    'Background color {0}': '',
    Black: '\u9ed1\u8272',
    Block: '\u584a',
    'Block {0}': '',
    Blockquote: '\u5f15\u6587\u5340\u584a',
    Blocks: '\u6a23\u5f0f',
    Blue: '\u85cd\u8272',
    'Blue component': '\u767d\u8272\u90e8\u5206',
    Body: '\u8868\u9ad4',
    Bold: '\u7c97\u9ad4',
    Border: '\u6846\u7dda',
    'Border color': '\u6846\u7dda\u984f\u8272',
    'Border style': '\u908a\u6846\u6a23\u5f0f',
    'Border width': '\u908a\u6846\u5bec\u5ea6',
    Bottom: '\u4e0b\u65b9\u5c0d\u9f4a',
    'Browse files': '',
    'Browse for an image': '\u700f\u89bd\u5716\u50cf',
    'Browse links': '',
    'Bullet list': '\u7121\u5e8f\u5217\u8868',
    Cancel: '\u53d6\u6d88',
    Caption: '\u6a19\u984c',
    Cell: '\u5132\u5b58\u683c',
    'Cell padding': '\u5132\u5b58\u683c\u5167\u908a\u8ddd',
    'Cell properties': '\u5132\u5b58\u683c\u5c6c\u6027',
    'Cell spacing': '\u5132\u5b58\u683c\u5916\u9593\u8ddd',
    'Cell styles': '\u5132\u5b58\u683c\u6a23\u5f0f',
    'Cell type': '\u5132\u5b58\u683c\u5225',
    Center: '\u7f6e\u4e2d',
    Characters: '\u5b57\u5143',
    'Characters (no spaces)': '\u5b57\u5143(\u7121\u7a7a\u683c)',
    Circle: '\u7a7a\u5fc3\u5713',
    Class: '\u985e\u578b',
    'Clear formatting': '\u6e05\u9664\u683c\u5f0f',
    Close: '\u95dc\u9589',
    Code: '\u4ee3\u78bc',
    'Code sample...': '\u793a\u7bc4\u4ee3\u78bc...',
    'Code view': '\u4ee3\u78bc\u8996\u5716',
    'Color Picker': '\u9078\u8272\u5668',
    'Color swatch': '\u984f\u8272\u6a23\u672c',
    Cols: '\u5217',
    Column: '\u6b04',
    'Column clipboard actions': '\u5217\u526a\u8cbc\u677f\u64cd\u4f5c',
    'Column group': '\u5217\u7d44',
    'Column header': '\u5217\u6a19\u984c',
    'Constrain proportions': '\u4fdd\u6301\u6bd4\u4f8b',
    Copy: '\u8907\u88fd',
    'Copy column': '\u8907\u88fd\u5217',
    'Copy row': '\u8907\u88fd\u884c',
    'Could not find the specified string.': '\u672a\u627e\u5230\u641c\u7d22\u5167\u5bb9\u3002',
    'Could not load emojis': '\u7121\u6cd5\u8f09\u5165Emojis',
    Count: '\u8a08\u6578',
    Currency: '\u8ca8\u5e63',
    'Current window': '\u7576\u524d\u7a97\u53e3',
    'Custom color': '\u81ea\u8a02\u984f\u8272',
    'Custom...': '\u81ea\u8a02......',
    Cut: '\u526a\u4e0b',
    'Cut column': '\u526a\u4e0b\u5217',
    'Cut row': '\u526a\u4e0b\u884c',
    'Dark Blue': '\u6df1\u85cd\u8272',
    'Dark Gray': '\u6df1\u7070\u8272',
    'Dark Green': '\u6df1\u7da0\u8272',
    'Dark Orange': '\u6df1\u6a59\u8272',
    'Dark Purple': '\u6df1\u7d2b\u8272',
    'Dark Red': '\u6df1\u7d05\u8272',
    'Dark Turquoise': '\u6df1\u85cd\u7da0\u8272',
    'Dark Yellow': '\u6697\u9ec3\u8272',
    Dashed: '\u865b\u7dda',
    'Date/time': '\u65e5\u671f/\u6642\u9593',
    'Decrease indent': '\u6e1b\u5c11\u7e2e\u9032',
    Default: '\u9810\u8a2d',
    'Delete accordion': '',
    'Delete column': '\u522a\u9664\u5217',
    'Delete row': '\u522a\u9664\u884c',
    'Delete table': '\u522a\u9664\u8868\u683c',
    Dimensions: '\u5c3a\u5bf8',
    Disc: '\u5be6\u5fc3\u5713',
    Div: 'DIV',
    Document: '\u6587\u4ef6',
    Dotted: '\u865b\u7dda',
    Double: '\u96d9\u7cbe\u5ea6',
    'Drop an image here': '\u62d6\u653e\u4e00\u5f35\u5716\u50cf\u81f3\u6b64',
    'Dropped file type is not supported':
      '\u6b64\u6a94\u6848\u985e\u578b\u4e0d\u652f\u6301\u62d6\u653e',
    Edit: '\u7de8\u8f2f',
    Embed: '\u5167\u5d4c',
    Emojis: 'Emojis',
    'Emojis...': 'Emojis...',
    Error: '\u932f\u8aa4',
    'Error: Form submit field collision.':
      '\u932f\u8aa4\uff1a\u8868\u683c\u51fa\u73fe\u591a\u91cd\u63d0\u4ea4\u885d\u7a81\u3002',
    'Error: No form element found.':
      '\u932f\u8aa4\uff1a\u627e\u4e0d\u5230\u8868\u683c\u5143\u7d20\u3002',
    'Extended Latin': '\u62c9\u4e01\u8a9e\u64f4\u5145',
    'Failed to initialize plugin: {0}': '\u7121\u6cd5\u521d\u59cb\u5316\u63d2\u4ef6 {0}',
    'Failed to load plugin url: {0}': '\u7121\u6cd5\u8f09\u5165\u63d2\u4ef6\u93c8\u7d50 {0}',
    'Failed to load plugin: {0} from url {1}':
      '\u7121\u6cd5\u5f9e {1} \u8f09\u5165\u63d2\u4ef6 {0}',
    'Failed to upload image: {0}': '\u4e0a\u8f09\u5716\u7247\u5931\u6557\uff1a{0}',
    File: '\u6587\u4ef6',
    Find: '\u5c0b\u627e',
    'Find (if searchreplace plugin activated)':
      '\u5c0b\u627e(\u5982\u679c\u5c0b\u627e\u53d6\u4ee3\u5916\u639b\u7a0b\u5f0f\u5df2\u555f\u52d5)',
    'Find and Replace': '\u5c0b\u627e\u548c\u53d6\u4ee3',
    'Find and replace...': '\u5c0b\u627e\u4e26\u53d6\u4ee3...',
    'Find in selection': '\u5728\u9078\u5340\u4e2d\u5c0b\u627e',
    'Find whole words only': '\u5168\u5b57\u5339\u914d',
    Flags: '\u65d7\u5e5f',
    'Focus to contextual toolbar': '\u79fb\u52d5\u7126\u9ede\u5230\u4e0a\u4e0b\u6587\u83dc\u55ae',
    'Focus to element path': '\u79fb\u52d5\u7126\u9ede\u5230\u5143\u7d20\u8def\u5f91',
    'Focus to menubar': '\u79fb\u52d5\u7126\u9ede\u5230\u529f\u80fd\u8868\u5217',
    'Focus to toolbar': '\u79fb\u52d5\u7126\u9ede\u5230\u5de5\u5177\u5217',
    Font: '\u5b57\u9ad4',
    'Font size {0}': '',
    'Font sizes': '\u5b57\u9ad4\u5927\u5c0f',
    'Font {0}': '',
    Fonts: '\u5b57\u9ad4',
    'Food and Drink': '\u98df\u7269\u548c\u98f2\u54c1',
    Footer: '\u8868\u5c3e',
    Format: '\u683c\u5f0f',
    'Format {0}': '',
    Formats: '\u683c\u5f0f',
    Fullscreen: '\u5168\u7192\u5e55',
    G: '\u7da0',
    General: '\u4e00\u822c',
    Gray: '\u7070\u8272',
    Green: '\u7da0\u8272',
    'Green component': '\u7da0\u8272\u90e8\u5206',
    Groove: '\u51f9\u69fd',
    'Handy Shortcuts': '\u5feb\u901f\u9375',
    Header: '\u8868\u982d',
    'Header cell': '\u8868\u982d\u5132\u5b58\u683c',
    'Heading 1': '\u4e00\u7d1a\u6a19\u984c',
    'Heading 2': '\u4e8c\u7d1a\u6a19\u984c',
    'Heading 3': '\u4e09\u7d1a\u6a19\u984c',
    'Heading 4': '\u56db\u7d1a\u6a19\u984c',
    'Heading 5': '\u4e94\u7d1a\u6a19\u984c',
    'Heading 6': '\u516d\u7d1a\u6a19\u984c',
    Headings: '\u6a19\u984c',
    Height: '\u9ad8\u5ea6',
    Help: '\u5e6b\u52a9',
    'Hex color code': '\u5341\u516d\u9032\u4f4d\u984f\u8272\u4ee3\u78bc',
    Hidden: '\u96b1\u85cf',
    'Horizontal align': '\u6c34\u6e96\u5c0d\u9f4a',
    'Horizontal line': '\u6c34\u6e96\u5206\u5272\u7dda',
    'Horizontal space': '\u6c34\u6e96\u9593\u8ddd',
    ID: 'ID',
    'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
      'ID\u61c9\u8a72\u4ee5\u82f1\u6587\u5b57\u6bcd\u958b\u982d\uff0c\u5f8c\u9762\u53ea\u80fd\u6709\u82f1\u6587\u5b57\u6bcd\u3001\u6578\u4f4d\u3001\u7834\u6298\u865f\u3001\u9ede\u3001\u5192\u865f\u6216\u5e95\u7dda\u3002',
    'Image is decorative': '\u9019\u662f\u88dd\u98fe\u5716\u50cf',
    'Image list': '\u5716\u7247\u6e05\u55ae',
    'Image title': '\u5716\u7247\u6a19\u984c',
    'Image...': '\u5716\u7247...',
    'ImageProxy HTTP error: Could not find Image Proxy':
      '\u5716\u7247\u670d\u52d9\uff1a\u627e\u4e0d\u5230\u670d\u52d9',
    'ImageProxy HTTP error: Incorrect Image Proxy URL':
      '\u5716\u7247\u670d\u52d9\uff1a\u932f\u8aa4\u93c8\u7d50',
    'ImageProxy HTTP error: Rejected request':
      '\u5716\u7247\u670d\u52d9\uff1a\u62d2\u7d55\u5b58\u53d6',
    'ImageProxy HTTP error: Unknown ImageProxy error':
      '\u5716\u7247\u670d\u52d9\uff1a\u672a\u77e5\u932f\u8aa4',
    'Increase indent': '\u589e\u52a0\u7e2e\u9032',
    Inline: '\u6587\u672c',
    Insert: '\u63d2\u5165',
    'Insert Template': '\u63d2\u5165\u7bc4\u672c',
    'Insert accordion': '',
    'Insert column after': '\u5728\u53f3\u5074\u63d2\u5165\u5217',
    'Insert column before': '\u5728\u5de6\u5074\u63d2\u5165\u5217',
    'Insert date/time': '\u63d2\u5165\u65e5\u671f/\u6642\u9593',
    'Insert image': '\u63d2\u5165\u5716\u7247',
    'Insert link (if link plugin activated)':
      '\u63d2\u5165\u9023\u7d50 (\u5982\u679c\u9023\u7d50\u5916\u639b\u7a0b\u5f0f\u5df2\u555f\u52d5)',
    'Insert row after': '\u5728\u4e0b\u65b9\u63d2\u5165\u884c',
    'Insert row before': '\u5728\u4e0a\u65b9\u63d2\u5165\u884c',
    'Insert table': '\u63d2\u5165\u8868\u683c',
    'Insert template...': '\u63d2\u5165\u7bc4\u672c...',
    'Insert video': '\u63d2\u5165\u8996\u983b',
    'Insert/Edit code sample': '\u63d2\u5165/\u7de8\u8f2f\u4ee3\u78bc\u793a\u7bc4',
    'Insert/edit image': '\u63d2\u5165/\u7de8\u8f2f\u5716\u7247',
    'Insert/edit link': '\u63d2\u5165/\u7de8\u8f2f\u9023\u7d50',
    'Insert/edit media': '\u63d2\u5165/\u7de8\u8f2f\u5a92\u9ad4',
    'Insert/edit video': '\u63d2\u5165/\u7de8\u8f2f\u8996\u983b',
    Inset: '\u5d4c\u5165',
    'Invalid hex color code: {0}': '\u7121\u6548\u7684\u984f\u8272\u78bc\uff1a{0}',
    'Invalid input': '\u7121\u6548\u8f38\u5165',
    Italic: '\u659c\u9ad4',
    Justify: '\u5169\u7aef\u5c0d\u9f4a',
    'Keyboard Navigation': '\u9375\u76e4\u6307\u5f15',
    Language: '\u8a9e\u8a00',
    'Learn more...': '\u4e86\u89e3\u66f4\u591a...',
    Left: '\u5de6',
    'Left to right': '\u7531\u5de6\u5230\u53f3',
    'Light Blue': '\u6dfa\u85cd\u8272',
    'Light Gray': '\u6dfa\u7070\u8272',
    'Light Green': '\u6dfa\u7da0\u8272',
    'Light Purple': '\u6dfa\u7d2b\u8272',
    'Light Red': '\u6dfa\u7d05\u8272',
    'Light Yellow': '\u6dfa\u9ec3\u8272',
    'Line height': '\u884c\u9ad8',
    'Link list': '\u9023\u7d50\u6e05\u55ae',
    'Link...': '\u9023\u7d50...',
    'List Properties': '\u6e05\u55ae\u5c6c\u6027',
    'List properties...': '\u6a19\u984c\u5b57\u9ad4\u5c6c\u6027',
    'Loading emojis...': '\u6b63\u5728\u8f09\u5165Emojis...',
    'Loading...': '\u8f09\u5165\u4e2d...',
    'Lower Alpha': '\u5c0f\u5beb\u82f1\u6587\u5b57\u6bcd',
    'Lower Greek': '\u5c0f\u5beb\u5e0c\u81d8\u5b57\u6bcd',
    'Lower Roman': '\u5c0f\u5beb\u7f85\u99ac\u6578\u5b57',
    'Match case': '\u5927\u5c0f\u5beb\u5339\u914d',
    Mathematical: '\u6578\u5b78',
    'Media poster (Image URL)': '\u5c01\u9762(\u5716\u7247\u4f4d\u5740)',
    'Media...': '\u591a\u5a92\u9ad4...',
    'Medium Blue': '\u4e2d\u85cd\u8272',
    'Medium Gray': '\u4e2d\u7070\u8272',
    'Medium Purple': '\u4e2d\u7d2b\u8272',
    'Merge cells': '\u5408\u4f75\u5132\u5b58\u683c',
    Middle: '\u7f6e\u4e2d\u5c0d\u9f4a',
    'Midnight Blue': '\u6df1\u85cd\u8272',
    'More...': '\u66f4\u591a...',
    Name: '\u540d\u7a31',
    'Navy Blue': '\u6d77\u8ecd\u85cd',
    'New document': '\u65b0\u589e\u6587\u4ef6',
    'New window': '\u65b0\u7a97\u53e3',
    Next: '\u4e0b\u4e00\u500b',
    No: '\u5426',
    'No alignment': '\u4e0d\u5c0d\u9f4a',
    'No color': '\u7121',
    'Nonbreaking space': '\u4e0d\u5206\u884c\u7a7a\u683c',
    None: '\u7121',
    'Numbered list': '\u6709\u5e8f\u5217\u8868',
    OR: '\u6216',
    Objects: '\u7269\u4ef6',
    Ok: '\u78ba\u5b9a',
    'Open help dialog': '\u6253\u958b\u5e6b\u52a9\u5c0d\u8a71\u65b9\u584a',
    'Open link': '\u6253\u958b\u9023\u7d50',
    'Open link in...': '\u9023\u7d50\u6253\u958b\u4f4d\u7f6e...',
    'Open popup menu for split buttons':
      '\u6253\u958b\u5f48\u51fa\u5f0f\u529f\u80fd\u8868\uff0c\u7528\u65bc\u62c6\u5206\u6309\u9215',
    Orange: '\u6a59\u8272',
    Outset: '\u5916\u7f6e',
    'Page break': '\u5206\u9801\u7b26',
    Paragraph: '\u6bb5\u843d',
    Paste: '\u8cbc\u4e0a',
    'Paste as text': '\u8cbc\u4e0a\u70ba\u6587\u672c',
    'Paste column after': '\u8cbc\u4e0a\u5f8c\u9762\u7684\u5217',
    'Paste column before': '\u8cbc\u4e0a\u6b64\u5217\u524d',
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
      '\u7576\u524d\u70ba\u7d14\u6587\u5b57\u8cbc\u4e0a\u6a21\u5f0f\uff0c\u518d\u6b21\u9ede\u64ca\u53ef\u4ee5\u56de\u5230\u666e\u901a\u8cbc\u4e0a\u6a21\u5f0f\u3002',
    'Paste or type a link': '\u8cbc\u4e0a\u6216\u8f38\u5165\u9023\u7d50',
    'Paste row after': '\u8cbc\u4e0a\u884c\u5230\u4e0b\u65b9',
    'Paste row before': '\u8cbc\u4e0a\u884c\u5230\u4e0a\u65b9',
    'Paste your embed code below:': '\u5c07\u5167\u5d4c\u4ee3\u78bc\u8cbc\u4e0a\u5728\u4e0b\u9762:',
    People: '\u4eba\u985e',
    Plugins: '\u5916\u639b\u7a0b\u5f0f',
    'Plugins installed ({0}):': '\u5df2\u5b89\u88dd\u5916\u639b\u7a0b\u5f0f ({0}):',
    'Powered by {0}': '\u7531{0}\u9a45\u52d5',
    Pre: '\u524d\u8a00',
    Preferences: '\u9996\u9078\u9805',
    Preformatted: '\u9810\u5148\u683c\u5f0f\u5316\u7684',
    'Premium plugins:': '\u4ed8\u8cbb\u5916\u639b\u7a0b\u5f0f\uff1a',
    'Press the Up and Down arrow keys to resize the editor.': '',
    'Press the arrow keys to resize the editor.': '',
    'Press {0} for help': '',
    Preview: '\u9810\u89bd',
    Previous: '\u4e0a\u4e00\u500b',
    Print: '\u5217\u5370',
    'Print...': '\u5217\u5370...',
    Purple: '\u7d2b\u8272',
    Quotations: '\u5f15\u7528',
    R: '\u7d05',
    'Range 0 to 255': '\u7bc4\u570d0\u81f3255',
    Red: '\u7d05\u8272',
    'Red component': '\u7d05\u8272\u90e8\u5206',
    Redo: 'Test',
    Remove: '\u79fb\u9664',
    'Remove color': '\u79fb\u9664\u984f\u8272',
    'Remove link': '\u79fb\u9664\u9023\u7d50',
    Replace: '\u53d6\u4ee3',
    'Replace all': '\u53d6\u4ee3\u5168\u90e8',
    'Replace with': '\u53d6\u4ee3\u70ba',
    Resize: '\u8abf\u6574\u5927\u5c0f',
    'Restore last draft': '\u6062\u5fa9\u4e0a\u6b21\u7684\u8349\u7a3f',
    'Reveal or hide additional toolbar items': '',
    'Rich Text Area': '\u5bcc\u6587\u672c\u5340\u57df',
    'Rich Text Area. Press ALT-0 for help.':
      '\u7de8\u8f2f\u5340\u3002\u6309Alt+0\u9375\u6253\u958b\u5e6b\u52a9\u3002',
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
      '\u7de8\u8f2f\u5340\u3002\u6309ALT-F9\u6253\u958b\u529f\u80fd\u8868\uff0c\u6309ALT-F10\u6253\u958b\u5de5\u5177\u5217\uff0c\u6309ALT-0\u67e5\u770b\u5e6b\u52a9',
    Ridge: '\u6d77\u810a\u5ea7',
    Right: '\u53f3',
    'Right to left': '\u7531\u53f3\u5230\u5de6',
    Row: '\u884c',
    'Row clipboard actions': '\u884c\u526a\u8cbc\u677f\u64cd\u4f5c',
    'Row group': '\u884c\u7d44',
    'Row header': '\u884c\u982d',
    'Row properties': '\u884c\u5c6c\u6027',
    'Row type': '\u884c\u985e\u578b',
    Rows: '\u884c\u6578',
    Save: '\u5132\u5b58',
    'Save (if save plugin activated)':
      '\u5132\u5b58(\u5982\u679c\u5132\u5b58\u5916\u639b\u7a0b\u5f0f\u5df2\u555f\u52d5)',
    Scope: '\u7bc4\u570d',
    Search: '\u641c\u7d22',
    'Select all': '\u5168\u9078',
    'Select...': '\u9078\u64c7...',
    Selection: '\u9078\u64c7',
    Shortcut: '\u6377\u5f91',
    'Show blocks': '\u986f\u793a\u5340\u584a\u908a\u6846',
    'Show caption': '\u986f\u793a\u6a19\u984c',
    'Show invisible characters': '\u986f\u793a\u4e0d\u53ef\u898b\u5b57\u5143',
    Size: '\u5b57\u578b\u5927\u5c0f',
    Solid: '\u5be6\u7dda',
    Source: '\u6e90',
    'Source code': '\u539f\u59cb\u7a0b\u5f0f\u78bc',
    'Special Character': '\u7279\u6b8a\u5b57\u5143',
    'Special character...': '\u7279\u6b8a\u5b57\u5143...',
    'Split cell': '\u62c6\u5206\u5132\u5b58\u683c',
    Square: '\u5be6\u5fc3\u65b9\u584a',
    'Start list at number': '\u4ee5\u6578\u5b57\u958b\u59cb\u6e05\u55ae',
    Strikethrough: '\u522a\u9664\u7dda',
    Style: '\u6a23\u5f0f',
    Subscript: '\u4e0b\u6a19',
    Superscript: '\u4e0a\u6a19',
    'Switch to or from fullscreen mode': '\u5207\u63db\u5168\u7192\u5e55\u6a21\u5f0f',
    Symbols: '\u7b26\u865f',
    'System Font': '\u7cfb\u7d71\u5b57\u9ad4',
    Table: '\u8868\u683c',
    'Table caption': '\u8868\u683c\u6a19\u984c',
    'Table properties': '\u8868\u683c\u5c6c\u6027',
    'Table styles': '\u8868\u683c\u6a23\u5f0f',
    Template: '\u7bc4\u672c',
    Templates: '\u7bc4\u672c',
    Text: '\u6587\u5b57',
    'Text color': '\u6587\u672c\u984f\u8272',
    'Text color {0}': '',
    'Text to display': '\u8981\u986f\u793a\u7684\u6587\u672c',
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
      '\u60a8\u8f38\u5165\u7684 URL \u4f3c\u4e4e\u662f\u4e00\u500b\u96fb\u90f5\u5730\u5740\u3002\u8981\u52a0\u4e0a\u6240\u9700\u7684 mailto:// \u9996\u78bc\u55ce\uff1f',
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
      '\u60a8\u8f38\u5165\u7684 URL \u4f3c\u4e4e\u662f\u4e00\u500b\u5916\u90e8\u9023\u7d50\u3002\u8981\u52a0\u4e0a\u6240\u9700\u7684 http:// \u9996\u78bc\u55ce\uff1f',
    'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
      '\u60a8\u8f38\u5165\u7684 URL \u4f3c\u4e4e\u662f\u4e00\u500b\u5916\u90e8\u9023\u7d50\u3002\u8981\u52a0\u4e0a\u6240\u9700\u7684 https:// \u9996\u78bc\u55ce\uff1f',
    Title: '\u6a19\u984c',
    'To open the popup, press Shift+Enter':
      '\u6309Shitf+Enter\u9375\u6253\u958b\u5c0d\u8a71\u65b9\u584a',
    'Toggle accordion': '',
    Tools: '\u5de5\u5177',
    Top: '\u4e0a\u65b9\u5c0d\u9f4a',
    'Travel and Places': '\u65c5\u904a\u548c\u5730\u9ede',
    Turquoise: '\u9752\u7da0\u8272',
    Underline: '\u5e95\u7dda',
    Undo: '\u5fa9\u539f',
    Upload: '\u4e0a\u8f09',
    'Uploading image': '\u4e0a\u8f09\u5716\u7247',
    'Upper Alpha': '\u5927\u5beb\u82f1\u6587\u5b57\u6bcd',
    'Upper Roman': '\u5927\u5beb\u7f85\u99ac\u6578\u5b57',
    Url: '\u5730\u5740',
    'User Defined': '\u81ea\u8a02',
    Valid: '\u6709\u6548',
    Version: '\u7248\u672c',
    'Vertical align': '\u5782\u76f4\u5c0d\u9f4a',
    'Vertical space': '\u5782\u76f4\u9593\u8ddd',
    View: '\u67e5\u770b',
    'Visual aids': '\u683c\u7dda',
    Warn: '\u8b66\u544a',
    White: '\u767d\u8272',
    Width: '\u5bec\u5ea6',
    'Word count': '\u5b57\u6578',
    Words: '\u55ae\u8a5e',
    'Words: {0}': '\u5b57\u6578\uff1a{0}',
    Yellow: '\u9ec3\u8272',
    Yes: '\u662f',
    'You are using {0}': '\u4f60\u6b63\u5728\u4f7f\u7528 {0}',
    'You have unsaved changes are you sure you want to navigate away?':
      '\u4f60\u9084\u6709\u6587\u4ef6\u5c1a\u672a\u5132\u5b58\uff0c\u78ba\u5b9a\u8981\u96e2\u958b\uff1f',
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      '\u4f60\u7684\u700f\u89bd\u5668\u4e0d\u652f\u63f4\u6253\u958b\u526a\u8cbc\u677f\uff0c\u8acb\u4f7f\u7528Ctrl+X/C/V\u7b49\u5feb\u901f\u9375\u3002',
    alignment: '\u5c0d\u9f4a',
    'austral sign': '\u6fb3\u5143\u7b26\u865f',
    'cedi sign': '\u585e\u5730\u7b26\u865f',
    'colon sign': '\u5192\u865f',
    'cruzeiro sign': '\u514b\u9b6f\u8cfd\u7f85\u5e63\u7b26\u865f',
    'currency sign': '\u8ca8\u5e63\u7b26\u865f',
    'dollar sign': '\u7f8e\u5143\u7b26\u865f',
    'dong sign': '\u8d8a\u5357\u76fe\u7b26\u865f',
    'drachma sign': '\u5fb7\u62c9\u514b\u99ac\u7b26\u865f',
    'euro-currency sign': '\u6b50\u5143\u7b26\u865f',
    example: '\u4f8b\u5b50',
    formatting: '\u683c\u5f0f\u5316',
    'french franc sign': '\u6cd5\u90ce\u7b26\u865f',
    'german penny symbol': '\u5fb7\u570b\u4fbf\u58eb\u7b26\u865f',
    'guarani sign': '\u74dc\u62c9\u5c3c\u7b26\u865f',
    history: '\u6b77\u53f2',
    'hryvnia sign': '\u683c\u88e1\u592b\u5c3c\u4e9e\u7b26\u865f',
    indentation: '\u7e2e\u9032',
    'indian rupee sign': '\u5370\u5ea6\u76e7\u6bd4',
    'kip sign': '\u8001\u64be\u57fa\u666e\u7b26\u865f',
    'lira sign': '\u91cc\u62c9\u7b26\u865f',
    'livre tournois sign': '\u88e1\u5f17\u5f17\u723e\u7b26\u865f',
    'manat sign': '\u99ac\u7d0d\u7279\u7b26\u865f',
    'mill sign': '\u5bc6\u723e\u7b26\u865f',
    'naira sign': '\u5948\u62c9\u7b26\u865f',
    'new sheqel sign': '\u65b0\u8b1d\u514b\u723e\u7b26\u865f',
    'nordic mark sign': '\u5317\u6b50\u99ac\u514b',
    'peseta sign': '\u6bd4\u85a9\u659c\u5854\u7b26\u865f',
    'peso sign': '\u6bd4\u7d22\u7b26\u865f',
    'ruble sign': '\u76e7\u5e03\u7b26\u865f',
    'rupee sign': '\u76e7\u6bd4\u7b26\u865f',
    'spesmilo sign': 'spesmilo\u7b26\u865f',
    styles: '\u6a23\u5f0f',
    'tenge sign': '\u5805\u6208\u7b26\u865f',
    'tugrik sign': '\u5716\u683c\u88e1\u514b\u7b26\u865f',
    'turkish lira sign': '\u571f\u8033\u5176\u91cc\u62c9',
    'won sign': '\u97d3\u5143\u7b26\u865f',
    'yen character': '\u65e5\u5143\u5b57\u6a23',
    'yen/yuan character variant one': '\u5143\u5b57\u6a23\uff08\u5927\u5beb\uff09',
    'yuan character': '\u4eba\u6c11\u5e63\u5143\u5b57\u6a23',
    'yuan character, in hong kong and taiwan':
      '\u5143\u5b57\u6a23\uff08\u6e2f\u81fa\u5730\u5340\uff09',
    '{0} characters': '{0} \u500b\u5b57\u5143',
    '{0} columns, {1} rows': '',
    '{0} words': '{0} \u5b57'
  })
}
