<template>
  <div class="container">
    <DisplayText :value="displayValue" :label="label" />
    <v-icon
      v-if="mode === EditorMode.Edit"
      @click="onClick"
      style="margin-top: 1rem; margin-left: 1rem"
      v-tooltip="{ text: isDefined(title) ? title : t('labels.selectItem') }"
      icon="mdi-database-search"
    />
    <DialogSearchSelect
      v-model="showSearchSelectDialog"
      :selected-item="model"
      :table-store-id="tableStoreId"
      :title="title"
      @change="itemChanged"
      @close="hideDialog"
    />
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { EditorMode } from '@/enums/EditorMode'
import { TableStoreId } from '@/enums/TableStoreId'
import type { TableRecord } from '@/models/TableRecord'
import { ref, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
defineProps({
  displayValue: { type: String },
  label: { type: String, default: '' },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit },
  tableStoreId: {
    type: String as PropType<TableStoreId>,
    default: TableStoreId.People
  },
  title: { type: String, default: null }
})

const emit = defineEmits<{
  (e: 'change', payload: TableRecord | null): void
}>()

// REACTIVE VARIABLES
const model = defineModel<TableRecord | null>({ default: null })
const showSearchSelectDialog = ref<boolean>(false)

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const itemChanged = (item: TableRecord | null) => {
  emit('change', item)
}

const hideDialog = () => {
  showSearchSelectDialog.value = false
}

const onClick = async () => {
  showSearchSelectDialog.value = true
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
</style>
