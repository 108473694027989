<template>
  <v-form :fast-fail="false" @submit.prevent ref="accountViewForm">
    <v-container class="ma-0 pa-0" style="position: relative" fluid>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.localization')" variant="outlined">
            <v-container style="margin-top: -0.5rem">
              <PrimarySelect
                v-model="store.globals.defaultLocaleCode"
                :label="t('labels.defaultLanguage')"
                :items="store.languageOptions"
              ></PrimarySelect>
              <p style="margin: 0; padding: 0">{{ t('text.defaultLanguage') }}</p>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.testing')" variant="outlined">
            <v-container style="margin-top: -1.5rem">
              <PrimarySwitch
                density="compact"
                :label="t('labels.settings.allowSelfScheduling')"
                v-model="store.globals.testing.allowSelfScheduling"
              />
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.training')" variant="outlined">
            <v-container style="margin-top: -1.5rem">
              <PrimarySwitch
                density="compact"
                :label="t('labels.settings.enableTraining')"
                v-model="store.globals.training.enabled"
              />
              <span v-if="store.globals.training.enabled">
                <PrimarySwitch
                  v-if="store.globals.training.enabled"
                  density="compact"
                  :label="t('labels.settings.trainingExpires')"
                  v-model="store.globals.training.expires"
                />
                <PrimarySwitch
                  density="compact"
                  :label="t('labels.settings.allowSelfScheduling')"
                  v-model="store.globals.training.allowSelfScheduling"
                />
              </span>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.clearance')" variant="outlined">
            <v-container style="margin-top: -1.5rem">
              <PrimarySwitch
                :label="t('labels.settings.enableClearance')"
                v-model="store.globals.clearance.enabled"
              />
              <span v-if="store.globals.clearance.enabled">
                <PrimarySwitch
                  v-if="store.globals.clearance.enabled"
                  :label="t('labels.settings.clearanceExpires')"
                  v-model="store.globals.clearance.expires"
                />
                <PrimarySwitch
                  :label="t('labels.settings.allowSelfScheduling')"
                  v-model="store.globals.clearance.allowSelfScheduling"
                />
              </span>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <div class="fixed-buttons d-flex justify-center align-center">
        <ButtonSecondary
          style="margin-right: 0.5rem"
          @click="store.cancel()"
          :loading="!store.isLoading"
          :disabled="!store.isDirty"
          :text="t('buttons.cancel')"
        />
        <ButtonPrimary
          @click="store.save()"
          :disabled="!store.isDirty || !accountViewForm?.isValid"
          type="submit"
          :loading="!store.isLoading"
          :text="t('buttons.save')"
        />
      </div>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

// STORES, IMPORTS, & COMPOSABLES
const { t } = useI18n({ useScope: 'global' })
const store = useSettingsStore()

// PROPS & EMITS

// REACTIVE VARIABLES
const accountViewForm = ref<any>()

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.top-line {
  border-top: 1px solid $color-dark-16;
}
.card {
  min-height: 23.1rem;
  margin-bottom: 1rem;
  border-color: $color-dark-16;
}
.sub-header {
  font-size: 0.8rem;
  font-weight: 500;
}

.fixed-buttons {
  position: fixed;
  width: 100%;
  left: 4%;
  bottom: 0rem;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
  background-color: $color-background-default;
  border-top: 1px solid $color-dark-16;
  z-index: 1000;
}
</style>
