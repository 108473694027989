<template>
  <div style="display: inline-flex; position: relative">
    <div
      v-if="
        header.headerProps?.filterType === AdvancedFilterType.List ||
        header.headerProps?.filterType === AdvancedFilterType.Status
      "
    >
      <PrimarySelect
        min-width="150"
        :hide-details="true"
        @click.prevent.stop
        v-model="store.advancedFilter[header.key]"
        :label="header.title"
        v-on:update:model-value="onAdvancedFilterChange"
        :items="header.headerProps?.options"
      />
    </div>
    <div v-if="header.headerProps?.filterType === AdvancedFilterType.Text">
      <SearchField
        @click.prevent.stop
        v-model="store.advancedFilter[header.key]"
        :label="header.title"
        min-width="150"
        v-on:update:model-value="onAdvancedFilterChange"
      />
    </div>
    <div v-if="header.headerProps?.filterType === AdvancedFilterType.Disabled">
      {{ header.title }}
    </div>
    <span v-if="header.sortable !== false">
      <v-icon :class="getSortClass(header)" :icon="getSortIcon(header)" />
    </span>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { getEarliestISODateTime, isDefined } from '@/composables/utils'
import { AdvancedFilterType } from '@/enums/AdvancedFilterType'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOperator } from '@/enums/SqlOperator'
import { Status } from '@/enums/Status'
import { StatusField } from '@/enums/StatusField'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IQueryFilter } from '@/interfaces/api/IQueryFilter'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import type { ITableSort } from '@/interfaces/ITableSort'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import type { PropType } from 'vue'

const settingsStore = useSettingsStore()

// PROPS & EMITS
const props = defineProps({
  currentSort: { type: Object as PropType<ITableSort> },
  header: { type: Object as PropType<ITableHeader>, required: true },
  tableStoreId: {
    type: String as PropType<TableStoreId>,
    default: TableStoreId.People
  }
})

// REACTIVE VARIABLES
const store = TableStoreFactory.get(props.tableStoreId) as ITableStore

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS

const getSortClass = (header: ITableHeader) => {
  const filterType = header.headerProps?.filterType
  return props.currentSort?.key === header.key
    ? `sort-${filterType}-active`
    : `sort-${filterType}-disabled`
}

const getSortIcon = (header: ITableHeader) => {
  switch (header.headerProps?.filterType) {
    case AdvancedFilterType.Text:
    case AdvancedFilterType.List: {
      return props.currentSort?.order === 'asc'
        ? 'mdi-sort-alphabetical-ascending'
        : 'mdi-sort-alphabetical-descending'
    }
    case AdvancedFilterType.Status: {
      return props.currentSort?.order === 'asc'
        ? 'mdi-sort-calendar-ascending'
        : 'mdi-sort-calendar-descending'
    }
  }
}

const onAdvancedFilterChange = async () => {
  const keys = Object.keys(store.advancedFilter)
  keys.forEach((key) => {
    // Ensure unused filter values are cleared.
    if (!isDefined(store.advancedFilter[key])) {
      delete store.advancedFilter[key]
    }
  })
  const where: Array<IQueryFilter> = []
  const date = getEarliestISODateTime()
  const today = date.toISOString()

  keys.forEach((key) => {
    const value = store.advancedFilter[key]

    const lastCompliantDate = date
      .add(settingsStore.getDaysWarning(key as StatusField), 'day')
      .toISOString()

    if (isDefined(value)) {
      const header = store.getHeader(key)
      if (header) {
        let queryFilter: IQueryFilter | undefined = undefined
        switch (header.headerProps?.filterType) {
          case AdvancedFilterType.List: {
            queryFilter = {
              comparison: SqlComparison.Equal,
              field: key,
              value1: value
            }
            break
          }

          case AdvancedFilterType.Status: {
            switch (value) {
              case Status.Compliant: {
                if (key === StatusField.TestDueDate || settingsStore.expires(key as StatusField)) {
                  queryFilter = {
                    comparison: SqlComparison.GreaterThan,
                    field: key,
                    value1: lastCompliantDate
                  }
                } else {
                  queryFilter = {
                    comparison: SqlComparison.IsNotNull,
                    field: key
                  }
                }
                break
              }
              case Status.DueSoon: {
                queryFilter = {
                  comparison: SqlComparison.GreaterThanOrEqual,
                  field: key,
                  value1: today,
                  operator: SqlOperator.And
                }
                where.push(queryFilter)
                queryFilter = {
                  comparison: SqlComparison.LessThanOrEqual,
                  field: key,
                  value1: lastCompliantDate
                }
                break
              }
              case Status.Overdue: {
                queryFilter = {
                  comparison: SqlComparison.LessThan,
                  field: key,
                  value1: today
                }
                break
              }
              case Status.NoRecord: {
                queryFilter = {
                  comparison: SqlComparison.IsNull,
                  field: key
                }
                break
              }
            }
            break
          }

          case AdvancedFilterType.Text: {
            queryFilter = {
              comparison: SqlComparison.Like,
              field: key,
              value1: `${value}%`
            }
            break
          }
        }
        if (queryFilter) {
          queryFilter.operator = SqlOperator.And
          where.push(queryFilter)
        }
      }
    }
  })

  if (where.length > 0) {
    where[where.length - 1].operator = undefined
  }
  store.itemQuery.where = where
  store.loadItems(true)
}
</script>

<style lang="scss" scoped>
.sort-list-active {
  top: 0.25rem;
  right: -0.25rem;
  color: $color-main-primary;
}
.sort-list-disabled {
  top: 0.25rem;
  right: -0.25rem;
  color: $color-dark-56;
}
.sort-list-disabled:hover {
  color: $color-main-primary;
}
.sort-status-active {
  top: 0.25rem;
  right: -0.25rem;
  color: $color-main-primary;
}
.sort-status-disabled {
  top: 0.25rem;
  right: -0.25rem;
  color: $color-dark-56;
}
.sort-status-disabled:hover {
  color: $color-main-primary;
}
.sort-text-active {
  color: $color-main-primary;
  position: absolute;
  top: 1.3rem;
  right: -1rem;
}
.sort-text-disabled {
  color: $color-dark-56;
  position: absolute;
  top: 1.3rem;
  right: -1rem;
}
.sort-text-disabled:hover {
  color: $color-main-primary;
}
</style>
