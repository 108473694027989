<template>
  <v-card
    class="card"
    elevation="0"
    variant="outlined"
    :title="t('headers.testingStatus')"
    :subtitle="t('text.testingStatus')"
    width="100%"
  >
    <v-row>
      <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5" xxl="5">
        <v-table>
          <thead>
            <tr>
              <th class="header-text">{{ t('charts.statistics.category') }}</th>
              <th class="header-text">{{ t('charts.statistics.numUsers') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="compliant-text">
                {{ msgCompliant ? msgCompliant : t('charts.statistics.compliant') }}
              </td>
              <td class="compliant-text">{{ model.compliant }}</td>
            </tr>
            <tr v-if="expires">
              <td class="due-soon-text">{{ t('charts.statistics.dueSoon') }}</td>
              <td class="due-soon-text">{{ model.dueSoon }}</td>
            </tr>
            <tr v-if="expires">
              <td class="overdue-text">{{ t('charts.statistics.overdue') }}</td>
              <td class="overdue-text">{{ model.overdue }}</td>
            </tr>
            <tr>
              <td class="no-record-text">
                {{ msgCompliant ? msgNoRecord : t('charts.statistics.noRecord') }}
              </td>
              <td class="no-record-text">{{ model.noRecord }}</td>
            </tr>
            <tr>
              <td class="total-text">{{ t('charts.statistics.total') }}</td>
              <td class="total-text">{{ model.total }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
      <v-col cols="12" xs="12" sm="7" md="7" lg="7" xl="7" xxl="7">
        <highcharts :options="chartOptions" type="pie" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import type { IStatistics } from '@/interfaces/IStatistics'
import { round } from 'lodash-es'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
defineProps({
  expires: { type: Boolean, default: true },
  msgCompliant: { type: String },
  msgNoRecord: { type: String }
})

// REACTIVE VARIABLES
const model = defineModel<IStatistics>({ default: {} })
const chartOptions = ref<Record<string, any>>({
  chart: {
    type: 'pie'
  },
  tooltip: {
    formatter: function (): string {
      // @ts-ignore
      return `<strong>${this.point.name}</strong><br/> ${this.point.raw} ${this.point.raw === 1 ? t('labels.user').toLowerCase() : t('labels.users').toLowerCase()} (${round(this.percentage, 1)}%)`
    },
    shared: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: null
  },
  plotOptions: {
    series: {
      allowPointSelect: false,
      cursor: 'pointer',
      dataLabels: [
        {
          enabled: true,
          distance: 20
        },
        {
          enabled: true,
          distance: -40,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '.75rem',
            textOutline: 'none',
            opacity: 0.7
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 5
          }
        }
      ]
    }
  }
})

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// WATCHERS
watch(model, (newValue) => {
  chartOptions.value.series = newValue.series
})

// FUNCTIONS
</script>

<style lang="scss" scoped>
.card {
  border-color: $color-dark-16;
  margin: 0;
  padding: 0;
}
.compliant-text {
  color: $color-accent-success;
}
.due-soon-text {
  color: $color-accent-primary;
}
.overdue-text {
  color: $color-accent-error;
}
.no-record-text {
  color: $color-dark-86;
}
.header-text {
  font-weight: bold;
}
.stats-section {
  border: 1px solid $color-dark-16;
  border-radius: 8px;
}
.text {
  margin: 0rem 0 1rem 0;
  padding: 0;
}
.total-text {
  font-weight: bold;
}
</style>
