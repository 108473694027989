<template>
  <v-stepper
    ref="importStepper"
    v-model="store.currentStep"
    :items="store.steps"
    :alt-labels="true"
  >
    <template v-slot:item.1>
      <v-container fluid>
        <v-row>
          <v-col class="column">
            <p class="stepper-instructions">
              {{ t('text.import.instructions1') }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="column">
            <PrimarySelect
              max-width="200"
              v-model="store.selectedImportDefinition"
              :label="t('labels.table')"
              :items="tableOptions"
            ></PrimarySelect>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:item.2>
      <v-container fluid>
        <v-row>
          <v-col class="column">
            <p class="stepper-instructions">
              {{ t('text.import.instructions2') }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="column">
            <v-file-input
              v-model="store.selectedFile"
              class="file-input"
              :max-width="300"
              :label="t('labels.openFilePicker')"
              color="primary"
              density="comfortable"
              variant="underlined"
              accept=".csv, text/csv, text/plain"
              @update:model-value="store.readFile"
              @click:clear="store.clearFile"
              primary
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="column">
            <p v-if="store.parsedFile && !store.fileError">
              {{ t('text.import.fileConfirmation') }}&nbsp;
            </p>
            <p class="error-message" v-if="store.fileError">{{ t('errors.invalidCSV') }}&nbsp;</p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.3>
      <v-container fluid>
        <v-row style="margin-bottom: 1rem">
          <v-col class="column">
            <p class="stepper-instructions" style="margin-bottom: 0">
              <strong>
                {{ t('labels.table') }}: {{ store.selectedImportDefinition?.friendlyName }}
              </strong>
            </p>
            <p class="stepper-instructions" style="margin-bottom: 0">
              {{ t('text.import.instructions3a') }}
              <span v-if="store.selectedImportDefinition?.endpoint === EndPoint.CustomFields">
                {{
                  t('info.importCustomFields', {
                    tableName: store.selectedImportDefinition?.friendlyName
                  })
                }}
              </span>
            </p>
            <p
              v-if="store.missingFields?.length > 0"
              class="stepper-error"
              style="margin-bottom: 0"
            >
              {{ t('errors.missingFields', { fieldNames: store.missingFields.join(', ') }) }}
            </p>
            <p v-if="store.skipCount > 0" class="stepper-warning" style="margin-bottom: 0">
              {{
                t('warnings.importWarning', {
                  tableName: store.selectedImportDefinition?.friendlyName
                })
              }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="column">
            <v-btn-toggle
              :disabled="store.selectedImportDefinition?.endpoint === EndPoint.CustomFields"
              class="toggle-button"
              v-model="store.importMode"
              density="compact"
              color="primary"
              rounded="8"
              mandatory
              variant="outlined"
            >
              <v-btn :value="ImportMode.Insert">{{ t('buttons.insert') }}</v-btn>
              <v-btn :value="ImportMode.Overwrite">{{ t('buttons.overwrite') }}</v-btn>
            </v-btn-toggle>
            <span v-if="store.importMode === ImportMode.Insert">{{
              t('text.import.instructions3b')
            }}</span>
            <span v-if="store.importMode === ImportMode.Overwrite">{{
              t('text.import.instructions3c')
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              class="import-table"
              v-if="store.selectedImportFields.length > 0"
              density="compact"
              :headers="store.selectedImportFields"
              :items="store.parsedFile?.data"
              :items-per-page="10"
              :items-per-page-options="store.itemsPerPageOptions"
            >
              <template
                v-for="(item, index) in store.selectedImportFields"
                v-slot:[`header.[${index}]`]
                :key="index"
              >
                <PrimarySelect
                  v-if="!item.ignore"
                  class="header-select"
                  density="compact"
                  :hide-details="true"
                  min-width="200"
                  v-model="store.selectedFields[index]"
                  :items="store.getFieldOptions(index)"
                ></PrimarySelect>
                <span class="ignore-label" v-if="item.ignore">
                  <strong>{{ item.title }}</strong>
                </span>
              </template>

              <template v-slot:item="{ index: rowIndex }">
                <tr class="v-data-table__tr">
                  <td
                    v-for="importField in store.selectedImportFields"
                    :key="importField.index"
                    class="v-data-table__td v-data-table-column--align-start"
                  >
                    {{ getParsedValue(rowIndex, importField.index) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.4>
      <v-container fluid>
        <v-row>
          <v-col class="column">
            <p class="stepper-instructions" style="margin-bottom: 0">
              {{ t('text.import.instructions4') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="stepper-instructions" style="margin-bottom: 0">
              <strong>{{ t('labels.table') }}:</strong>
              {{ store.selectedImportDefinition?.friendlyName }}
            </p>
            <p class="stepper-instructions" style="margin-bottom: 0">
              <strong>{{ t('labels.importMode') }}:</strong> {{ t(`buttons.${store.importMode}`) }}
            </p>
            <p class="stepper-instructions" style="margin-bottom: 0">
              <strong>{{ t('labels.totalRecords') }}:</strong> {{ store.totalRecords }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="store.importState === ImportState.Ready">
          <v-col>
            <ButtonPrimary
              :disabled="store.isImporting"
              @click="store.importFile()"
              :text="t('buttons.import')"
            />&nbsp;
            <ButtonSecondary
              :disabled="!store.isImporting"
              @click="store.cancelImport()"
              :text="t('buttons.cancel')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <strong>{{ t('labels.progress') }}: {{ store.importProgress }}%</strong>
            <v-progress-linear
              color="primary"
              height="10"
              v-model:model-value="store.importProgress"
              :striped="store.isImporting"
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-if="isDone">
          <v-col>
            <p class="stepper-instructions" style="margin-bottom: 0">
              <strong>{{ t('labels.totalInserted') }}:</strong> {{ store.totalInserted }}
            </p>
            <p class="stepper-instructions" style="margin-bottom: 0">
              <strong>{{ t('labels.totalOverwritten') }}:</strong> {{ store.totalOverwritten }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="store.importErrors.length > 0" style="margin: 0; padding: 0">
          <v-col style="margin: 0; padding: 0">
            <v-divider style="margin: 1rem 0 1rem 0" />
            <p class="stepper-instructions">
              <span class="error-message">{{ t('errors.import') }}</span>
            </p>
            <p>
              <v-data-table
                class="import-table"
                :headers="store.errorHeaders"
                density="compact"
                :items="store.importErrors"
                :items-per-page="10"
                :items-per-page-options="store.itemsPerPageOptions"
              >
                <template v-slot:item.messages="{ item }">
                  <span :key="index" v-for="(message, index) in item.messages"
                    >{{ message }}.
                  </span>
                </template>
              </v-data-table>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-container fluid>
        <v-row v-if="store.importState === ImportState.Ready">
          <v-col align="left" class="column">
            <ButtonSecondary
              v-if="store.currentStep > 1"
              :disabled="store.isImporting"
              @click="importStepper.prev()"
              density="compact"
              :text="t('buttons.previous')"
            />
          </v-col>
          <v-col align="right" class="column">
            <ButtonPrimary
              v-if="store.currentStep < store.steps.length"
              :disabled="!hasCompleted"
              @click="importStepper.next()"
              density="compact"
              :text="t('buttons.next')"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="
            store.importState === ImportState.Cancelled ||
            store.importState === ImportState.Completed
          "
        >
          <v-col align="left" class="column">
            <ButtonPrimary @click="store.resetImport" density="compact" :text="t('buttons.done')" />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-stepper>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { EndPoint } from '@/enums/EndPoint'
import { ImportMode } from '@/enums/ImportMode'
import { ImportState } from '@/enums/ImportState'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { useImportStore } from '@/stores/import/ImportStore'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })
const store = useImportStore()

// PROPS & EMITS

// REACTIVE VARIABLES
const importStepper = ref()

// COMPUTED PROPERTIES
const hasCompleted = computed(() => {
  switch (store.currentStep) {
    case 3: {
      return store.missingFields?.length === 0
    }
    default: {
      return store.steps[store.currentStep - 1].hasCompleted
    }
  }
})

const isDone = computed(() => {
  return store.importState === ImportState.Cancelled || store.importState === ImportState.Completed
})

const tableOptions = computed(() => {
  return store.importDefinitions.map((importDefinition: IImportDefinition) => {
    return { title: importDefinition.friendlyName, value: importDefinition }
  })
})

// LIFECYCLE HOOKS

// FUNCTIONS
const getParsedValue = (rowIndex: number, fieldIndex: number) => {
  const rowData = store.parsedFile?.data[rowIndex]
  let value
  if (rowData) {
    value = rowData[fieldIndex]
  }
  if (value === null) {
    return '(NULL)'
  }
  if (value === '') {
    return '(EMPTY)'
  }
  if (value === undefined) {
    return '(undefined)'
  }
  return value
}
</script>

<style lang="scss" scoped>
.column {
  padding: 0;
  margin: 0;
}
.file-input {
  margin-right: 1rem;
}

.ignore-label {
  min-width: 200px;
  padding: 0;
  margin: 0;
}

.stepper-instructions {
  margin: 0 0 1rem 0;
  padding: 0;
}

.stepper-error {
  margin: 0 0 1rem 0;
  padding: 0;
  color: $color-accent-error;
}

.stepper-warning {
  margin: 0 0 1rem 0;
  padding: 0;
  color: $color-accent-primary;
}

.error-message {
  color: $color-accent-error;
}

.header-select {
  padding: 0;
  margin: 0;
}

.header-action {
  padding: 0;
  margin: 0.55rem 0 0 0;
  min-width: 150px;
}

.import-table {
  vertical-align: top;
}

.toggle-button {
  margin-right: 1rem;
}
</style>
