<template>
  <BasePage>
    <v-tabs
      v-model="schedulerStore.selectedTab"
      align-tabs="start"
      density="compact"
      color="primary"
      :grow="false"
    >
      <v-tab :key="SchedulerTab.Appointments" :value="SchedulerTab.Appointments">
        <v-icon icon="mdi-calendar-account"></v-icon>{{ t('tabs.scheduler.appointments') }}</v-tab
      >
      <v-tab :key="SchedulerTab.ManageSlots" :value="SchedulerTab.ManageSlots">
        <v-icon icon="mdi-calendar-multiple"></v-icon>{{ t('tabs.scheduler.manageSlots') }}</v-tab
      >
      <v-tab :key="SchedulerTab.SchedulerSettings" :value="SchedulerTab.SchedulerSettings">
        <v-icon icon="mdi-cogs"></v-icon>{{ t('tabs.scheduler.schedulerSettings') }}</v-tab
      >
    </v-tabs>
    <v-divider />
    <v-tabs-window class="tab-content" v-model="schedulerStore.selectedTab">
      <v-tabs-window-item :key="SchedulerTab.Appointments" :value="SchedulerTab.Appointments">
        <AppointmentsView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SchedulerTab.ManageSlots" :value="SchedulerTab.ManageSlots">
        <ManageSlotsView />
      </v-tabs-window-item>
      <v-tabs-window-item
        :key="SchedulerTab.SchedulerSettings"
        :value="SchedulerTab.SchedulerSettings"
      >
        <SchedulerSettingsView />
      </v-tabs-window-item>
    </v-tabs-window>
    <EditEvent :show-delete="true" />
    <DialogDelete
      v-model="eventsStore.showDeleteDialog"
      :loading="eventsStore.workStatus?.deleteOne"
      :message="t('dialogs.delete.warning', { name: t('dialogs.delete.eventName') })"
      :title="t('dialogs.headers.deleteEvent')"
      @cancel="eventsStore.finalizeDelete"
      @delete="deleteEvent"
    />
  </BasePage>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { SchedulerTab } from '@/enums/SchedulerTab'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSchedulerStore } from '@/stores/ui/SchedulerStore'
import { useI18n } from 'vue-i18n'
import AppointmentsView from './scheduler/AppointmentsView.vue'
import ManageSlotsView from './scheduler/ManageSlotsView.vue'
import SchedulerSettingsView from './scheduler/SchedulerSettingsView.vue'

const { t } = useI18n({ useScope: 'global' })
const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
const schedulerStore = useSchedulerStore()

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const deleteEvent = async () => {
  const results = await eventsStore.deleteOne(eventsStore.selectedItem!)
  if (!results.error) {
    eventsStore.loadItems()
    eventsStore.showDeleteDialog = false
    eventsStore.showEditDialog = false
  }
}
</script>

<style lang="scss" scoped></style>
