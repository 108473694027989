<template>
  <div>
    <DisplayText :value="displayValue" :label="label" v-if="mode === EditorMode.View" />
    <v-combobox
      v-bind="$attrs"
      v-model="model"
      v-if="mode === EditorMode.Edit"
      color="primary"
      variant="underlined"
      density="compact"
    >
      <template v-slot:label>
        <span>{{ label }}&nbsp;<span v-if="required" class="required-text">*</span></span>
      </template>
      <!-- @vue-skip -->
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </v-combobox>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { EditorMode } from '@/enums/EditorMode'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { computed, type PropType } from 'vue'

// PROPS & EMITS
defineProps({
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit }
})

// REACTIVE VARIABLES
const model = defineModel<ISimpleOption | string | number | null>()

// COMPUTED PROPERTIES
const displayValue = computed(() => {
  if (typeof model.value === 'object' && model.value?.title) {
    return model.value.title
  }
  return model.value
})

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped></style>
