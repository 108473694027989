import { getStoreDefinition } from '@/composables/StoreDefinition'
import { EditorMode } from '@/enums/EditorMode'
import { EndPoint } from '@/enums/EndPoint'
import { FitTestTab } from '@/enums/FitTestTab'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useAPIStore } from './ApiStore'

let _store: IFitTestsStore

export const useFitTestsStore = () => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.FitTests
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as IFitTestsStore
  _extend(_store)
  return _store
}

const _extend = (store: IFitTestsStore) => {
  const apiStore = useAPIStore()

  store.selectedTab = ref<FitTestTab>(FitTestTab.Details) as unknown as FitTestTab
  store.defaultEditorMode = EditorMode.View
  store.editorMode = EditorMode.View
  store.itemsPerPage = 5

  store.selectByIdNumber = async (idNumber: string) => {
    const queryOptions = {
      limit: 1,
      where: [
        {
          comparison: SqlComparison.Equal,
          field: 'idNumber',
          value1: idNumber
        }
      ],
      orderBy: [{ field: 'testDate', order: SqlOrder.DESC }]
    }
    const { data, error } = await apiStore.getMany(EndPoint.FitTests, queryOptions)
    if (!error && data && data.count > 0) {
      store.editItem(data?.results[0], false)
    } else {
      store.clearSelectedItem()
    }
  }

  store.setFitTestFilterByIdNumber = (idNumber: string) => {
    store.items = []
    store.itemQuery = {
      limit: 1,
      offset: 0,
      where: [
        {
          comparison: SqlComparison.Equal,
          field: 'idNumber',
          value1: idNumber
        }
      ],
      orderBy: [{ field: 'testDate', order: SqlOrder.DESC }]
    }
  }

  store.updateFitTestsByPerson = async (oldIdNumber: string, newIdNumber: string) => {
    const query: IQueryOptions = {
      where: [
        {
          comparison: SqlComparison.Equal,
          field: 'idNumber',
          value1: oldIdNumber
        }
      ],
      update: {
        idNumber: newIdNumber
      }
    }
    await store.updateMany(query)
  }
}
