/**
 * Enum of rules used by the editors to enforce validation.
 */
export enum Rule {
  DuplicateString = 'duplicate-string',
  Required = 'value-required',
  ValidDate = 'valid-date',
  ValidEmail = 'email',
  ValidNumber = 'valid-number'
}
