import { getStoreDefinition } from '@/composables/StoreDefinition'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { defineStore } from 'pinia'

export const useDailyChecksStore = (): ITableStore => {
  const option = TableStoreOptions.DailyChecks
  const useStore = defineStore(option.id, getStoreDefinition(option))
  return useStore() as unknown as ITableStore
}
