export class TimeValue {
  public hours: number
  public minutes: number
  public seconds: number
  public milliseconds: number

  /**
   * Class that defines a simple TimeValue range to make it easier to separate date and time values.
   * @param hours Total number of hours from 0 to 23.
   * @param minutes Total number of minutes from 0 to 59.
   * @param seconds Total number of minutes from 0 to 59.
   * @param milliseconds Total number of millisconds from 0 to 999.
   */
  public constructor(
    hours: number = 0,
    minutes: number = 0,
    seconds: number = 0,
    milliseconds: number = 0
  ) {
    this.hours = hours
    this.minutes = minutes
    this.seconds = seconds
    this.milliseconds = milliseconds
  }

  /**
   * Returns the meridiem based on the current hour.
   * @returns
   */
  public get meridiem() {
    if (this.hours >= 12) {
      return 'PM'
    }
    return 'AM'
  }

  /**
   * Returns the total minutes for this time value.
   */
  public getMinutes() {
    return this.hours * 60 + this.minutes
  }

  /**
   * Returns the total seconds for this time value.
   */
  public getSeconds() {
    return this.getMinutes() * 60
  }

  /**
   * Returns the total milliseconds for this time value.
   */
  public getMilliseconds() {
    return this.getSeconds() * 1000
  }

  /**
   * Returns a cloned value from an existing time value.
   * @param value A valid TimeValue instance.
   * @returns
   */
  public static fromTimeValue(value: TimeValue) {
    const hours = value.hours
    const minutes = value.minutes
    const seconds = value.seconds
    const milliseconds = value.milliseconds
    return new TimeValue(hours, minutes, seconds, milliseconds)
  }

  /**
   * Returns a cloned value from an existing number of milliseconds.
   * @param value A valid number of millisconds. If the value exceeds 24 hours then drop the excess time.
   * @returns
   */
  public static fromMilliseconds(value: number) {
    const millisecondsInADay = 24 * 60 * 60 * 1000
    while (value > millisecondsInADay) {
      value -= millisecondsInADay
    }
    const hours = Math.floor(value / (60 * 60 * 1000))
    const minutes = Math.floor((value % (60 * 60 * 1000)) / (60 * 1000))
    const seconds = Math.floor((value % (60 * 1000)) / 1000)
    const milliseconds = value % 1000
    return new TimeValue(hours, minutes, seconds, milliseconds)
  }

  /**
   * Get the hours equivalent for a twelve hour clock.
   * @param hours The hours value (0...23).
   * @returns
   */
  public static getMeridiemHours(hours: number) {
    let meridiemHours = ''
    if (hours === 0) {
      meridiemHours = '12'
    } else if (hours > 12) {
      const newHours = hours - 12
      meridiemHours = TimeValue.formatValue(newHours)
    } else {
      meridiemHours = TimeValue.formatValue(hours)
    }
    return meridiemHours
  }

  /**
   * Format hours by padding and optionally converting to twelve hour clock.
   * @param hours The hours value (0...23).
   * @param twelveHourClock Flag that indicates the result should be for a twelve hour clock format.
   * @returns
   */
  public static formatHours(hours: number, twelveHourClock: boolean = false) {
    if (twelveHourClock) {
      return TimeValue.getMeridiemHours(hours)
    }
    return hours.toString().padStart(2, '0')
  }

  /**
   * Format value by padding.
   * @param hours The hours value (0...59).
   * @returns
   */
  public static formatValue(value: number, padding: number = 2) {
    return value.toString().padStart(padding, '0')
  }

  /**
   * Convert the time value to a local short time string (##:##) with optional meridiem (##:## AM/PM).
   * @param twelveHourClock Flag that when set returns time with
   * @returns
   */
  public toShortTimeString = (twelveHourClock: boolean = false): string => {
    const hours = TimeValue.formatHours(this.hours, twelveHourClock)
    const minutes = TimeValue.formatValue(this.minutes)
    if (twelveHourClock) {
      return `${hours}:${minutes} ${this.meridiem}`
    }
    return `${hours}:${minutes}`
  }

  /**
   * Convert the time value to a local time string (##:##:##.###) with an optional meridiem.
   * @param twelveHourClock Flag that when set returns time with
   * @returns
   */
  public toTimeString = (twelveHourClock: boolean = false, short: boolean = true): string => {
    if (short) {
      return this.toShortTimeString(twelveHourClock)
    }
    const hours = TimeValue.formatHours(this.hours, twelveHourClock)
    const minutes = TimeValue.formatValue(this.minutes)
    const seconds = TimeValue.formatValue(this.seconds)
    const milliseconds = TimeValue.formatValue(this.milliseconds)
    if (twelveHourClock) {
      return `${hours}:${minutes}:${seconds}.${milliseconds} ${this.meridiem}`
    }
    return `${hours}:${minutes}:${seconds}.${milliseconds}`
  }
}
