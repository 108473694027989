import { getStoreDefinition } from '@/composables/StoreDefinition'
import { getEarliestISODateTime, getLatestISODateTime } from '@/composables/utils'
import { DatabaseView } from '@/enums/DatabaseView'
import { EndPoint } from '@/enums/EndPoint'
import type { EventCategory } from '@/enums/EventCategory'
import { SearchType } from '@/enums/SearchType'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOperator } from '@/enums/SqlOperator'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { IQueryFilter } from '@/interfaces/api/IQueryFilter'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import type { EventRecord } from '@/models/EventRecord'
import type { TableRecord } from '@/models/TableRecord'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { useAPIStore } from './ApiStore'

let _store: IEventsStore

export const useEventsStore = (): ITableStore => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.Events
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as IEventsStore
  _extend(_store)
  return _store
}

const _extend = (store: IEventsStore) => {
  const apiStore = useAPIStore()

  store.DEFAULT_DAY_RANGE = 4
  store.MAX_DATE = 730

  store.useView(DatabaseView.EventsWithDetail)
  store.itemsPerPage = 5
  store.searchType = SearchType.Query

  store.clearEvents = async (
    startDate: Date,
    endDate: Date,
    eventCategory?: EventCategory
  ): Promise<void> => {
    const where: Array<IQueryFilter> = [
      {
        comparison: SqlComparison.Equal,
        field: 'status',
        value1: 1,
        operator: SqlOperator.And
      },
      {
        comparison: SqlComparison.Between,
        field: 'dtStart',
        value1: startDate.toISOString(),
        value2: endDate.toISOString()
      }
    ]
    if (eventCategory && eventCategory > 0) {
      where.unshift({
        comparison: SqlComparison.Equal,
        field: 'category',
        value1: eventCategory,
        operator: SqlOperator.And
      })
    }
    await store.deleteMany(where)
  }

  store.getEventConflictsByDeviceId = async (
    deviceId: number,
    startDate: Date,
    endDate: Date
  ): Promise<Array<EventRecord>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['id', 'dtStart', 'dtEnd'],
      orderBy: [{ field: 'dtStart', order: SqlOrder.ASC }],
      where: [
        {
          comparison: SqlComparison.Equal,
          field: 'deviceId',
          value1: deviceId,
          operator: SqlOperator.And,
          children: [
            {
              comparison: SqlComparison.GreaterThanOrEqual,
              field: 'dtStart',
              value1: startDate.toISOString(),
              operator: SqlOperator.And
            },
            {
              comparison: SqlComparison.LessThan,
              field: 'dtStart',
              value1: endDate.toISOString(),
              operator: SqlOperator.Or,
              children: [
                {
                  comparison: SqlComparison.GreaterThan,
                  field: 'dtEnd',
                  value1: startDate.toISOString(),
                  operator: SqlOperator.And
                },
                {
                  comparison: SqlComparison.LessThan,
                  field: 'dtEnd',
                  value1: endDate.toISOString()
                }
              ]
            }
          ]
        }
      ]
    }

    const response = await apiStore.getMany(EndPoint.Events, query)
    let items: Array<TableRecord> = []
    if (!response.error && response.data.results) {
      items = response.data?.results
    }
    return items as Array<EventRecord>
  }

  store.getLocationValues = async (): Promise<Array<string>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['location'],
      orderBy: [{ field: 'location', order: SqlOrder.ASC }]
    }
    const response = await apiStore.getMany(EndPoint.Events, query)
    let values: Array<string> = []
    if (!response.error) {
      const items = response.data?.results || []
      values = items.map((item) => {
        return item.location
      })
    }
    return values
  }

  store.getMinStartDate = (): string => {
    return getEarliestISODateTime().toISOString()
  }

  store.getMaxStartDate = (endDate: Date) => {
    if (!dayjs(endDate).isValid()) {
      endDate = new Date()
    }
    return getLatestISODateTime(endDate).toISOString()
  }

  store.getMinEndDate = (startDate: Date) => {
    if (!dayjs(startDate).isValid()) {
      startDate = new Date()
    }
    return getEarliestISODateTime(startDate).toISOString()
  }

  store.getMaxEndDate = (endDate: Date) => {
    if (!dayjs(endDate).isValid()) {
      endDate = new Date()
    }
    const day = dayjs(endDate).add(store.MAX_DATE, 'day').toDate()
    return getLatestISODateTime(day).toISOString()
  }

  store.loadEventsByRange = async (range: ICalendarRange) => {
    store.items = []
    store.itemQuery = {
      where: [
        {
          field: 'dtStart',
          comparison: SqlComparison.Between,
          value1: new Date(range.start).toISOString(),
          value2: new Date(range.end).toISOString()
        }
      ]
    }
    await store.loadItems()
  }

  store.setEventsFilterForAttendee = (id: number, dtStart: string) => {
    store.items = []
    store.count = 0
    store.itemQuery = {
      limit: store.itemsPerPage,
      where: [
        {
          comparison: SqlComparison.GreaterThanOrEqual,
          field: 'dtStart',
          value1: dtStart,
          operator: SqlOperator.And
        },
        {
          comparison: SqlComparison.Equal,
          field: 'attendeeId',
          value1: id
        }
      ],
      orderBy: [{ field: 'dtStart', order: SqlOrder.DESC }]
    }
  }

  store.setEventsFilterForOrganizer = (id: number, dtStart: string) => {
    store.items = []
    store.count = 0
    store.itemQuery = {
      limit: store.itemsPerPage,
      where: [
        {
          comparison: SqlComparison.GreaterThanOrEqual,
          field: 'dtStart',
          value1: dtStart,
          operator: SqlOperator.And
        },
        {
          comparison: SqlComparison.Equal,
          field: 'organizerId',
          value1: id
        }
      ],
      orderBy: [{ field: 'dtStart', order: SqlOrder.DESC }]
    }
  }
}
