import { Rule } from '@/enums/Rule'
import type { IRuleWithPayload } from '@/interfaces/IRuleWithPayload'
import * as EmailValidator from 'email-validator'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { isDefined } from './utils'

/**
 * Manages all rules related to user import and selection controls.
 * @returns
 */
export function useRulesManager() {
  const rules = ref<Map<Rule, Function>>(new Map<Rule, Function>())
  const i18n = useI18n({ useScope: 'global' })

  rules.value.set(
    Rule.DuplicateString,
    (value: string, payload: Array<string> = [], errorSlug: string) => {
      if (
        !payload
          .map((item) => {
            return item.toLowerCase()
          })
          .includes(value?.toLowerCase())
      ) {
        return true
      }
      return i18n.t(errorSlug)
    }
  )

  rules.value.set(Rule.ValidEmail, (value: string) => {
    if (!value) return true
    const isValid = EmailValidator.validate(value)
    if (isValid) {
      return true
    }
    return i18n.t('validation.invalidEmail')
  })

  rules.value.set(Rule.ValidNumber, (value: number, payload: Record<string, any> = {}) => {
    if (!isDefined(value)) {
      return true
    }
    if (isNaN(value)) {
      return i18n.t('validation.invalidNumber')
    }
    if (
      isDefined(payload.min) &&
      isDefined(payload.max) &&
      (value < payload.min || value > payload.max)
    ) {
      return i18n.t('validation.invalidMinMax', { min: payload.min, max: payload.max })
    } else if (isDefined(payload.max) && value > payload.max) {
      return i18n.t('validation.invalidMax', { max: payload.max })
    } else if (isDefined(payload.min) && value < payload.min) {
      return i18n.t('validation.invalidMin', { min: payload.min })
    }
    return true
  })

  rules.value.set(Rule.Required, (value: string) => {
    if (isDefined(value)) return true
    return i18n.t('validation.valueRequired')
  })

  rules.value.set(Rule.ValidDate, (value: string, payload: Record<string, any> = {}) => {
    if (!value) return true
    const date = new Date(value)
    if (isNaN(date.getTime()) === true) {
      return i18n.t('validation.invalidDate')
    }
    if (isDefined(payload.min)) {
      const minMilliseconds = new Date(payload.min).getTime()
      if (isNaN(minMilliseconds) === false) {
        const milliseconds = date.getTime()
        if (milliseconds < minMilliseconds) {
          return i18n.t('validation.invalidMinDate')
        }
      }
    }
    if (isDefined(payload.max)) {
      const maxMilliseconds = new Date(payload.max).getTime()
      if (isNaN(maxMilliseconds) === false) {
        const milliseconds = date.getTime()
        if (milliseconds > maxMilliseconds) {
          return i18n.t('validation.invalidMaxDate')
        }
      }
    }
    return true
  })

  /**
   * Returns a set of rules.
   * @param keys A single rule, a single rule with a payload or any array of rules.
   * @returns
   */
  const getRules = (keys: Rule | IRuleWithPayload | Array<Rule | IRuleWithPayload>) => {
    if (!Array.isArray(keys)) {
      keys = [keys]
    }
    return keys.map((rule: Rule | IRuleWithPayload) => {
      if (typeof rule === 'string') {
        return rules.value.get(rule)
      }
      const foundRule = rules.value.get(rule.key)
      if (foundRule) {
        return (value: any) => {
          return foundRule(value, rule.payload, rule.errorSlug)
        }
      }
    })
  }

  return {
    getRules
  }
}
