import { TimeValue } from '@/classes/TimeValue'
import { getLatestISODateTime } from '@/composables/utils'
import { EventCategory } from '@/enums/EventCategory'
import { ManageSlotsMode } from '@/enums/ManageSlotsMode'
import { ManageSlotsStatus } from '@/enums/ManageSlotsStatus'
import { StoreId } from '@/enums/StoreId'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { EventRecord } from '@/models/EventRecord'
import dayjs from 'dayjs'
import { chunk, debounce, type DebouncedFunc } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { TableStoreFactory } from '../db/TableStoreFactory'
import { useCalendarStore } from './CalendarStore'

const SLOTS_PER_GROUP = 250

let _loadAdminOptionsPending: DebouncedFunc<any> | undefined = undefined

export const useSlotsStore = defineStore(
  StoreId.Slots,
  () => {
    // STORES, IMPORTS, & COMPOSABLES
    const calendarStore = useCalendarStore()
    const devicesStore = TableStoreFactory.get(TableStoreId.Devices) as IDevicesStore
    const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
    const peopleStore = TableStoreFactory.get(TableStoreId.People) as IPeopleStore

    // REACTIVE VARIABLES
    const administratorOptions = ref<Array<ISimpleOption>>([])
    const conflicts = ref<Array<EventRecord>>([])
    const deviceOptions = ref<Array<ISimpleOption>>([])
    const duration = ref<TimeValue>(new TimeValue(0, 15))
    const endDate = ref<Date>(dayjs().add(eventsStore.DEFAULT_DAY_RANGE, 'day').toDate())
    const endTime = ref<TimeValue>(new TimeValue(16, 0))
    const eventCategory = ref<EventCategory>(EventCategory.FitTest)
    const includeWeekends = ref<boolean>(false)
    const locationValues = ref<Array<string>>([])
    const mode = ref<ManageSlotsMode>(ManageSlotsMode.Create)
    const showCreateEvents = ref<boolean>(false)
    const skippedSlots = ref<Array<EventRecord>>([])
    const slotGroupIndex = ref<number>(0)
    const slotGroups = ref<Array<Array<EventRecord>>>([])
    const selectedAdminOption = ref<ISimpleOption | null>(null)
    const selectedDeviceOption = ref<ISimpleOption | null>(null)
    const selectedLocation = ref<string | null>(null)
    const startDate = ref<Date>(dayjs().toDate())
    const startTime = ref<TimeValue>(new TimeValue(8, 0))
    const status = ref<ManageSlotsStatus>(ManageSlotsStatus.Ready)
    const timeRangeError = ref<boolean>(false)
    const totalSlots = ref<number>(0)

    // WATCHERS
    watch(
      (): any => eventCategory.value,
      (value) => {
        switch (value) {
          case EventCategory.FitTest: {
            duration.value = calendarStore.testDuration
            break
          }
          case EventCategory.MedicalClearance: {
            selectedDeviceOption.value = null
            duration.value = calendarStore.clearanceDuration
            break
          }
          case EventCategory.Training: {
            selectedDeviceOption.value = null
            duration.value = calendarStore.trainingDuration
            break
          }
        }
      }
    )

    watch(
      (): any => startTime.value,
      () => {
        const range = endTime.value.getMilliseconds() - startTime.value.getMilliseconds()
        timeRangeError.value = range < duration.value.getMilliseconds()
      },
      { deep: true }
    )

    watch(
      (): any => endTime.value,
      () => {
        const range = endTime.value.getMilliseconds() - startTime.value.getMilliseconds()
        timeRangeError.value = range < duration.value.getMilliseconds()
      },
      { deep: true }
    )

    // COMPUTED
    const minStartDate = computed(() => {
      return eventsStore.getMinStartDate()
    })

    const maxStartDate = computed(() => {
      return eventsStore.getMaxStartDate(endDate.value)
    })

    const minEndDate = computed(() => {
      return eventsStore.getMinEndDate(startDate.value)
    })

    const maxEndDate = computed(() => {
      return eventsStore.getMaxEndDate(endDate.value)
    })

    const percentComplete = computed(() => {
      return Math.round((slotGroupIndex.value / totalSlotGroups.value) * 100)
    })

    const totalSlotGroups = computed(() => {
      return slotGroups.value.length
    })

    const validTimeSpan = computed(() => {
      return startTime.value.getMinutes() < endTime.value.getMinutes()
    })

    // FUNCTIONS

    const clearSlots = () => {
      mode.value = ManageSlotsMode.Clear
      status.value = ManageSlotsStatus.Ready
      slotGroupIndex.value = 0
      slotGroups.value = []
    }

    const createEvent = (startTime: number, duration: number): EventRecord => {
      const event: EventRecord = new EventRecord()
      if (selectedAdminOption.value) {
        event.organizerId = selectedAdminOption.value.value as number
      }
      if (selectedDeviceOption.value) {
        event.deviceId = selectedDeviceOption.value.value as number
      }
      event.dtStart = new Date(startTime).toISOString()
      event.dtEnd = new Date(startTime + duration).toISOString()
      event.category = eventCategory.value
      if (selectedLocation.value) {
        event.location = selectedLocation.value
      }
      return event
    }

    /**
     * Use the form input to create multiple slots. Show the creation dialog.
     */
    const createSlots = async () => {
      mode.value = ManageSlotsMode.Create
      skippedSlots.value = []
      status.value = ManageSlotsStatus.Ready
      slotGroups.value = []
      slotGroupIndex.value = 0
      totalSlots.value = 0
      const slots = []
      const startDay = TimeValue.formatValue(startDate.value.getDate())
      const startMonth = TimeValue.formatValue(startDate.value.getMonth() + 1)
      const startYear = startDate.value.getFullYear()
      const endDay = TimeValue.formatValue(endDate.value.getDate())
      const endMonth = TimeValue.formatValue(endDate.value.getMonth() + 1)
      const endYear = endDate.value.getFullYear()
      let currentDateValue = dayjs(
        `${startYear}-${startMonth}-${startDay} ${startTime.value.toTimeString()}`
      )
      const endDateValue = getLatestISODateTime(dayjs(`${endYear}-${endMonth}-${endDay}`).toDate())

      conflicts.value = []
      if (eventCategory.value === EventCategory.FitTest && selectedDeviceOption.value?.value) {
        conflicts.value = await eventsStore.getEventConflictsByDeviceId(
          selectedDeviceOption.value?.value as number,
          currentDateValue.toDate(),
          endDateValue.toDate()
        )
      }

      while (currentDateValue <= endDateValue) {
        const dayOfWeek = currentDateValue.day()
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6

        // Skip wekends if the user does not want them included.
        if (!isWeekend || includeWeekends.value) {
          const durationMilliseconds = duration.value.getMilliseconds()
          const startTimeValue = `${currentDateValue.format('MM/DD/YYYY')} ${startTime.value.toTimeString()}`
          const endTimeValue = `${currentDateValue.format('MM/DD/YYYY')} ${endTime.value.toTimeString()}`
          const endTimeMilliseconds = dayjs(endTimeValue).toDate().getTime()

          let currentTimeMilliseconds = dayjs(startTimeValue).toDate().getTime()
          while (currentTimeMilliseconds <= endTimeMilliseconds - durationMilliseconds) {
            const event = createEvent(currentTimeMilliseconds, durationMilliseconds)
            if (!hasConflict(event)) {
              slots.push(event)
            } else {
              skippedSlots.value.push(event)
            }
            currentTimeMilliseconds += durationMilliseconds
          }
        }
        currentDateValue = currentDateValue.add(1, 'day')
      }

      totalSlots.value = slots.length
      slotGroups.value = chunk(slots, SLOTS_PER_GROUP)
    }

    const hasConflict = (event: EventRecord) => {
      const eventStartMs = new Date(event.dtStart).getTime()
      const eventEndMs = new Date(event.dtEnd).getTime()
      const results = conflicts.value.some((conflict) => {
        const conflictStartMs = new Date(conflict.dtStart).getTime()
        const conflictEndMs = new Date(conflict.dtEnd).getTime()
        return (
          (eventStartMs >= conflictStartMs && eventStartMs < conflictEndMs) ||
          (eventEndMs > conflictEndMs && eventEndMs < conflictEndMs)
        )
      })
      return results
    }

    /**
     * Load Administrator options.
     * @returns
     */
    const loadAdministratorOptions = async () => {
      let keyword: string
      if (selectedAdminOption.value) {
        if (typeof selectedAdminOption.value === 'string') {
          keyword = selectedAdminOption.value
        } else {
          return
        }
      }

      const waitTime = _loadAdminOptionsPending ? 1000 : 0

      if (_loadAdminOptionsPending) {
        _loadAdminOptionsPending.cancel()
      }

      _loadAdminOptionsPending = debounce(async () => {
        administratorOptions.value = await peopleStore.getAdministratorOptions(keyword)
      }, waitTime)
      _loadAdminOptionsPending()
    }

    const loadDeviceOptions = async () => {
      deviceOptions.value = await devicesStore.getDeviceOptions()
    }

    const loadLocationValues = async () => {
      locationValues.value = await eventsStore.getLocationValues()
    }

    const loadOptions = async () => {
      const p1 = loadAdministratorOptions()
      const p2 = loadDeviceOptions()
      const p3 = loadLocationValues()
      return Promise.all([p1, p2, p3])
    }

    const saveSlots = async () => {
      status.value = ManageSlotsStatus.InProgress
      for (let index = slotGroupIndex.value; index < totalSlotGroups.value; index++) {
        // @ts-expect-error
        if (status.value === ManageSlotsStatus.Paused) {
          break
        }
        const slotGroup = slotGroups.value[slotGroupIndex.value]
        await eventsStore.save(true, false, true, slotGroup)
        slotGroupIndex.value = index + 1
      }

      // Mark the process completed.
      if (slotGroupIndex.value === totalSlotGroups.value) {
        status.value = ManageSlotsStatus.Complete
        slotGroupIndex.value = totalSlotGroups.value
      }
    }

    return {
      administratorOptions,
      conflicts,
      deviceOptions,
      duration,
      endDate,
      endTime,
      eventCategory,
      includeWeekends,
      locationValues,
      minStartDate,
      maxStartDate,
      minEndDate,
      maxEndDate,
      mode,
      percentComplete,
      selectedAdminOption,
      selectedDeviceOption,
      selectedLocation,
      showCreateEvents,
      skippedSlots,
      slotGroupIndex,
      slotGroups,
      startDate,
      startTime,
      status,
      timeRangeError,
      totalSlotGroups,
      totalSlots,
      validTimeSpan,
      clearSlots,
      createSlots,
      loadAdministratorOptions,
      loadDeviceOptions,
      loadLocationValues,
      loadOptions,
      saveSlots
    }
  },
  {
    persist: {
      storage: localStorage,
      pick: []
    }
  }
)
