import { getStoreDefinition } from '@/composables/StoreDefinition'
import { isDefined } from '@/composables/utils'
import { DatabaseView } from '@/enums/DatabaseView'
import { EndPoint } from '@/enums/EndPoint'
import { ManagePersonTab } from '@/enums/ManagePersonTab'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOperator } from '@/enums/SqlOperator'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useAPIStore } from './ApiStore'

let _store: IPeopleStore

export const usePeopleStore = () => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.People
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as IPeopleStore
  _extend(_store)
  return _store
}

const _extend = (store: IPeopleStore) => {
  const apiStore = useAPIStore()

  // Override the default table with a view.
  store.useView(DatabaseView.PeopleWithStatus)

  store.selectedTab = ref<ManagePersonTab>(ManagePersonTab.Info) as unknown as ManagePersonTab

  store.getAdministratorOptions = async (keyword?: string): Promise<Array<ISimpleOption>> => {
    const query: IQueryOptions = {
      limit: 250,
      isDistinct: true,
      fields: ['id', 'firstName', 'lastName', 'idNumber'],
      where: [
        {
          field: 'isTestAdmin',
          comparison: SqlComparison.Equal,
          value1: true
        }
      ],
      orderBy: [
        { field: 'lastName', order: SqlOrder.ASC },
        { field: 'firstName', order: SqlOrder.ASC }
      ]
    }
    if (isDefined(keyword) && query.where) {
      query.where[0].operator = SqlOperator.And
      query.where[0].children = [
        {
          field: 'firstName',
          comparison: SqlComparison.Like,
          value1: `${keyword}%`,
          operator: SqlOperator.Or
        },
        {
          field: 'lastName',
          comparison: SqlComparison.Like,
          value1: `${keyword}%`,
          operator: SqlOperator.Or
        },
        {
          field: 'idNumber',
          comparison: SqlComparison.Like,
          value1: `${keyword}%`
        }
      ]
    }
    const response = await apiStore.getMany(EndPoint.People, query)
    let options: Array<ISimpleOption> = []
    if (!response.error) {
      const supervisors = response.data?.results || []
      options = supervisors.map((item) => {
        return { title: `${item.lastName}, ${item.firstName} (${item.idNumber})`, value: item.id! }
      })
    }
    return options
  }

  store.getDirectReports = async (personId: number): Promise<Array<string>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['id', 'firstName', 'lastName', 'idNumber'],
      where: [
        {
          field: 'supervisorId',
          comparison: SqlComparison.Equal,
          value1: personId
        }
      ],
      orderBy: [
        { field: 'lastName', order: SqlOrder.ASC },
        { field: 'firstName', order: SqlOrder.ASC }
      ]
    }
    const response = await apiStore.getMany(EndPoint.People, query)
    let options: Array<string> = []
    if (!response.error) {
      const supervisors = response.data?.results || []
      options = supervisors.map((item) => {
        return `${item.lastName}, ${item.firstName} (${item.idNumber})`
      })
    }
    return options
  }

  store.getSupervisorOptions = async (): Promise<Array<ISimpleOption>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['id', 'firstName', 'lastName', 'idNumber'],
      where: [
        {
          field: 'isSupervisor',
          comparison: SqlComparison.Equal,
          value1: true
        }
      ],
      orderBy: [
        { field: 'lastName', order: SqlOrder.ASC },
        { field: 'firstName', order: SqlOrder.ASC }
      ]
    }
    const response = await apiStore.getMany(EndPoint.People, query)
    let options: Array<ISimpleOption> = []
    if (!response.error) {
      const supervisors = response.data?.results || []
      options = supervisors.map((item) => {
        return { title: `${item.lastName}, ${item.firstName} (${item.idNumber})`, value: item.id! }
      })
    }
    return options
  }
}
