/**
 * Enum of email template types.
 */
export enum EmailTemplate {
  AppointmentClearance = 'appointmentClearance',
  AppointmentTesting = 'appointmentTesting',
  AppointmentTraining = 'appointmentTraining',
  DueSoonClearance = 'dueSoonClearance',
  DueSoonTesting = 'dueSoonTesting',
  DueSoonTraining = 'dueSoonTraining',
  OverdueClearance = 'overdueClearance',
  OverdueTesting = 'overdueTesting',
  OverdueTraining = 'overdueTraining'
}
