import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  return [
    {
      title: i18n.t('fields.devices.name'),
      key: 'name',
      align: 'start',
      nowrap: true,
      headerProps: { searchField: true }
    },
    {
      title: i18n.t('fields.devices.serialNumber'),
      key: 'serialNumber',
      align: 'start',
      nowrap: true,
      headerProps: { searchField: true }
    },
    {
      title: i18n.t('fields.devices.model'),
      key: 'model',
      align: 'start',
      nowrap: true,
      headerProps: { searchField: true }
    },
    {
      title: i18n.t('fields.devices.location'),
      key: 'location',
      align: 'start',
      nowrap: true,
      headerProps: { searchField: true }
    },
    {
      title: i18n.t('fields.devices.calStatus'),
      key: 'lastCalibration',
      align: 'start',
      nowrap: true
    },
    {
      title: i18n.t('fields.devices.planStatus'),
      key: 'planExpiration',
      align: 'start',
      nowrap: true
    },
    {
      title: i18n.t('fields.devices.calCert'),
      key: 'calCertId',
      align: 'start',
      nowrap: true,
      sortable: false
    },
    {
      title: i18n.t('fields.devices.planInfo'),
      key: 'planInfo',
      align: 'start',
      sortable: false,
      nowrap: true,
      headerProps: { searchField: true }
    },
    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      nowrap: true,
      sortable: false
    }
  ]
}
