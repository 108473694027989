<template>
  <BasePage style="position: relative">
    <div style="position: absolute; right: 0.25rem; top: 0.75rem">
      <ButtonIcon
        :text="t('buttons.refreshData')"
        icon="mdi-database-refresh"
        @click="refreshStatistics(true)"
      />
    </div>
    <v-container class="ma-1 pa-1" fluid>
      <v-row>
        <v-col rowspan="1" cols="12" xs="12" sm="12" md="12" lg="4" xl="2" xxl="2">
          <DashboardFilter
            :definitions="customFieldsStore.filterableFieldDefinitions"
            @change="refreshStatistics"
          />
        </v-col>
        <v-col style="display: flex" cols="12" xs="12" sm="12" md="12" lg="8" xl="10" xxl="10">
          <v-container class="ma-0 pa-0" fluid>
            <v-row>
              <v-col style="display: flex" cols="12" xs="12" sm="12" md="12" lg="10" xl="6" xxl="6">
                <StatsSection
                  v-model="dashboardStore.stats.testing"
                  prepend-icon="mdi-text-box-check"
                  :title="t('headers.testingStatus')"
                  :subtitle="t('text.testingStatus')"
                />
              </v-col>
              <v-col
                v-if="settingsStore.globals.training.enabled"
                style="display: flex"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="10"
                xl="6"
                xxl="6"
              >
                <StatsSection
                  v-model="dashboardStore.stats.training"
                  prepend-icon="mdi-school"
                  :title="t('headers.trainingStatus')"
                  :subtitle="t('text.trainingStatus')"
                  :expires="settingsStore.globals.training.expires"
                />
              </v-col>
              <v-col
                v-if="settingsStore.globals.clearance.enabled"
                style="display: flex"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="10"
                xl="6"
                xxl="6"
              >
                <StatsSection
                  v-model="dashboardStore.stats.clearance"
                  prepend-icon="mdi-medical-bag"
                  :title="t('headers.clearanceStatus')"
                  :subtitle="t('text.clearanceStatus')"
                  :expires="settingsStore.globals.clearance.expires"
                  :msg-compliant="t('labels.cleared')"
                  :msg-no-record="t('labels.notCleared')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </BasePage>
</template>

<script setup lang="ts">
import { MessageType } from '@/enums/MessageType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useDashboardStore } from '@/stores/ui/DashboardStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import { debounce, type DebouncedFunc } from 'lodash-es'
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

// STORES, IMPORTS, & COMPOSABLES
const customFieldsStore = TableStoreFactory.get(TableStoreId.CustomFields) as ICustomFieldsStore
const dashboardStore = useDashboardStore()
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()
const { t } = useI18n({ useScope: 'global' })

// PRIVATE VARIABLES
let _searchPending: DebouncedFunc<any>

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  await customFieldsStore.loadItems()
  await dashboardStore.loadStatistics()
})

// FUNCTIONS
const refreshStatistics = async (notify: boolean = false) => {
  if (notify) {
    await dashboardStore.loadStatistics()
    snackbarStore.addMessage(t('text.programStatusUpdated'), MessageType.Info)
    return
  }

  if (_searchPending) {
    _searchPending.cancel()
  }
  _searchPending = debounce(async () => {
    await dashboardStore.loadStatistics()
  }, 1250)
  _searchPending()
}
</script>

<style lang="scss" scoped></style>
