import { formatDate, isDefined } from '@/composables/utils'
import { FitTestAlgorithm } from '@/enums/FitTestAlgorithm'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'
import type { FitTestRecord } from '../FitTestRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>,
  localeCode?: string
): Array<ITableHeader> => {
  return [
    {
      title: i18n.t('fields.fitTests.testDate'),
      key: 'testDate',
      align: 'start',
      value: (item: FitTestRecord) => {
        return isDefined(item.testDate) ? formatDate(item.testDate, true, localeCode) : '-'
      }
    },
    {
      title: i18n.t('fields.fitTests.dueDate'),
      key: 'dueDate',
      align: 'start',
      value: (item: FitTestRecord) => {
        return isDefined(item.dueDate) ? formatDate(item.dueDate, false, localeCode) : '-'
      }
    },
    {
      title: i18n.t('fields.fitTests.respirator'),
      key: 'maskDescription',
      align: 'start'
    },
    {
      title: i18n.t('fields.fitTests.maskSize'),
      key: 'maskSize',
      align: 'start'
    },
    {
      title: i18n.t('fields.fitTests.method'),
      key: 'algorithm',
      align: 'start',
      value: (item: FitTestRecord) => {
        switch (item.algorithm) {
          case FitTestAlgorithm.QLFT: {
            return i18n.t('fields.fitTests.methods.qlft')
          }
          default: {
            return i18n.t('fields.fitTests.methods.qnft')
          }
        }
      }
    },
    {
      title: i18n.t('fields.fitTests.status'),
      key: 'overallPass',
      align: 'start'
    },

    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      sortable: false,
      nowrap: true
    }
  ]
}
