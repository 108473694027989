import type { App } from 'vue'

import ButtonAlternate from '@/components/buttons/ButtonAlternate.vue'
import ButtonIcon from '@/components/buttons/ButtonIcon.vue'
import ButtonLink from '@/components/buttons/ButtonLink.vue'
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
import ButtonSecondary from '@/components/buttons/ButtonSecondary.vue'

import BasePage from '@/components/BasePage.vue'
import CalendarEvents from '@/components/CalendarEvents.vue'
import DataTable from '@/components/DataTable.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import DialogDelete from '@/components/dialogs/DialogDelete.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import DialogSearchSelect from '@/components/dialogs/DialogSearchSelect.vue'
import DialogSlotsConfirm from '@/components/dialogs/DialogSlotsConfirm.vue'
import DialogSubscribe from '@/components/dialogs/DialogSubscribe.vue'
import DisplayText from '@/components/DisplayText.vue'
import FitTestHistory from '@/components/FitTestHistory.vue'
import FitTestStatus from '@/components/FitTestStatus.vue'
import FitTestForm from '@/components/forms/FitTestForm.vue'
import HeaderWithFilter from '@/components/HeaderWithFilter.vue'
import SnackBar from '@/components/SnackBar.vue'
import StatsSection from '@/components/StatsSection.vue'
import StatusChip from '@/components/StatusChip.vue'

import BaseEditor from '@/components/editors/BaseEditor.vue'
import EditColumns from '@/components/editors/EditColumns.vue'
import EditCustomField from '@/components/editors/EditCustomField.vue'
import EditDevice from '@/components/editors/EditDevice.vue'
import EditEmailTemplate from '@/components/editors/EditEmailTemplate.vue'
import EditEvent from '@/components/editors/EditEvent.vue'
import EditFitTest from '@/components/editors/EditFitTest.vue'
import EditMask from '@/components/editors/EditMask.vue'
import ManagePerson from '@/components/editors/ManagePerson.vue'

import DashboardFilter from '@/components/inputs/DashboardFilter.vue'
import DatePicker from '@/components/inputs/DatePicker.vue'
import FileView from '@/components/inputs/FileView.vue'
import ItemPicker from '@/components/inputs/ItemPicker.vue'
import NumericField from '@/components/inputs/NumericField.vue'
import PrimaryComboBox from '@/components/inputs/PrimaryComboBox.vue'
import PrimarySelect from '@/components/inputs/PrimarySelect.vue'
import PrimarySwitch from '@/components/inputs/PrimarySwitch.vue'
import PrimaryTextArea from '@/components/inputs/PrimaryTextArea.vue'
import PrimaryTextField from '@/components/inputs/PrimaryTextField.vue'
import RadioGroup from '@/components/inputs/RadioGroup.vue'
import SearchField from '@/components/inputs/SearchField.vue'
import TextSelectCombo from '@/components/inputs/TextSelectCombo.vue'
import TimePicker from '@/components/inputs/TimePicker.vue'
import RichTextEditor from '@/components/richText/RichTextEditor.vue'

import MedicalClearanceForm from '@/components/forms/MedicalClearanceForm.vue'

import { vMaska } from 'maska/vue'

export const localComponents = {
  install(app: App) {
    app.component('BaseDialog', BaseDialog)
    app.component('BaseEditor', BaseEditor)
    app.component('BasePage', BasePage)
    app.component('ButtonAlternate', ButtonAlternate)
    app.component('ButtonIcon', ButtonIcon)
    app.component('ButtonLink', ButtonLink)
    app.component('ButtonPrimary', ButtonPrimary)
    app.component('ButtonSecondary', ButtonSecondary)
    app.component('CalendarEvents', CalendarEvents)
    app.component('DashboardFilter', DashboardFilter)
    app.component('DataTable', DataTable)
    app.component('DatePicker', DatePicker)
    app.component('DialogSlotsConfirm', DialogSlotsConfirm)
    app.component('DialogDelete', DialogDelete)
    app.component('DialogInfo', DialogInfo)
    app.component('DialogSearchSelect', DialogSearchSelect)
    app.component('DialogSubscribe', DialogSubscribe)
    app.component('DisplayText', DisplayText)
    app.component('EditColumns', EditColumns)
    app.component('EditCustomField', EditCustomField)
    app.component('EditDevice', EditDevice)
    app.component('EditEmailTemplate', EditEmailTemplate)
    app.component('EditEvent', EditEvent)
    app.component('EditFitTest', EditFitTest)
    app.component('EditMask', EditMask)
    app.component('ManagePerson', ManagePerson)
    app.component('FileView', FileView)
    app.component('FitTestForm', FitTestForm)
    app.component('FitTestHistory', FitTestHistory)
    app.component('FitTestStatus', FitTestStatus)
    app.component('HeaderWithFilter', HeaderWithFilter)
    app.component('ItemPicker', ItemPicker)
    app.component('MedicalClearanceForm', MedicalClearanceForm)
    app.component('NumericField', NumericField)
    app.component('PrimaryComboBox', PrimaryComboBox)
    app.component('PrimarySelect', PrimarySelect)
    app.component('PrimarySwitch', PrimarySwitch)
    app.component('PrimaryTextArea', PrimaryTextArea)
    app.component('PrimaryTextField', PrimaryTextField)
    app.component('RadioGroup', RadioGroup)
    app.component('RichTextEditor', RichTextEditor)
    app.component('SearchField', SearchField)
    app.component('SnackBar', SnackBar)
    app.component('StatsSection', StatsSection)
    app.component('StatusChip', StatusChip)
    app.component('TextSelectCombo', TextSelectCombo)
    app.component('TimePicker', TimePicker)

    app.directive('maska', vMaska)
  }
}
