<template>
  <div>
    <ScheduleXCalendar :calendar-app="calendarApp">
      <template v-slot:headerContent>
        <v-row>
          <v-col class="left-header-col" cols="12" xs="12" sm="12" md="12" lg="6" xl="6" xxl="6">
            <PrimarySelect
              :width="100"
              v-model="store.currentView"
              :items="store.getViewOptions()"
              :hide-details="true"
            />
            <v-icon style="margin-left: 1rem; margin-top: 0.5rem" @click="goBackward"
              >mdi-chevron-left</v-icon
            >
            <v-icon style="margin-top: 0.5rem; margin-right: 1rem" @click="goForward"
              >mdi-chevron-right</v-icon
            >
            <ButtonIcon
              :disabled="store.selectedDate === dayjs().format('YYYY-MM-DD')"
              style="margin-top: 0.25rem"
              @click="gotoToday"
              :text="t('buttons.today')"
              icon="mdi-calendar-today"
            />
            <v-menu location="bottom" :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <ButtonIcon
                  style="margin-top: 0.25rem"
                  v-bind="props"
                  :text="t('buttons.filter')"
                  icon="mdi-calendar-filter"
                />
              </template>
              <div class="menu">
                <strong>{{ t('labels.calendar.show') }}:</strong>
                <PrimarySwitch
                  v-model="store.enableFitTests"
                  :label="t('labels.categories.testing')"
                  @update:model-value="onFilterChanged"
                />
                <PrimarySwitch
                  v-model="store.enableClearance"
                  v-if="settingsStore.globals.clearance.enabled"
                  :label="t('labels.categories.clearance')"
                  @update:model-value="onFilterChanged"
                />
                <PrimarySwitch
                  v-model="store.enableTraining"
                  v-if="settingsStore.globals.training.enabled"
                  :label="t('labels.categories.training')"
                  @update:model-value="onFilterChanged"
                />
                <PrimarySwitch
                  v-model="store.enableOpenSlots"
                  :label="t('labels.categories.openSlot')"
                  @update:model-value="onFilterChanged"
                />
              </div>
            </v-menu>
            <ButtonIcon
              style="margin-top: 0.25rem"
              @click="showSubscribe"
              :text="t('buttons.subscribe')"
              icon="mdi-calendar-export"
            />
            <ButtonIcon
              style="margin-top: 0.25rem"
              :text="t('buttons.refreshData')"
              icon="mdi-database-refresh"
              @click="refreshData"
            />
          </v-col>
          <v-col
            class="right-header-col"
            :class="{ 'day-justify': store.currentView === CalendarView.Day }"
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            xxl="6"
          >
            <h3 class="text-header-date">
              {{ store.getDateTitle(settingsStore.locale) }}
            </h3>
          </v-col>
        </v-row>
      </template>
    </ScheduleXCalendar>
    <DialogSubscribe
      v-model="store.showSubscribeDialog"
      :title="t('dialogs.headers.subscribe')"
      @close="hideSubscribe"
    />
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { roundToNearest } from '@/composables/utils'
import { CalendarId } from '@/enums/CalendarId'
import { CalendarView } from '@/enums/CalendarView'
import { EventCategory } from '@/enums/EventCategory'
import { EventStatus } from '@/enums/EventStatus'
import { MessageType } from '@/enums/MessageType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import { EventRecord } from '@/models/EventRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import Colors from '@/styles/_colors.module.scss'
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek
} from '@schedule-x/calendar'
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls'
import { createScrollControllerPlugin } from '@schedule-x/scroll-controller'
import { ScheduleXCalendar } from '@schedule-x/vue'
import dayjs from 'dayjs'
import { debounce, type DebouncedFunc } from 'lodash-es'
import { nextTick, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
let _viewChangePending: DebouncedFunc<any>

const { t } = useI18n({ useScope: 'global' })
const calendarStore = useCalendarStore()
const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()
const store = useCalendarStore()

// Scrolls to the current hour automatically.
const scrollController = createScrollControllerPlugin({
  initialScroll: dayjs().format('HH:00')
})

// Interface to update the calendar from Vue.
const calendarControls = createCalendarControlsPlugin()

// The main calendar configuration. Includes multiple calendar types.
const calendarApp = createCalendar({
  plugins: [calendarControls, scrollController],
  views: [createViewWeek(), createViewDay(), createViewMonthGrid(), createViewMonthAgenda()],
  selectedDate: store.selectedDate,
  locale: settingsStore.localeCode,
  defaultView: store.currentView,
  isResponsive: false,
  skipValidation: true,
  weekOptions: {
    gridHeight: store.gridHeight,
    nDays: store.totalWeekDays
  },
  firstDayOfWeek: store.firstDayOfWeek,
  callbacks: {
    // Fires when an event is clicked.
    onEventClick(calendarEvent) {
      showEventDetails(calendarEvent)
    },
    // Fires when a user clicks a date on the Agenda view.
    onClickAgendaDate(date) {
      store.selectedDate = date
    },
    // Fires when clicking a day in month view.
    onClickDate(date) {
      jumpToDate(date)
    },
    // Fires when clicking somewhere in the time grid of a week or day view.
    onClickDateTime(dateTime) {
      createNewEvent(dateTime)
    },
    // Fires when the calendar first renders.
    onRender($app) {
      const range = $app.calendarState.range.value
      if (range) {
        onCalendarChanged(range)
      }
    },
    onRangeUpdate: (range) => {
      onCalendarChanged(range)
    }
  },
  calendars: {
    [CalendarId.Tentative]: {
      colorName: CalendarId.Tentative,
      lightColors: {
        main: '#afafaf',
        container: '#f7f7f7',
        onContainer: Colors.textPrimary
      }
    },
    [CalendarId.Testing]: {
      colorName: CalendarId.Testing,
      lightColors: {
        main: '#4baaef',
        container: '#edf6fd',
        onContainer: Colors.textPrimary
      }
    },
    [CalendarId.Training]: {
      colorName: CalendarId.Training,
      lightColors: {
        main: '#dc338c',
        container: '#f4e3ec',
        onContainer: Colors.textPrimary
      }
    },
    [CalendarId.Clearance]: {
      colorName: CalendarId.Clearance,
      lightColors: {
        main: '#60e888',
        container: '#e9f6ed',
        onContainer: Colors.textPrimary
      }
    }
  },
  events: []
})

// PROPS & EMITS
const props = defineProps({
  events: {
    type: Array<EventRecord>,
    default: () => {
      return []
    }
  }
})

const emit = defineEmits<{
  (e: 'change', payload: ICalendarRange): void
  (e: 'eventSelected', id: number): void
}>()

// WATCHERS
watch(
  (): any => store.currentView,
  () => {
    if (store.currentView) {
      calendarControls.setView(store.currentView)
    }
  }
)

watch(
  (): any => props.events,
  () => {
    updateCalendar()
  },
  { deep: true }
)

watch(
  (): any => store.selectedDate,
  () => {
    calendarControls.setDate(store.selectedDate)
  }
)

watch(
  (): any => store.firstDayOfWeek,
  () => {
    calendarControls.setFirstDayOfWeek(store.firstDayOfWeek)
    if (store.currentView === CalendarView.Week) {
      refreshWeekView()
    }
  }
)

watch(
  (): any => store.totalWeekDays,
  () => {
    const weekOptions = calendarControls.getWeekOptions()
    weekOptions.nDays = store.totalWeekDays
    calendarControls.setWeekOptions(weekOptions)
    if (store.currentView === CalendarView.Week) {
      refreshWeekView()
    }
  }
)

watch(
  (): any => store.gridHeight,
  () => {
    const weekOptions = calendarControls.getWeekOptions()
    weekOptions.gridHeight = store.gridHeight
    calendarControls.setWeekOptions(weekOptions)
  }
)

watch(
  (): any => settingsStore.localeCode,
  (newValue) => {
    calendarControls.setLocale(newValue)
    nextTick().then(() => {
      updateCalendar()
    })
  }
)

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS
onMounted(() => {
  if (!settingsStore.globals.clearance.enabled) {
    store.enableClearance = false
  }
  if (!settingsStore.globals.training.enabled) {
    store.enableTraining = false
  }
})

// FUNCTIONS
const createNewEvent = (dateTime: string) => {
  const today = new Date()
  const dateValue = new Date(dateTime)
  // Do not allow event creation in the past.
  if (dateValue < today) {
    return
  }
  const pieces = dateTime.split(' ')
  const date = pieces[0]
  let hours = parseInt(pieces[1].substring(0, 2))
  let minutes = roundToNearest(parseInt(pieces[1].substring(3, 5)), 15)
  if (minutes === 60) {
    hours++
    minutes = 0
  }
  const dtStart = new Date(`${date} ${hours}:${minutes}`)
  const eventDuration = calendarStore.testDuration.getMinutes()
  const dtEnd = dayjs(dtStart).add(eventDuration, 'minute').toDate()
  const event = new EventRecord()
  event.dtStart = dtStart.toISOString()
  event.dtEnd = dtEnd.toISOString()
  eventsStore.createNewItem(event)
  eventsStore.showEditDialog
}

const getFilteredEvents = () => {
  return props.events.filter((event: EventRecord) => {
    let showEvent = false
    // Show Fit Tests events
    if (store.enableFitTests && event.category === EventCategory.FitTest) {
      showEvent = true
    }
    // Show Medical Clearance events
    if (store.enableClearance && event.category === EventCategory.MedicalClearance) {
      showEvent = true
    }
    // Show Respirator Training events
    if (store.enableTraining && event.category === EventCategory.Training) {
      showEvent = true
    }
    // Show tentative events for any selected categries
    if (showEvent && event.status === EventStatus.Tentative) {
      showEvent = store.enableOpenSlots
    }
    // If no other categories are selected and enable open slots is slected
    // then show all tentative evetns across all other categories.
    if (
      !store.enableClearance &&
      !store.enableFitTests &&
      !store.enableTraining &&
      store.enableOpenSlots &&
      event.status === EventStatus.Tentative
    ) {
      showEvent = true
    }
    return showEvent
  })
}

const getPeople = (event: EventRecord) => {
  const people = [`${event.organizerFirstName} ${event.organizerLastName}`]
  if (event.attendeeId) {
    people.push(`${event.attendeeFirstName} ${event.attendeeLastName}`)
  }
  if (event.deviceId) {
    people.push(`${event.deviceName} (${event.deviceSerial})`)
  }
  return people
}

const gotoToday = () => {
  store.selectedDate = dayjs().format('YYYY-MM-DD')
}

const goForward = () => {
  store.selectedDate = dayjs(store.selectedDate).add(1, store.getDateUnit()).format('YYYY-MM-DD')
}

const goBackward = () => {
  store.selectedDate = dayjs(store.selectedDate)
    .subtract(1, store.getDateUnit())
    .format('YYYY-MM-DD')
}

const hideSubscribe = () => {
  store.showSubscribeDialog = false
}

const onCalendarChanged = (newRange: ICalendarRange) => {
  store.range = newRange
  emit('change', store.range)
  store.selectedDate = calendarControls.getDate()
  store.currentView = calendarControls.getView()
}

const onFilterChanged = () => {
  updateCalendar()
}

const jumpToDate = (date: string) => {
  calendarControls.setDate(date)
  store.currentView = CalendarView.Day
}

const refreshData = async () => {
  await eventsStore.loadItems()
  snackbarStore.addMessage(t('text.calendarUpdated'), MessageType.Info)
}

const refreshWeekView = () => {
  if (_viewChangePending) {
    _viewChangePending.cancel()
  }
  _viewChangePending = debounce(() => {
    calendarControls.setView(CalendarView.Week)
  }, 750)
  _viewChangePending()
}

const showSubscribe = () => {
  store.showSubscribeDialog = true
}

const showEventDetails = (calendarEvent: any) => {
  emit('eventSelected', calendarEvent.id)
}

/**
 * Update calendar with events based on the current date/time range.
 */
const updateCalendar = () => {
  // Filter by category and open slots.
  const filteredEvents = getFilteredEvents()

  // Convert EventRecords to schedule-x calendar format.
  const calendarEvents = filteredEvents.map((event: EventRecord) => {
    return {
      id: event.id,
      calendarId: store.getCalendarId(event),
      title: store.getEventTitle(event.category, event.status),
      decription: '',
      location: event.location,
      start: dayjs(event.dtStart).format('YYYY-MM-DD HH:mm'),
      end: dayjs(event.dtEnd).format('YYYY-MM-DD HH:mm'),
      people: getPeople(event),
      _options: {
        disableDND: true,
        disableResize: true
      }
    }
  })

  // Update calendar events.
  calendarApp.events.set(calendarEvents as any)
}
</script>

<style lang="scss" scoped>
.day-justify {
  @media screen and (max-width: 1280px) {
    padding-left: 3.75rem !important;
  }
}
.left-header-col {
  margin: 0;
  padding: 0.5rem;
  display: flex;
  align-items: start;
  justify-content: start;

  @media screen and (max-width: 1280px) {
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0 0 0;
  }
}
.right-header-col {
  margin: 0;
  padding: 0.5rem;
  display: flex;
  align-items: end;
  justify-content: end;

  @media screen and (max-width: 1280px) {
    align-items: center;
    justify-content: center;
    padding: 0 0 0.25rem 0;
  }
}
.menu {
  margin: 0.5rem 0 0 0;
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  border: grey 1px solid;
}
.text-header-date {
  font-size: 1rem;
  margin-top: 0.6rem;
}
</style>
