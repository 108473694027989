<template>
  <BaseDialog v-bind="$attrs" v-model="model" :max-height="1000" :icon="icon" :persistent="true">
    <template v-slot:actions>
      <ButtonSecondary
        @click="cancelRevertOrCloseEdit"
        v-if="store.editorMode === EditorMode.Edit"
        :loading="store.workStatus?.save"
        :text="cancelButtonTitle"
      />
      <ButtonSecondary
        v-if="store.editorMode === EditorMode.View"
        @click="closeView"
        :text="t('buttons.close')"
      />
      <v-spacer />
      <span v-if="store.editorMode === EditorMode.Edit && allowEdit" class="required-text">
        * = {{ t('labels.required').toLowerCase() }}
      </span>
      <ButtonAlternate
        v-if="
          store.editorMode === EditorMode.Edit && showDelete && isDefined(store.selectedItem?.id)
        "
        @click="confirmDelete"
        :loading="store.workStatus?.deleteOne"
        :text="t('buttons.delete')"
      />
      <ButtonPrimary
        v-tooltip="{ text: t('text.unsavedChanges') }"
        v-if="store.editorMode === EditorMode.Edit && allowEdit"
        @click="saveItem"
        :disabled="disableSave || !store.isDirty"
        type="submit"
        :loading="store.workStatus?.save"
        :text="t('buttons.save')"
      />
      <ButtonPrimary
        v-if="store.editorMode === EditorMode.View && allowEdit"
        @click="startEditing"
        :text="t('buttons.edit')"
      />
    </template>

    <!-- @vue-skip -->
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { EditorMode } from '@/enums/EditorMode'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed, ref, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const emit = defineEmits<{
  (e: 'cancelEdit', reverted: boolean): void
  (e: 'closeView'): void
  (e: 'saveItem'): void
  (e: 'startEditing'): void
}>()

const props = defineProps({
  allowEdit: { type: Boolean, default: true },
  disableSave: { type: Boolean, default: false },
  showDelete: { type: Boolean, default: false },
  tableStoreId: {
    type: String as PropType<TableStoreId>,
    default: TableStoreId.People
  }
})

// REACTIVE VARIABLES
const model = defineModel<boolean>({ default: false })
let store = ref<ITableStore>(TableStoreFactory.get(props.tableStoreId))

// COMPUTED PROPERTIES
const cancelButtonTitle = computed(() => {
  if (isDefined(store.value.selectedItem?.id)) {
    if (store.value.isDirty) {
      return t('buttons.revert')
    }
    if (store.value.defaultEditorMode === EditorMode.View) {
      return t('buttons.cancel')
    }
    return t('buttons.close')
  }
  return t('buttons.cancel')
})

const icon = computed(() => {
  return store.value.editorMode === EditorMode.View ? 'mdi-information' : 'mdi-pencil'
})

// LIFECYCLE HOOKS

// WATCHERS
watch(
  (): any => props.tableStoreId,
  (value) => {
    if (value) {
      store.value = TableStoreFactory.get(value)
    }
  },
  { deep: true }
)

// FUNCTIONS
const cancelRevertOrCloseEdit = () => {
  let reverted = false
  if (isDefined(store.value.selectedItem?.id)) {
    if (store.value.isDirty) {
      // Revert a change to an existing item.
      store.value.revertEdit()
      reverted = true
    } else {
      // Finish up the edit and close any dialogs.
      store.value.finalizeEdit()
    }
  } else {
    // For new items, finish up the edit and close any dialogs.
    store.value.finalizeEdit()
  }
  emit('cancelEdit', reverted)
}

const closeView = () => {
  store.value.finalizeEdit()
  emit('closeView')
}

const confirmDelete = async () => {
  if (store.value.selectedItem) {
    store.value.confirmDelete(store.value.selectedItem)
  }
}

const saveItem = async () => {
  emit('saveItem')
}

const startEditing = () => {
  store.value.editorMode = EditorMode.Edit
  emit('startEditing')
}
</script>

<style lang="scss" scoped>
.dirty {
  color: red;
}
</style>
