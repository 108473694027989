import type { ITableHeader } from '@/interfaces/ITableHeader'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { type Composer } from 'vue-i18n'
import type { EmailTemplateRecord } from '../EmailTemplateRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  const calendarStore = useCalendarStore()
  return [
    {
      title: i18n.t('fields.emailTemplates.category'),
      key: 'category',
      nowrap: true,
      align: 'start',
      value: (item: EmailTemplateRecord) => {
        return calendarStore.getEventTitle(item.category)
      }
    },
    {
      title: i18n.t('fields.emailTemplates.subject'),
      key: 'subject',
      nowrap: true,
      align: 'start',
      headerProps: {
        searchField: true
      }
    },
    {
      title: i18n.t('fields.emailTemplates.content'),
      key: 'content',
      nowrap: true,
      align: 'start',
      headerProps: {
        hidden: true,
        searchField: true
      }
    },
    {
      title: i18n.t('fields.emailTemplates.enabled'),
      key: 'enabled',
      nowrap: true,
      align: 'start',
      value: (item: EmailTemplateRecord) => {
        return item.enabled ? i18n.t('labels.yes') : i18n.t('labels.no')
      }
    },
    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      nowrap: true,
      sortable: false
    }
  ]
}
