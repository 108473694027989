import { getStoreDefinition } from '@/composables/StoreDefinition'
import { isDefined } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import { MessageType } from '@/enums/MessageType'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreId } from '@/enums/TableStoreId'
import { TableStoreOptions } from '@/enums/TableStoreOptions'
import type { ICollectionResponse } from '@/interfaces/api/ICollectionResponse'
import type { ICustomFieldDefinition } from '@/interfaces/api/ICustomFieldDefinition'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import type { IItemResponse } from '@/interfaces/api/IItemResponse'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { CustomFieldRecord } from '@/models/CustomFieldRecord'
import type { PeopleRecord } from '@/models/PeopleRecord'
import type { TableRecord } from '@/models/TableRecord'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSnackbarStore } from '../ui/SnackbarStore'
import { useAPIStore } from './ApiStore'
import { TableStoreFactory } from './TableStoreFactory'

let _store: ICustomFieldsStore

export const useCustomFieldsStore = () => {
  if (_store) {
    return _store
  }
  const option = TableStoreOptions.CustomFields
  const useStore = defineStore(option.id, getStoreDefinition(option))
  _store = useStore() as unknown as ICustomFieldsStore
  _extend(_store)
  return _store
}

const _extend = (store: ICustomFieldsStore) => {
  const snackbarStore = useSnackbarStore()
  const i18n = useI18n({ useScope: 'global' })
  const apiStore = useAPIStore()
  const peopleStore = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
  const fitTestsStore = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore

  store.allFieldDefinitions = ref<Array<ICustomFieldDefinition>>(
    []
  ) as unknown as Array<ICustomFieldDefinition>
  store.inactiveItems = ref<Array<CustomFieldRecord>>([]) as unknown as Array<CustomFieldRecord>

  // COMPUTED PROPERTIES
  store.activeFieldDefinitions = computed(() => {
    return store.allFieldDefinitions.filter((fieldDefinition) => {
      return fieldDefinition.item?.active === 1
    })
  }) as unknown as Array<ICustomFieldDefinition>

  store.filterableFieldDefinitions = computed(() => {
    return store.allFieldDefinitions.filter((fieldDefinition) => {
      return fieldDefinition.item?.active === 1 && fieldDefinition.item.allowFiltering
    })
  }) as unknown as Array<ICustomFieldDefinition>

  store.disabledFieldDefinitions = computed(() => {
    return store.allFieldDefinitions.filter((fieldDefinition) => {
      return fieldDefinition.item?.enabled === 0
    })
  }) as unknown as Array<ICustomFieldDefinition>

  // FUNCTIONS
  store.addFieldDefinition = (item: CustomFieldRecord) => {
    const fieldDefinition: ICustomFieldDefinition = {
      id: item.id!,
      valueField: item.enabled ? `custom${item.id}Data` : `${item.labelName?.toLowerCase()}`,
      labelField: item.enabled ? `custom${item.id}Label` : undefined,
      autoValues: [],
      selectOptions: item.combo === 0 ? store.getFieldOptions(item) : [],
      item
    }
    fieldDefinition.autoValues = []
    store.allFieldDefinitions.push(fieldDefinition)
  }

  store.loadAutoCompleteValues = async (): Promise<Array<void>> => {
    const promises: Array<Promise<void>> = []
    store.activeFieldDefinitions.forEach((fieldDefinition: ICustomFieldDefinition) => {
      if (fieldDefinition.item?.autoComplete) {
        promises.push(
          store.getAutoCompleteValues(fieldDefinition).then((results) => {
            fieldDefinition.autoValues = results
          })
        )
      }
    })
    return Promise.all(promises)
  }

  // Note the CustomFields endpoint never really deletes, it just sets the item to inactive.
  /**
   * Deactivate a custom field. Note the CustomFields endpoint never
   * really deletes, it just sets the item to inactive.
   * @param showError Flag that is used to suppress any server-side errors.
   */
  store.deleteOne = async (
    item: TableRecord,
    showError: boolean = true
  ): Promise<IItemResponse> => {
    if (!item.enabled) {
      throw Error('You cannot delete disabled CustomFieldRecords.')
    }
    store.workStatus.deleteOne = true

    const definition = store.getFieldDefinition(item.id!)
    const label = definition
      ? definition.valueField[0].toUpperCase() + definition.valueField.slice(1)
      : ''
    const newItem = new CustomFieldRecord()
    newItem.id = item.id
    newItem.required = 0
    newItem.active = 0
    newItem.combo = 0
    newItem.labelName = label

    const response = await apiStore.save(EndPoint.CustomFields, newItem, showError)

    if (!response.error && definition) {
      const query: Record<string, any> = {
        update: {}
      }
      if (definition.labelField) {
        query.update[definition.labelField] = null
      }
      query.update[definition.valueField] = null
      await peopleStore.updateMany(query)
      await fitTestsStore.updateMany(query)
    }
    store.workStatus.deleteOne = false
    store.finalizeDelete()
    await store.loadItems()
    return response
  }

  store.getFieldDefinition = (id: number): ICustomFieldDefinition | undefined => {
    return store.allFieldDefinitions.find((fieldDefinition) => {
      return fieldDefinition.id === id
    })
  }

  /**
   * Gets the collection of all field options if it is type = combo.
   * @param id Item unique identifier.
   * @returns
   */
  store.getFieldOptions = (item: CustomFieldRecord): Array<ISimpleOption> => {
    const options: Array<ISimpleOption> = []
    if (item) {
      const keys = Object.keys(item).filter((key) => {
        return key.startsWith('option')
      })
      keys.forEach((key) => {
        if (item[key]) {
          options.push({ title: item[key], value: item[key] })
        }
      })
    }
    return options
  }

  /**
   * Saves an item to the database.
   * @param showError Flag that is used to supress any server-side errors.
   * @returns
   */
  store.save = async (
    showError: boolean = true,
    skipRefresh: boolean = false,
    closeDialog: boolean = true,
    payload?: Array<TableRecord> | TableRecord
  ): Promise<IItemResponse> => {
    if (!payload) {
      payload = store.selectedItem
    }

    const item = payload as CustomFieldRecord
    let response: IItemResponse = { data: null, error: null }
    if (item.combo === 1) {
      const keys = Object.keys(item).filter((key) => {
        return key.startsWith('option')
      })
      keys.forEach((key) => {
        item[key] = null
      })
    }
    if (item.id) {
      response = await apiStore.save(EndPoint.CustomFields, item, showError)
    } else {
      const nextInactiveItem: CustomFieldRecord = store.inactiveItems[0]
      if (nextInactiveItem) {
        item.active = 1
        item.enabled = 1
        item.id = nextInactiveItem.id
        response = await apiStore.save(EndPoint.CustomFields, item, showError)
      } else {
        snackbarStore.addMessage(i18n.t('errors.noInactiveFields'), MessageType.Error)
      }
    }

    const definition = store.getFieldDefinition(item.id!)
    if (definition?.labelField) {
      const query: Record<string, any> = {
        update: {}
      }
      query.update[definition.labelField] = item.labelName
      await peopleStore.updateMany(query)
      await fitTestsStore.updateMany(query)
    }
    if (!response.error) {
      if (!skipRefresh) {
        await store.loadItems()
      }
      store.finalizeEdit(closeDialog)
    }
    return response
  }

  /**
   * Gets all custom field records from the database. Re-orders them arbirarily.
   * @param showError Flag that is used to supress any server-side errors.
   */
  store.loadItems = async (showError: boolean = true): Promise<void> => {
    store.workStatus.search = true
    const queryOptions: IQueryOptions = {
      orderBy: [{ field: 'id', order: SqlOrder.ASC }]
    }
    const response: ICollectionResponse = await apiStore.getMany(
      EndPoint.CustomFields,
      queryOptions,
      showError
    )
    store.workStatus.search = false

    store.items = []
    store.inactiveItems = []
    store.allFieldDefinitions = []

    // Order as required.
    const indices = [4, 5, 0, 1, 2, 3]
    if (response.data && response.data.results.length > 0) {
      const sortedData: Array<CustomFieldRecord> = []
      indices.forEach((index) => {
        if (response.data?.results[index]) {
          sortedData.push(response.data.results[index] as CustomFieldRecord)
        }
      })

      sortedData.forEach(async (item: CustomFieldRecord) => {
        if (item.active === 1) {
          store.items.push(item as CustomFieldRecord)
        } else {
          store.inactiveItems.push(item as CustomFieldRecord)
        }
        store.addFieldDefinition(item)
      })
      await store.loadAutoCompleteValues()
    }
    store.count = store.items.length
  }

  store.getSanitizedData = (
    customField: CustomFieldRecord | undefined,
    customValue: string | null
  ): string | null => {
    // Nullify disabled fields.
    if (customField?.active !== 1) {
      return null
    }
    // If the component is not a combo no need to sanitize.
    if (customField.combo === 1) {
      return customValue
    }
    // Search select options. If there is not a match return null.
    const fieldDefinition = store.getFieldDefinition(customField.id!)
    let foundOption
    if (fieldDefinition) {
      foundOption = fieldDefinition.selectOptions.find((option: ISimpleOption) => {
        return option.value === customValue
      })
    }
    return isDefined(foundOption) ? customValue : null
  }

  store.getAutoCompleteValues = async (
    definition: ICustomFieldDefinition
  ): Promise<Array<string>> => {
    let values: Array<string> = []
    if (definition.item && definition.item.autoComplete) {
      const response = await apiStore.getMany(EndPoint.People, {
        isDistinct: true,
        fields: [definition.valueField]
      })
      if (response?.data && response?.data?.count > 0) {
        const results: Array<CustomFieldRecord> = response.data.results as Array<CustomFieldRecord>

        values = results
          .filter((customFieldRecord) => {
            return customFieldRecord[definition.valueField] !== null
          })
          .map((customFieldRecord) => {
            return customFieldRecord[definition.valueField]
          })
      }
    }
    return values
  }

  store.sanitizePerson = (item: PeopleRecord) => {
    if (item) {
      store.allFieldDefinitions.forEach(async (fieldDefinition) => {
        if (fieldDefinition.item?.enabled === 1) {
          item[fieldDefinition.valueField] = store.getSanitizedData(
            fieldDefinition.item,
            item[fieldDefinition.valueField]
          )
        }
      })
    }
  }
}
