<template>
  <BaseEditor
    v-bind="$attrs"
    v-model="store.showEditDialog"
    :allow-edit="allowEdit"
    :show-delete="showDelete"
    :table-store-id="TableStoreId.Events"
    :title="title"
    :max-width="435"
    :max-height="getSafeHeight(display, 650)"
    @save-item="saveItem"
  >
    <v-form :fast-fail="false" @submit.prevent ref="editEventForm">
      <v-row>
        <v-col cols="12" class="main">
          <DisplayText
            v-if="isDefined(item.id)"
            :label="t('labels.eventCategory')"
            :value="calendarStore.getEventTitle(item.category, item.status)"
          />
          <PrimarySelect
            v-model="item.category"
            :label="t('labels.eventCategory')"
            :items="calendarStore.getEventCategories(false)"
            @update:model-value="onCategoryChange"
          ></PrimarySelect>
          <DatePicker
            required
            :mode="store.editorMode"
            v-model="startDate"
            :label="t('labels.events.startDate')"
            :value="formatDate(startDate.toISOString(), false, settingsStore.localeCode)"
            :min="minStartDate"
            :max="maxEndDate"
            :rules="
              RulesManager.getRules([
                Rule.Required,
                {
                  key: Rule.ValidDate,
                  payload: {
                    min: minStartDate,
                    max: maxEndDate
                  }
                }
              ])
            "
          />

          <v-container class="ma-0 pa-0" max-width="425px">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                <TimePicker
                  :mode="store.editorMode"
                  v-model="startTime"
                  :label="t('labels.events.startTime')"
                  :twelve-hour-clock="calendarStore.twelveHourClock"
                />
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" xs="12" sm="6" md="3" lg="3" xl="3" xxl="3">
                <TimePicker
                  :mode="store.editorMode"
                  v-model="duration"
                  :label="t('labels.events.duration')"
                  :max-hours="8"
                  :duration-mode="true"
                />
              </v-col>
            </v-row>
          </v-container>
          <PrimaryComboBox
            required
            :mode="store.editorMode"
            style="margin-top: 1rem"
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="selectedAdminOption"
            @update:model-value="onAdministratorChange"
            @blur="onAdministratorBlur"
            :label="t('labels.administrator')"
            :items="administratorOptions"
          />
          <PrimaryComboBox
            required
            :mode="store.editorMode"
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="item.location"
            :label="t('labels.location')"
            :items="locationValues"
          />
          <PrimaryComboBox
            required
            :mode="store.editorMode"
            v-if="item.category === EventCategory.FitTest"
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="selectedDeviceOption"
            :label="t('labels.events.device')"
            :items="deviceOptions"
            @blur="onDeviceBlur"
          />
          <ItemPicker
            :mode="store.editorMode"
            :rules="RulesManager.getRules(Rule.Required)"
            v-model="selectedAttendee"
            :display-value="attendeeTitle"
            :title="t('labels.events.selectAttendee')"
            :label="t('labels.events.attendee')"
            :TableStoreId="TableStoreId.People"
            @change="attendeeChanged"
          />
        </v-col>
      </v-row>
    </v-form>
  </BaseEditor>
</template>

<script setup lang="ts">
let _loadAdminOptionsPending: DebouncedFunc<any> | undefined = undefined

// STORES, IMPORTS, & COMPOSABLES
import { TimeValue } from '@/classes/TimeValue'
import { useRulesManager } from '@/composables/RulesManager'
import { formatDate, getSafeHeight, isDefined } from '@/composables/utils'
import { EventCategory } from '@/enums/EventCategory'
import { EventStatus } from '@/enums/EventStatus'
import { MessageType } from '@/enums/MessageType'
import { Rule } from '@/enums/Rule'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { EventRecord } from '@/models/EventRecord'
import type { TableRecord } from '@/models/TableRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import dayjs from 'dayjs'
import { debounce, type DebouncedFunc } from 'lodash-es'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay, type DisplayInstance } from 'vuetify'
import PrimarySelect from '../inputs/PrimarySelect.vue'

const calendarStore = useCalendarStore()
const devicesStore = TableStoreFactory.get(TableStoreId.Devices) as IDevicesStore
const display: DisplayInstance = useDisplay()
const peopleStore = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
const RulesManager = useRulesManager()
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()
const store = TableStoreFactory.get(TableStoreId.Events) as IEventsStore

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
defineProps({
  showDelete: { type: Boolean, default: false }
})

// REACTIVE VARIABLES
const administratorOptions = ref<Array<ISimpleOption>>([])
const deviceOptions = ref<Array<ISimpleOption>>([])
const duration = ref<TimeValue>()
const editEventForm = ref<any>()
const locationValues = ref<Array<string>>([])

// COMPUTED PROPERTIES
const allowEdit = computed(() => {
  const startDate = dayjs(item.value.dtStart).format('MM/DD/YYYY')
  const today = dayjs().format('MM/DD/YYYY')
  return dayjs(startDate) >= dayjs(today)
})

const attendeeTitle = computed(() => {
  if (item.value.attendeeId) {
    return `${item.value.attendeeLastName}, ${item.value.attendeeFirstName} (${item.value.attendeeIdNumber})`
  }
  return null
})

const item = computed(() => {
  if (!store.selectedItem) {
    return new EventRecord()
  }
  return store.selectedItem as EventRecord
})

const minStartDate = computed(() => {
  return store.getMinStartDate()
})

const maxEndDate = computed(() => {
  return store.getMaxEndDate(startDate.value)
})

const selectedAdminOption = computed({
  get() {
    const administratorOption = administratorOptions.value.find((option) => {
      return option.value === item.value.organizerId
    })
    return administratorOption || null
  },
  set(newValue) {
    if (newValue) {
      item.value.organizerId = newValue.value as number
    }
  }
})

const selectedAttendee = computed(() => {
  if (item.value.attendeeId) {
    return {
      id: item.value.attendeeId,
      firstName: item.value.attendeeFirstName,
      lastName: item.value.attendeeLastName,
      idNumber: item.value.idNumber
    }
  }
  return null
})

const selectedDeviceOption = computed({
  get() {
    const deviceOption = deviceOptions.value.find((option) => {
      return option.value === item.value.deviceId
    })
    return deviceOption || null
  },
  set(newValue) {
    if (newValue) {
      item.value.deviceId = newValue.value as number
    }
  }
})

const startDate = computed({
  get() {
    if (item.value?.dtStart) {
      const dateValue = dayjs(item.value.dtStart).format('MM/DD/YYYY')
      const timeValue = startTime.value.toTimeString()
      return new Date(`${dateValue} ${timeValue}`)
    }
    return new Date()
  },
  set(newValue) {
    if (newValue) {
      const date = newValue.getDate()
      const month = newValue.getMonth() + 1
      const year = newValue.getFullYear()
      const hours = startTime.value.hours
      const minutes = startTime.value.minutes
      item.value.dtStart = new Date(`${month}/${date}/${year} ${hours}: ${minutes}`).toISOString()
    }
  }
})

const startTime = computed({
  get() {
    if (item.value?.dtStart) {
      const date = new Date(item.value.dtStart)
      return new TimeValue(date.getHours(), date.getMinutes())
    }
    return new TimeValue()
  },
  set(newValue) {
    if (newValue) {
      const dtStart = item.value?.dtStart ? new Date(item.value.dtStart) : new Date()
      const date = dtStart.getDate()
      const month = dtStart.getMonth() + 1
      const year = dtStart.getFullYear()
      const hours = startTime.value.hours
      const minutes = startTime.value.minutes
      item.value.dtStart = new Date(`${month}/${date}/${year} ${hours}: ${minutes}`).toISOString()
    }
  }
})

const title = computed((): string => {
  if (item.value?.id) {
    if (allowEdit.value) {
      return t('dialogs.headers.editEvent')
    } else {
      return t('dialogs.headers.viewEvent')
    }
  }
  return t('dialogs.headers.createEvent')
})

// WATCHERS
watch(
  (): any => store.selectedItem,

  (value) => {
    if (value) {
      const startTimeMs = new Date(item.value.dtStart).getTime()
      const endTimeMs = new Date(item.value.dtEnd).getTime()
      const durationMs = endTimeMs - startTimeMs
      duration.value = TimeValue.fromMilliseconds(durationMs)
      loadAdministratorOptions()
      loadDeviceOptions()
      loadLocationValues()
    }
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
const attendeeChanged = (attendee: TableRecord | null) => {
  if (attendee) {
    item.value.attendeeId = attendee.id!
    item.value.attendeeLastName = attendee.lastName
    item.value.attendeeFirstName = attendee.firstName
    item.value.attendeeIdNumber = attendee.idNumber
    item.value.status = EventStatus.Confirmed
  } else {
    item.value.attendeeId = null
    item.value.status = EventStatus.Tentative
  }
}

const getDurationInMs = (value?: TimeValue) => {
  if (value) {
    return value.getMilliseconds()
  }
  return calendarStore.getEventDuration(item.value.category).getMilliseconds()
}

/**
 * Load Administrator options.
 * @returns
 */
const loadAdministratorOptions = async () => {
  let keyword: string
  if (selectedAdminOption.value) {
    if (typeof selectedAdminOption.value === 'string') {
      keyword = selectedAdminOption.value
    } else {
      return
    }
  }

  const waitTime = _loadAdminOptionsPending ? 1000 : 0

  if (_loadAdminOptionsPending) {
    _loadAdminOptionsPending.cancel()
  }

  _loadAdminOptionsPending = debounce(async () => {
    administratorOptions.value = await peopleStore.getAdministratorOptions(keyword)
  }, waitTime)
  _loadAdminOptionsPending()
}

const loadDeviceOptions = async () => {
  deviceOptions.value = await devicesStore.getDeviceOptions()
}

const loadLocationValues = async () => {
  locationValues.value = await store.getLocationValues()
}

const onAdministratorBlur = () => {
  if (typeof selectedAdminOption.value !== 'object') {
    selectedAdminOption.value = null
  }
}

const onAdministratorChange = async () => {
  await loadAdministratorOptions()
}

const onCategoryChange = async () => {
  console.info('onCategoryChange: ', item.value.category)
  switch (item.value.category) {
    case EventCategory.FitTest: {
      duration.value = calendarStore.testDuration
      break
    }
    case EventCategory.MedicalClearance: {
      duration.value = calendarStore.clearanceDuration
      break
    }
    case EventCategory.Training: {
      duration.value = calendarStore.trainingDuration
      break
    }
  }
}

const onDeviceBlur = () => {
  if (typeof selectedDeviceOption.value !== 'object') {
    selectedDeviceOption.value = null
  }
}

const saveItem = async () => {
  const { valid } = await editEventForm.value.validate()
  if (!valid) {
    return
  }

  const startDateMs = startDate.value.getTime()
  const durationMs = getDurationInMs(duration.value)
  const endDateTimeMs = startDateMs + durationMs
  const endDate = new Date(endDateTimeMs)
  item.value.dtEnd = endDate.toISOString()

  if (item.value.category === EventCategory.FitTest && item.value.deviceId) {
    const conflicts = await store.getEventConflictsByDeviceId(
      item.value.deviceId,
      startDate.value,
      endDate
    )
    if (conflicts.length > 0) {
      snackbarStore.addMessage(t('errors.deviceConflict'), MessageType.Error)
      return
    }
  }

  await store.save()
}
</script>

<style lang="scss" scoped>
.max-message {
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
}
.main {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
