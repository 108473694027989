import { toBoolean, toNumber, toText } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { useI18n } from 'vue-i18n'

export const getImportDefinition = (): IImportDefinition => {
  const i18n = useI18n({ useScope: 'global' })
  return {
    endpoint: EndPoint.CustomFields,
    keyFields: ['id'],
    friendlyName: i18n.t('tables.names.customFields'),
    fields: [
      {
        title: i18n.t('fields.id'),
        name: 'id',
        convert: toNumber,
        required: true
      },
      {
        title: i18n.t('fields.customFields.labelName'),
        name: 'labelName',
        convert: toText(20),
        required: true
      },
      {
        title: i18n.t('fields.customFields.required'),
        name: 'required',
        convert: toNumber,
        defaultValue: 0
      },
      {
        title: i18n.t('fields.customFields.enabled'),
        name: 'enabled',
        convert: toNumber,
        defaultValue: 1
      },
      {
        title: i18n.t('fields.customFields.active'),
        name: 'active',
        convert: toNumber,
        defaultValue: 0
      },
      {
        title: i18n.t('fields.customFields.combo'),
        name: 'combo',
        convert: toNumber,
        defaultValue: 0
      },
      {
        title: i18n.t('fields.customFields.option0'),
        name: 'option0',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option1'),
        name: 'option1',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option2'),
        name: 'option2',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option3'),
        name: 'option3',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option4'),
        name: 'option4',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option5'),
        name: 'option5',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option6'),
        name: 'option6',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option7'),
        name: 'option7',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option8'),
        name: 'option8',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.option9'),
        name: 'option9',
        convert: toText(25)
      },
      {
        title: i18n.t('fields.customFields.autoComplete'),
        name: 'autoComplete',
        convert: toBoolean,
        defaultValue: true
      },
      {
        title: i18n.t('fields.customFields.allowFiltering'),
        name: 'allowFiltering',
        convert: toBoolean,
        defaultValue: true,
        aliases: ['Allow Program Status Filtering']
      },
      {
        title: i18n.t('fields.customFields.validation'),
        name: 'validation',
        convert: toText(255)
      }
    ]
  }
}
