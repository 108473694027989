import type { IImportFieldDefinition } from '@/interfaces/IImportFieldDefinition'
import type { ConvertFunction } from '@/types/ConvertFunction'

/**
 * Class that defines a field definition used to map raw import file columns to database table fields.
 */
export class ImportField implements IImportFieldDefinition {
  public name: string | null
  public title: string
  public index: number
  public aliases: Array<string>
  public ignore?: boolean
  public defaultValue?: any
  public required?: boolean
  public key: string
  public convert?: ConvertFunction | undefined

  /**
   * Instantiates a new Import field.
   * @param name The field name stored in the database.
   * @param title The title displayed to the user.
   * @param index The numeric index corresponding to the column in the import file.
   * @param ignore Flag that indicates the field should be ignored. Defaults to false.
   * @param aliases An optional list of field aliases used to map the column.
   * @param defaultValue An optional default value for the column value.
   * @param required An optional flag that indicates the data value is required. Defaults to false.
   * @param convert An optional function used to convert the data value to the proper format.
   */
  constructor(
    name: string | null,
    title: string,
    index: number,
    ignore: boolean = false,
    aliases: Array<string> = [],
    defaultValue?: any,
    required: boolean = false,
    convert?: ConvertFunction
  ) {
    this.name = name
    this.title = title
    this.index = index
    this.ignore = ignore
    this.aliases = aliases
    this.defaultValue = defaultValue
    this.required = required
    this.key = `[${this.index}]`
    this.convert = convert
  }
}
