import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'
import type { MaskRecord } from '../MaskRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  return [
    {
      title: i18n.t('fields.masks.manufacturer'),
      key: 'maskManufacturer',
      align: 'start',
      nowrap: true,
      headerProps: { lookupField: true, searchField: true }
    },
    {
      title: i18n.t('fields.masks.model'),
      key: 'maskModel',
      align: 'start',
      nowrap: true,
      headerProps: { lookupField: true, searchField: true }
    },
    {
      title: i18n.t('fields.masks.style'),
      key: 'maskStyle',
      align: 'start',
      nowrap: true,
      headerProps: { lookupField: true, searchField: true }
    },
    {
      title: i18n.t('fields.masks.n95'),
      key: 'n95',
      align: 'start',
      nowrap: true,
      value: (item: MaskRecord) => `${item.n95 ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    { title: i18n.t('fields.masks.passLevel'), key: 'ffPassLevel', align: 'start', nowrap: true },
    {
      title: i18n.t('fields.masks.approval'),
      key: 'approval',
      align: 'start',
      nowrap: true,
      headerProps: { hidden: true, searchField: true }
    },
    {
      title: i18n.t('fields.masks.maskDescription'),
      key: 'maskDescription',
      align: 'start',
      nowrap: true,
      headerProps: { hidden: true, searchField: true }
    },
    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      nowrap: true,
      sortable: false
    }
  ]
}
