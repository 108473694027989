<template>
  <div>
    <PrimaryTextField
      v-bind="$attrs"
      v-model="model"
      v-if="item?.combo === 1 && !item?.autoComplete"
      :required="item?.required === 1"
      :label="item?.labelName"
      @blur="trimValues"
    >
    </PrimaryTextField>
    <PrimaryComboBox
      v-bind="$attrs"
      v-model="model"
      v-if="item?.combo === 1 && item?.autoComplete"
      :required="item?.required === 1"
      :label="item?.labelName"
      :items="autoValues"
      @blur="trimValues"
    ></PrimaryComboBox>
    <PrimarySelect
      v-bind="$attrs"
      v-model="model"
      v-if="item?.combo === 0"
      :required="item?.required === 1"
      :items="selectOptions"
      :label="item?.labelName"
    >
    </PrimarySelect>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { type PropType } from 'vue'

defineProps({
  item: { type: Object },
  autoValues: {
    type: Array as PropType<Array<string>>,
    default: () => {
      return []
    }
  },
  selectOptions: {
    type: Array as PropType<Array<ISimpleOption>>,
    default: () => {
      return []
    }
  }
})

// PROPS & EMITS

// REACTIVE VARIABLES
const model = defineModel<string>()

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const trimValues = () => {
  if (model.value) {
    model.value = model.value.trim()
  }
}
</script>

<style lang="scss" scoped></style>
