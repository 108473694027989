import { nullifyEmptyStrings } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { StoreProcedure } from '@/enums/StoreProcedure'
import type { ICollectionResponse } from '@/interfaces/api/ICollectionResponse'
import type { IFieldSearchOptions } from '@/interfaces/api/IFieldSearchOptions'
import type { IItemResponse } from '@/interfaces/api/IItemResponse'
import type { IQueryFilter } from '@/interfaces/api/IQueryFilter'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { TableRecord } from '@/models/TableRecord'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import { getAuthInstance } from '@fusion/auth'
import axios from 'axios'
import { defineStore } from 'pinia'

export const useAPIStore = defineStore('api', () => {
  // STORES, IMPORTS, & COMPOSABLES
  const snackbarStore = useSnackbarStore()
  const auth0 = getAuthInstance()
  const http = axios.create({
    baseURL: APP_ENV.VITE_APP_FITPRO_HOST,
    timeout: 60000
  })

  // FUNCTIONS - PRIVATE
  async function _getHTTPHeaders() {
    const token = await auth0.getTokenSilently()
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return headers
  }

  function _getErrorResponse(response: any | string): any {
    if (typeof response === 'string') {
      return new Error(response)
    } else if (response.error) {
      return response.error
    } else {
      return response
    }
  }

  // FUNCTIONS - PUBLIC
  async function deleteOne(
    endpoint: EndPoint,
    id: Number,
    showError: boolean = true
  ): Promise<IItemResponse> {
    const url = `${endpoint}/${id}`
    const headers = await _getHTTPHeaders()
    let response
    try {
      response = await http.delete(url, { headers })
      return { data: response.data, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: null, error: _getErrorResponse(error.response.data) }
    }
  }

  async function deleteMany(
    endpoint: EndPoint,
    queryFilter: Array<IQueryFilter>,
    showError: boolean = true
  ): Promise<ICollectionResponse> {
    const headers = await _getHTTPHeaders()

    try {
      const url = `${endpoint}`
      const response = await http.delete(url, { data: { where: queryFilter }, headers })
      return { data: response.data, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: { results: [], count: 0 }, error: _getErrorResponse(error.response.data) }
    }
  }

  async function execute(
    command: StoreProcedure,
    args: Array<string | boolean | number>,
    showError: boolean = true
  ): Promise<ICollectionResponse> {
    const headers = await _getHTTPHeaders()

    let response
    try {
      const url = EndPoint.Exec
      response = await http.post(url, { command, args }, { headers })
      return { data: { results: response.data?.results, count: response.data.count }, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(EndPoint.Exec, error)
      }
      return { data: { results: [], count: 0 }, error: _getErrorResponse(error.response.data) }
    }
  }

  async function getOne(
    endpoint: EndPoint,
    id: Number,
    fields: Array<string> = [],
    showError: boolean = true
  ): Promise<IItemResponse> {
    let url = `${endpoint}/${id}`
    if (fields.length > 0) {
      url = `${url}?fields=${fields.join(',')}`
    }
    const headers = await _getHTTPHeaders()
    let response
    try {
      response = await http.get(url, { headers })
      nullifyEmptyStrings(response.data, endpoint)
      return { data: response.data, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: null, error: _getErrorResponse(error.response.data) }
    }
  }

  async function getMany(
    endpoint: EndPoint,
    queryOptions?: IQueryOptions,
    showError: boolean = true
  ): Promise<ICollectionResponse> {
    const headers = await _getHTTPHeaders()

    try {
      let response
      if (queryOptions) {
        const url: string = `${endpoint}/query-builder`
        response = await http.post(url, queryOptions, { headers })
        return { data: response.data, error: null }
      } else {
        const url = `${endpoint}`
        response = await http.get(url, { headers })
        nullifyEmptyStrings(response.data, endpoint)
        return { data: response.data, error: null }
      }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: { results: [], count: 0 }, error: _getErrorResponse(error.response.data) }
    }
  }

  async function save(
    endpoint: EndPoint,
    item: TableRecord,
    showError: boolean = true
  ): Promise<IItemResponse> {
    let response
    const headers = await _getHTTPHeaders()
    try {
      nullifyEmptyStrings(item, endpoint)
      if (item.id) {
        const url = `${endpoint}/${item.id}`
        response = await http.patch(url, item, { headers })
      } else {
        const url = `${endpoint}`
        response = await http.post(url, item, { headers })
      }
      return { data: response.data, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: null, error: _getErrorResponse(error.response.data) }
    }
  }

  async function createMany(
    endpoint: EndPoint,
    items: Array<TableRecord>,
    showError: boolean = true
  ): Promise<IItemResponse> {
    let response
    const headers = await _getHTTPHeaders()
    try {
      items.forEach((item) => {
        nullifyEmptyStrings(item, endpoint)
      })
      const url = `${endpoint}`
      response = await http.post(url, items, { headers })
      return { data: response.data, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: null, error: _getErrorResponse(error.response.data) }
    }
  }

  async function search(
    endpoint: EndPoint,
    searchOptions: IFieldSearchOptions,
    showError: boolean = true
  ): Promise<ICollectionResponse> {
    const headers = await _getHTTPHeaders()
    const url: string = `${endpoint}/search`
    try {
      const response = await http.post(url, searchOptions, { headers })
      nullifyEmptyStrings(response.data, endpoint)
      return { data: response.data, error: null }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: { results: [], count: 0 }, error: _getErrorResponse(error.response.data) }
    }
  }

  async function updateMany(
    endpoint: EndPoint,
    queryOptions?: IQueryOptions,
    showError: boolean = true
  ): Promise<ICollectionResponse> {
    const headers = await _getHTTPHeaders()

    try {
      let response
      const url = `${endpoint}`
      if (queryOptions?.update) {
        nullifyEmptyStrings(queryOptions.update, endpoint)
        response = await http.patch(url, queryOptions, { headers })
        return { data: response.data, error: null }
      } else {
        response = await http.patch(url, { headers })
        return { data: response.data, error: null }
      }
    } catch (error: any) {
      if (showError) {
        snackbarStore.addAxiosMessage(endpoint, error)
      }
      return { data: { results: [], count: 0 }, error: _getErrorResponse(error.response.data) }
    }
  }

  return { deleteOne, createMany, deleteMany, execute, getOne, getMany, save, search, updateMany }
})
