import { EndPoint } from './EndPoint'
import { TableStoreId } from './TableStoreId'

/**
 * Collection of Pinia store ids and their corresponding REST endpoints suffix.
 */
export class TableStoreOptions {
  public static AccountSettings = {
    id: TableStoreId.AccountSettings,
    endpoint: EndPoint.AccountSettings
  }

  public static CustomFields = {
    id: TableStoreId.CustomFields,
    endpoint: EndPoint.CustomFields
  }

  public static DailyChecks = {
    id: TableStoreId.DailyChecks,
    endpoint: EndPoint.DailyChecks
  }

  public static Devices = {
    id: TableStoreId.Devices,
    endpoint: EndPoint.Devices
  }

  public static EmailTemplates = {
    id: TableStoreId.EmailTemplates,
    endpoint: EndPoint.EmailTemplates
  }

  public static Events = {
    id: TableStoreId.Events,
    endpoint: EndPoint.Events
  }

  public static Files = {
    id: TableStoreId.Files,
    endpoint: EndPoint.Files
  }

  public static FitTests = {
    id: TableStoreId.FitTests,
    endpoint: EndPoint.FitTests
  }

  public static Masks = {
    id: TableStoreId.Masks,
    endpoint: EndPoint.Masks
  }

  public static MedicalClearances = {
    id: TableStoreId.MedicalClearances,
    endpoint: EndPoint.MedicalClearances
  }

  public static People = {
    id: TableStoreId.People,
    endpoint: EndPoint.People
  }
}
