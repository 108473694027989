/**
 * Flags that indicate the front end is calling the backend. Used to indicate work in the user itnerface.
 */
export class WorkStatus {
  public deleteOne: boolean = false
  public deleteMany: boolean = false
  public getOne: boolean = false
  public getMany: boolean = false
  public save: boolean = false
  public search: boolean = false
  public updateMany: boolean = false
}
