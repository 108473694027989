<template>
  <BaseDialog
    v-model="model"
    v-bind="$attrs"
    :min-height="600"
    :max-height="600"
    :max-width="500"
    icon="mdi-database-search"
  >
    <DataTable
      :height="285"
      :no-data-text="noDataText"
      v-model="selectedItems"
      :table-store-id="tableStoreId"
      :show-new="false"
      :show-select="true"
      :is-lookup="true"
      :show-refresh="false"
    />

    <template v-slot:actions>
      <ButtonSecondary @click="cancelSelection" :text="t('buttons.cancel')" />
      <v-spacer />
      <ButtonAlternate
        :disabled="!selectedItems || selectedItems[0] === null"
        @click="clearSelection"
        :text="t('buttons.clear')"
      />
      <ButtonPrimary
        :disabled="selectedItems?.length === 0 || selectedItems[0] === null"
        @click="saveSelection"
        :text="t('buttons.select')"
      />
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { TableRecord } from '@/models/TableRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { ref, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const model = defineModel<boolean>({ default: false })

const props = defineProps({
  selectedItem: { type: Object as PropType<TableRecord>, default: null },
  tableStoreId: {
    type: String as PropType<TableStoreId>,
    default: TableStoreId.People
  }
})

const store = TableStoreFactory.get(props.tableStoreId) as ITableStore

const emit = defineEmits<{
  (e: 'change', payload: TableRecord | null): void
}>()

// REACTIVE VARIABLES
const noDataText = ref<string>(t('text.pleaseEnterFilter'))
const selectedItems = ref<Array<TableRecord>>([])

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// WATCHERS
watch(
  (): any => store.searchText,
  (value: any) => {
    if (isDefined(value)) {
      noDataText.value = t('text.searching')
    } else {
      noDataText.value = t('text.pleaseEnterFilter')
    }
  }
)

watch(
  (): any => store.items,
  (value: any) => {
    if (isDefined(store.searchText) && value.length === 0) {
      noDataText.value = t('text.noMatchingRecords')
    }
  }
)

// FUNCTIONS
const cancelSelection = () => {
  close()
}

const clearSelection = () => {
  emit('change', null)
  close()
}

const close = () => {
  model.value = false
  store.searchText = ''
}

const saveSelection = () => {
  if (selectedItems.value) {
    emit('change', selectedItems.value[0])
  }
  model.value = false
}
</script>

<style lang="scss" scoped></style>
