const style = `
a {
  color: rgb(0, 174, 239);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* List styles */
ol {
  padding: 0 2rem;
  margin: 0 1rem 0 0.4rem;

  li {
    list-style-type: decimal;
    li {
      list-style-type: lower-alpha;
      li {
        list-style-type: lower-roman;
        li {
          list-style-type: upper-alpha;
          li {
            list-style-type: upper-roman;
          }
        }
      }
    }
  }

  li p {
    margin-top: 0em;
    margin-bottom: 0em;
  }
}

ul {
  padding: 0 2rem;
  margin: 0 1rem 0 0.4rem;

  li {
    list-style-type: disc;
    li {
      list-style-type: circle;
      li {
        list-style-type: square;
        li {
          list-style-type: disc;
          li {
            list-style-type: circle;
            li {
              list-style-type: square;
            }
          }
        }
      }
    }
  }

  li p {
    margin-top: 0em;
    margin-bottom: 0em;
  }
}

.variable {
  cursor: default;
  background-color: #65b9dd;
  color: #fff;
  padding: 0px 6px 1px 6px;
  border-radius: 3px;
  font-size: 1rem;
  display: inline-block;
}
`
export default style
