import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'
import type { CustomFieldRecord } from '../CustomFieldRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  const _fieldNamesMap = [
    i18n.t('fields.people.custom1Data'),
    i18n.t('fields.people.custom2Data'),
    i18n.t('fields.people.custom3Data'),
    i18n.t('fields.people.custom4Data'),
    i18n.t('fields.people.company'),
    i18n.t('fields.people.location')
  ]
  return [
    {
      title: i18n.t('fields.customFields.labelName'),
      key: 'labelName',
      align: 'start',
      nowrap: true,
      sortable: false
    },
    {
      title: i18n.t('fields.customFields.fieldName'),
      key: 'fieldName',
      align: 'start',
      nowrap: true,
      sortable: false,
      value: (item: CustomFieldRecord) => {
        if (item.id) {
          return _fieldNamesMap[item.id - 1]
        }
        return '-'
      }
    },
    {
      title: i18n.t('fields.customFields.required'),
      key: 'required',
      value: (item: CustomFieldRecord) =>
        `${item.required ? i18n.t('labels.yes') : i18n.t('labels.no')}`,
      align: 'start',
      sortable: false,
      nowrap: true
    },
    {
      title: i18n.t('fields.customFields.autoComplete'),
      key: 'autoComplete',
      value: (item: CustomFieldRecord) =>
        `${item.combo === 1 ? (item.autoComplete ? i18n.t('labels.yes') : i18n.t('labels.no')) : i18n.t('labels.notApplicable')}`,
      align: 'start',
      nowrap: true,
      sortable: false
    },
    {
      title: i18n.t('fields.customFields.allowDashboardFiltering'),
      key: 'allowFiltering',
      value: (item: CustomFieldRecord) =>
        `${item.allowFiltering ? i18n.t('labels.yes') : i18n.t('labels.no')}`,
      align: 'start',
      nowrap: true,
      sortable: false
    },
    {
      title: i18n.t('fields.customFields.type'),
      key: 'combo',
      value: (item: CustomFieldRecord) =>
        `${item.combo ? i18n.t('fields.customFields.values.textInput') : i18n.t('fields.customFields.values.dropdown')}`,
      align: 'start',
      sortable: false,
      nowrap: true
    },
    {
      title: i18n.t('fields.customFields.options'),
      key: 'options',
      value: (item: CustomFieldRecord) => {
        const options: Array<string> = []
        const keys = Object.keys(item).filter((key) => {
          return key.startsWith('option')
        })

        keys.forEach((key: string) => {
          if (item[key] && item[key].length > 0) {
            options.push(item[key])
          }
        })
        return options.sort()
      },
      align: 'start',
      sortable: false,
      nowrap: false
    },
    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      nowrap: true,
      sortable: false
    }
  ]
}
