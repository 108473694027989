<template>
  <DataTable
    disable-delete-property="enabled"
    :disableDeleteMessage="t('warnings.customFieldsReadOnly')"
    :disabled-value="0"
    :hide-default-footer="true"
    :table-store-id="TableStoreId.CustomFields"
    :delete-warning="deleteWarning"
    :search-type="SearchType.Query"
    :disable-new="store.items?.length >= MAX_RECORDS"
    :delete-title="t('dialogs.headers.deleteCustomField')"
    @new="createNewItem"
  >
    <template v-slot:item.options="{ value }">
      <div class="options" v-if="value.length > 0">
        <v-chip
          class="ma-1"
          color="primary"
          size="small"
          :model-value="true"
          v-for="(item, index) in value"
          :key="index"
          label
          density="compact"
          >{{ item }}</v-chip
        >
      </div>
      <div v-else>-</div>
    </template>
    <template v-slot:footer>
      <EditCustomField />
    </template>
  </DataTable>
  <p>{{ t('text.maxCustomFields') }}</p>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { SearchType } from '@/enums/SearchType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import { CustomFieldRecord } from '@/models/CustomFieldRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const store = TableStoreFactory.get(TableStoreId.CustomFields) as ICustomFieldsStore

const MAX_RECORDS = 6

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const deleteWarning = computed((): string => {
  if (store.selectedItem?.labelName) {
    return t('dialogs.delete.warning', {
      name: store.selectedItem.labelName
    })
  }
  return t('dialogs.delete.warning')
})

// LIFECYCLE HOOKS

// FUNCTIONS
const createNewItem = () => {
  store.createNewItem(new CustomFieldRecord())
}
</script>

<style lang="scss" scoped>
.options {
  padding: 0rem;
  margin: 0.25rem 0 0.25rem 0;
  max-width: 400px;
}
</style>
