<template>
  <v-container class="ma-0 pa-0" max-width="425px">
    <v-row>
      <v-col cols="12">
        <v-card class="card" :title="t('headers.schedulerSettings.calendar')" variant="outlined">
          <v-container>
            <PrimarySelect
              v-model="store.firstDayOfWeek"
              :label="t('labels.settings.firstDayOfWeek')"
              :items="store.getWeekDayOptions()"
            />
            <NumericField
              required
              :label="t('labels.settings.totalWeekDays')"
              :min="1"
              :max="7"
              v-model="store.totalWeekDays"
            />
            <PrimarySelect
              v-model="store.gridHeight"
              :label="t('labels.settings.eventHeight')"
              :items="store.getGridHeightOptions()"
            />
            <PrimarySwitch
              v-model="store.twelveHourClock"
              :label="t('labels.settings.twelveHourClock')"
            />
            <TimePicker
              required
              :label="t('labels.settings.testingDuration')"
              :max-hours="8"
              v-model="store.testDuration"
              :duration-mode="true"
            />
            <TimePicker
              v-if="settingsStore.globals.clearance.enabled"
              required
              :label="t('labels.settings.clearanceDuration')"
              :max-hours="8"
              v-model="store.clearanceDuration"
              :duration-mode="true"
            />
            <TimePicker
              v-if="settingsStore.globals.training.enabled"
              required
              :label="t('labels.settings.trainingDuration')"
              :max-hours="8"
              v-model="store.trainingDuration"
              :duration-mode="true"
            />
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useI18n } from 'vue-i18n'

// STORES, IMPORTS, & COMPOSABLES
const settingsStore = useSettingsStore()
const store = useCalendarStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.card {
  margin: 1rem 1rem 0 0;
  border-color: $color-dark-16;
}
</style>
