/**
 * Enum of all table store ids.
 */
export enum TableStoreId {
  AccountSettings = 'accountSettings',
  CustomFields = 'customFields',
  DailyChecks = 'dailyChecks',
  Devices = 'devices',
  Events = 'events',
  Files = 'files',
  FitTests = 'fitTests',
  Masks = 'masks',
  MedicalClearances = 'medicalClearances',
  People = 'people'
}
