<template>
  <div>
    <div style="font-weight: bold; margin: 0; padding: 0">{{ t('labels.filterBy') }}</div>
    <v-expansion-panels
      v-bind="$attrs"
      :elevation="0"
      :rounded="false"
      variant="accordion"
      :tile="false"
      :static="true"
    >
      <v-expansion-panel v-for="definition in definitions" :key="definition.id">
        <template v-slot:title>
          <v-checkbox
            :indeterminate="getIndeterminate(definition)"
            density="compact"
            :hide-details="true"
            :model-value="getAllOn(definition)"
            :label="definition.item?.labelName"
            @click.prevent.stop="toggleAllValues(definition)"
          />
        </template>

        <template v-slot:text>
          <div class="scroll-box">
            <v-checkbox
              v-for="(value, index) in getValues(definition)"
              density="compact"
              :hide-details="true"
              :key="index"
              :label="value"
              :model-value="dashboardstore.filters[definition.valueField][value]"
              @click="toggleSingleValue(definition, value)"
            />
          </div>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import type { ICustomFieldDefinition } from '@/interfaces/api/ICustomFieldDefinition'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { useDashboardStore } from '@/stores/ui/DashboardStore'
import { type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const dashboardstore = useDashboardStore()

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
defineProps({
  definitions: { type: Array as PropType<Array<ICustomFieldDefinition>> }
})

const emit = defineEmits<{
  (e: 'change'): void
}>()

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// WATCHERS

// FUNCTIONS
const getAllOn = (definition: ICustomFieldDefinition) => {
  let allOn = false
  const filter = dashboardstore.filters[definition.valueField]
  const values = getValues(definition)
  if (filter && values.length > 0) {
    allOn = !values.some((value) => {
      return filter[value] === false
    })
  }
  return allOn
}

const getAllOff = (definition: ICustomFieldDefinition) => {
  let allOff = true
  const filter = dashboardstore.filters[definition.valueField]
  if (filter) {
    const values = getValues(definition)
    allOff = !values.some((value) => {
      return filter[value] === true
    })
  }
  return allOff
}

const getIndeterminate = (definition: ICustomFieldDefinition) => {
  return !getAllOn(definition) && !getAllOff(definition)
}

const getValues = (definition: ICustomFieldDefinition): Array<string> => {
  if (definition.item?.combo === 0) {
    return definition.selectOptions.map((option: ISimpleOption) => {
      return option.title
    })
  }
  return definition.autoValues
}

const toggleSingleValue = (definition: ICustomFieldDefinition, value: string) => {
  const filter = dashboardstore.filters[definition.valueField]
  if (filter[value]) {
    filter[value] = false
  } else {
    filter[value] = true
  }
  emit('change')
}

const toggleAllValues = (definition: ICustomFieldDefinition) => {
  const filter = dashboardstore.filters[definition.valueField]
  const newValue = getIndeterminate(definition) ? true : !getAllOn(definition)

  const values = getValues(definition)
  values.forEach((value: string) => {
    filter[value] = newValue
  })
  emit('change')
}
</script>

<style lang="scss" scoped>
.scroll-box {
  max-height: 20rem;
  overflow: auto;
  padding: 0;
  margin: 0;
}
</style>
