<template>
  <v-number-input
    style="margin-bottom: 1rem"
    v-maska="_mask"
    v-bind="$attrs"
    v-model="model"
    color="primary"
    variant="underlined"
    control-variant="default"
    inset
    :required="required"
    :min="min"
    :max="max"
    :rules="rules"
  ></v-number-input>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { isDefined } from '@/composables/utils'
import { Rule } from '@/enums/Rule'
import type { IRuleWithPayload } from '@/interfaces/IRuleWithPayload'
import { computed, onMounted, ref, watch } from 'vue'

const RulesManager = useRulesManager()

// PROPS & EMITS
const props = defineProps({
  mask: { type: String },
  min: { type: Number, default: Number.MAX_SAFE_INTEGER },
  max: { type: Number, default: Number.MAX_SAFE_INTEGER },
  required: { type: Boolean, default: false }
})

// REACTIVE VARIABLES
const _mask = ref<string>()
const model = defineModel<number | null | undefined>({ default: 0 })

// COMPUTED PROPERTIES
const rules: any = computed(() => {
  const ruleDefinitions: Array<Rule | IRuleWithPayload> = [
    { key: Rule.ValidNumber, payload: { min: props.min, max: props.max } }
  ]
  if (props.required) {
    ruleDefinitions.push(Rule.Required)
  }
  return RulesManager.getRules(ruleDefinitions)
})

// LIFECYCLE HOOKS
onMounted(() => {
  if (props.mask) {
    _mask.value = props.mask
  } else if (props.max) {
    _mask.value = '#'.repeat(props.max.toString().length)
  }
})

// WATCHERS
watch(
  (): any => model.value,
  (newValue, oldValue) => {
    if (!isDefined(newValue) && props.required) {
      model.value = oldValue
    }
  }
)

// FUNCTIONS
</script>

<style lang="scss" scoped></style>
