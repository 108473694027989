import { WorkStatus } from '@/classes/WorkStatus'
import type { DatabaseView } from '@/enums/DatabaseView'
import { EditorMode } from '@/enums/EditorMode'
import { EndPoint } from '@/enums/EndPoint'
import { HeaderMode } from '@/enums/HeaderMode'
import { SearchType } from '@/enums/SearchType'
import type { ICollectionResponse } from '@/interfaces/api/ICollectionResponse'
import type { IFieldSearchOptions } from '@/interfaces/api/IFieldSearchOptions'
import type { IItemResponse } from '@/interfaces/api/IItemResponse'
import type { IQueryFilter } from '@/interfaces/api/IQueryFilter'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISort } from '@/interfaces/api/ISort'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import type { ITableOptions } from '@/interfaces/ITableOptions'
import type { ITableStoreOption } from '@/interfaces/ITableStoreOption'
import { getDefaultSort, getTableHeaders } from '@/models/tableHeaders'
import { TableRecord } from '@/models/TableRecord'
import { useAPIStore } from '@/stores/db/ApiStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { debounce, isEqual, type DebouncedFunc } from 'lodash-es'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { convertTableSortToOrderBy, isDefined } from './utils'

export const getStoreDefinition: Function = (option: ITableStoreOption) => {
  const tableStoreDefinition = () => {
    // STORES, IMPORTS, & COMPOSABLES
    const apiStore = useAPIStore()
    const settingsStore = useSettingsStore()
    const i18n = useI18n({ useScope: 'global' })

    let _currentHeaderMode = HeaderMode.Filtered
    let _searchPending: DebouncedFunc<any>

    // REACTIVE VARIABLES
    const _allHeaders = ref<Array<ITableHeader>>([])
    const _backupItem = ref<TableRecord>()
    const _selectedItem = ref<TableRecord>()
    const advancedFilter = ref<Record<string, string | number | boolean | null>>({})
    const advancedFilterApplied = ref<boolean>(false)
    const count = ref<number>(0)
    const defaultEditorMode = ref<EditorMode>(EditorMode.Edit)
    const editorMode = ref<EditorMode>(defaultEditorMode.value)
    const expandedItems = ref<Array<TableRecord>>([])
    const infoMessage = ref<string>()
    const infoTitle = ref<string>()
    const items = ref<Array<TableRecord>>([])
    const itemsPerPage = ref<number>(10)
    const searchText = ref<string>('')
    const searchType = ref<SearchType>(SearchType.FieldSearch)
    const showDeleteDialog = ref<boolean>(false)
    const showEditDialog = ref<boolean>(false)
    const showInfoDialog = ref<boolean>(false)
    const _view = ref<string>()
    const workStatus = ref<WorkStatus>(new WorkStatus())

    const itemQuery = ref<IQueryOptions | IFieldSearchOptions>({
      where: []
    })

    // COMPUTED PROPERTIES
    const backupItem = computed(() => {
      return _backupItem.value
    })

    const defaultSort = computed((): Array<ISort> => {
      return getDefaultSort(option.id)
    })

    const isDirty = computed(() => {
      return !isEqual({ ..._backupItem.value }, { ..._selectedItem.value })
    })

    const selectedItem = computed(() => {
      return _selectedItem.value
    })

    const searchFields = computed(() => {
      return getHeaders(_currentHeaderMode)
        .filter((header) => {
          return header.headerProps?.searchField === true
        })
        .map((header) => {
          return header.key
        })
    })

    // WATCHERS
    watch(
      (): any => advancedFilter,
      () => {
        advancedFilterApplied.value = !isEqual(advancedFilter.value, {})
      },
      { deep: true }
    )

    // FUNCTIONS
    const cancelLoad = (clear: boolean = false) => {
      if (_searchPending) {
        _searchPending.cancel()
      }
      if (clear) {
        items.value = []
        count.value = 0
      }
    }

    const finalizeDelete = () => {
      showDeleteDialog.value = false
    }

    const finalizeEdit = (closeDialog: boolean = true) => {
      if (closeDialog) {
        showEditDialog.value = false
      }
      _backupItem.value = { ..._selectedItem.value }
      editorMode.value = defaultEditorMode.value
    }

    const clearMissingColumns = async () => {
      const activeHeaderKeys = getHeaders(HeaderMode.Filtered).map((header) => {
        return header.key
      })
      const filterKeys = Object.keys(advancedFilter.value)
      filterKeys.forEach((filterKey) => {
        if (activeHeaderKeys.indexOf(filterKey) === -1) {
          delete advancedFilter.value[filterKey]
        }
      })
      return loadItems()
    }

    const clearSelectedItem = () => {
      _backupItem.value = undefined
      _selectedItem.value = undefined
    }

    const confirmDelete = (item: TableRecord) => {
      _setSelectedItem(item)
      showDeleteDialog.value = true
    }

    const createNewItem = (item: TableRecord) => {
      _setSelectedItem(item)
      showEditDialog.value = true
    }

    const deleteOne = async (
      item: TableRecord | undefined,
      showError: boolean = true
    ): Promise<IItemResponse | undefined> => {
      if (item?.id) {
        workStatus.value.deleteOne = true
        const response = await apiStore.deleteOne(option.endpoint, item.id, showError)
        workStatus.value.deleteOne = false
        await loadItems()
        finalizeDelete()
        return response
      }
    }

    const deleteMany = async (
      where: Array<IQueryFilter>,
      showError: boolean = true
    ): Promise<IItemResponse | undefined> => {
      workStatus.value.deleteMany = true
      const response = await apiStore.deleteMany(option.endpoint, where, showError)
      workStatus.value.deleteMany = false
      await loadItems()
      return response
    }

    const editItem = (item: TableRecord, showDialog: boolean = true) => {
      _setSelectedItem(item)
      if (showDialog) {
        showEditDialog.value = true
      }
    }

    const getOne = async (
      id: number,
      fields: Array<string> = [],
      showError: boolean = true
    ): Promise<IItemResponse> => {
      workStatus.value.getOne = true
      const response = await apiStore.getOne(option.endpoint, id, fields, showError)
      workStatus.value.getOne = false
      return response
    }

    const _parseFieldSearch = (tableOptions: ITableOptions) => {
      const fieldSearchOptions: IFieldSearchOptions = {
        currentPage: tableOptions.page,
        itemsPerPage: tableOptions.itemsPerPage,
        fields: searchFields.value,
        text: tableOptions.search,
        orderBy: convertTableSortToOrderBy(tableOptions.sortBy)
      }
      return fieldSearchOptions
    }

    const _parseQuery = (tableOptions: ITableOptions) => {
      const queryOptions: IQueryOptions = {
        where: itemQuery.value.where,
        limit: tableOptions.itemsPerPage,
        offset: Math.floor(tableOptions.itemsPerPage * (tableOptions?.page - 1)),
        orderBy: convertTableSortToOrderBy(tableOptions.sortBy)
      }
      return queryOptions
    }

    const getHeaders = (mode: HeaderMode = HeaderMode.Filtered) => {
      _currentHeaderMode = mode
      if (mode === HeaderMode.All) {
        return _allHeaders.value
      }
      if (mode === HeaderMode.Lookup) {
        return _allHeaders.value.filter((header: ITableHeader) => {
          return header.headerProps?.lookupField === true && !header.headerProps?.hidden
        })
      }
      return _allHeaders.value.filter((header: ITableHeader) => {
        if (header.headerProps?.hidden) {
          return false
        }
        if (header.key === 'actions' || !settingsStore.disabledHeaders[option.id]) {
          return true
        }
        return !settingsStore.disabledHeaders[option.id].includes(header.key)
      })
    }

    const hideInfo = () => {
      infoTitle.value = undefined
      infoMessage.value = undefined
    }

    const loadItems = async (
      defer: boolean = false,
      tableOptions?: ITableOptions,
      showError: boolean = true
    ): Promise<void> => {
      let response: ICollectionResponse = {
        data: {
          results: [],
          count: 0
        },
        error: null
      }

      if (_searchPending) {
        _searchPending.cancel()
      }

      // Substitute the view if it is defined on the store.
      const endpoint = isDefined(_view.value) ? EndPoint.Views : option.endpoint

      let searchMethod = null
      if (searchType.value === SearchType.FieldSearch) {
        workStatus.value.search = true
        if (tableOptions) {
          itemQuery.value = _parseFieldSearch(tableOptions)
        }
        searchMethod = apiStore.search
      } else {
        workStatus.value.getMany = true
        if (tableOptions) {
          itemQuery.value = _parseQuery(tableOptions)
        }
        searchMethod = apiStore.getMany
      }
      itemQuery.value.view = _view.value

      if (defer) {
        _searchPending = debounce(async () => {
          response = await searchMethod(endpoint, itemQuery.value as IFieldSearchOptions, showError)
          items.value = (response.data?.results as Array<TableRecord>) || []
          count.value = response.data?.count || 0
        }, 750)
        _searchPending()
      } else {
        response = await searchMethod(endpoint, itemQuery.value as IFieldSearchOptions, showError)
        items.value = (response.data?.results as Array<TableRecord>) || []
        count.value = response.data?.count || 0
      }
      workStatus.value.search = false
      workStatus.value.getMany = false
    }

    const getHeader = (key: string): ITableHeader | undefined => {
      return _allHeaders.value.find((header) => {
        return header.key === key
      })
    }

    const resetAdvancedFilter = async () => {
      if (advancedFilterApplied.value) {
        advancedFilter.value = {}
        itemQuery.value.where = []
        await loadItems()
      }
    }

    const revertEdit = async () => {
      _selectedItem.value = { ..._backupItem.value }
    }

    const save = async (
      showError: boolean = true,
      skipRefresh: boolean = false,
      closeDialog: boolean = true,
      payload?: TableRecord | Array<TableRecord>
    ): Promise<IItemResponse | undefined> => {
      if (!payload) {
        payload = selectedItem.value
      }
      if (payload) {
        workStatus.value.save = true
        const response = await apiStore.save(option.endpoint, payload, showError)
        if (!response.error) {
          if (!skipRefresh) {
            await loadItems()
          }
          finalizeEdit(closeDialog)
        }
        workStatus.value.save = false
        return response
      }
    }

    const _setSelectedItem = (item: TableRecord | undefined) => {
      _backupItem.value = item
      _selectedItem.value = { ...item }
    }

    const showInfo = (title: string, message: string) => {
      infoTitle.value = title
      infoMessage.value = message
      showInfoDialog.value = true
    }

    const updateHeaders = async () => {
      _allHeaders.value = await getTableHeaders(option.id, i18n)
    }

    const updateMany = async (
      queryOptions?: IQueryOptions,
      showError: boolean = true
    ): Promise<ICollectionResponse> => {
      workStatus.value.updateMany = true
      const response = await apiStore.updateMany(option.endpoint, queryOptions, showError)
      workStatus.value.updateMany = false
      return response
    }

    const useView = (view: DatabaseView) => {
      _view.value = view
    }

    const state = {
      cancelLoad,
      clearMissingColumns,
      clearSelectedItem,
      confirmDelete,
      createNewItem,
      deleteOne,
      deleteMany,
      editItem,
      finalizeDelete,
      finalizeEdit,
      getOne,
      getHeader,
      getHeaders,
      hideInfo,
      loadItems,
      resetAdvancedFilter,
      revertEdit,
      save,
      showInfo,
      updateHeaders,
      updateMany,
      useView,
      advancedFilter,
      advancedFilterApplied,
      backupItem,
      count,
      defaultEditorMode,
      defaultSort,
      editorMode,
      expandedItems,
      infoMessage,
      infoTitle,
      isDirty,
      itemQuery,
      items,
      itemsPerPage,
      searchFields,
      searchText,
      searchType,
      selectedItem,
      showDeleteDialog,
      showEditDialog,
      showInfoDialog,
      workStatus
    }
    return state
  }
  return tableStoreDefinition
}
