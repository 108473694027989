<template>
  <div>
    <Editor ref="emailEditor" id="emailEditor" v-model="model" :init="editorOptions" />
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import editorStyle from '@/components/richText/RichTextEditorStyles'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import Editor from '@tinymce/tinymce-vue'
import tinymce, { type RawEditorOptions } from 'tinymce'
import 'tinymce/icons/default/icons.min.js'
import 'tinymce/models/dom/model.min.js'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/skins/content/default/content.js'
import 'tinymce/skins/ui/oxide/content.js'
import 'tinymce/skins/ui/oxide/skin.js'
import 'tinymce/themes/silver/theme.min.js'
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { loadLocales } from './RichTextEditorLocales'
import './VariablesPlugin'

const settingsStore = useSettingsStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const model = defineModel<string>({ default: '' })
const emailEditor = ref<any>()

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const editorOptions: RawEditorOptions = computed((): RawEditorOptions => {
  return {
    id: 'emailEditor',
    api_key: 'gpl',
    branding: false,
    content_style: editorStyle,
    highlight_on_focus: true,
    language: settingsStore.locale.code,
    licensekey: 'gpl',
    menu: {
      format: {
        title: 'Format',
        items: 'blocks | forecolor backcolor | removeformat'
      }
    },
    menubar: 'file edit format link table',
    plugins: 'lists link table variable',
    promotion: false,
    resize: false,
    toolbar:
      'undo redo bold italic underline strikethrough align | superscript subscript bullist numlist blockquote link addVariable',
    variables: [
      { key: 'appointment', value: t('editor.appointment') },
      { key: 'appointmentTime', value: t('editor.appointmentTime') },
      { key: 'category', value: t('editor.category') },
      { key: 'company', value: t('editor.company') },
      { key: 'firstName', value: t('editor.firstName') },
      { key: 'lastName', value: t('editor.lastName') },
      { key: 'location', value: t('editor.location') },
      { key: 'testDueDate', value: t('editor.testDueDate') },
      { key: 'trainingExpiration', value: t('editor.trainingExpiration') },
      { key: 'clearanceExpiration', value: t('editor.clearanceExpiration') },
      { key: 'selfSchedulingLink', value: t('editor.selfSchedulingLink') },
      { key: 'fitTestStatus', value: t('editor.fitTestStatus') },
      { key: 'trainingStatus', value: t('editor.trainingStatus') },
      { key: 'medicalClearanceStatus', value: t('editor.medicalClearanceStatus') }
    ],
    setup: (editor) => {
      editor.on('init', () => {
        setHeight()
      })
    }
  }
})

// LIFECYCLE HOOKS
onBeforeMount(() => {
  window.addEventListener('resize', setHeight)
  loadLocales()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setHeight)
})

// WATCHERS
watch(
  (): any => settingsStore.localeCode,

  () => {
    if (tinymce.activeEditor) {
      emailEditor.value.rerender(editorOptions.value)
    }
  }
)

// FUNCTIONS d
const setHeight = () => {
  const offset = 425
  const minHeight = 225
  if (tinymce.activeEditor) {
    let height = window.innerHeight ? window.innerHeight : document.body.clientHeight
    let styleHeight = ''
    if (height - offset < minHeight) {
      styleHeight = `%{minHeight}px`
    } else {
      styleHeight = `${height - offset}px`
    }
    tinymce.activeEditor.editorContainer.style.height = styleHeight
  }
}
</script>

<style lang="scss" scoped></style>
