import { TimeValue } from '@/classes/TimeValue'
import { CalendarId } from '@/enums/CalendarId'
import { CalendarView } from '@/enums/CalendarView'
import { EventCategory } from '@/enums/EventCategory'
import { EventStatus } from '@/enums/EventStatus'
import type { LocaleDefinition } from '@/enums/LocaleDefinition'
import { StoreId } from '@/enums/StoreId'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import type { EventRecord } from '@/models/EventRecord'
import dayjs, { type ManipulateType } from 'dayjs'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from './SettingsStore'

export const useCalendarStore = defineStore(
  StoreId.Calendar,
  () => {
    // STORES, IMPORTS, & COMPOSABLES
    const i18n = useI18n({ useScope: 'global' })
    const settingsStore = useSettingsStore()

    // REACTIVE VARIABLES
    const clearanceDuration = ref<TimeValue>(new TimeValue(0, 30))
    const currentView = ref<string>()
    const enableClearance = ref<boolean>(true)
    const enableFitTests = ref<boolean>(true)
    const enableTraining = ref<boolean>(true)
    const enableOpenSlots = ref<boolean>(true)
    const firstDayOfWeek = ref<number>(1)
    const gridHeight = ref<number>(1500)
    const range = ref<ICalendarRange>()
    const testDuration = ref<TimeValue>(new TimeValue(0, 30))
    const totalWeekDays = ref<number>(7)
    const trainingDuration = ref<TimeValue>(new TimeValue(0, 15))
    const twelveHourClock = ref<boolean>(false)
    const selectedDate = ref<string>(dayjs().format('YYYY-MM-DD'))
    const showSubscribeDialog = ref<boolean>(false)

    // WATCHERS

    // COMPUTED
    const selectedDateValue = computed({
      get() {
        return dayjs(selectedDate.value).toDate()
      },
      set(newValue) {
        selectedDate.value = dayjs(newValue).format('YYYY-MM-DD')
      }
    })

    // FUNCTIONS
    /**
     * Get the calendar identifier based on event status and the category.
     * @param event An event instance.
     * @returns
     */
    const getCalendarId = (event: EventRecord): CalendarId => {
      if (event.status === EventStatus.Tentative) {
        return CalendarId.Tentative
      }
      switch (event.category) {
        case EventCategory.FitTest: {
          return CalendarId.Testing
        }
        case EventCategory.MedicalClearance: {
          return CalendarId.Clearance
        }
        case EventCategory.Training: {
          return CalendarId.Training
        }
      }
      return CalendarId.Unknown
    }

    /**
     * Get the full date title based on the locale.
     * @param locale The current locale.
     * @returns
     */
    const getDateTitle = (locale?: LocaleDefinition) => {
      if (!locale) {
        return ''
      }
      switch (currentView.value) {
        case CalendarView.Agenda:
        case CalendarView.Week:
        case CalendarView.Month: {
          if (range.value) {
            return `${dayjs(range.value.start).format('ll')} ${i18n.t('labels.to')} ${dayjs(range.value.end).format('ll')}`
          }
          return ''
        }
        case CalendarView.Day: {
          const date = dayjs(selectedDate.value)
          const month = date.month()
          const year = date.year()
          const months = date.localeData().monthsShort()
          const shortMonth = months[month]
          return `${shortMonth} ${year}`
        }
        default: {
          return ''
        }
      }
    }

    const getEventCategories = (includeAll: boolean = false) => {
      const categories = [
        { title: i18n.t('labels.categories.testing'), value: EventCategory.FitTest }
      ]
      if (settingsStore.globals.clearance.enabled) {
        categories.push({
          title: i18n.t('labels.categories.clearance'),
          value: EventCategory.MedicalClearance
        })
      }
      if (settingsStore.globals.training.enabled) {
        categories.push({
          title: i18n.t('labels.categories.training'),
          value: EventCategory.Training
        })
      }
      if (includeAll) {
        categories.unshift({
          title: i18n.t('labels.categories.all'),
          value: EventCategory.All
        })
      }
      return categories
    }

    /**
     * Gets the duration of the event based on the category.
     * If no category is provided then return 15 minutes.
     * @param category The event category.
     * @returns
     */
    const getEventDuration = (category?: EventCategory): TimeValue => {
      switch (category) {
        case EventCategory.FitTest: {
          return testDuration.value
        }
        case EventCategory.MedicalClearance: {
          return clearanceDuration.value
        }
        case EventCategory.Training: {
          return trainingDuration.value
        }
      }
      return new TimeValue(0, 15)
    }

    /**
     * Get the event title based on the event category.
     * @param event An event instance.
     * @returns
     */
    const getEventTitle = (category: EventCategory, status?: EventStatus): string => {
      let title = ''
      switch (category) {
        case EventCategory.FitTest: {
          title = i18n.t('labels.categories.testing')
          break
        }
        case EventCategory.MedicalClearance: {
          title = i18n.t('labels.categories.clearance')
          break
        }
        case EventCategory.Training: {
          title = i18n.t('labels.categories.training')
          break
        }
      }
      if (status === EventStatus.Tentative) {
        title += ` (${i18n.t('labels.categories.openSlot')})`
      }
      return title
    }

    const getDateUnit = (): ManipulateType | undefined => {
      if (currentView.value === CalendarView.Agenda || currentView.value === CalendarView.Month) {
        return 'month'
      }
      return currentView.value as ManipulateType
    }

    /**
     * Options used to populate Grid height selector.
     * @returns
     */
    const getGridHeightOptions = () => {
      return [
        { title: i18n.t('labels.settings.sizeOptions.small'), value: 1000 },
        { title: i18n.t('labels.settings.sizeOptions.medium'), value: 1500 },
        { title: i18n.t('labels.settings.sizeOptions.large'), value: 3800 },
        { title: i18n.t('labels.settings.sizeOptions.extraLarge'), value: 5700 },
        { title: i18n.t('labels.settings.sizeOptions.largest'), value: 8000 }
      ]
    }

    /**
     * Options used to populate weekday selector.
     * @returns
     */
    const getWeekDayOptions = () => {
      const valueMap = [7, 1, 2, 3, 4, 5, 6]
      const weekdays = dayjs.localeData().weekdays()
      return weekdays.map((weekday, index) => {
        return { title: weekday, value: valueMap[index] }
      })
    }

    /**
     * Calculate view options for the Calendar View Selector.
     */
    const getViewOptions = () => {
      return [
        { title: i18n.t('labels.calendar.day'), value: CalendarView.Day },
        { title: i18n.t('labels.calendar.week'), value: CalendarView.Week },
        { title: i18n.t('labels.calendar.month'), value: CalendarView.Month },
        { title: i18n.t('labels.calendar.agenda'), value: CalendarView.Agenda }
      ]
    }

    return {
      clearanceDuration,
      currentView,
      enableClearance,
      enableFitTests,
      enableTraining,
      enableOpenSlots,
      firstDayOfWeek,
      gridHeight,
      range,
      selectedDate,
      selectedDateValue,
      showSubscribeDialog,
      testDuration,
      totalWeekDays,
      trainingDuration,
      twelveHourClock,
      getCalendarId,
      getDateTitle,
      getDateUnit,
      getEventCategories,
      getEventDuration,
      getEventTitle,
      getGridHeightOptions,
      getWeekDayOptions,
      getViewOptions
    }
  },
  {
    persist: {
      storage: localStorage,
      pick: [
        'currentView',
        'clearanceDuration',
        'enableClearance',
        'enableFitTests',
        'enableTraining',
        'enableOpenSlots',
        'firstDayOfWeek',
        'gridHeight',
        'selectedDate',
        'testDuration',
        'totalWeekDays',
        'trainingDuration',
        'twelveHourClock'
      ]
    }
  }
)
