<template>
  <BaseEditor
    v-bind="$attrs"
    v-model="store.showEditDialog"
    :table-store-id="TableStoreId.FitTests"
    :title="t('dialogs.headers.fitTest')"
    @save-item="saveItem"
    :max-width="850"
    :min-height="getSafeHeight(display, 605)"
    :max-height="getSafeHeight(display, 605)"
    :allow-edit="permissionsManager.flags.allowEditFitTests"
  >
    <template v-if="store.selectedItem !== undefined" v-slot:status>
      <FitTestStatus v-model="store.selectedItem.overallPass" />
    </template>
    <v-form :fast-fail="false" @submit.prevent ref="editFitTestForm">
      <FitTestForm />
    </v-form>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { getSafeHeight } from '@/composables/utils'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { getPermissionsInstance } from '@fusion/auth'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay, type DisplayInstance } from 'vuetify'
import FitTestForm from '../forms/FitTestForm.vue'

const store = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore
const display: DisplayInstance = useDisplay()
const peopleStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
const permissionsManager = getPermissionsInstance()

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const editFitTestForm = ref<any>()

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const saveItem = async () => {
  const { valid } = await editFitTestForm.value.validate()
  if (!valid) {
    return
  }

  const response = await store.save()
  if (!response.error) {
    peopleStore.loadItems()
  }
}
</script>

<style lang="scss" scoped></style>
