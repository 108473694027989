<template>
  <v-container class="calendar" fluid>
    <v-row>
      <v-col>
        <CalendarEvents
          @change="updateEvents"
          @event-selected="showEventDetails"
          :events="eventsStore.items"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { EditorMode } from '@/enums/EditorMode'
import { MessageType } from '@/enums/MessageType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import '@schedule-x/theme-default/dist/index.css'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

// STORES, IMPORTS, & COMPOSABLES
const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore
const snackbarStore = useSnackbarStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const updateEvents = (range: ICalendarRange) => {
  eventsStore.loadEventsByRange(range)
}

const showEventDetails = (eventId: number) => {
  const event = eventsStore.items.find((item) => {
    return item.id === eventId
  })
  if (event) {
    const startDate = dayjs(event.dtStart).format('MM/DD/YYYY')
    const today = dayjs().format('MM/DD/YYYY')
    eventsStore.editorMode = dayjs(startDate) >= dayjs(today) ? EditorMode.Edit : EditorMode.View
    eventsStore.editItem(event)
    eventsStore.showEditDialog = true
  } else {
    snackbarStore.addMessage(t('warnings.cannotFindEvent'), MessageType.Warning)
  }
}
</script>

<style lang="scss" scoped>
.calendar {
  margin: 0 0 0 0;
  padding: 0.5rem 0 0 0;
}
</style>
